import { GetIssueAccessDocument, GetIssueAccessQuery, GetMeDocument, GetMeQuery } from '@/generated/graphql';
import { client } from '@/urql';
import { RouteLocationNormalized } from 'vue-router';
import { tokenService } from './tokenService';
import { useSignin } from './useSignin';
import { useAuthStore } from './useAuthStore';
import { useAccessControl } from './useAccessControl';

export enum SpecialAuthPaths {
  OIDC_SIGNIN = '/oidc-signin',
  LOGIN = '/login',
  HOME = '/home',
  DELEGATIONS = '/delegations',
  ISSUE_PROCESS = '/issues/:issueId/process',
  ISSUE_PROCESS_BY_YEAR = '/issues/:issueYear/:issueYearOrder/process',
  NOT_FOUND = '/not_found',
  USER_SETTINGS = '/preferences',
}

export const authMiddleware = {
  routeGuards: async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    if (to.path === SpecialAuthPaths.OIDC_SIGNIN) {
      const token = to.query['token']?.toString();
      const state = to.query['state']?.toString();
      if (token && state) {
        tokenService.saveToken(token);

        const { data, error } = await client.query<GetMeQuery>(GetMeDocument, {}).toPromise();
        if (error || !data?.me) {
          return SpecialAuthPaths.LOGIN;
        }

        const oauthState = localStorage.getItem(state) as string;
        const from = JSON.parse(oauthState)?.location || '/';
        localStorage.removeItem(oauthState);

        if (!data.me.email) {
          return `${SpecialAuthPaths.USER_SETTINGS}?from=${encodeURIComponent(from)}`;
        }

        if (data.me.delegations?.length) {
          return `${SpecialAuthPaths.DELEGATIONS}?from=${encodeURIComponent(from)}`;
        }

        return from;
      } else {
        return SpecialAuthPaths.LOGIN;
      }
    }

    const signin = useSignin();
    await signin.login();

    const authStore = useAuthStore();

    if (authStore.isAuthed && to.path === SpecialAuthPaths.LOGIN) {
      return SpecialAuthPaths.HOME;
    }

    const { requiredRoles = [] } = to.meta;

    if (requiredRoles?.length && !requiredRoles.includes(authStore.activeRole || '')) {
      return from;
    }

    const ac = useAccessControl();
    if (
      !authStore.isAdmin &&
      to.matched?.length === 1 &&
      [SpecialAuthPaths.ISSUE_PROCESS, SpecialAuthPaths.ISSUE_PROCESS_BY_YEAR].includes(to.matched[0].path as SpecialAuthPaths)
    ) {
      const { issueId, issueYear, issueYearOrder } = to.params;
      const input = issueId ? { issueId } : { issueYearOrderInput: { year: issueYear, order: issueYearOrder } };
      const { data, error } = await client.query<GetIssueAccessQuery>(GetIssueAccessDocument, { input }).toPromise();
      if (error || !data?.singleIssue) {
        return SpecialAuthPaths.NOT_FOUND;
      }

      return ac.canEditIssue(data.singleIssue) || SpecialAuthPaths.NOT_FOUND;
    }
  },
};

export default authMiddleware;
