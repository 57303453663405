<script lang="ts" setup>
import { User } from '@/features/auth/models';
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import DataTable from '@/features/theme/dataTables';
import { DataTableConfig } from '@/features/theme/dataTables/dataTableTypes';
import { computed, h } from 'vue';
import { useI18n } from 'vue-i18n';
import { extend } from '@vue/shared';

const props = defineProps<{
  users: User[];
  selected?: Maybe<User>;
}>();

const emits = defineEmits<{
  (e: 'select', user: User): void;
  (e: 'create'): void;
  (e: 'edit'): void;
  (e: 'delegations'): void;
}>();

type UserItem = Pick<User, 'id' | 'fullName' | 'nationalId' | 'email' | 'role'> & {
  hasDelegations: string;
};

const { t } = useI18n();
const usersDataset = computed(() => {
  return props.users.map((user) => ({
    id: user.id,
    fullName: user.fullName,
    nationalId: user.nationalId,
    email: user.email,
    role: t(`role.${user.role}`),
    hasDelegations: user.delegations.length,
  }));
});

const onDelegationsClick = () => {
  emits('delegations');
}

const userDataTableConfig: Partial<DataTableConfig<UserItem>> = {
  headers: {
    id: {
      hidden: true,
    },
    fullName: {
      formatter: 'base.name',
    },
    nationalId: {
      formatter: 'base.ssn',
    },
    email: {
      formatter: 'base.email',
    },
    role: {
      formatter: 'base.role',
    },
    hasDelegations: {
      formatter: 'user.delegation.type',
    },
  },
  data: {
    fullName: {
      className: 'text-primary bold',
    },
    nationalId: {
      className: 'text-primary bold',
    },
    email: {
      className: 'text-primary bold',
    },
    role: {
      className: 'bold',
    },
    hasDelegations: {
      className: 'bold',
      component: (delegationLength: unknown) => {
        const n = typeof delegationLength === 'number' ? delegationLength : 0;
        return {
          render() {
            return h(extend(Button), {
              type: "primary",
              class: "m-auto",
              onclick: () => onDelegationsClick()
            }, [n > 0 ? t('user.delegation.view') : t('user.delegation.create')])
          },
        };
      },
    },
  },
  selectable: true,
};

const isUserItem = (val: unknown): val is UserItem => {
  const item = val as UserItem;
  return !!item.id;
};
const { findById } = useCollectionSearching();

const onUserSelect = (row: unknown) => {
  if (isUserItem(row)) {
    const user = findById(props.users, row.id);
    if (user) {
      emits('select', user);
    }
  }
};
const selectedUserItem = computed(() => {
  if (!props.selected) {
    return null;
  }

  return findById(usersDataset.value, props.selected.id);
});

const hasDelegations = computed(() => !!props.selected?.delegations?.length);
const onCreateClick = () => {
  emits('create');
};
const onEditClick = () => {
  emits('edit');
};
</script>

<template>
  <DataTable 
    :dataset="usersDataset" 
    :config="userDataTableConfig" 
    @select="onUserSelect" 
    :selected="selectedUserItem"
  >
    <template #widget-top-left>
      <Button 
        @click="onCreateClick" 
        :type="ButtonType.primary" 
        v-tooltip="{ content: t('base.create'), theme: 'primary' }"
      >
        <Icon 
          icon="Plus" 
          :options="{ width: 24, height: 24, color: Colors.white }" 
        />
      </Button>
      <Button 
        @click="onEditClick" 
        :type="ButtonType.primary" 
        v-tooltip="{ content: t('base.edit'), theme: 'primary' }" 
        v-if="selected"
      >
        <Icon 
          icon="Edit" 
          :options="{ width: 24, height: 24, color: Colors.white }" 
        />
      </Button>
    </template>
  </DataTable>
</template>
