<template>
  <Component :is="component" v-bind="componentProps" />
</template>
<script lang="ts" setup>
import { Component as ComponentType, computed } from 'vue';

const props = withDefaults(
  defineProps<{
    item: { component?: string | null };
    itemKey: string;
    itemComponents: Record<string, ComponentType>;
    defaultComponent: ComponentType;
  }>(),
  {
    itemKey: 'item',
  }
);

const component = computed(() => (props.item.component ? props.itemComponents[props.item.component] || props.defaultComponent : props.defaultComponent));
const componentProps = computed(() => ({ [props.itemKey]: props.item }));
</script>
