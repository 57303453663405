<script lang="ts" setup>
import * as Icons from '@/features/theme/icons';
import { computed } from 'vue';
import { Colors } from './models/Colors';

export type IconType = keyof typeof Icons;
export type IconOptions = {
  width?: number;
  height?: number;
  color?: Colors;
};

const props = defineProps<{
  icon: IconType;
  options?: IconOptions;
}>();

const normalizedOptions = computed(() => {
  return { ...props.options, heigth: props.options?.height };
});
</script>
<template>
  <component :is="Icons[icon]" v-bind="normalizedOptions" />
</template>
