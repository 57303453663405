import useIssueStore from '@/features/issues/composables/useIssueStore';
import { IssueCardViewModel, IssueSearch } from '@/features/issues/models';
import { IssueSortEnum, useGetIssueCardViewModelConnectionQuery } from '@/generated/graphql';
import { computed, ComputedRef, ref } from 'vue';

interface UseIssueSearch {
  searchResults: ComputedRef<Maybe<IssueCardViewModel[]>>;
  searchLoading: ComputedRef<boolean>;
  setSpecification(search: IssueSearch): void;
  clearSpecification(): void;
  performSearch(): Promise<void>;
  hasNoSpecification: ComputedRef<boolean>;
  hasSearchResults: ComputedRef<boolean>;
  totalCount: ComputedRef<number>;
  issueSearch: ComputedRef<IssueSearch>;
}

export default function useIssueSearch(eager: boolean = false): UseIssueSearch {
  const store = useIssueStore();
  const issueSearch = ref<IssueSearch>(store.issueSearch);

  const {
    data: issueCardsData,
    fetching,
    executeQuery: executeGetIssueCardsQuery,
  } = useGetIssueCardViewModelConnectionQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      first: 30,
      input: computed(() => issueSearch.value.spec) 
    },
    pause: true,
  });

  if (eager) {
    executeGetIssueCardsQuery();
  }

  const totalCount = computed<number>(() => issueCardsData.value?.issueConnection?.totalCount ?? 0);

  const searchResults = computed<Maybe<IssueCardViewModel[]>>(() => issueCardsData.value?.issueConnection?.edges?.map((edge) => edge.node) ?? []);
  
  const performSearch = async (): Promise<void> => {
    store.after = undefined;
    store.patchIssueSpecification({ ...issueSearch.value });
    executeGetIssueCardsQuery();
  };

  const clearSpecification = () => {
    issueSearch.value = {
      spec: { sortBy: IssueSortEnum.LastUpdated },
      filter: {}
    };
    store.resetIssueSpecification();
  };
  
  const setSpecification = (search: IssueSearch) => {
    issueSearch.value = { ...search };
  };

  const hasNoSpecification = computed<boolean>(() => {
    return (
      !issueSearch.value.spec.community && 
      !issueSearch.value.spec.search &&
      !issueSearch.value.spec.fromDate &&
      !issueSearch.value.spec.toDate &&
      (issueSearch.value.spec.processPhases || []).length === 0 &&
      (issueSearch.value.spec.tags || []).length === 0 &&
      !issueSearch.value.spec.geoJson &&
      (issueSearch.value.spec.postalCodes || []).length === 0 &&
      (issueSearch.value.spec.issueStatus || []).length === 0 &&
      (issueSearch.value.spec.delegationIds || []).length === 0 &&
      !issueSearch.value.spec.skipulagsstofnunDelegation
    );
  });

  const hasSearchResults = computed<boolean>(() => {
    return !!(searchResults.value && searchResults.value.length > 0);
  });

  return {
    searchResults,
    searchLoading: computed(() => fetching.value),
    clearSpecification,
    setSpecification,
    performSearch,
    hasNoSpecification,
    hasSearchResults,
    totalCount,
    issueSearch: computed(() => issueSearch.value)
  };
}
