import { LocationQueryRaw, useRoute, useRouter } from 'vue-router';
import useAreaRouteMapper from './useAreaRouteMapper';
import { Area } from '@/generated/graphql';
import { computed, ComputedRef } from 'vue';
import useNavigationStore from './useNavigationStore';

interface UseNavigation {
  checkAreaRouteExists(area: Area, routePath: string): boolean;
  pushAreaRoute(area: Area, routePath: string): void;
  toAreaRoute(area: Area, routePath: string): string;
  toAreaRouteName(area: Area, routeName: string): string;
  pushAreaRouteByName(area: Area, routeName: string, routeParams?: Record<string, string | number>, routeQuery?: LocationQueryRaw): void;

  // Active area
  activeArea: ComputedRef<Area | null>;
  toActiveAreaRoute(routePath: string): string;
  toActiveAreaRouteName(routeName: string): string;
  checkActiveAreaRouteExists(routePath: string): boolean;
  pushActiveAreaRoute(routePath: string): void;
  pushActiveAreaRouteByName(routeName: string, routeParams?: Record<string, string | number>, routeQuery?: LocationQueryRaw): void;

  //
  navigateBack(): void;

  isRoute(routeName: string): boolean;
}

export default function useNavigation(): UseNavigation {
  const store = useNavigationStore();
  const router = useRouter();
  const { mapRoutePath } = useAreaRouteMapper();
  const route = useRoute();

  const checkAreaRouteExists = (area: Area, routePath: string): boolean => !!router.resolve(mapRoutePath(area, routePath));
  const pushAreaRoute = (area: Area, routePath: string) => {
    router.push({ path: mapRoutePath(area, routePath) });
  };
  const toAreaRouteName = (area: Area, routeName: string) => `${area.id}_${routeName}`;
  const pushAreaRouteByName = (area: Area, routeName: string, routeParams?: Record<string, string | number>, routeQuery?: LocationQueryRaw) => {
    router.push({ name: toAreaRouteName(area, routeName), params: routeParams, query: routeQuery });
  };

  const activeArea = computed(() => store.activeArea);
  const toActiveAreaRoute = (routePath: string): string => {
    if (activeArea.value === null) {
      throw new Error('No active area');
    }

    return mapRoutePath(activeArea.value, routePath);
  };
  const toActiveAreaRouteName = (routeName: string): string => {
    if (activeArea.value === null) {
      throw new Error('No active area');
    }

    return toAreaRouteName(activeArea.value, routeName);
  };
  const checkActiveAreaRouteExists = (routePath: string): boolean => {
    if (activeArea.value === null) {
      return false;
    }

    return checkAreaRouteExists(activeArea.value, routePath);
  };
  const pushActiveAreaRoute = (routePath: string) => {
    if (activeArea.value === null) {
      throw new Error('No active area');
    }

    return pushAreaRoute(activeArea.value, routePath);
  };

  const pushActiveAreaRouteByName = (routeName: string, routeParams?: Record<string, string | number>, routeQuery?: LocationQueryRaw) => {
    if (activeArea.value === null) {
      throw new Error('No active area');
    }

    pushAreaRouteByName(activeArea.value, routeName, routeParams, routeQuery);
  };

  const navigateBack = () => {
    if (window.history.length) {
      router.back();
    } else {
      router.push({ path: '/' });
    }
  };

  const isRoute = (routeName: string): boolean => {
    return route.name === toActiveAreaRouteName(routeName) || route.name === routeName;
  };

  return {
    activeArea,
    checkAreaRouteExists,
    toAreaRoute: mapRoutePath,
    toAreaRouteName,
    pushAreaRoute,
    pushAreaRouteByName,

    toActiveAreaRoute,
    toActiveAreaRouteName,
    checkActiveAreaRouteExists,
    pushActiveAreaRoute,
    pushActiveAreaRouteByName,

    navigateBack,

    isRoute,
  };
}
