import PageError from '@/features/rendering/pages/PageError.vue';
import { RouteRecordRaw } from 'vue-router';
import FakeUserLogins from '@/features/userManagement/components/FakeUserLogins.vue';
import UserSettings from '@/features/navigation/UserSettings.vue';
import useAuthStore from '../auth/useAuthStore';
import { SpecialAuthPaths } from '../auth/authMiddleware';

export const fakesPath = 'fakes';

export const fakeUserLoginsRoute: RouteRecordRaw = {
  path: `/${fakesPath}`,
  component: FakeUserLogins,
  name: 'Gerviaðgangar',
  beforeEnter: () => {
    const authStore = useAuthStore();

    if (authStore.isAdmin || authStore.user?.isFake) {
      return true;
    } else {
      return false;
    }
  },
};

export const preferencesRoute: RouteRecordRaw = {
  path: SpecialAuthPaths.USER_SETTINGS,
  component: UserSettings,
  name: 'Stillingar',
};

export default [fakeUserLoginsRoute, preferencesRoute, { path: '/:catchAll(.*)', component: PageError, name: 'SYSTEM_NotFound', props: { status: 404 } }];
