export const enum LayerZIndex {
  Raster = 0,
  Base = 1,
  Feature = 2,
  Draw = 5,
  Top = 10,
}

export enum DrawTool {
  Point = 'Point',
  Line = 'LineString',
  Polygon = 'Polygon',
}
