<script lang="ts" setup>
import Checkbox from '@/features/theme/base/Checkbox.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import { ref } from 'vue';

const props = withDefaults(
  defineProps<{
    id: string;
    label: string | number;
    bold?: boolean;
    checked?: boolean;
    expanded?: boolean;
  }>(),
  {
    bold: false,
    checked: false,
  }
);

const emit = defineEmits<{
  (e: 'checked', checked: boolean): void;
}>();

const expanded = ref<boolean>(props.expanded);
const onLabelClick = () => {
  expanded.value = !expanded.value;
};
const onUpdate = () => {
  if (!props.checked && !expanded.value) {
    onLabelClick();
  }
  emit('checked', !props.checked);
};

</script>
<template>
  <li class="toggler">
    <div class="toggler__header" :class="{ 'toggler__header--bold': bold }">
      <div @click="onLabelClick" class="toggler__label">
        <Icon 
          class="icon mr-1" 
          icon="DropdownArrow" 
          :options="{ width: 24, height: 24, color: Colors.grey600 }" 
          :class="{ 'rotate-180': expanded }" 
        />
        <div class="toggler_checkbox">
          <Checkbox 
            :model-value="checked" 
            :id="id" 
            @update:model-value="onUpdate" 
          />
          {{ label }}
        </div>
      </div>
    </div>
    <div v-if="expanded" class="toggler__content">
      <slot />
    </div>
  </li>
</template>

<style lang="scss" scoped>
.toggler {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.toggler__label {
  display: flex;
  cursor: pointer;
}

.toggler__header {
  margin-bottom: 0.2rem;
}

.toggler__header--bold {
  font-weight: bold;
}

.toggler_checkbox {
  display: Flex;
  align-items: center;
}

.icon {
  transition: all 0.3s;

  &.rotate-180 {
    transform: rotateX(180deg);
  }
}
</style>
