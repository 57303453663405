<script setup lang="ts">
import { nanoid } from 'nanoid';
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import useNavigationStore from '../navigation/useNavigationStore';
import Translate from '../translations/Translate.vue';

const router = useRouter();

const codeRequestUrl = ref('');
async function fetchCodeRequestUrl() {
  const address = import.meta.env.DEV ? import.meta.env.VITE_CODE_REQUEST_URL : `${window.location.origin}/auth/code-request-url`;
  const response = await fetch(address);
  const json = await response.json();

  const nonce = nanoid();
  const url = new URL(json.link);
  url.searchParams.append('state', nonce);
  localStorage.setItem(nonce, JSON.stringify({ location: router.options.history.state?.back || '/' }));
  codeRequestUrl.value = url.toString();
}

fetchCodeRequestUrl();

function handleClick() {
  window.location.href = codeRequestUrl.value;
}

const store = useNavigationStore();
store.setMainMenuMargin(true);
store.setMainMenuTheme('login');
</script>

<template>
  <div class="login-page flex flex-center">
    <div class="login_card bg-white green-shadow--xs">
      <h2 class="h6 color-primary"><Translate t="base.login" /></h2>
      <p class="text grey-600 regular"><Translate t="auth.login.eid" /></p>
      <button class="btn btn-primary" @click="handleClick"><Translate t="auth.login.eid.btn" /></button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/layout' as layout;
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/colors' as colors;

.login-page {
  max-width: calc(100% - 4rem);
  margin: auto;
  padding-top: 5rem;
  @include mq.tablet() {
    padding-top: 10rem;
  }
}

.login_card {
  grid-column: 3 / span 4;
  padding: 4.8rem 3.2rem;

  @include mq.tablet() {
    padding: 8rem;
  }
}

h2 {
  margin-bottom: 1.6rem;
}

p {
  margin-bottom: 3.2rem;
  font-size: 1.8rem;
}

button {
  margin-top: 3.2rem;
}
</style>
