<script lang="ts" setup>
import IssuePhasePill from '../IssuePhasePill.vue';
import { PillSize } from '@/features/theme/base/models/PillProps';
import Bookmark from '@/features/theme/icons/Bookmark.vue';
import { IssueCardViewModel, IssueDetails } from '@/features/issues/models';
import Card from '@/features/theme/base/Card.vue';
import Button from '@/features/theme/base/Button.vue';
import Arrow from '@/features/theme/icons/Arrow.vue';
import DirectionType from '@/features/theme/icons/models/DirectionType';
import IssueTags from '../IssueTags.vue';
import { computed } from 'vue';
import useIssue from '../../composables/useIssue';
import Translate from '@/features/translations/Translate.vue';
import { useI18n } from 'vue-i18n';
import RichText from '@/features/theme/base/richTextEditor/RichText.vue';
import { issueYearOrderPath } from '@/features/issues/issueYearOrderPath';

const props = withDefaults(
  defineProps<{
    issue: IssueCardViewModel | IssueDetails;
    selected?: boolean;
    bookmarked?: boolean;
    showBookmark?: boolean;
    showTags?: boolean;
    cardSize: Maybe<'small' | 'medium' | 'large'>;
    iconOnly?: boolean;
    idPlacement?: 'header' | 'footer';
  }>(),
  {
    selected: false,
    bookmarked: false,
    showBookmark: true,
    showTags: true,
    cardSize: 'medium',
    iconOnly: false,
    idPlacement: 'header',
  }
);

const emits = defineEmits<{
  (e: 'bookmark', issueId: string): void;
}>();

const onBookmarkClick = () => {
  emits('bookmark', props.issue.id.toString());
};

const { issueNumberFormatted, issuePublishDateFormatted } = useIssue(computed(() => props.issue));

const { t } = useI18n();
</script>

<template>
  <Card
    class="issue-card"
    :size="cardSize"
    :class="{ selected, hasPadding: cardSize !== null }"
  >
    <template
      v-if="idPlacement === 'header'"
      #header
    >
      <div class="issue-header w-100">
        <div class="color-primary eyebrow eyebrow--small bold text-uppercase">
          <Translate t="issue.issue_no" />
          {{ issueNumberFormatted }}
          <span class="px-1">&bull;</span>
          <Translate t="issue.published" />
          {{ issuePublishDateFormatted }}
        </div>
        <div class="header-left">
          <IssuePhasePill
            :icon="iconOnly"
            :issue="issue"
            :icon-height="16"
            :icon-width="16"
            :pill-size="PillSize.small"
          ></IssuePhasePill>
          <div
            v-if="showBookmark"
            @click.stop="onBookmarkClick"
            class="bookmark"
            v-tooltip="{ content: t('monitoring.tooltip'), theme: 'primary' }"
          >
            <Bookmark
              :heigth="24"
              :width="24"
              :filled="bookmarked"
            ></Bookmark>
          </div>
        </div>
      </div>
    </template>
    <div class="card-content">
      <div v-if="idPlacement === 'footer'">
        <IssuePhasePill
          :icon="iconOnly"
          :phase="issue.currentPhase"
          :icon-height="16"
          :icon-width="16"
          :pill-size="PillSize.small"
        ></IssuePhasePill>
      </div>
      <div>
        <RouterLink
          :to="issueYearOrderPath(props.issue)"
          class="p--large bold"
        >
          {{ issue.title || '[Heiti máls]' }},
          <Translate t="issue.issue_no_small" />
          {{ issueNumberFormatted }}
        </RouterLink>
        <div style="margin-bottom: 0.4rem" />
        <RichText
          :opsJson="issue.richTextDescription"
          :rawText="issue.description"
        />
        <div
          v-if="idPlacement === 'footer'"
          class="color-primary eyebrow eyebrow--small bold mt-1"
        >
          <span>
            <Translate t="issue.issue_no" />
            {{ issueNumberFormatted }}
          </span>
          <span class="px-1">&bull;</span>
          <span>
            <Translate t="issue.published" />
            {{ issuePublishDateFormatted }}
          </span>
        </div>
      </div>
    </div>
    <template
      v-if="showTags"
      #footer
    >
      <IssueTags :issue="issue" />
    </template>
    <RouterLink :to="issueYearOrderPath(props.issue)">
      <Button
        v-if="selected"
        class="issue-card-link no-outline"
      >
        <Translate t="issue.link" />
        <Arrow
          :direction="DirectionType.right"
          :heigth="24"
          :width="24"
          :color="'#fff'"
        ></Arrow>
      </Button>
    </RouterLink>
  </Card>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/media-queries' as media-queries;

.issue-card {
  box-sizing: border-box;
  position: relative;
  border: 1px solid transparent;
  border-radius: 4px;
  &.selected {
    border-color: color.$primary;
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.1);
  }

  &.hasPadding {
    padding: 2.4rem;
  }
}

.word-break-none {
  word-break: normal;
}

.issue-card-link {
  position: absolute;
  bottom: 0;
  right: 0;
}

.issue-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media-queries.laptop-down {
    flex-wrap: wrap-reverse;
    gap: 2rem;
  }
}

.header-left {
  display: flex;
  align-items: center;

  @include media-queries.mobile {
    justify-content: space-between;
    width: 100%;
  }
}

.bookmark {
  display: flex;
  margin-left: 2.4rem;
}

.card-content {
  margin-top: 0.4rem;
  margin-bottom: 2.4rem;
  display: flex;
  gap: 1.6rem;
}

.card-tags {
  .card-tag {
    margin-right: 2.4rem;
  }
}

.pill-text {
  margin-left: 1rem;
}
</style>
