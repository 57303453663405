<script lang="ts" setup>
import usePostalCodes from '@/features/composables/usePostalCodes';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import CheckboxToggle from '@/features/communities/components/CheckboxToggle.vue';
import without from 'lodash-es/without';
import uniq from 'lodash-es/uniq';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
    title?: string;
    customClass?: string;
  }>(),
  {
    modelValue: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

const { postalCodesGroupedByRegion } = usePostalCodes();

const isSelected = (postCode: string): boolean => {
  return props.modelValue.includes(postCode);
};

const updateSelected = (postCode: string, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, postCode]);
  } else {
    const idx = props.modelValue.findIndex((pc) => pc === postCode);
    if (idx !== -1) {
      emit('update:modelValue', [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)]);
    }
  }
};

const isRegionChecked = (region: string | number): boolean => {
  if (props.modelValue.length === 0) {
    return false;
  }
  const regionCities = postalCodesGroupedByRegion.value[region];
  let allCitiesChecked = true;
  for (const city in regionCities) {
    if (!isCityRegionChecked(region, city)) {
      allCitiesChecked = false;
    }
  }
  return allCitiesChecked;
};

const onRegionChecked = (region: string | number, val: boolean) => {
  let postCodes: string[] = [];
  for (const city in postalCodesGroupedByRegion.value[region]) {
    const cityPostcodes = postalCodesGroupedByRegion.value[region][city];
    postCodes = postCodes.concat(cityPostcodes.map(({ number }) => number));
    onCityRegionChecked(region, city, val);
  }
  if (val) {
    emit('update:modelValue', uniq(props.modelValue.concat(postCodes)));
  } else {
    emit('update:modelValue', without(props.modelValue, ...postCodes));
  }
};

const isCityRegionChecked = (region: string | number, city: string | number): boolean => {
  if (props.modelValue.length === 0) {
    return false;
  }
  let postCodes: string[] = [];
  const cityPostCodes = postalCodesGroupedByRegion.value[region][city];
  postCodes = postCodes.concat(cityPostCodes.map(({ number }) => number));
  let checked = postCodes.filter((p) => props.modelValue.includes(p));
  return checked.length == postCodes.length;
};

const expandRegion = (region: string | number): boolean => {
  const regionCities = postalCodesGroupedByRegion.value[region];
  for (const city in regionCities) {
    if (isCityRegionChecked(region, city)) {
      return true;
    }
  }
  return false;
};

const expandCity = (region: string | number, city: string | number): boolean => {
  const cityPostCodes = postalCodesGroupedByRegion.value[region][city];
  return cityPostCodes.map(p => p.number).some(p => props.modelValue.includes(p));
};

const onCityRegionChecked = (region: string | number, city: string | number, val: boolean) => {
  const postCodes = postalCodesGroupedByRegion.value[region][city].map(({ number }) => number);
  if (val) {
    emit('update:modelValue', uniq(props.modelValue.concat(postCodes)));
  } else {
    emit('update:modelValue', without(props.modelValue, ...postCodes));
  }
};

</script>
<template>
  <LayoutFormGroup :title="title" fullwidth>
    <LayoutInput>
      <ul :class="`${customClass}`">
        <CheckboxToggle
          v-for="(postalCodesByCity, region) in postalCodesGroupedByRegion"
          :label="region"
          :key="region"
          bold
          :id="`region-${region}`"
          :checked="isRegionChecked(region)"
          :expanded="expandRegion(region)"
          @checked="(val) => onRegionChecked(region, val)"
        >
          <ul>
            <template v-for="(postalCodes, city) in postalCodesByCity" :key="city">
              <CheckboxToggle
                v-if="postalCodes.length > 0" 
                class="pl-3"
                :label="city"
                :key="city"
                :id="`city-${city}`"
                :checked="isCityRegionChecked(region, city)"
                :expanded="expandCity(region, city)"
                @checked="(val) => onCityRegionChecked(region, city, val)"
              >
                <ul>
                  <li 
                    class="pl-6" 
                    v-for="postalCode in postalCodes" 
                    :key="postalCode.number"
                  >
                    <Checkbox
                      :id="`zip-${postalCode.number}`"
                      :key="postalCode.number"
                      :model-value="isSelected(postalCode.number)"
                      @update:model-value="(val) => updateSelected(postalCode.number, val)"
                      class="postal-code"
                    >
                      {{ postalCode.number }} - {{ postalCode.cityAreaName }}
                    </Checkbox>
                  </li>
                </ul>
              </CheckboxToggle>
            </template>
          </ul>
        </CheckboxToggle>
      </ul>
    </LayoutInput>
  </LayoutFormGroup>
</template>
<style lang="scss" scoped>
  @use '@/scss/design-tokens/media-queries' as mq;
  .checkbox-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
    @include mq.mobile() {
      grid-template-columns: repeat(1, 1fr);
      font-size: 1.5rem;
    }
  }

  .postal-code {
    margin: 0.25rem 0 0.25rem 0;
  }
</style>