import { InputMaybe, IssuePhaseStepEnum, IssueStepSpecificationInput, useGetPhaseStepDataQuery } from '@/generated/graphql';
import { useStepFieldInputsStore } from '../useStepFieldInputsStore';
import { computed, ref } from 'vue';
import { IssueDetails } from '@/features/issues/models';
import useActiveIssue from './useActiveIssue';
import useAuthStore from '@/features/auth/useAuthStore';

type UseGetPhaseStepDataQueryVariables =
  | MaybeRef<{
      phaseId: MaybeRef<string>;
      input?: MaybeRef<InputMaybe<IssueStepSpecificationInput> | undefined>;
    }>
  | undefined;

const useCommunicationStep = (issue: MaybeRef<IssueDetails | undefined>) => {
  const { selectedPhase, selectedStep } = useActiveIssue(issue);
  const authStore = useAuthStore();
  const store = useStepFieldInputsStore();

  const variables: UseGetPhaseStepDataQueryVariables = ref({ phaseId: '' });

  const { executeQuery: getPhaseStepData, stale } = useGetPhaseStepDataQuery({
    pause: true,
    requestPolicy: 'network-only',
    variables,
  });

  const verifyCommunicationStepDataInput = async () => {
    const phaseId = selectedPhase.value?.id.toString();
    if (phaseId) {
      const stepFieldInput = store.inputByPhaseId(phaseId);

      const ops = stepFieldInput.quillDelta?.ops;
      if (
        ops?.length &&
        ops
          .map(op => op.insert)
          .join('')
          .trim().length
      ) {
        return true;
      }

      variables.value.phaseId = phaseId;
      variables.value.input = {
        issuePhaseId: phaseId,
        stepType: selectedStep.value?.type,
      };

      const response = await getPhaseStepData();

      const phase = response.data.value?.phase;
      if (phase?.stepData?.historicStepData?.some(sd => !sd.published)) {
        return true;
      }

      if (phase?.stepData?.currentStepData?.stepFiles?.length) {
        return true;
      }
    }

    return false;
  };

  const isComPhaseAdmin = computed(() => {
    return authStore.isAdmin || authStore.isAdalOrDeilisEmployee;
  });

  const isSLSStep = computed(() => {
    const slsSteps = [IssuePhaseStepEnum.StadfestingSkipulagsstofnunar, IssuePhaseStepEnum.UmsognSkipulagsstofnunar];
    return selectedStep.value?.type && slsSteps.includes(selectedStep.value.type);
  });

  return {
    verifyCommunicationStepDataInput,

    isComPhaseAdmin,
    isSLSStep,
  };
};

export default useCommunicationStep;
