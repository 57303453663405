<script lang="ts" setup>
import Logo from '@/features/theme/icons/Logo.vue';
import { MenuTheme } from '@/features/theme/models';
import { useI18n } from 'vue-i18n';
import Translate from '../translations/Translate.vue';
import useNavigation from './useNavigation';
import { computed } from 'vue';

defineProps<{
  theme: MenuTheme;
}>();

const { t } = useI18n();
const { toActiveAreaRouteName } = useNavigation();
const footerRouteNames = computed(() => ['Persónuvernd', 'Cookies']);
</script>

<template>
  <footer :class="`bg-${theme.background} color-${theme.color}`">
    <div class="logo-container">
      <Logo :heigth="48" :width="180" :with-text="true" :color="theme.color"></Logo>
    </div>
    <div class="info-container flex flex-center">
      <span class="info-item regular">
        <Translate t="app.title" />
      </span>
      <span class="dot-sperator">•</span>
      <div class="info-item split-item regular">
        <Translate t="app.address" />
      </div>
      <span class="dot-sperator">•</span>
      <a class="info-item text--large underscore" :class="`underscore-${theme.color}`" :href="`mailto:${t('app.email')}`">
        <Translate t="app.email" />
      </a>
      <span class="dot-sperator">•</span>
      <a class="info-item text--large" :href="`tel:${t('app.phone').replace(/[\s\-]/gi, '')}`">
        <Translate t="app.phone" />
      </a>
      <span class="dot-sperator">•</span>
      <span class="info-item regular">
        ©{{ new Date().getFullYear() }}
        <Translate t="app.organization" />
      </span>
    </div>
    <div class="links-container">
      <ul>
        <li v-for="name in footerRouteNames" :key="name">
          <RouterLink :to="{ name: toActiveAreaRouteName(name) }">
            <Translate :value="`nav.${name}`" />
          </RouterLink>
        </li>
      </ul>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;
@use '@/scss/design-tokens/layout' as layout;
@use '@/scss/design-tokens/z-indicies' as z-index;

footer {
  height: layout.$footer-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: layout.$footer-padding;
  position: relative;
  z-index: z-index.$z-index-footer;

  @include media.laptop-down {
    height: unset;
    min-height: layout.$footer-height;
    flex-direction: column;
    align-items: start;
  }
}

.logo-container {
  @include media.laptop-down {
    margin-bottom: 32px;
  }
}

.dot-sperator {
  @include media.laptop-down {
    display: none;
  }
}

.underscore {
  &::after {
    bottom: -3px;
  }
}

.info-container {
  @include media.laptop-down {
    flex-direction: column;
    align-items: start;
  }
}

.links-container {
  @include media.laptop-down {
    margin-top: 24px;
  }
}

.info-item {
  font-size: 1.5rem;
  @include media.laptop-down {
    margin-top: 24px;
  }
}

.split-item {
  @include media.laptop-down {
    display: flex;
    flex-direction: column;
  }
}
</style>
