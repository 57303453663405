<script lang="ts" setup>
import IssueCardList from '@/features/issues/components/issueList/IssueCardList.vue';
import { IssueCardViewModel } from '@/features/issues/models';
import InputSearch from '@/features/theme/base/InputSearch.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import debounce from 'lodash-es/debounce';
import { onMounted, onUnmounted, ref } from 'vue';
import useIssueSearch from '../composables/useIssueSearch';

withDefaults(
  defineProps<{
    disabled?: boolean;
  }>(),
  {
    disabled: false,
  }
);

const emits = defineEmits<{
  (e: 'select', issueId: string): void;
}>();

const { searchLoading, searchResults, performSearch, issueSearch, hasNoSpecification, setSpecification } = useIssueSearch(false);
const runDebouncedSearch = debounce(() => {
  if (!hasNoSpecification.value) {
    performSearch();
  }
}, 500);

const searchFocused = ref<boolean>(false);
const onSearchFocus = () => {
  searchFocused.value = true;
};

const onSearchUpdate = (v: string) => {
  const spec = { ...issueSearch.value.spec, search: v  };
  setSpecification({ ...issueSearch.value, spec: spec });
  runDebouncedSearch();
};

const onIssueSelect = (issue: IssueCardViewModel) => {
  const issueId = issue.id.toString();
  emits('select', issueId);
  searchFocused.value = false;
  const spec = { ...issueSearch.value.spec, search: ''  };
  setSpecification({ ...issueSearch.value, spec: spec });
};

const onDocumentClick = () => {
  searchFocused.value = false;
};

onMounted(() => {
  const spec = { ...issueSearch.value.spec, search: ''  };
  setSpecification({ ...issueSearch.value, spec: spec });
  document.addEventListener('click', onDocumentClick);
});

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick);
});
</script>
<template>
  <div
    @click.stop
    class="position-relative"
  >
    <div
      class="input-search-container"
      :class="{ searchFocused }"
    >
      <InputSearch
        fullwidth
        :disabled="disabled"
        :include-button="false"
        label="search.input"
        :icon-options="{ color: Colors.grey600 }"
        :model-value="issueSearch.spec.search!"
        @update:model-value="onSearchUpdate"
        @focus="onSearchFocus"
      />
    </div>
    <div class="results-container scrollbar">
      <h5 class="search-results-title px-2 py-1 bg-grey-200 p--large">
        <Translate t="search.results.title" />
      </h5>
      <div class="bg-white px-2 py-2">
        <div v-if="searchLoading">
          <Translate t="search.loading" />
          ...
        </div>
        <IssueCardList
          v-else-if="searchResults && searchResults.length > 0"
          :issues="searchResults"
          :card-size="null"
          :show-bookmark="false"
          :show-tags="false"
          icon-only
          id-placement="footer"
          card-style="border"
          @select="onIssueSelect"
        />
        <div v-else>
          <Translate t="search.results.none" />
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/z-indicies' as z;

.results-container {
  position: absolute;
  top: 6.9rem;
  min-width: 100%;
  left: 0;
  box-shadow: 0px 24px 96px rgba(colors.$primary, 0.1);
  border-radius: 2px;
  max-height: 0;
  overflow: hidden auto;
  padding-right: 0;
  z-index: z.$z-index-sidebar-search-results;
}

.detailed-search-container {
  overflow: hidden auto;
  max-height: 50vh;
  padding-right: 5rem;
}

.input-search-container {
  &.searchFocused + .results-container {
    max-height: 40vh;
  }
}
</style>
