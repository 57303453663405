<script lang="ts" setup>
import useVectorSource from '@/features/map/composables/useVectorSource';
import { onMounted, onUnmounted, shallowRef, watch } from 'vue';
import Draw, { Options } from 'ol/interaction/Draw';
import { Listener } from 'ol/events';
import useMapModelChecker from '@/features/map/composables/useMapModelChecker';
import Interaction from './Interaction.vue';
import { FeatureDecorated } from '@/features/map/models/mapModels';
import Geometry from 'ol/geom/Geometry';

const props = withDefaults(
  defineProps<{
    options?: Options;
  }>(),
  {
    options: (): Options => ({
      type: 'Point',
    }),
  }
);

const emits = defineEmits<{
  (e: 'drawend', feature: FeatureDecorated<Geometry>): void;
}>();

const getVectorSource = useVectorSource();

const drawHandle = shallowRef(new Draw({ ...props.options, source: getVectorSource() }));

const { isGeometryFeatureEvent } = useMapModelChecker();
const onDrawEnd: Listener = (e) => {
  if (isGeometryFeatureEvent(e)) {
    emits('drawend', {
      feature: e.feature,
      delete: () => getVectorSource().removeFeature(e.feature),
      render: () => getVectorSource().addFeature(e.feature),
    });
  }
};

onMounted(() => {
  drawHandle.value.addEventListener('drawend', onDrawEnd);
});

onUnmounted(() => {
  drawHandle.value.removeEventListener('drawend', onDrawEnd);
});

watch(
  () => props.options,
  (newValue) => {
    drawHandle.value.removeEventListener('drawend', onDrawEnd);
    drawHandle.value = new Draw({ ...newValue, source: getVectorSource() });
    drawHandle.value.addEventListener('drawend', onDrawEnd);
  }
);
</script>
<template>
  <Interaction :interaction="drawHandle">
    <slot />
  </Interaction>
</template>
