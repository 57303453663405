import store from '@/features/store';
import { useNavigationStore } from './useNavigationStore';
import { RouteLocationNormalized } from 'vue-router';

const updateActiveAreaMiddleware = (to: RouteLocationNormalized, from: RouteLocationNormalized): void => {
  const navStore = useNavigationStore(store);
  if (to.meta.areaId !== undefined && to.meta.areaId !== navStore.activeAreaId) {
    navStore.setActiveAreaId(to.meta.areaId);
  }
};

export default updateActiveAreaMiddleware;
