import { get as getProjection, Projection } from 'ol/proj';
import { ProjectionEnum } from '@/features/map/models/mapProjection';

export default function useProjection(projection: ProjectionEnum = ProjectionEnum.EPSG3057): Projection {
  const proj = getProjection(projection);

  if (!proj) {
    throw new Error('Default projection not registered, see proj4');
  }

  return proj;
}
