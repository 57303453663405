import { App } from 'vue';

import urql, { makeOperation, createClient, dedupExchange, cacheExchange, fetchExchange } from '@urql/vue';
import { authExchange } from '@urql/exchange-auth';
import { tokenService } from './features/auth/tokenService';
import { useSignin } from './features/auth/useSignin';

interface AuthState {
  token: string;
}

export const client = createClient({
  url: import.meta.env.DEV ? import.meta.env.VITE_GRAPHQL_URL || 'https://skipulagsgatt.test.lisa.is/graphql' : `${window.location.origin}/graphql`,
  exchanges: [
    dedupExchange,
    cacheExchange,
    authExchange<AuthState>({
      addAuthToOperation: ({ authState, operation }) => {
        if (!authState || !authState.token) {
          return operation;
        }

        const fetchOptions = typeof operation.context.fetchOptions === 'function' ? operation.context.fetchOptions() : operation.context.fetchOptions || {};
        const made = makeOperation(operation.kind, operation, {
          ...operation.context,
          fetchOptions: {
            ...fetchOptions,
            headers: {
              Authorization: `Bearer ${tokenService.getToken()}`,
              ...fetchOptions.headers,
            },
          },
        });

        return made;
      },
      getAuth: async ({ authState }) => {
        if (!authState) {
          const token = tokenService.getToken();
          if (token !== null) {
            return { token };
          }

          return null;
        }

        const signin = useSignin();
        signin.clearLocalUser();

        return null;
      },
      didAuthError: ({ error }) => {
        return error.graphQLErrors.some((e) => e.extensions?.code === 'AUTH_NOT_AUTHENTICATED');
      },
    }),
    fetchExchange,
  ],
});

export const useUrql = (app: App) => {
  app.use(urql, client);
};
