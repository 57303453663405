<script lang="ts" setup>
import Map, { MapOptions } from 'ol/Map';
import View from 'ol/View';
import { defaults as defaultControls } from 'ol/control';
import useProjection from '@/features/map/composables/useProjection';
import { onMounted, onUnmounted, provide, shallowRef } from 'vue';
import { mapKey } from '@/features/map/models/mapSymbols';

const props = withDefaults(
  defineProps<{
    id?: string;
    options?: MapOptions;
  }>(),
  {
    id: 'map',
    options: (): MapOptions => ({
      controls: defaultControls({ attribution: false, rotate: false }),
    }),
  }
);

const emits = defineEmits<{
  (e: 'mounted', map: Map): void;
  (e: 'unmounted'): void;
  (e: 'change:zoom', payload: { newZoom: number; oldZoom: number }): void;
}>();

const projection = useProjection();

const map = shallowRef(Object.assign(new Map(props.options), { name: 'OL-Map' }));

let mapZoom = 3;
const onMapMoveEnd = () => {
  const newZoom = map.value.getView().getZoom();
  if (typeof newZoom === 'number' && newZoom !== mapZoom) {
    mapZoom = newZoom;
    emits('change:zoom', { newZoom, oldZoom: mapZoom });
  }
};

onMounted(() => {
  map.value.setTarget(props.id);

  map.value.setView(
    new View({
      center: [515557.83526395843, 473623.4874323396],
      zoom: mapZoom,
      projection: projection.getCode(),
    })
  );

  map.value.addEventListener('moveend', onMapMoveEnd);

  emits('mounted', map.value);
});

onUnmounted(() => {
  map.value.removeEventListener('moveend', onMapMoveEnd);

  emits('unmounted');
});

provide(mapKey, () => map.value);
</script>
<template>
  <div id="map-container">
    <div tabindex="-1" :id="id"></div>
    <slot></slot>
  </div>
</template>
<style lang="scss">
@use 'node_modules/ol/ol.css';

#map {
  height: 100%;
}

#map-container {
  position: relative;
}
</style>
