<script lang="ts" setup>
import { ref, watch } from 'vue';
import { User } from '@/features/auth/models';
import { TableRow, TableData } from '@/features/theme/base/tables';

const props = withDefaults(
  defineProps<{
    value: User;
    selected?: boolean;
  }>(),
  {
    selected: false,
  }
);

const emits = defineEmits<{
  (e: 'click', value: User): void;
}>();

const onClick = () => {
  emits('click', props.value);
};

const optionElement = ref();
watch(
  () => props.selected,
  (newValue) => {
    if (newValue && optionElement.value) {
      optionElement.value.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }
);
</script>
<template>
  <TableRow 
    :key="value.fullName" 
    clickable
    @click="onClick"
    class="option" 
    ref="optionElement" 
    :table-spacer="false"
    :class="{ selected }"
  >
    <TableData class="color-primary">{{ value.fullName }}</TableData>
    <TableData class="color-primary">{{ value.nationalId }}</TableData>
    <TableData class="color-primary">{{ value.email }}</TableData>
  </TableRow>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1rem;
  box-sizing: border-box;
  color: colors.$primary;

  &.selected {
    background-color: colors.$primary;
    color: colors.$white;
  }

  &:not(.selected):hover {
    background-color: colors.$primary--xlight;
    color: colors.$primary;
  }
}
</style>
