<script lang="ts" setup>
import FileList from '@/features/files/components/FileList.vue';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import useIssuePhaseReviewDates from '@/features/issues/composables/useIssuePhaseReviewDates';
import useIssuePhaseStepFiles from '@/features/issues/composables/useIssuePhaseStepFiles';
import { IssueStep, IssuePhase, Issue } from '@/features/issues/models';
import ArticleText from '@/features/theme/base/article/ArticleText.vue';
import RichText from '@/features/theme/base/richTextEditor/RichText.vue';
import Translate from '@/features/translations/Translate.vue';
import { IssuePhaseStepEnum } from '@/generated/graphql';
import { computed } from 'vue';

const props = defineProps<{
  step: IssueStep;
  phase: IssuePhase;
  issue: Issue;
}>();

const { getPhaseStepKey } = useProcessTranslation();
const issuePhaseStepFiles = useIssuePhaseStepFiles(
  computed(() => props.phase),
  computed(() => props.step)
);
const issuePhaseStepFields = computed(() => props.step.fields.filter(({ value }) => value.length > 0));
const { reviewStartDateFormatted, reviewEndDateFormatted, reviewStartDatePassed } = useIssuePhaseReviewDates(computed(() => props.phase));
const isPhaseDescription = computed(() => props.step.order === 1 && props.step.type === IssuePhaseStepEnum.FyrirliggjandiGogn);
const showReviewDates = computed(() => {
  if (props.phase.state === 'draft') {
    return false;
  }

  if (!reviewStartDateFormatted.value || !reviewEndDateFormatted.value) {
    return false;
  }

  return isPhaseDescription.value;
});
</script>
<template>
  <li v-if="issuePhaseStepFields.length > 0 || issuePhaseStepFiles.length > 0 || isPhaseDescription">
    <div class="step-title mb-1">
      <p class="p--large">
        <Translate
          :value="getPhaseStepKey(issue.process, phase, step)"
          :default-value="step.name"
        />
      </p>
      <div
        v-if="showReviewDates"
        class="text--small"
      >
        {{ reviewStartDateFormatted }} -
        {{ reviewEndDateFormatted }}
      </div>
    </div>
    <ArticleText v-if="isPhaseDescription && issue.description !== phase.description">
      <RichText
        :opsJson="phase.richTextDescription"
        :rawText="phase.description"
      />
    </ArticleText>
    <template
      v-for="field in issuePhaseStepFields"
      :key="field.key"
    >
      <ArticleText>{{ field.value }}</ArticleText>
    </template>
    <div
      v-if="issuePhaseStepFiles.length > 0"
      class="file-list-container"
    >
      <FileList
        class="mb-2"
        :show-registration-dates="true"
        :files="issuePhaseStepFiles"
      />
    </div>
  </li>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.file-list-container {
  max-width: 100%;

  @include mq.laptop() {
    max-width: 50%;
  }
}
</style>
