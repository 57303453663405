<script lang="ts" setup>
import { IssueCardViewModel, IssueDetails } from '@/features/issues/models';
import useWatchedIssues from '@/features/issues/composables/useWatchedIssues';
import IssueCard from './IssueCard.vue';

const props = withDefaults(
  defineProps<{
    issues?: (IssueCardViewModel | IssueDetails)[];
    selected?: string;
    cardSize?: Maybe<'small' | 'medium' | 'large'>;
    showBookmark?: boolean;
    showTags?: boolean;
    iconOnly?: boolean;
    idPlacement?: 'header' | 'footer';
    cardStyle?: 'margin' | 'margin-small' | 'border';
  }>(),
  {
    issues: () => [],
    cardSize: 'medium',
    showBookmark: true,
    showTags: true,
    iconOnly: false,
    idPlacement: 'header',
    cardStyle: 'margin',
  }
);

const emits = defineEmits<{
  (e: 'select', issue: IssueCardViewModel): void;
  (e: 'deselect'): void;
  (e: 'bookmark', issueId: string): void;
}>();

const { isIssueWatched } = useWatchedIssues();

const onCardClick = (issue: IssueCardViewModel) => {
  if (issue.id === props.selected) {
    emits('deselect');
  } else {
    emits('select', issue);
  }
};

const onBookmark = (issueId: string) => {
  emits('bookmark', issueId);
};
</script>
<template>
  <div
    class="issue-card-list"
    :class="cardStyle"
  >
    <IssueCard
      :selected="selected === issue.id"
      :bookmarked="isIssueWatched(issue.id)"
      :card-size="cardSize"
      :show-bookmark="showBookmark"
      :show-tags="showTags"
      :icon-only="iconOnly"
      :id-placement="idPlacement"
      @click="() => onCardClick(issue)"
      @bookmark="onBookmark"
      class="issue-card-item"
      v-for="issue in issues"
      :key="issue.id"
      :issue="issue"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.issue-card-list {
  display: flex;
  flex-direction: column;

  &.margin {
    gap: 3.2rem;
  }

  &.margin-small {
    gap: 2rem;
  }

  &.border {
    .issue-card-item:not(:last-child) {
      border-bottom: 1px solid colors.$grey-200;
      margin-bottom: 3.2rem;
    }
  }
}

.issue-card-item {
  cursor: pointer;
}
</style>
