<script lang="ts" setup>
import Limit from '@/features/theme/base/Limit.vue';
import { IssueStatusEnum } from '@/generated/graphql';
import IssueDetailedSearchBlock from './IssueDetailedSearchBlock.vue';
import PostCodeCheckboxForm from '@/features/communities/components/PostCodeCheckboxForm.vue';
import IssueSearchStatus from './IssueSearchStatus.vue';
import IssueSearchDelegations from './IssueSearchDelegations.vue';
import IssueSearchProcess from './IssueSearchProcess.vue';
import IssueSearchPeriod from './IssueSearchPeriod.vue';
import IssueSearchTags from './IssueSearchTags.vue';
import { ref } from 'vue';
import { IssueSearch } from '@/features/issues/models';
import { IssueSearchProcessInput } from './IssueSearchProcessInput';

const props = defineProps<{
  issueSearch: IssueSearch;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', issueSearch: IssueSearch): void;
}>();

const updatePostalCodes = (postalCodes: string[]) => {
  const spec = { ...props.issueSearch.spec, postalCodes: postalCodes };
  emits('update:modelValue', { ...props.issueSearch, spec: spec });
};

const onPeriodUpdate = (fromDate: Maybe<Date>, toDate: Maybe<Date>, periodFilter: string[]) => {
  const spec = { ...props.issueSearch.spec, fromDate: fromDate, toDate: toDate };
  const filter = { ...props.issueSearch.filter, periodFilter: periodFilter };
  emits('update:modelValue', { ...props.issueSearch, spec: spec, filter: filter });
};

const updateIssueStatus = (statusTypes: IssueStatusEnum[], statusTypesFilter: string[]) => {
  const spec = { ...props.issueSearch.spec, issueStatus: statusTypes };
  const filter = { ...props.issueSearch.filter, issueStatusFilter: statusTypesFilter };
  emits('update:modelValue', { ...props.issueSearch, spec: spec, filter: filter });
};

const updateProcessPhases = (processPhases: IssueSearchProcessInput[], processPhasesFilter: string[]) => {
  const spec = { ...props.issueSearch.spec, processPhases: processPhases };
  const filter = { ...props.issueSearch.filter, processPhasesFilter: processPhasesFilter };
  emits('update:modelValue', { ...props.issueSearch, spec: spec, filter: filter });
};

const updateTags = (tags: string[]) => {
  const spec = { ...props.issueSearch.spec, tags: tags };
  emits('update:modelValue', { ...props.issueSearch, spec: spec });
};

const updateDelegationIds = (delegationIds: string[], delegationsFilter: string[]) => {
  const spec = { ...props.issueSearch.spec, delegationIds: delegationIds };
  const filter = { ...props.issueSearch.filter, delegationsFilter: delegationsFilter };
  emits('update:modelValue', { ...props.issueSearch, spec: spec, filter: filter });
};

const updateSkipulagsstofnunDelegation = (selected: boolean, delegationsFilter: string[]) => {
  const spec = { ...props.issueSearch.spec, skipulagsstofnunDelegation: selected };
  const filter = { ...props.issueSearch.filter, delegationsFilter: delegationsFilter };
  emits('update:modelValue', { ...props.issueSearch, spec: spec, filter: filter });
};

// TODO: commented out for the time being
// const { stringToFeatures, featuresToGeoJson } = useGeoJson();
// const { transformFeatureIsToWgs } = useProjections();
// const { isFeatureLikeFeature } = useMapModelChecker();

// const onAddressQueryUpdate = (value: string) => {
//   if (value && value.length > 0) {
//     client
//       .query<{ addresses: AddressSearchResultViewModel[] }>(GetAddressesDocument, {
//         query: value,
//       })
//       .toPromise()
//       .then((res) => {
//         if (res.data?.addresses && res.data.addresses.length > 0) {
//           const features = res.data.addresses.flatMap(({ geoJson }) => stringToFeatures(geoJson)).filter(isFeatureLikeFeature);
//           emits('update:modelValue', {
//             ...props.modelValue,
//             geoJson: featuresToGeoJson(features.map(transformFeatureIsToWgs)),
//             geoSearch: value,
//           });
//         }
//       });
//   } else {
//     emits('update:modelValue', {
//       ...props.modelValue,
//       geoJson: null,
//       geoSearch: '',
//     });
//   }
// };

const showLocation = ref(
  (props.issueSearch.spec.geoSearch !== undefined || (props.issueSearch.spec.postalCodes && props.issueSearch.spec.postalCodes.length > 0)) ?? false
);
const showIssueStatus = ref(props.issueSearch.spec.issueStatus ? props.issueSearch.spec.issueStatus.length > 0 : false);
const showDelegations = ref(props.issueSearch.spec.delegationIds ? props.issueSearch.spec.delegationIds.length > 0 : false);
const showProcessPhases = ref(props.issueSearch.spec.processPhases ? props.issueSearch.spec.processPhases.length > 0 : false);
const showPeriod = ref(props.issueSearch.spec.fromDate !== undefined || props.issueSearch.spec.toDate !== undefined);
const showTags = ref(props.issueSearch.spec.tags ? props.issueSearch.spec.tags.length > 0 : false);
</script>

<template>
  <div>
    <IssueDetailedSearchBlock
      class="search-block"
      translation-key="search.location"
      :show-body="showLocation"
      :search-filter="issueSearch.spec.postalCodes?.join(', ')"
    >
      <!--TODO: commented out for the time being-->
      <!-- <InputSearch
        :model-value="modelValue.geoSearch"
        @change="onAddressQueryUpdate"
        fullwidth
        :include-button="false"
        label="search.location.input"
        :icon-options="{ color: Colors.primary }"
        icon="Marker"
      /> -->
      <div class="search-limit-container">
        <Limit
          size="small"
          :expandable="false"
          class="search-limit"
        >
          <PostCodeCheckboxForm
            :custom-class="'checkbox-list'"
            :model-value="issueSearch.spec.postalCodes || []"
            @update:model-value="val => updatePostalCodes(val)"
            class="fade"
          />
        </Limit>
      </div>
    </IssueDetailedSearchBlock>
    <IssueDetailedSearchBlock
      class="search-block"
      translation-key="search.issue_status"
      :show-body="showIssueStatus"
      :search-filter="issueSearch.filter?.issueStatusFilter?.join(', ')"
    >
      <IssueSearchStatus
        :model-value="issueSearch.spec.issueStatus || []"
        :filter="issueSearch.filter?.issueStatusFilter || []"
        @update:model-value="(val, filter) => updateIssueStatus(val, filter)"
      />
    </IssueDetailedSearchBlock>
    <IssueDetailedSearchBlock
      class="search-block"
      translation-key="search.issue_delegations"
      :show-body="showDelegations"
      :search-filter="issueSearch.filter?.delegationsFilter?.join(', ')"
    >
      <IssueSearchDelegations
        :model-value="issueSearch.spec.delegationIds || []"
        :filter="issueSearch.filter?.delegationsFilter || []"
        :skipulagsstofnun-delegation="issueSearch.spec.skipulagsstofnunDelegation || false"
        @update:model-value="(ids, filter) => updateDelegationIds(ids, filter)"
        @update:skipulagsstofnun-delegation="(selected, filter) => updateSkipulagsstofnunDelegation(selected, filter)"
      />
    </IssueDetailedSearchBlock>
    <IssueDetailedSearchBlock
      class="search-block"
      translation-key="search.issue_process"
      :show-body="showProcessPhases"
      :search-filter="issueSearch.filter?.processPhasesFilter?.join(', ')"
    >
      <IssueSearchProcess
        :model-value="issueSearch.spec.processPhases || []"
        :filter="issueSearch.filter?.processPhasesFilter || []"
        @update:model-value="(val, filter) => updateProcessPhases(val, filter)"
      />
    </IssueDetailedSearchBlock>
    <IssueDetailedSearchBlock
      class="search-block"
      translation-key="search.date_period"
      :show-body="showPeriod"
      :search-filter="issueSearch.filter?.periodFilter?.join(', ')"
    >
      <IssueSearchPeriod
        :model-value="issueSearch.spec"
        :filter="issueSearch.filter?.periodFilter || []"
        @update:on-period-update="onPeriodUpdate"
      />
    </IssueDetailedSearchBlock>
    <IssueDetailedSearchBlock
      v-if="true"
      translation-key="search.issue_type"
      :show-body="showTags"
      :search-filter="issueSearch.spec.tags?.join(', ')"
      class="search-block"
    >
      <IssueSearchTags
        :model-value="issueSearch.spec.tags || []"
        @update:model-value="val => updateTags(val)"
      />
    </IssueDetailedSearchBlock>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/colors' as colors;

.search-block {
  border-bottom: 1px solid colors.$grey-300;
}

.search-limit-container {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid colors.$grey-300;
}

.search-limit {
  mask-image: linear-gradient(180deg, #000 98%, transparent);
}
</style>
