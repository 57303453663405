import { Area } from '@/generated/graphql';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { MenuThemes } from '@/features/theme/themes';
import { MenuTheme } from '../theme/models';

type MenuThemeKey = keyof typeof MenuThemes;

export const useNavigationStore = defineStore('navigation', () => {
  const areas = ref<Area[]>([]);
  const activeAreaId = ref<ID | null>(null);

  const areaById = computed(() =>
    areas.value.reduce((prev: Record<ID, Area>, curr) => {
      prev[curr.id] = curr;
      return prev;
    }, {})
  );
  const defaultArea = computed<Area | null>(() => areas.value[0] || null);
  const activeArea = computed<Area | null>(() => {
    if (activeAreaId.value === null || !areaById.value[activeAreaId.value]) {
      return defaultArea.value;
    }
    return areaById.value[activeAreaId.value];
  });

  const setAreas = (a: Area[]) => (areas.value = a);
  const setActiveAreaId = (id: ID | null) => (activeAreaId.value = id);
  const clearActiveAreaId = () => setActiveAreaId(null);

  const mainMenu = ref<boolean>(true);
  const showMainMenu = (show: boolean = true) => (mainMenu.value = show);
  const mainMenuMargin = ref<boolean>(true);
  const setMainMenuMargin = (flag: boolean = true) => (mainMenuMargin.value = flag);

  const mainFooter = ref<boolean>(true);
  const showMainFooter = (show: boolean = true) => (mainFooter.value = show);
  const mainFooterMargin = ref<boolean>(true);
  const setMainFooterMargin = (flag: boolean = true) => (mainFooterMargin.value = flag);

  const mainMenuThemeKey = ref<MenuThemeKey>('default');
  const mainMenuTheme = computed<MenuTheme>(() => MenuThemes[mainMenuThemeKey.value]);
  const setMainMenuTheme = (theme: keyof typeof MenuThemes) => {
    mainMenuThemeKey.value = theme;
  };

  const sidebar = ref<boolean>(false);
  const showSideBar = (show: boolean = true) => (sidebar.value = show);

  const resetDefaults = () => {
    mainMenuThemeKey.value = 'default';
    mainMenu.value = true;
    mainMenuMargin.value = true;
    mainFooter.value = true;
    mainFooterMargin.value = true;
    sidebar.value = false;
  };

  return {
    areas,
    activeAreaId,

    areaById,
    defaultArea,
    activeArea,

    setAreas,
    setActiveAreaId,
    clearActiveAreaId,

    mainMenu,
    showMainMenu,
    mainMenuMargin,
    setMainMenuMargin,

    mainFooter,
    showMainFooter,
    mainFooterMargin,
    setMainFooterMargin,

    mainMenuTheme,
    mainMenuThemeKey,
    setMainMenuTheme,

    sidebar,
    showSideBar,

    resetDefaults,
  };
});

export default useNavigationStore;
