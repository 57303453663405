<script lang="ts" setup>
const emits = defineEmits<{
  (e: 'click'): void;
}>();

const onClick = () => {
  emits('click');
};
</script>
<template>
  <div @click="onClick" class="action"><slot /></div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.action {
  background: colors.$white;
  box-shadow: 0rem 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
}
</style>
