<script lang="ts" setup>
import Geometry from 'ol/geom/Geometry';
import LayerVector from '@/features/map/components/layers/LayerVector.vue';
import SourceVector from '@/features/map/components/sources/SourceVector.vue';
import InteractionDraw from '@/features/map/components/interactions/InteractionDraw.vue';
import { Options } from 'ol/layer/BaseVector';
import VectorSource from 'ol/source/Vector';
import { DrawTool, LayerZIndex } from '@/features/issues/models/issueMaps';
import { FeatureDecorated } from '@/features/map/models/mapModels';

withDefaults(
  defineProps<{
    options?: Options<VectorSource<Geometry>>;
    drawType?: DrawTool;
    freehand?: boolean;
  }>(),
  {
    options: () => ({
      zIndex: LayerZIndex.Draw,
    }),
    freehand: false,
  }
);

const emits = defineEmits<{
  (e: 'feature', feature: FeatureDecorated<Geometry>): void;
}>();

const onDrawEnd = (feature: FeatureDecorated<Geometry>) => {
  emits('feature', feature);
};
</script>
<template>
  <LayerVector :options="options">
    <SourceVector :options="{ wrapX: false }">
      <template v-for="t in ['Point', 'LineString', 'Polygon']" :key="t">
        <InteractionDraw v-if="t === drawType" :options="{ type: t, freehand }" @drawend="onDrawEnd" />
      </template>
    </SourceVector>
  </LayerVector>
</template>
