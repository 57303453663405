<script lang="ts" setup></script>
<template>
  <div class="layout-centered container--medium">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
.layout-centered {
}
</style>
