import { UserRoleType } from '@/generated/graphql';
import jwt_decode from 'jwt-decode';

import { Claims } from './models';

const TOKEN_STORAGE_KEY = 'token';
const REAL_USER_TOKEN_STORAGE_KEY = 'real_user_token';

let _token: string | null = null;

export const tokenService = {
  clearToken() {
    _token = null;
    localStorage.removeItem(TOKEN_STORAGE_KEY);
  },
  saveToken(token: string) {
    localStorage.setItem(TOKEN_STORAGE_KEY, token);
    _token = token;
  },
  getToken() {
    if (_token === null) {
      _token = localStorage.getItem(TOKEN_STORAGE_KEY);
    }

    return _token;
  },
  getTokenActiveRole(): UserRoleType {
    const token = this.getToken();
    if (!token) {
      throw new Error('authService.ts::getUserRole: Token is falsy');
    }

    const claims = jwt_decode<Claims>(token);
    return claims.access_role as UserRoleType;
  },
  getTokenDelegationMemberId() {
    const token = this.getToken();
    if (!token) {
      throw new Error('authService.ts::getTokenDelegationMemberId: Token is falsy');
    }

    const claims = jwt_decode<Claims>(token);

    return claims.active_delegation_member_id;
  },
  getTokenUserId(token: string) {
    const claims = jwt_decode<Claims>(token);
    return claims.user_id;
  },

  isFakeUser() {
    const token = this.getToken();
    if (!token) {
      throw new Error('authService.ts::isFakeUser: Token is falsy');
    }

    const claims = jwt_decode<Claims>(token);
    return !!claims.is_fake;
  },
  clearRealUserToken() {
    localStorage.removeItem(REAL_USER_TOKEN_STORAGE_KEY);
  },
  saveRealUserToken(token: string) {
    localStorage.setItem(REAL_USER_TOKEN_STORAGE_KEY, token);
  },
  getRealUserToken() {
    return localStorage.getItem(REAL_USER_TOKEN_STORAGE_KEY);
  },
};

export default tokenService;
