<script lang="ts" setup>
import IssueSearch from '@/features/issueSearch/components/IssueSearch.vue';
import useSidebar from './useSidebar';

const { hideSidebar } = useSidebar();

const onContainerClick = () => {
  hideSidebar();
};
</script>
<template>
  <div @click="onContainerClick" class="sidebar-container">
    <div @click.stop class="sidebar-content-container">
      <IssueSearch />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/z-indicies' as zIndices;
@use '@/scss/design-tokens/media-queries' as mq;

.sidebar-container {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;
  background-color: rgba(colors.$primary--dark, 0.5);
  z-index: zIndices.$z-index-sidebar;
}

.sidebar-content-container {
  box-sizing: border-box;
  width: 95%;

  @include mq.laptop {
    width: 50%;
  }
}
</style>
