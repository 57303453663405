<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string;
    name?: string;
    modelValue?: boolean;
    disabled?: boolean;
    customClass?: string;
  }>(),
  {
    name: '',
    modelValue: false,
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();
const onInputChange = () => {
  emit('update:modelValue', !props.modelValue);
};
</script>

<template>
  <div class="checkbox-container" :class="`${customClass}`">
    <label class="checkbox" :for="id" :class="{ disabled }">
      <input type="checkbox" :id="id" :name="name" :checked="modelValue" @change="onInputChange" :disabled="disabled" />
      <span class="checkbox-checkmark" :class="{ disabled }"></span>
      <span class="checkbox-label" :class="{ disabled }"><slot></slot></span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/media-queries' as mq;

.checkbox-container {
  padding-left: 0.2rem;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  padding-left: 30px;
  padding-top: 2px;

  &.disabled {
    cursor: no-drop;
  }
}

.checkbox:hover {
  color: color.$blue-lagoon;
  .checkbox-checkmark {
    box-shadow: 0px 0px 0px 2px color.$blue-lagoon;
    border-radius: 2px;
    &.disabled {
      box-shadow: 0px 0px 0px 2px color.$grey-200;
    }
  }
}

.checkbox input:checked ~ .checkbox-checkmark {
  background-color: color.$blue-lagoon;
  box-shadow: 0px 0px 0px 2px color.$blue-lagoon;
}

.checkbox input:checked ~ .checkbox-label {
  color: color.$blue-lagoon !important;
  @include font.bold-font-style;
}

/* Custom checkmark */
.checkbox .checkbox-checkmark:after {
  left: 8px;
  width: 6px;
  height: 16px;
  border: solid color.$white;
  border-width: 0 2px 2px 0;
  transform: rotate(40deg);
}

/* Show the checkmark when checked */
.checkbox input:checked ~ .checkbox-checkmark:after {
  display: block;
}

/* Custom checkbox */
.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: color.$white;
  box-shadow: 0px 0px 0px 2px color.$grey-700;
  border-radius: 2px;
  &.disabled {
    box-shadow: 0px 0px 0px 2px color.$grey-200;
  }
}

/* Create checkmark (hide when not checked) */
.checkbox-checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.checkbox-label {
  &.disabled {
    color: color.$grey-600;
  }
  @include mq.mobile() {
    font-size: 1.5rem;
  }
}
</style>
