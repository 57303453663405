import { IssueCardViewModel } from './models';
import uniqBy from 'lodash-es/uniqBy';

let _cache: IssueCardViewModel[] = [];

export const issueCardsCache = {
  get() {
    return [..._cache];
  },

  clear() {
    _cache = [];
  },

  concat(arr: IssueCardViewModel[]) {
    _cache = uniqBy([..._cache, ...arr], i => i.id);
  },
};
