<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import InputText from '@/features/theme/base/InputText.vue';
import { useGetAvailableDelegatorListsQuery, DelegationType, UserRoleType, DelegationTicketInput } from '@/generated/graphql';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import DropDown from '@/features/theme/base/DropDown.vue';
import Option from '@/features/theme/base/models/Option';
import { computed, ref, watchEffect } from 'vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { User } from '@/features/auth/models';
import useAuthStore from '@/features/auth/useAuthStore';



const props = defineProps<{
  selectedUser: User;
  modelValue: DelegationTicketInput[];
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', model: DelegationTicketInput[]): void;
  (e: 'submit'): void;
}>();

const onSubmit = () => {
  emits('submit');
};

const inputs: DelegationTicketInput[] = props.selectedUser.delegations.map((d) => {
  return {
    delegationId: d.delegation?.id,
    ticketId: d.id,
    email: d.email,
    role: d.role,
    delegationType: d.delegation?.type,
  };
});

props.modelValue.push(...inputs);

const { mapDelegationRoleTranslationKey } = useAuthStore();

const { data: delegatorLists } = useGetAvailableDelegatorListsQuery();

const selectedDelegatorType = ref<Maybe<string>>();
const delegatorTypeOptions = computed<Option[]>(() => {
  return (delegatorLists.value?.availableDelegators || []).map((ad) => ({
    name: ad.title,
    value: ad.type,
  }));
});

watchEffect(() => {
  if (!selectedDelegatorType.value && delegatorTypeOptions.value.length) {
    selectedDelegatorType.value = delegatorTypeOptions.value[0].value;
  }
});

const delegators = (dt: DelegationType): Option[] => {
  const list = delegatorLists.value?.availableDelegators.find((d) => d.type === dt);
  return list?.delegators.map((item) => ({ name: item.name, value: item.delegationId })) || [];
};

const delegationRoles = (dt: DelegationType): Option[] => {
  const list = delegatorLists.value?.availableDelegators.find((d) => d.type === dt);
  return list?.availableRoles.map((role) => ({ name: mapDelegationRoleTranslationKey(role), value: role.toString() })) || [];
};

const onClickAdd = () => {
  props.modelValue.push({
    delegationType: selectedDelegatorType.value as DelegationType,
  });
};

const onClickDelete = (index: number) => props.modelValue.splice(index, 1);
</script>

<template>
  <form @submit.prevent="onSubmit" class="container">
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <DropDown
            :model-value="selectedDelegatorType"
            @update:model-value="(val) => (selectedDelegatorType = val)"
            fullwidth
            label="user.delegation.type"
            :options="delegatorTypeOptions"
          ></DropDown>
        </LayoutInput>
        <LayoutInput>
          <Button class="w-100" @click="onClickAdd" :type="ButtonType.secondary" :prevent="true">
            <span class="mr-1">
              <Translate t="base.add" />
            </span>
            <Icon icon="Plus" :options="{ width: 21, height: 21, color: Colors.primaryDark }" />
          </Button>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup v-for="(member, index) in props.modelValue" :key="index">
        <template class="mb-4" #explanation>
          <div class="flex flex-align-bottom">
            <p class="bold p--large">
              <Translate t="user.delegation.on_behalf_of" :interpolations="{ delegatorTypeName: $t(`user.delegation.type.${member!.delegationType}`) }" />
            </p>
          </div>
        </template>
        <LayoutInput :span="2">
          <DropDown
            :model-value="member.delegationId"
            @update:model-value="(val) => (member.delegationId = val)"
            fullwidth
            label="Umboðsveitendur"
            :options="delegators(member.delegationType!)"
          ></DropDown>
        </LayoutInput>
        <LayoutInput>
          <DropDown
            :model-value="member.role"
            @update:model-value="val => { member.role = val as UserRoleType; }"
            fullwidth
            label="Hlutverk"
            :options="delegationRoles(member.delegationType!)"
          ></DropDown>
        </LayoutInput>
        <LayoutInput :span="2">
          <InputText fullwidth v-model="member.email" label="base.email" />
        </LayoutInput>
        <LayoutInput>
          <Button @click="() => onClickDelete(index)" :type="ButtonType.tertiaryIconOnly" :prevent="true">
            <Icon icon="Delete" />
          </Button>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <Button :type="ButtonType.primary">{{ $t('base.save') }}</Button>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
</template>

<style lang="scss" scoped>
.container {
  min-width: 64vw;
}

.flex-align-bottom {
  align-items: flex-end;
}
</style>
