import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import useGeoJson from '@/features/map/composables/useGeoJson';
import useMapModelChecker from '@/features/map/composables/useMapModelChecker';
import useProjections from '@/features/map/composables/useProjections';
import { Issue, IssueDetails, IssuePhase, MapIssue } from '@/features/issues/models';
import useIssuePhaseStyles from './useIssuePhaseStyles';
import { Extent, createEmpty as createEmptyExtent, extend as extendExtent } from 'ol/extent';
import { isFuture, isPast, parseISO } from 'date-fns';
import Style from 'ol/style/Style';
import Circle from 'ol/style/Circle';

type TIssue = Issue | IssueDetails | MapIssue;
type TPhase = IssuePhase | MapIssue['currentPhase'];

export default function useIssueFeatures() {
  const { stringToGeometry } = useGeoJson();
  const { transformGeometryWgsToIs } = useProjections();
  const { isGeometryPoint, isGeometryPolygon, isGeometryLine } = useMapModelChecker();
  const { phaseNameToMarkerStyle, defaultMarkerStyle, phaseNameToFeatureStyle, defaultFeatureStyle } = useIssuePhaseStyles();

  const getCurrentPhaseState = (issue: TIssue, phase: TPhase) => {
    if (issue.isHidden || !phase) {
      return 'unpublished';
    }

    const reviewStartDatePassed = phase.reviewStartDate && isPast(parseISO(phase.reviewStartDate));
    const reviewEndDateNotPassed = phase.reviewEndDate && isFuture(parseISO(phase.reviewEndDate));

    if (phase.state === 'published') {
      return phase.hasReviews && reviewStartDatePassed && reviewEndDateNotPassed ? 'published' : 'draft';
    } else if (phase.state === 'closed' && !phase.isLast) {
      return 'in_progress';
    }

    return phase.state || 'draft';
  };

  const getIssueFeatures = (issue: Issue | IssueDetails): Feature[] => {
    const features: Feature[] = [];

    for (const feature of issue.geographies?.features || []) {
      const geom = stringToGeometry(feature.geometry);
      if (geom) {
        const feature = new Feature(transformGeometryWgsToIs(geom));

        const style = issue.currentPhase ? phaseNameToFeatureStyle(getCurrentPhaseState(issue, issue.currentPhase)) : defaultFeatureStyle;
        if (feature.getGeometry() instanceof Point) {
          feature.setStyle(
            new Style({
              image: new Circle({
                radius: 5,
                fill: style.getFill(),
                stroke: style.getStroke(),
              }),
            })
          );
        } else {
          feature.setStyle(style);
        }
        features.push(feature);
      }
    }
    return features;
  };

  const getIssueMarkerFeature = (issue: TIssue): Feature<Point> | null => {
    if (!issue.marker) {
      return null;
    }

    let geom = stringToGeometry(issue.marker);

    if (!geom) {
      return null;
    }

    geom = transformGeometryWgsToIs(geom);
    let markerFeature: Feature<Point> | null = null;

    if (isGeometryPoint(geom)) {
      markerFeature = new Feature(geom);
    } else {
      const extent = geom.getExtent();
      const x = extent[0] + (extent[2] - extent[0]) / 2;
      const y = extent[1] + (extent[3] - extent[1]) / 2;
      markerFeature = new Feature<Point>([x, y]);
    }

    if (markerFeature) {
      markerFeature.setId(issue.id);
      markerFeature.setStyle(issue.currentPhase ? phaseNameToMarkerStyle(getCurrentPhaseState(issue, issue.currentPhase)) : defaultMarkerStyle);
    }

    return markerFeature;
  };

  const getIssueFeatureExtent = (issue: Issue | IssueDetails): Extent => {
    let extent = createEmptyExtent();
    const markerExtent = getIssueMarkerFeature(issue)?.getGeometry()?.getExtent();
    if (markerExtent) {
      extent = extendExtent(extent, markerExtent);
    }
    for (const feature of getIssueFeatures(issue)) {
      const featureExtent = feature.getGeometry()?.getExtent();

      if (featureExtent) {
        extent = extendExtent(extent, featureExtent);
      }
    }
    return extent;
  };

  return { getIssueFeatures, getIssueMarkerFeature, getIssueFeatureExtent };
}
