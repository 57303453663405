import { FeatureDecorated } from '@/features/map/models/mapModels';
import Feature from 'ol/Feature';
import { Geometry } from 'ol/geom';
import { MaybeRef, unref } from 'vue';

const _blacklist: { [key: string]: string[] } = {};

type MaybeDecorated = FeatureDecorated<Geometry> | Feature<Geometry>;
type MaybeFeature = MaybeRef<MaybeDecorated>;

export const unDecorateFeature = (maybeDecorated: MaybeDecorated) => (maybeDecorated instanceof Feature ? maybeDecorated : maybeDecorated.feature);
export const unRefFeature = (maybeRef: MaybeFeature) => unDecorateFeature(unref(maybeRef));
export const featureCmpKey = (maybeRef: MaybeFeature) => {
  const feature = unRefFeature(maybeRef);
  const geometry = feature?.getGeometry();
  if (geometry) {
    const flatCoords = JSON.parse(JSON.stringify(geometry)).flatCoordinates.toString();
    return flatCoords
      .split(',')
      .map((c: string) => {
        if (c?.split('.')) return c.split('.')[0];
      })
      .toString();
  }
};

export const isFeatureCoordinatesEqual = (a: MaybeFeature, b: MaybeFeature) => {
  return featureCmpKey(a) === featureCmpKey(b);
};

export const issueGeometriesBlacklist = {
  add(issueId: string, maybeFeature: MaybeFeature) {
    const feature = unRefFeature(maybeFeature);
    const cmp = featureCmpKey(feature);
    if (!_blacklist[issueId]) {
      _blacklist[issueId] = [];
    }

    if (!_blacklist[issueId].includes(cmp)) {
      _blacklist[issueId].push(cmp);
    }
  },
  concat(issueId: string, maybeFeatures: MaybeFeature[]) {
    for (const mf of maybeFeatures) {
      this.add(issueId, mf);
    }
  },
  clearIssue(issueId: string) {
    delete _blacklist[issueId];
  },
  get(issueId: string) {
    return [...(_blacklist[issueId] || [])];
  },
  contains(issueId: string, maybeFeature: MaybeFeature) {
    return !!_blacklist[issueId]?.includes(featureCmpKey(unRefFeature(maybeFeature)));
  },
};
