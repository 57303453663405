<script lang="ts" setup>
import Checkbox from '@/features/theme/base/Checkbox.vue';
import Translate from '@/features/translations/Translate.vue';
import useIssueTags from '@/features/issues/composables/useIssueTags';
import Limit from '@/features/theme/base/Limit.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
    title?: string;
  }>(),
  {
    modelValue: () => [],
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

const toggleTag = (tag: string, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, tag]);
  } else {
    const idx = props.modelValue.findIndex(pc => pc === tag);
    if (idx !== -1) {
      emit('update:modelValue', [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)]);
    }
  }
};

const { tagsByType, tagTypeKeysSorted } = useIssueTags();

const isTagSelected = (tag: string) => {
  return (props.modelValue || []).includes(tag);
};

function getTagsByType(key: string) {
  if (tagsByType.value[key] !== undefined) {
    return tagsByType.value[key];
  }

  return [];
}
</script>

<template>
  <div class="grid-container">
    <div
      v-for="type in tagTypeKeysSorted"
      :key="type"
      class="grid-item"
    >
      <div class="grid-item-column">
        <h5 class="tags-caption my-1">
          <Translate :value="`tags.type.${type}`" />
        </h5>
        <div class="search-limit-container">
          <Limit
            size="small"
            :expandable="false"
            class="search-limit"
          >
            <Checkbox
              :model-value="isTagSelected(tag.value)"
              @update:model-value="val => toggleTag(tag.value, val)"
              class="checkbox-item"
              v-for="tag in getTagsByType(type)"
              :id="tag.value"
              :key="tag.value"
            >
              {{ tag.value }}
            </Checkbox>
          </Limit>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/colors' as colors;

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @include mq.mobile() {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-item {
  display: inline-flex;
  align-items: center;
  &:last-child {
    margin-bottom: 4rem;
  }
  @include mq.mobile() {
    width: 100%;
    &:first-child {
      margin-bottom: 0.5rem;
    }
    &:last-child {
      margin-bottom: 6rem;
    }
  }
}

.grid-item-column {
  width: 100%;
}

.search-limit-container {
  padding: 0.5rem 1rem 0.5rem 1rem;
  border: 1px solid colors.$grey-300;
}

.search-limit {
  mask-image: linear-gradient(transparent, #000 2%, #000 98%, transparent);
}

.checkbox-item {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tags-caption {
  font-size: 1.8rem;

  @include mq.mobile() {
    font-size: 1.6rem;
  }
}
</style>
