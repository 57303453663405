<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { computed, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    cols?: number;
    rows?: number;
    label?: string;
    max?: number;
    modelValue?: Maybe<string>;
    disabled?: boolean;
    fullwidth?: boolean;
    errorMessage?: Maybe<string>;
    customClass?: string;
  }>(),
  {
    modelValue: '',
    disabled: false,
    fullwidth: false
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const isFocus = ref(false);
const isActive = computed(() => isFocus.value || inputValue.value !== '');
const localValue = ref<string>('');
const inputValue = computed(() => localValue.value || props.modelValue || '');
const onInput = (e: Event) => {
  localValue.value = (e.target as HTMLInputElement).value || '';
  emit('update:modelValue', localValue.value);
};
watch(
  () => props.modelValue,
  (newValue) => {
    localValue.value = newValue || '';
  }
);
</script>

<template>
  <div class="input_container" :class="{ active: isActive, disabled, fullwidth }">
    <slot v-if="label" name="label">
      <label class="text_label regular">
        <Translate :value="label" />
      </label>
    </slot>
    <textarea
      class="textarea_input"
      :value="inputValue"
      @focus="isFocus = true"
      @focusout="isFocus = false"
      @input="onInput"
      :class="[{'has-error': errorMessage}, `${customClass}`]"
      :cols="cols"
      :rows="rows"
      :disabled="disabled"
    ></textarea>
    <div class="number_count grey-600" v-if="max">
      <Translate
        t="base.charlimit"
        :interpolations="{
          n: inputValue.length,
          max,
        }"
      />
    </div>
    <Translate v-if="errorMessage" class="error-message" :value="errorMessage" />
  </div>
</template>

<style lang="scss" scoped>
textarea {
  background-color: white;
  width: 100%;
}

.input_container {
  display: inline-block;
  width: fit-content;
  max-width: unset;

  &.fullwidth {
    width: 100%;
  }
}

.textarea_history {
  overflow-y: auto !important;
  padding: 1rem !important;
  display: flex;
}

.textarea_history:hover {
  cursor: auto;
}

.number_count {
  margin-top: 0.8rem;
  text-align: right;
}
</style>
