<template>
  <div class="accordion">
    <slot
      :accordionToggle="accordionToggle"
      :accordionShow="accordionShow"
      :accordionHide="accordionHide"
      :accordionBodyVisible="bodyVisible"
    />
    <div class="accordion__bodyContainer">
      <Transition
        :appear="animateInitially"
        @before-enter="onAnimBeforeEnter"
        @enter="onAnimEnter"
        @after-enter="onAnimAfterEnter"
        @before-leave="onAnimBeforeLeave"
        @leave="onAnimLeave"
      >
        <div
          v-if="bodyVisible"
          ref="accordionBody"
          class="accordion__body"
          :class="{ accordion__anim: animated }"
          :style="{ transitionDuration: animated ? transitionDuration : '', transitionTimingFunction: animated ? transitionTimingFunction : '' }"
        >
          <slot
            :accordionToggle="accordionToggle"
            :accordionHide="accordionHide"
            name="body"
          />
        </div>
      </Transition>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

interface AccordionState {
  bodyVisible: boolean;
}

export default defineComponent({
  props: {
    /**
     * Use to toggle accordion body. Use this or slot provided function
     */
    showBody: {
      required: false,
      type: Boolean,
      default: false,
    },
    /**
     * Determine if the accordion should be animated
     */
    animated: {
      required: false,
      type: Boolean,
      default: true,
    },

    /**
     * Determine if the accordion body should be animated initially, if showBody is intialized as true
     */
    animateInitially: {
      required: false,
      type: Boolean,
      default: false,
    },

    /**
     * Specify the CSS transition duration
     */
    transitionDuration: {
      required: false,
      type: String,
      default: '.2s',
    },

    /**
     * Specify the CSS transition timing function
     */
    transitionTimingFunction: {
      required: false,
      type: String,
      default: 'ease-out',
    },

    useCalculatedHeight: {
      required: false,
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      bodyVisible: false,
    } as AccordionState;
  },
  watch: {
    showBody(value) {
      this.bodyVisible = value;
    },
  },
  created() {
    this.bodyVisible = this.showBody;
  },
  methods: {
    accordionToggle(): void {
      this.bodyVisible = !this.bodyVisible;
    },
    accordionShow(): void {
      this.bodyVisible = true;
    },
    accordionHide(): void {
      this.bodyVisible = false;
    },
    handleResize(): void {
      if (this.$refs.accordionBody) {
        const el = this.$refs.accordionBody as HTMLElement;
        el.style.maxHeight = `${el.scrollHeight}px`;
      }
    },
    onAnimBeforeEnter(el: Element): void {
      if (this.useCalculatedHeight) {
        // eslint-disable-next-line no-param-reassign
        (el as HTMLElement).style.maxHeight = '0px';
      }
    },
    onAnimEnter(el: Element): void {
      if (this.useCalculatedHeight) {
        // eslint-disable-next-line no-param-reassign
        (el as HTMLElement).style.maxHeight = `${el.scrollHeight}px`;
      }
    },
    onAnimAfterEnter(): void {
      window.addEventListener('resize', this.handleResize);
    },
    onAnimBeforeLeave(el: Element): void {
      window.removeEventListener('resize', this.handleResize);
      if (this.useCalculatedHeight) {
        // eslint-disable-next-line no-param-reassign
        (el as HTMLElement).style.maxHeight = `${el.scrollHeight}px`;
      }
    },
    onAnimLeave(el: Element): void {
      if (this.useCalculatedHeight) {
        // eslint-disable-next-line no-param-reassign
        (el as HTMLElement).style.maxHeight = '0px';
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.accordion {
  .accordion__bodyContainer {
    overflow: hidden;
    word-break: normal;
  }
  .accordion__body {
    z-index: -1;

    &.accordion__anim {
      transition-property: max-height;
    }
  }
}
</style>
