import messages, { TranslationKey } from './defaultMessages';

interface UseTranslationModelChecker {
  isTranslationKey(v: unknown): v is TranslationKey;
}

export default function useTranslationModelChecker(): UseTranslationModelChecker {
  const isTranslationKey = (v: unknown): v is TranslationKey => {
    const key = v as TranslationKey;
    return typeof key === 'string' && !!messages[key];
  };

  return {
    isTranslationKey,
  };
}
