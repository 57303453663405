<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import NineDots from '@/features/theme/icons/NineDots.vue';
import { ButtonType, ButtonSize } from '@/features/theme/base/models/ButtonType';
import useAuthUser from '@/features/auth/useAuthUser';
import { MenuTheme } from '@/features/theme/models';
import { MenuThemes } from '@/features/theme/themes';
import { onMounted, onUnmounted } from 'vue';
import Translate from '../translations/Translate.vue';

const props = withDefaults(
  defineProps<{
    open?: boolean;
    theme?: MenuTheme;
  }>(),
  {
    open: false,
    theme: () => MenuThemes.default,
  }
);

const emits = defineEmits<{
  (e: 'update:open', open: boolean): void;
}>();

const {
  isAuthenticated,
  username,
  nationalId,

  hasActiveDelegation,
  activeDelegation,
} = useAuthUser();

const toggleOpen = () => {
  emits('update:open', !props.open);
};

const onDocumentClick = () => {
  if (props.open) {
    emits('update:open', false);
  }
};

onMounted(() => {
  document.addEventListener('click', onDocumentClick);
});

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick);
});
</script>

<template>
  <div
    @click.stop
    class="nav-container"
  >
    <Button
      :type="theme.menuButtonType || ButtonType.primary"
      :size="ButtonSize.medium"
      @click="toggleOpen"
      class="hidden-in-mobile"
    >
      <NineDots
        :color="theme.menuButtonIconColor"
        :custom-class="'icon-left'"
      />
      <Translate t="nav.menu" />
    </Button>
    <Button
      :type="theme.menuButtonType || ButtonType.primary"
      :size="ButtonSize.small"
      @click="toggleOpen"
      class="hidden-in-desktop hidden-in-laptop"
    >
      <span class="sr-only">Navigation Menu</span>
      <NineDots :color="theme.menuButtonIconColor" />
    </Button>
    <div
      class="nav-menu"
      v-if="open"
    >
      <div
        v-if="isAuthenticated"
        class="user-info"
      >
        <p class="bold username">{{ username }}</p>
        <p
          v-if="hasActiveDelegation"
          class="regular"
        >
          <Translate t="auth.delegation" />
          : {{ activeDelegation?.delegation?.entityName }}
        </p>
        <p
          v-else-if="nationalId"
          class="regular"
        >
          <Translate t="base.ssn.short" />
          . {{ nationalId }}
        </p>
      </div>
      <div class="router-links">
        <slot name="top" />
      </div>
      <div class="bottom-links router-links">
        <slot name="bottom" />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/z-indicies' as zIndex;

.nav-container {
  position: relative;
  width: fit-content;
}

.nav-menu {
  position: absolute;
  top: 70px;
  right: 0;
  width: 330px;
  background-color: white;
  box-shadow: 0px 24px 96px rgb(0 111 126 / 10%);
  z-index: zIndex.$z-index-dropdown-options;
}

.user-info {
  box-sizing: border-box;
  width: 100%;
  padding: 24px 56px;
  background-color: colors.$grey-200;
  color: colors.$black;

  .username {
    font-size: 1.6rem;
  }
}

.router-links {
  display: flex;
  flex-direction: column;
}

.bottom-links {
  border-top: 1px solid colors.$grey-200;
}
</style>
