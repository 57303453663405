import { DelegationTicket } from '@/generated/graphql';
import { computed, ComputedRef } from 'vue';
import { User } from './models';
import useAuthStore from './useAuthStore';

interface UseAuthUser {
  isAuthenticated: ComputedRef<boolean>;
  user: ComputedRef<Maybe<User>>;
  username: ComputedRef<Maybe<string>>;
  nationalId: ComputedRef<Maybe<string>>;

  hasActiveDelegation: ComputedRef<boolean>;
  activeDelegation: ComputedRef<Maybe<DelegationTicket>>;

  isAdmin: ComputedRef<boolean>;
}

export default function useAuthUser(): UseAuthUser {
  const authStore = useAuthStore();

  const user = computed<Maybe<User>>(() => authStore.user);
  const isAuthenticated = computed<boolean>(() => !!authStore.user);
  const username = computed<Maybe<string>>(() => authStore.user?.fullName);
  const nationalId = computed<Maybe<string>>(() => authStore.user?.nationalId);
  const hasActiveDelegation = computed<boolean>(() => !!authStore.activeDelegation);
  const activeDelegation = computed<Maybe<DelegationTicket>>(() => authStore.activeDelegation);

  const isAdmin = computed<boolean>(() => authStore.isAdmin);

  return {
    user,
    isAuthenticated,
    username,
    nationalId,

    hasActiveDelegation,
    activeDelegation,

    isAdmin,
  };
}
