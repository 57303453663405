import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { Colors } from '@/features/theme/base/models/Colors';
import { FeatureStyle, MarkerStyle, StyleEnum } from '@/features/issues/models/issueStyles';

type StyleConfig = {
  [key in StyleEnum]: {
    marker: Style;
    feature: Style;
  };
};

interface UseIssueFeatureStyles {
  styleConfig: StyleConfig;

  phaseNameToStyleEnum: (name?: Maybe<string>) => StyleEnum;

  defaultMarkerStyle: Style;
  phaseNameToMarkerStyle: (name: string) => Style;

  defaultFeatureStyle: Style;
  phaseNameToFeatureStyle: (name: string) => Style;
}

export default function useIssueFeatureStyles(defaultStyleKey: StyleEnum = StyleEnum.Created): UseIssueFeatureStyles {
  const markerStyle = (config: MarkerStyle): Style => {
    return new Style({
      image: new Icon({
        src: config.icon,
      }),
    });
  };

  const featureStyle = (config: FeatureStyle): Style => {
    return new Style({
      stroke: new Stroke({
        color: config.strokeColor,
        width: 2,
      }),
      fill: new Fill({
        color: config.fillColor,
      }),
    });
  };

  const styleConfig: StyleConfig = {
    Confirmed: {
      marker: markerStyle({ icon: '/map/confirmed.png' }),
      feature: featureStyle({ strokeColor: Colors.green, fillColor: `${Colors.greenLight}B0` }),
    },
    Created: {
      marker: markerStyle({ icon: '/map/created.png' }),
      feature: featureStyle({ strokeColor: Colors.blue, fillColor: `${Colors.blueLight}B0` }),
    },
    InProgress: {
      marker: markerStyle({ icon: '/map/in_progress.png' }),
      feature: featureStyle({ strokeColor: Colors.orange, fillColor: `${Colors.orangeLight}B0` }),
    },
    Rejected: {
      marker: markerStyle({ icon: '/map/rejected.png' }),
      feature: featureStyle({ strokeColor: Colors.red, fillColor: `${Colors.redLight}B0` }),
    },
    ToReview: {
      marker: markerStyle({ icon: '/map/in_review.png' }),
      feature: featureStyle({ strokeColor: Colors.purple, fillColor: `${Colors.purpleLight}B0` }),
    },
  };

  const defaultStyle = styleConfig[defaultStyleKey];

  const phaseNameToStyleEnum = (name?: Maybe<string>): StyleEnum => {
    if (!name) {
      return defaultStyleKey;
    }

    const phases: Record<string, StyleEnum> = {
      draft: StyleEnum.Created,
      published: StyleEnum.InProgress,
      closed: StyleEnum.Confirmed,
      unpublished: StyleEnum.Rejected,
    };

    return phases[name] || defaultStyleKey;
  };

  const phaseNameToMarkerStyle = (name: string): Style => {
    return styleConfig[phaseNameToStyleEnum(name)].marker;
  };

  const phaseNameToFeatureStyle = (name: string): Style => {
    return styleConfig[phaseNameToStyleEnum(name)].feature;
  };

  return {
    styleConfig,
    phaseNameToStyleEnum,

    defaultMarkerStyle: defaultStyle.marker,
    phaseNameToMarkerStyle,

    defaultFeatureStyle: defaultStyle.feature,
    phaseNameToFeatureStyle,
  };
}
