import useCollectionSearching from '@/features/composables/useCollectionSearching';
import { Monitoring, MonitoringDetails } from '@/features/monitoring/models';
import { defineStore } from 'pinia';
import { ref } from 'vue';

type StoreMonitoring = Monitoring | MonitoringDetails;

export const useMonitoringStore = defineStore('monitoring', () => {
  const { findIndexById } = useCollectionSearching();
  const monitorings = ref<StoreMonitoring[]>([]);

  const setMonitorings = (list: StoreMonitoring[]) => (monitorings.value = list);
  const setMonitoringDetails = (monitoring: MonitoringDetails) => {
    const idx = findIndexById(monitorings.value, monitoring.id);

    if (idx === -1) {
      monitorings.value.push(monitoring);
    } else {
      monitorings.value[idx] = monitoring;
    }
  };

  const removeMonitoring = (id: ID) => {
    const idx = findIndexById(monitorings.value, id);

    if (idx !== -1) {
      monitorings.value = [...monitorings.value.slice(0, idx), ...monitorings.value.slice(idx + 1)];
    }
  };

  return {
    monitorings,

    setMonitorings,
    setMonitoringDetails,
    removeMonitoring,
  };
});
