<script setup lang="ts">
import { ButtonType, ButtonSize } from './models/ButtonType';

const props = withDefaults(
  defineProps<{
    tag?: string;
    type?: ButtonType;
    size?: ButtonSize;
    prevent?: boolean;
    disabled?: boolean;
  }>(),
  {
    tag: 'button',
    type: ButtonType.primary,
    size: ButtonSize.medium,
    prevent: false,
    disabled: false,
  }
);

const emits = defineEmits<{
  (e: 'click'): void;
}>();

const onBtnClick = (e: Event) => {
  if (props.prevent) {
    e.preventDefault();
  }
  emits('click');
};
</script>

<template>
  <component :is="tag" @click="onBtnClick" :class="`btn btn-${type} btn--${size} flex flex-center`" :disabled="disabled"><slot /></component>
</template>

<style lang="scss" scoped>
.btn {
  position: relative;
  font-weight: 700;
  cursor: pointer;

  &:disabled {
    cursor: no-drop;
    opacity: 0.6;
  }
}
</style>
