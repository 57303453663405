<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import InputText from '@/features/theme/base/InputText.vue';
import ComboboxOptionsContainer from './ComboboxOptionsContainer.vue';
import ComboboxOption from './ComboboxOption.vue';

const props = withDefaults(
  defineProps<{
    label?: string;
    modelValue?: Maybe<string>;
    errorMessage?: Maybe<string>;
    scroll?: 'small' | 'medium' | 'large';
    options?: string[];
    editable?: boolean;
  }>(),
  {
    modelValue: '',
    options: () => [],
    editable: true,
  }
);

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'option:selected', option: string): void;
}>();

const localValue = ref<string>();
const inputValue = computed<Maybe<string>>(() => (typeof props.modelValue === 'string' ? props.modelValue : localValue.value));

const onModelValueUpdate = (value: string) => {
  updateSelectedValue(value);
  resetSelectionCursor();
  showOptions();
};

const updateSelectedValue = (value: string): void => {
  localValue.value = value;
  emits('update:modelValue', value);
};

const selectionCursor = ref<Maybe<number>>();
const resetSelectionCursor = () => {
  selectionCursor.value = displayOptions.value.length === 1 ? 0 : null;
  if (props.modelValue) {
    const idx = displayOptions.value.findIndex((v) => v === props.modelValue);
    if (idx !== -1) {
      selectionCursor.value = idx;
    }
  }
};

const optionsExpanded = ref<boolean>(false);
const showOptions = () => {
  optionsExpanded.value = true;
};
const hideOptions = () => {
  optionsExpanded.value = false;
};

const onOptionClick = (option: string) => {
  updateSelectedValue(option);
  resetSelectionCursor();
  hideOptions();
  emits('option:selected', option);
};
const displayOptions = computed(() => {
  if (!inputValue.value) {
    return props.options;
  }

  const searchValue = inputValue.value.toLowerCase();

  return props.options.filter((opt) => opt.toLowerCase().includes(searchValue));
});

const inputFocused = ref<boolean>(false);
const onInputFocus = (focus: boolean) => {
  inputFocused.value = focus;
  if (focus) {
    resetSelectionCursor();
    showOptions();
  }
};

const onInputKeyUp = () => {
  if (optionsExpanded.value) {
    if (typeof selectionCursor.value === 'number' && selectionCursor.value > 0) {
      selectionCursor.value = selectionCursor.value - 1;
    } else {
      resetSelectionCursor();
      hideOptions();
    }
  }
};

const onInputKeyDown = () => {
  if (!optionsExpanded.value) {
    showOptions();
  }
  if (typeof selectionCursor.value !== 'number') {
    selectionCursor.value = 0;
  } else if (typeof selectionCursor.value === 'number' && selectionCursor.value < displayOptions.value.length - 1) {
    selectionCursor.value = selectionCursor.value + 1;
  }
};

const onInputKeyEnter = () => {
  if (optionsExpanded.value && typeof selectionCursor.value === 'number' && selectionCursor.value < displayOptions.value.length) {
    updateSelectedValue(displayOptions.value[selectionCursor.value]);
    resetSelectionCursor();
    hideOptions();
  }
};

const onInputKeyEsc = () => {
  resetSelectionCursor();
  hideOptions();
};

const onInputKeyTab = () => {
  resetSelectionCursor();
  hideOptions();
};

const onInputClick = () => {
  showOptions();
};

const onDocumentClick = () => {
  hideOptions();
};
onMounted(() => {
  document.addEventListener('click', onDocumentClick);
});

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick);
});
</script>
<template>
  <div
    @click.stop
    class="combobox-container"
    tabindex="-1"
  >
    <InputText
      @focus="onInputFocus"
      :error-message="errorMessage"
      :model-value="inputValue"
      @update:model-value="onModelValueUpdate"
      fullwidth
      :label="label"
      @keyup.up="onInputKeyUp"
      @keyup.down="onInputKeyDown"
      :readonly="!editable"
      @keyup.enter="onInputKeyEnter"
      @keyup.esc="onInputKeyEsc"
      @keydown.tab="onInputKeyTab"
      @click="onInputClick"
    />
    <ComboboxOptionsContainer
      :scroll="scroll"
      v-if="optionsExpanded"
    >
      <ComboboxOption
        v-for="(option, index) in displayOptions"
        @click="onOptionClick"
        :key="option"
        :value="option"
        :selected="index === selectionCursor"
      />
    </ComboboxOptionsContainer>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/z-indicies' as zIndex;
@use '@/scss/design-tokens/colors' as colors;

.combobox-container {
  position: relative;
}
</style>
