<script lang="ts" setup>
import SubMenu from '@/features/navigation/SubMenu.vue';
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import Spinner from '@/features/theme/base/Spinner.vue';
import Tab from '@/features/theme/base/Tab.vue';
import Tabs from '@/features/theme/base/Tabs.vue';
import { computed, ref } from 'vue';
import IssueTable from './IssueTable.vue';
import useMyIssues from '@/features/issues/composables/useMyIssues';
import useWatchedIssues from '../composables/useWatchedIssues';
import Translate from '@/features/translations/Translate.vue';
import useAuthStore from '@/features/auth/useAuthStore';
import Alert from '@/features/theme/base/Alert.vue';

const { user, activeDelegation, mapDelegationRoleTranslationKey, hasCreateAccess, hasEditAccess } = useAuthStore();

const tabs = computed(() => {
  if (hasCreateAccess.value) {
    return ['issue.created_by_me', 'issue.editable_by_me', 'issue.commented_by_me', 'issue.monitored_by_me'];
  }
  if (hasEditAccess.value) {
    return ['issue.editable_by_me', 'issue.commented_by_me', 'issue.monitored_by_me'];
  }
  return ['issue.commented_by_me', 'issue.monitored_by_me'];
});

const activeTab = ref<string>(hasCreateAccess.value ? 'issue.created_by_me' : hasEditAccess.value ? 'issue.editable_by_me' : 'issue.commented_by_me');

const onTabClick = (tab: string) => {
  activeTab.value = tab;
};

const { issuesCreatedByMe, issuesEditableByMe, issuesReviewedByMe, loadingMyIssues } = useMyIssues();
const { watchedIssues } = useWatchedIssues();

</script>
<template>
  <div></div>
  <SubMenu type="info">
    <LayoutCentered>
      <Tabs>
        <Tab v-for="tab in tabs" :key="tab" :value="tab" :active="activeTab === tab" @click="() => onTabClick(tab)" />
      </Tabs>
    </LayoutCentered>
  </SubMenu>
  <LayoutCentered>
    <div class="flex flex-center flex-column" v-if="loadingMyIssues">
      <Spinner />
      <Translate t="base.loading" />
    </div>
    <template v-else>
      <Alert class="mb-2">
          <Translate
            v-if="!activeDelegation"
            t="my.issues.user.role.help"
            :interpolations="{ user: user!.fullName }"/>
          <Translate
            v-else
            t="my.issues.user.role.delegation.help"
            :interpolations="{
            user: user!.fullName,
            delegation: activeDelegation.delegation?.entityName,
            role: $t(mapDelegationRoleTranslationKey(user?.role!))
          }"/>
        </Alert>
      <IssueTable :issues="issuesCreatedByMe" v-if="activeTab === 'issue.created_by_me'" key="created" />
      <IssueTable :issues="issuesEditableByMe" v-else-if="activeTab === 'issue.editable_by_me'" key="editable" />
      <IssueTable :issues="issuesReviewedByMe" v-else-if="activeTab === 'issue.commented_by_me'" key="commented" />
      <IssueTable :issues="watchedIssues" v-else-if="activeTab === 'issue.monitored_by_me'" key="monitored" />
    </template>
  </LayoutCentered>
</template>
