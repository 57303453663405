type SearchableById = { id: ID };

interface UseCollectionSearching {
  findById<T extends SearchableById>(items: T[], id: Maybe<ID>): Maybe<T>;
  findIndexById<T extends SearchableById>(items: T[], id: Maybe<ID>): number;
  findNextById<T extends SearchableById>(items: T[], id: Maybe<ID>): Maybe<T>;
  findPrevById<T extends SearchableById>(items: T[], id: Maybe<ID>): Maybe<T>;
}

export default function useCollectionSearching(): UseCollectionSearching {
  const findById = <T extends SearchableById>(items: T[], id: Maybe<ID>) => {
    return items.find((item) => item.id === id);
  };

  const findIndexById = <T extends SearchableById>(items: T[], id: Maybe<ID>) => {
    return items.findIndex((item) => item.id === id);
  };

  const findNextById = <T extends SearchableById>(items: T[], id: Maybe<ID>) => {
    const currIdx = findIndexById(items, id);

    if (currIdx === -1 || currIdx >= items.length) {
      return null;
    }

    return items[currIdx + 1];
  };

  const findPrevById = <T extends SearchableById>(items: T[], id: Maybe<ID>) => {
    const currIdx = findIndexById(items, id);

    if (currIdx <= 0) {
      return null;
    }

    return items[currIdx - 1];
  };

  return { findById, findIndexById, findNextById, findPrevById };
}
