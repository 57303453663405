interface UseBodyScrolling {
  enableBodyScroll(): void;
  disableBodyScroll(): void;
}

export default function useBodyScrolling(): UseBodyScrolling {
  const enableBodyScroll = () => {
    document.querySelector('body')!.style.overflow = 'unset';
  };
  const disableBodyScroll = () => {
    document.querySelector('body')!.style.overflow = 'hidden';
  };
  return {
    enableBodyScroll,
    disableBodyScroll,
  };
}
