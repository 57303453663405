<script lang="ts" setup>
import { NavigationType } from '@/generated/graphql';
import { ref, computed } from 'vue';
import { CRUD, Resources, useAccessControl } from '../auth/useAccessControl';
import useAuthStore from '../auth/useAuthStore';
import useAuthUser from '../auth/useAuthUser';
import { useSignin } from '../auth/useSignin';
import ProcessStart from '../issueProcess/components/ProcessStart.vue';
import Modal from '../theme/base/Modal.vue';
import { MenuTheme } from '../theme/models';
import { MenuThemes } from '../theme/themes';
import NavMenu from './NavMenu.vue';
import NavMenuLink from './NavMenuLink.vue';
import { fakesPath } from '../router/routes';
import useDownloadFiles from '../files/composables/useDownloadFiles';
import { IconType } from '../theme/base/Icon.vue';
import useNavigation from './useNavigation';
import Translate from '../translations/Translate.vue';
import { SpecialAuthPaths } from '../auth/authMiddleware';
import { useRoute } from 'vue-router';
import UserSettingsForm from './UserSettingsForm.vue';
import { usePushIssueProcessYearOrder } from '@/features/issues/composables/usePushIssueProcessYearOrder';
import { IssueCardViewModel } from '../issues/models';
import { FaqRouteName } from '@/features/faq/FaqRouteName';

const { pushIssueProcessYearOrder } = usePushIssueProcessYearOrder();

const props = withDefaults(
  defineProps<{
    nav: NavigationType;
    theme?: MenuTheme;
  }>(),
  {
    theme: () => MenuThemes.default,
  }
);

const route = useRoute();
const navOpen = ref<boolean>(false);
const onNavMenuUpdate = () => {
  navOpen.value = !navOpen.value;
};
const onLinkClick = () => {
  navOpen.value = false;
};

const { logout } = useSignin();
const onSignoutClick = () => {
  logout();
  navOpen.value = false;
};

const { isAuthenticated } = useAuthUser();
const { canAccessRoute, canAccessResource } = useAccessControl();

const menuRoutes = computed(() => {
  return props.nav.routes.filter(({ hideInMenu }) => !hideInMenu).filter(canAccessRoute);
});

const topRoutes = computed(() => {
  // Tmp, maybe stilling í Contentful
  return menuRoutes.value.slice(0, 4);
});

const bottomRoutes = computed(() => {
  // Tmp, maybe stilling í Contentful
  return menuRoutes.value.slice(4);
});

const modalOpen = ref<boolean>(false);
const onModalClose = () => {
  modalOpen.value = false;
};

const onIssueProcessClick = () => {
  modalOpen.value = true;
};

const settingsModalOpen = ref<boolean>(false);
const onSettingsModalClose = () => {
  settingsModalOpen.value = false;
};

const onSettingsClick = () => {
  settingsModalOpen.value = true;
};

const authStore = useAuthStore();
const canAccessFakes = computed(() => {
  return authStore.isAdmin || authStore.user?.isFake;
});

const { getIssuesCsvUrl, downloadFileUrl, fetchFileObjectUrl } = useDownloadFiles();
const onDownloadCsvClick = async () => {
  const url = getIssuesCsvUrl();
  const objectUrl = await fetchFileObjectUrl(url);
  downloadFileUrl(objectUrl, 'Öll mál.zip');
};

const routeToIcon = (name: string): IconType => {
  const routeIcons: Record<string, IconType> = {
    'User management': 'User',
    Issues: 'MapIcon',
    Monitoring: 'Bell',
    'My Issues': 'Review',
    Instructions: 'Information',
  };
  return routeIcons[name] || 'QuestionMark';
};

const { pushActiveAreaRouteByName } = useNavigation();
const onProcessStarted = (issue: IssueCardViewModel) => {
  pushIssueProcessYearOrder(issue);
};

const onProcessCancelled = () => {
  modalOpen.value = false;
};
const onLoginClick = () => {
  pushActiveAreaRouteByName('Login');
};

const frontpageUrl = computed(() => {
  return `${SpecialAuthPaths.HOME.replace(/^\//, '')}#information`;
});

const delegationsUrl = computed(() => {
  return `${SpecialAuthPaths.DELEGATIONS.replace(/^\//, '')}?from=${encodeURIComponent(route.fullPath)}`;
});
</script>
<template>
  <div>
    <NavMenu
      @update:open="onNavMenuUpdate"
      :open="navOpen"
      :theme="theme"
    >
      <template #top>
        <NavMenuLink
          @click="onLinkClick"
          v-for="route in topRoutes"
          :key="route.path"
          :to="route.path"
          :icon="routeToIcon(route.name)"
        >
          <Translate
            :value="`contentful.${route.name}`"
            :default-value="route.name"
          />
        </NavMenuLink>
        <NavMenuLink
          icon="Plus"
          v-if="canAccessResource(Resources.Issue, CRUD.Create)"
          @click="onIssueProcessClick"
          key="issue-process"
        >
          <Translate t="nav.issue_create" />
        </NavMenuLink>
        <NavMenuLink
          icon="Cogwheel"
          v-if="isAuthenticated"
          @click="onSettingsClick"
          key="user-preferences"
        >
          <Translate t="base.settings" />
        </NavMenuLink>
      </template>
      <template #bottom>
        <NavMenuLink
          @click="onLinkClick"
          v-for="route in bottomRoutes"
          :key="route.path"
          :to="route.path"
          :icon="routeToIcon(route.name)"
        >
          <Translate
            :value="`contentful.${route.name}`"
            :default-value="route.name"
          />
        </NavMenuLink>
        <NavMenuLink
          @click="onLinkClick"
          :to="`faq?name=${FaqRouteName.Faq}`"
          :icon="routeToIcon(FaqRouteName.Faq)"
        >
          <Translate t="nav.faq" />
        </NavMenuLink>
        <NavMenuLink
          @click="onLinkClick"
          :to="`faq?name=${FaqRouteName.Instructions}`"
          :icon="routeToIcon(FaqRouteName.Instructions)"
        >
          <Translate t="nav.instructions" />
        </NavMenuLink>
        <NavMenuLink
          v-if="canAccessFakes"
          @click="onLinkClick"
          :key="fakesPath"
          :to="fakesPath"
        >
          <Translate t="user.fake.accounts" />
        </NavMenuLink>
        <NavMenuLink
          v-if="isAuthenticated"
          icon="Refresh"
          @click="onLinkClick"
          :key="SpecialAuthPaths.DELEGATIONS"
          :to="delegationsUrl"
        >
          <Translate t="nav.change_account" />
        </NavMenuLink>
        <NavMenuLink
          icon="Download"
          v-if="authStore.isAdmin"
          @click="onDownloadCsvClick"
          key="download-csv"
        >
          <Translate t="issue.files.csv" />
        </NavMenuLink>
        <NavMenuLink
          v-if="!isAuthenticated"
          icon="Login"
          @click="onLoginClick"
          key="login"
        >
          <Translate t="base.login" />
        </NavMenuLink>
        <NavMenuLink
          icon="Logout"
          @click="onSignoutClick"
          v-if="isAuthenticated"
          key="logout"
        >
          <Translate t="base.logout" />
        </NavMenuLink>
      </template>
    </NavMenu>
    <Modal
      v-if="canAccessResource(Resources.Issue, CRUD.Create)"
      :open="modalOpen"
      @close="onModalClose"
      scrollable
    >
      <ProcessStart
        @start="onProcessStarted"
        @cancel="onProcessCancelled"
      />
    </Modal>
    <Modal
      v-if="isAuthenticated"
      :open="settingsModalOpen"
      @close="onSettingsModalClose"
      scrollable
    >
      <UserSettingsForm v-if="settingsModalOpen" />
    </Modal>
  </div>
</template>
