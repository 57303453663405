import { computed, unref } from 'vue';
import { useI18n } from 'vue-i18n';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';

export default function useIssueLegalReference(issueProcess: MaybeRef<{ title: Maybe<string> }>, issuePhase: MaybeRef<Maybe<{ name: Maybe<string> }>>) {
  const { getProcessKey, getProcessPhaseKey } = useProcessTranslation();
  const { t } = useI18n();

  const getprocessLegalTooltipKey = computed(() => getProcessKey(unref(issueProcess)) + '.legal');
  const getphaseLegalTooltipKey = computed(() => getProcessPhaseKey(unref(issueProcess), unref(issuePhase)) + '.legal');

  const getProcessLegalTooltipText = computed(() => t(getprocessLegalTooltipKey.value));
  const getPhaseLegalTooltipText = computed(() => t(getphaseLegalTooltipKey.value));

  return {
    getProcessLegalTooltipText,
    getPhaseLegalTooltipText,
  };
}
