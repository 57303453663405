<script lang="ts" setup>
import Card from '@/features/theme/base/Card.vue';
import { computed, ref } from 'vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { useCreateIssueMutation, useGetProcessCategoriesQuery } from '@/generated/graphql';
import Radio from '@/features/theme/base/Radio.vue';
import Button from '@/features/theme/base/Button.vue';
import Translate from '@/features/translations/Translate.vue';
import Accordion from '@/features/components/Accordion.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Icon from '@/features/theme/base/Icon.vue';
import Spinner from '@/features/theme/base/Spinner.vue';
import useCollectionSorting from '@/features/composables/useCollectionSorting';
import { IssueCardViewModel, IssueDetails } from '@/features/issues/models';

const emits = defineEmits<{
  (e: 'start', issue: IssueCardViewModel): void;
  (e: 'cancel'): void;
}>();

const { data } = useGetProcessCategoriesQuery();
const { sortBy } = useCollectionSorting();
const categories = computed(() => sortBy(data.value?.issueProcessCategories || [], 'sortOrder', 'asc'));
const selectedProcess = ref<Maybe<string>>();

const onCancelClick = () => {
  emits('cancel');
};

const onCreateClick = async () => {
  if (selectedProcess.value) {
    await startProcess(selectedProcess.value);
  }
};
const startingProcess = ref<boolean>(false);
const { executeMutation: executeCreateIssueMutation } = useCreateIssueMutation();
const startProcess = async (processId: ID) => {
  startingProcess.value = true;
  const res = await executeCreateIssueMutation({
    processId: processId.toString(),
  });
  if (!res.data?.createIssue.issue) {
    // Todo: Normal error handling
    throw new Error('Failed to create issue');
  }
  const issue = res.data.createIssue.issue as IssueDetails;
  emits('start', issue);
  startingProcess.value = false;
};
</script>
<template>
  <Card>
    <template #header>
      <div class="title-container pr-4">
        <h6 class="h6 card-title color-primary bold">
          <Translate t="process.create" />
        </h6>
        <p class="card-help p--large">
          <Translate t="process.create.select" />
        </p>
      </div>
    </template>
    <Accordion
      v-for="category in categories"
      :key="category.type"
    >
      <template #default="{ accordionToggle, accordionBodyVisible }">
        <div
          @click="accordionToggle"
          class="flex accordion-header cursor-pointer"
          :class="{ 'pb-1': !accordionBodyVisible }"
        >
          <h4 class="p--large">{{ category.name }}</h4>
          <Icon
            class="icon"
            icon="DropdownArrow"
            :options="{ width: 24, height: 24, color: Colors.grey600 }"
            :class="{ 'rotate-180': accordionBodyVisible }"
          />
        </div>
      </template>
      <template #body>
        <ul class="process-list my-1">
          <li
            class="mb-2"
            v-for="process in category.processes"
            :key="process.id"
          >
            <Radio
              name="process"
              v-model="selectedProcess"
              :id="process.title"
              :value="process.id"
            >
              {{ process.title }}
            </Radio>
          </li>
        </ul>
      </template>
    </Accordion>
    <template #footer>
      <div class="flex flex-space-between">
        <Button
          @click="onCancelClick"
          :type="ButtonType.secondary"
        >
          <Translate t="base.cancel" />
        </Button>
        <Button
          @click="onCreateClick"
          :disabled="!selectedProcess || startingProcess"
        >
          <Translate
            v-if="!startingProcess"
            t="issue.create"
          />
          <Spinner
            v-else
            :color="Colors.white"
          />
        </Button>
      </div>
    </template>
  </Card>
</template>
<style lang="scss" scoped>
.title-container {
  display: flex;
  flex-direction: column;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
}

.icon {
  transition: all 0.3s;

  &.rotate-180 {
    transform: rotateX(180deg);
  }
}

.accordion-body {
  box-sizing: border-box;
}
</style>
