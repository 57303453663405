<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { ref } from 'vue';
import Button from './Button.vue';
import { ButtonType } from './models/ButtonType';

withDefaults(
  defineProps<{
    size?: 'small' | 'medium' | 'large';
    overflow?: 'hidden' | 'scroll';
    expandable?: boolean;
    fullwidth?: boolean;
  }>(),
  {
    size: 'small',
    overflow: 'scroll',
    expandable: true,
    fullwidth: false,
  }
);

const showAll = ref<boolean>(false);
const onShowAllClick = () => {
  showAll.value = true;
};
</script>
<template>
  <div class="limit" :class="{ 'limit--active': !showAll }">
    <div class="limit__content" :class="`limit__content--${size} limit__content--${overflow} ${overflow === 'scroll' && !showAll ? 'scrollbar' : ''}`">
      <slot />
    </div>
    <div v-if="!showAll && expandable" class="limit__control">
      <Button @click="onShowAllClick" class="m-auto" :class="{ 'w-100': fullwidth }" :type="ButtonType.tertiary">
        <Translate t="base.show_all" />
      </Button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/layout' as layouts;
@use '@/scss/design-tokens/colors' as colors;

@mixin limit($key, $size) {
  &--#{$key} {
    max-height: $size;
  }
}

.limit--active {
  .limit__content {
    @each $key, $limit in layouts.$limits {
      @include limit($key, $limit);
    }

    &--hidden {
      overflow-y: hidden;
    }

    &--scroll {
      overflow-y: scroll;
    }
  }
}

.limit__content {
  width: 100%;
}

.limit__gradient {
  height: 4.8rem;
}
</style>
