<script lang="ts" setup>
import FaqNavBar from '@/features/faq/components/FaqNavBar.vue';
import { FaqRoute } from './FaqRoute';
import { Ref, ref, watch } from 'vue';
import { FaqRouteName } from '@/features/faq/FaqRouteName';
import Instructions from '@/features/faq/components/Instructions.vue';
import Faq from '@/features/faq/components/Faq.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const initialRouteName: FaqRouteName = (router.currentRoute.value.query?.name || FaqRouteName.Faq) as FaqRouteName;
const activeFaqRouteName: Ref<FaqRouteName> = ref(initialRouteName);

const faqRoutes: Ref<FaqRoute[]> = ref([
  {
    path: '/faq',
    name: FaqRouteName.Faq,
    titleKey: 'faq.routes.faq.title',
    isActive: initialRouteName === FaqRouteName.Faq,
  },
  {
    path: '/instructions',
    name: FaqRouteName.Instructions,
    titleKey: 'faq.routes.instructions.navTitle',
    isActive: initialRouteName === FaqRouteName.Instructions,
  },
]);

const onRouteActivation = (faqRouteName: FaqRouteName) => {
  activeFaqRouteName.value = faqRouteName;
  faqRoutes.value = faqRoutes.value.map((fr) => {
    return { ...fr, isActive: fr.name === faqRouteName };
  });
};

const onFaqRouteClick = (faqRouteName: FaqRouteName) => {
  onRouteActivation(faqRouteName);
  router.replace({ path: router.currentRoute.value.path, query: { name: faqRouteName } });
};

watch(
  () => router.currentRoute.value.query?.name,
  (value) => {
    const name = value as FaqRouteName;
    if (name && [FaqRouteName.Faq, FaqRouteName.Instructions].includes(name) && activeFaqRouteName.value !== name) {
      onRouteActivation(name);
    }
  }
);
</script>

<template>
  <div class="body-container">
    <div class="navbar-container">
      <FaqNavBar
        :faq-routes="faqRoutes"
        @on-faq-route-click="onFaqRouteClick"
      />
    </div>
    <div>
      <Faq v-if="activeFaqRouteName === FaqRouteName.Faq" />
      <Instructions v-else-if="activeFaqRouteName === FaqRouteName.Instructions" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;
.navbar-container {
  margin-bottom: 4rem;
}

.body-container {
  margin: auto;
  width: 60%;

  @include media.mobile {
    width: 88%;
  }
}
</style>
