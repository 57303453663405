<script lang="ts" setup>
import { AlertType } from './models/AlertType';
import Translate from '@/features/translations/Translate.vue';
import Icon, { IconOptions, IconType } from './Icon.vue';
import { computed } from 'vue';
import { Colors } from './models/Colors';

const props = withDefaults(
  defineProps<{
    alertType?: AlertType;
    title?: string;
    tooltip?: string;
  }>(),
  {
    alertType: AlertType.info,
  }
);

const alertMap: Record<
  AlertType,
  {
    icon: IconType;
    color: Colors;
  }
> = {
  danger: {
    icon: 'Error',
    color: Colors.red,
  },
  info: {
    icon: 'Information',
    color: Colors.primary,
  },
  success: {
    icon: 'CheckmarkCircle',
    color: Colors.green,
  },
  warning: {
    icon: 'Warning',
    color: Colors.orange,
  },
};

const icon = computed<IconType>(() => {
  return alertMap[props.alertType].icon;
});

const iconOptions = computed<IconOptions>(() => ({
  width: 24,
  height: 24,
  color: alertMap[props.alertType].color,
}));
</script>

<template>
  <div :class="`alert-container alert--${alertType}`">
    <span
      class="alert-bubble"
      v-tooltip="tooltip ? { theme: 'primary', content: tooltip } : undefined"
    >
      <Icon
        :icon="icon"
        :options="iconOptions"
      />
    </span>
    <h3
      v-if="title"
      class="alert-title bold"
    >
      <Translate :value="title" />
    </h3>
    <p class="alert-description p--medium regular">
      <slot></slot>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
.alert-container {
  position: relative;
  padding: 1.6rem 1.6rem 1.6rem 5.6rem;
  min-width: 32vw;
  // Temp for large screen sizes
  // max-width: 1056px;
}

.alert-bubble {
  position: absolute;
  top: 1.6rem;
  left: 1.6rem;
}

.alert-title {
  font-size: 1.8rem;
  margin-bottom: 0.4rem;
}

.alert-description {
}

.alert--warning {
  background-color: colors.$orange--light;

  .alert-title {
    color: colors.$orange;
  }
}

.alert--danger {
  background-color: colors.$red--light;

  .alert-title {
    color: colors.$red;
  }
}

.alert--info {
  background-color: colors.$primary--xlight;
  .alert-title {
    color: colors.$primary;
  }
}

.alert--success {
  background-color: colors.$green--light;
  .alert-title {
    color: colors.$green;
  }
}
</style>
