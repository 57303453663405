import Feature, { FeatureLike } from 'ol/Feature';
import { LineString, MultiPolygon, Point, Polygon } from 'ol/geom';
import Geometry from 'ol/geom/Geometry';
import Style, { StyleLike } from 'ol/style/Style';

type HasGeometryFeature = {
  feature: Feature<Geometry>;
};

interface UseMapModelChecker {
  isGeometryFeatureEvent(event: unknown): event is HasGeometryFeature;
  isGeometryPoint(geometry?: Maybe<Geometry>): geometry is Point;
  isGeometryLine(geometry?: Maybe<Geometry>): geometry is LineString;
  isGeometryPolygon(geometry?: Maybe<Geometry>): geometry is Polygon;
  isGeometryMultiPolygon(geometry?: Maybe<Geometry>): geometry is MultiPolygon;
  isStyleLikeStyle(styleLike?: Maybe<StyleLike>): styleLike is Style;
  isFeatureLikeFeature(featureLike?: Maybe<FeatureLike>): featureLike is Feature;
}

export default function useIssueModelChecker(): UseMapModelChecker {
  const isGeometryFeatureEvent = (val: unknown): val is HasGeometryFeature => {
    const event = val as HasGeometryFeature;

    return !!(event && event.feature && event.feature.getGeometry);
  };

  const isGeometryPoint = (geom?: Maybe<Geometry>): geom is Point => {
    return geom?.getType() === 'Point';
  };

  const isGeometryLine = (geom?: Maybe<Geometry>): geom is LineString => {
    return geom?.getType() === 'LineString';
  };

  const isGeometryPolygon = (geom?: Maybe<Geometry>): geom is Polygon => {
    return geom?.getType() === 'Polygon';
  };

  const isGeometryMultiPolygon = (geom?: Maybe<Geometry>): geom is MultiPolygon => {
    return geom?.getType() === 'MultiPolygon';
  };

  const isStyleLikeStyle = (styleLike?: Maybe<StyleLike>): styleLike is Style => {
    return !!styleLike;
  };

  const isFeatureLikeFeature = (featureLike?: Maybe<FeatureLike>): featureLike is Feature => {
    return !!featureLike;
  };

  return {
    isGeometryFeatureEvent,
    isGeometryPoint,
    isGeometryLine,
    isGeometryPolygon,
    isGeometryMultiPolygon,
    isStyleLikeStyle,
    isFeatureLikeFeature,
  };
}
