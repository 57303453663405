import { useGetDelegationsQuery } from '@/generated/graphql';
import { computed } from 'vue';
import useCollectionSorting from '@/features/composables/useCollectionSorting';
import { groupBy } from 'lodash-es';

export default function useDelegations() {
  const { data: delegationsData } = useGetDelegationsQuery();
  const { sortBy } = useCollectionSorting();
  
  const delegations = computed(() => delegationsData.value?.delegations || []);
  const delegationsGroupedByType = computed(() => {
    const groupedByType = groupBy(sortBy(delegations.value, 'entityName', 'asc').filter(x => x.entityId !== '74'), 'type');
    return groupedByType;
  });

  const getDelegationNameById = (delegationId: string) : string | undefined => {
    return delegations.value.find(d => d.id == delegationId)?.entityName;
  };

  return {
    delegations,
    delegationsGroupedByType,
    getDelegationNameById
  };
}
