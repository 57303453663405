import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useFileStore = defineStore('files', () => {
  const garbage = ref<string[]>([]);

  const addToGarbage = (fileId: string) => {
    garbage.value = [...garbage.value.filter((fid) => fid !== fileId), fileId];
  };

  const removeFromGarbage = (fileId: string) => {
    garbage.value = garbage.value.filter((fid) => fid !== fileId);
  };

  return {
    garbage,
    addToGarbage,
    removeFromGarbage,
  };
});

export default useFileStore;
