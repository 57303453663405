<script lang="ts" setup>
import { QuillDeltaToHtmlConverter } from 'quill-delta-to-html';
import { computed } from 'vue';

const props = defineProps<{ opsJson?: string; rawText?: Maybe<string> }>();

const converterConfig = { paragraphTag: 'span' };

const html = computed(() => {
  let ops = [];

  if (props.opsJson) {
    ops = JSON.parse(props.opsJson);
  } else if (props.rawText) {
    ops.push({ insert: props.rawText });
  }

  const converter = new QuillDeltaToHtmlConverter(ops, converterConfig);
  return converter.convert();
});
</script>

<template>
  <span
    id="richtextcontainer"
    v-html="html"
  ></span>
</template>
