<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { computed, watch } from 'vue';
import PageError from '@/features/rendering/pages/PageError.vue';
import LoadingPage from '@/features/theme/LoadingPage.vue';
import { IssueSpecificationInput, Paragraph } from '@/generated/graphql';
import ProcessManager from './ProcessManager.vue';
import useNavigationStore from '@/features/navigation/useNavigationStore';
import useSingleIssue from '@/features/issues/composables/useSingleIssue';

defineProps<{
  paragraph?: Paragraph;
}>();

const route = useRoute();

const specs = computed<IssueSpecificationInput>(() => {
  if (typeof route.params.issueId === 'string') {
    return {
      issueId: route.params.issueId,
    };
  } else if (typeof route.params.issueYear === 'string' && typeof route.params.issueYearOrder === 'string') {
    return {
      issueYearOrderInput: {
        year: route.params.issueYear,
        order: route.params.issueYearOrder,
      },
    };
  }

  return {};
});

if (!Object.keys(specs.value).length) {
  throw new Error('404');
}

const { issue, fetching } = useSingleIssue(specs);

const { showMainMenu } = useNavigationStore();
watch(
  specs,
  newValue => {
    if (newValue) {
      showMainMenu(false);
    }
  },
  {
    immediate: true,
  }
);
</script>

<template>
  <LoadingPage
    class="pt-2"
    v-if="fetching"
  />
  <ProcessManager
    v-else-if="issue"
    :issue="issue"
  />
  <PageError v-else />
</template>
