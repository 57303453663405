import { Area, GetAreasDocument } from '@/generated/graphql';
import { client } from '@/urql';
import store from '@/features/store';
import router from '@/features/router';
import useNavigationStore from './useNavigationStore';
import useAreaRouteMapper from './useAreaRouteMapper';

const setupAreas = async (): Promise<void> => {
  const result = await client.query<{ areas: Area[] }>(GetAreasDocument, {}).toPromise();
  const areas = result.data ? result.data.areas : [];

  const { mapAreasToRoutes } = useAreaRouteMapper();
  mapAreasToRoutes(areas).forEach(router.addRoute);

  const navStore = useNavigationStore(store);
  navStore.setAreas(areas);
};

export default setupAreas;
