import parse from 'date-fns/parse';
import format from 'date-fns/format';
import is from 'date-fns/locale/is';
import useModelChecker from './useModelChecker';
import parseJSON from 'date-fns/parseJSON';
import { computed, unref } from 'vue';

type LocaleKey = 'is';
type DateLike = Date | string;

export default function useDateFormatting(date?: MaybeRef<Maybe<DateLike>>) {
  const { isDate } = useModelChecker();
  const locales: Record<LocaleKey, Locale> = { is };

  const localizedFormat = (length: 1 | 2 | 3 | 4 = 1) => {
    return 'P'.repeat(length);
  };

  const localizedFormatWithTime = (length: 1 | 2 | 3 | 4 = 1) => {
    return `${localizedFormat(length)}${`p`.repeat(length)}`;
  };

  const formatDate = (date: DateLike, formatStr: string, locale: LocaleKey = 'is') => {
    return format(isDate(date) ? date : new Date(Date.parse(date)), formatStr, { locale: locales[locale] });
  };

  const formatDateLocalized = (date: DateLike, length: 1 | 2 | 3 | 4 = 1, locale: LocaleKey = 'is') => {
    return formatDate(date, localizedFormat(length), locale);
  };

  const formatDateTimeLocalized = (date: DateLike, length: 1 | 2 | 3 | 4 = 1, locale: LocaleKey = 'is') => {
    return formatDate(date, localizedFormatWithTime(length), locale);
  };

  const parseDate = (date: string, formatStr: string, locale: LocaleKey = 'is') => {
    return parse(date, formatStr, new Date(), { locale: locales[locale] });
  };

  const parseDateLocalized = (date: string, length: 1 | 2 | 3 | 4 = 1, locale: LocaleKey = 'is') => {
    return parseDate(date, localizedFormat(length), locale);
  };

  const parseDateJson = (date: string | number | Date): Date => {
    return parseJSON(date);
  };

  const dateLocalizedFormatted = computed(() => {
    const d = date && unref(date);
    return formatDateLocalized(d || new Date());
  });

  return {
    formatDate,
    formatDateLocalized,
    formatDateTimeLocalized,

    parseDate,
    parseDateLocalized,
    parseDateJson,

    dateLocalizedFormatted,
  };
}
