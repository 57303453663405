import { ref, Ref } from 'vue';
import type { DataTableFilter, SortBy } from './dataTableTypes';
import orderBy from 'lodash-es/orderBy';

interface UseDataTableSort {
  dataTableSortBy: Ref<SortBy>;
  dataTableToggleSortBy(sortBy: string): void;
  dataTableSortFilter: DataTableFilter;
}

export default function useDataTableSort(): UseDataTableSort {
  const dataTableSortBy = ref<SortBy>({});
  const dataTableToggleSortBy = (sortBy: string): void => {
    if (!dataTableSortBy.value[sortBy]) {
      dataTableSortBy.value[sortBy] = 'desc';
    } else if (dataTableSortBy.value[sortBy] == 'desc') {
      dataTableSortBy.value[sortBy] = 'asc';
    } else {
      delete dataTableSortBy.value[sortBy];
    }
  };
  const dataTableSortFilter: DataTableFilter = (dataset) => {
    if (!dataTableSortBy.value) {
      return dataset;
    }
    return orderBy(dataset, Object.keys(dataTableSortBy.value), Object.values(dataTableSortBy.value));
  };

  return {
    dataTableSortBy,
    dataTableToggleSortBy,
    dataTableSortFilter,
  };
}
