<script lang="ts" setup>
import IssueLayerDraw from '@/features/issues/components/issueMap/IssueLayerDraw.vue';
import IssueLayerImage from '@/features/issues/components/issueMap/IssueLayerImage.vue';
import useMapFeatureStack from '@/features/issues/composables/useMapFeatureStack';
import { DrawTool } from '@/features/issues/models/issueMaps';
import { LoftmyndirCapability } from '@/features/loftmyndir/models';
import Map from '@/features/map/components/Map.vue';
import { ref, onMounted } from 'vue';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { FeatureDecorated } from '@/features/map/models/mapModels';
import IssueUndoRedoTool from '@/features/issues/components/issueMap/IssueUndoRedoTool.vue';
import IssueDrawTool from '@/features/issues/components/issueMap/IssueDrawTool.vue';
import IssueSaveTool from '@/features/issues/components/issueMap/IssueSaveTool.vue';
import IssueCancelTool from '@/features/issues/components/issueMap/IssueCancelTool.vue';
import useGeoJson from '@/features/map/composables/useGeoJson';
import useProjections from '@/features/map/composables/useProjections';
import useIssuePhaseStyles from '@/features/issues/composables/useIssuePhaseStyles';
import MonitoringMapLayerFeatures from './MonitoringMapLayerFeatures.vue';

const props = defineProps<{ 
  disabled?: boolean;
  geoJson?: string;
}>();

const emits = defineEmits<{
  (e: 'features', features: Feature<Geometry>[]): void;
  (e: 'save', areaGeoJson: string): void;
  (e: 'cancel'): void;
}>();

const { features, canRedo, canUndo, undoFeature, redoFeature, pushFeature } = useMapFeatureStack();

const updateFeatures = () => {
  emits('features', features.value);
};

const addFeature = (feature: FeatureDecorated<Geometry>) => {
  pushFeature(feature);
  updateFeatures();
};

const drawTool = ref<DrawTool>();

const onDrawFeature = (feature: FeatureDecorated<Geometry>) => {
  addFeature(feature);
};

const onUndo = () => {
  undoFeature();
  updateFeatures();
};

const onRedo = () => {
  redoFeature();
  updateFeatures();
};

const { featuresToGeoJson, stringToGeometry } = useGeoJson();

const { transformFeaturesIsToWgs, transformGeometryWgsToIs } = useProjections();

const onSave = () => {
  if (features.value.length > 0) {
    const areaGeoJson = featuresToGeoJson(transformFeaturesIsToWgs(features.value));
    emits('save', areaGeoJson);
  }
};

const onCancel = () => {
  emits('cancel');
};

const { defaultFeatureStyle } = useIssuePhaseStyles();

const geoJson2Features = (geoJson: string) => {
  const features: Feature[] = [];
  const geom = stringToGeometry(geoJson);

  if (geom) {
    const feature = new Feature(transformGeometryWgsToIs(geom));
    feature.setStyle(defaultFeatureStyle);
    features.push(feature);
  }

  return features;
};

type ToggleTools = 'draw';

const selectedToggleTool = ref<ToggleTools>();

const selectToggleTool = (tool: ToggleTools | 'none') => {
  if (tool !== 'none') {
    selectedToggleTool.value = tool;
  } else {
    selectedToggleTool.value = undefined;
  }
};

const onToolToggle = (tool: ToggleTools, toggle: boolean) => {
  selectToggleTool(toggle ? tool : 'none');
};

onMounted(() => {
  if (props.geoJson) {
    const { geoJson } = props;
    const feats = geoJson2Features(geoJson);
    for (const feat of feats) {
      addFeature({ feature: feat });
    }
  }
});
</script>
<template>
  <Map class="ol__map">
    <div
      class="top-right"
      v-if="!disabled"
    >
      <IssueUndoRedoTool
        :undo-enabled="canUndo"
        :redo-enabled="canRedo"
        @undo="onUndo"
        @redo="onRedo"
      />
      <IssueSaveTool
        :disabled="features.length === 0"
        @save="onSave"
      />
      <IssueDrawTool 
        v-model="drawTool" 
        :toggle="selectedToggleTool === 'draw'"
        @update:toggle="(v) => onToolToggle('draw', v)"
      />
      <IssueCancelTool @cancel="onCancel" />
    </div>
    <IssueLayerImage :loftmyndir="LoftmyndirCapability.Grayscale" />
    <MonitoringMapLayerFeatures :features="features" />
    <IssueLayerDraw
      :draw-type="drawTool"
      @feature="onDrawFeature"
    />
  </Map>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
.top-right {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 4.8rem;
  gap: 1rem;

  @include mq.laptop-down() {
    right: 2rem;
  }
}
</style>
