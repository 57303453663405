<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import Button from './Button.vue';
import Modal from './Modal.vue';
import { ButtonType } from './models/ButtonType';
import { TranslationKey } from '@/features/translations/defaultMessages';

withDefaults(
  defineProps<{
    active: boolean;
    type?: 'choice' | 'warning';
    yesButtonTranslationKey?: TranslationKey;
    noButtonTranslationKey?: TranslationKey;
  }>(),
  {
    active: false,
    type: 'choice',
  }
);

const emits = defineEmits<{
  (e: 'yes'): void;
  (e: 'no'): void;
  (e: 'ok'): void;
}>();

const onYes = () => {
  emits('yes');
};

const onNo = () => {
  emits('no');
};

const onOk = () => {
  emits('ok');
};

const onClose = () => {
  onNo();
  onOk();
};
</script>
<template>
  <Modal
    :open="active"
    @close="onClose"
  >
    <div class="p-5 bg-white">
      <div class="confirm-container">
        <slot />
        <div
          class="flex gap-2"
          v-if="type === 'choice'"
        >
          <Button
            class="button"
            @click="onYes"
            prevent
            :type="ButtonType.primary"
          >
            <Translate :t="(yesButtonTranslationKey ? yesButtonTranslationKey : 'base.yes')" />
          </Button>
          <slot name="extraButtons" />
          <Button
            class="button"
            @click="onNo"
            prevent
            :type="ButtonType.secondary"
          >
            <Translate :t="(noButtonTranslationKey ? noButtonTranslationKey : 'base.no')" />
          </Button>
        </div>
        <div
          class="flex"
          v-else-if="type === 'warning'"
        >
          <Button
            class="button"
            @click="onOk"
            prevent
            :type="ButtonType.primary"
          >
            <Translate t="base.ok" />
          </Button>
        </div>
      </div>
    </div>
  </Modal>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.alert {
  @include mq.laptop() {
    min-width: 40rem;
  }
}

.button {
  min-width: 8rem;
}

.confirm-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  min-width: 640px;
  max-width: 60vw;

  @include mq.laptop-down {
    min-width: auto;
    max-width: auto;
  }
}
</style>
