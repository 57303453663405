<script lang="ts" setup>
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import { PillSize } from '@/features/theme/base/models/PillProps';
import Translate from '@/features/translations/Translate.vue';
import { ref } from 'vue';
import IssuePhasePill from '../IssuePhasePill.vue';
import IssueMapAction from './IssueMapAction.vue';
import { IssueStatusEnum } from '@/generated/graphql';

const legendPills: IssueStatusEnum[] = [IssueStatusEnum.InProgress, IssueStatusEnum.Complete, IssueStatusEnum.Reviewing, IssueStatusEnum.Unpublished, IssueStatusEnum.InSlsReview];

const showContent = ref<boolean>(false);
const onActionClick = () => {
  showContent.value = !showContent.value;
};
</script>
<template>
  <div class="legend">
    <IssueMapAction
      @click="onActionClick"
      class="legend__action"
      :class="{ 'legend__action--selected': showContent }"
    >
      <span class="legend__action-left">
        <Icon
          class="legend__action-question-mark--mobile"
          icon="QuestionMark"
          :options="{ width: 24, height: 24, color: showContent ? Colors.white : Colors.primary }"
        />
        <Icon
          class="legend__action-question-mark--laptop"
          icon="QuestionMark"
          :options="{ width: 24, height: 24, color: Colors.primary }"
        />
        <Translate
          class="legend__action-text"
          t="issue.map.legend"
        />
      </span>
      <Icon
        class="legend__chevron"
        :class="{ 'legend__chevron--rotate90': showContent, 'legend__chevron--rotate270': !showContent }"
        icon="Chevron"
      />
    </IssueMapAction>
    <IssueMapAction
      v-if="showContent"
      class="legend__content"
    >
      <p class="mb-2 regular">
        <Translate t="issue.map.legend.help" />
      </p>
      <ul class="legend__pills">
        <IssuePhasePill
          fullwidth
          :pill-size="PillSize.small"
          v-for="pill in legendPills"
          :type="pill"
        />
      </ul>
    </IssueMapAction>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/colors' as colors;

.legend {
  width: 25rem;
  display: flex;
  flex-direction: column;
}

.legend__action {
  display: flex;
  padding: 1.6rem;
  margin-bottom: 0.2rem;
  align-self: flex-end;
  cursor: pointer;

  @include mq.laptop() {
    align-self: default;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.4rem;
    width: 100%;
    font-size: 1.4rem;
  }

  &--selected {
    background-color: colors.$primary;
    color: colors.$white;

    @include mq.laptop() {
      background-color: colors.$white;
      color: colors.$black;
    }
  }
}

.legend__action-question-mark--mobile {
  @include mq.laptop() {
    display: none;
  }
}
.legend__action-question-mark--laptop {
  display: none;
  @include mq.laptop() {
    display: block;
    margin-right: 1.4rem;
  }
}

.legend__action-left {
  display: flex;
  align-items: center;
}

.legend__action-text {
  display: none;

  @include mq.laptop() {
    display: block;
    text-transform: uppercase;
  }
}

.legend__content {
  padding: 1.6rem;
}

.legend__pills {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.legend__chevron {
  display: none;

  @include mq.laptop() {
    display: block;
    transition: all 0.3s;

    &--rotate90 {
      transform: rotate(90deg);
    }

    &--rotate270 {
      transform: rotate(-90deg);
    }
  }
}
</style>
