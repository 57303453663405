<script lang="ts" setup>
import { computed } from 'vue';
import Download from '../icons/Download.vue';
import Icon from './Icon.vue';
import { FileType } from './models/ButtonType';
import { Colors } from './models/Colors';

const props = withDefaults(
  defineProps<{
    fileUrl?: string;
    fileType?: FileType;
    download?: boolean;
  }>(),
  {
    fileUrl: '',
    fileType: FileType.doc,
    download: undefined,
  }
);

const downloadUrl = computed<string>(() => {
  if (import.meta.env.VITE_BACKEND_URL) {
    return `${import.meta.env.VITE_BACKEND_URL}/${props.fileUrl}`;
  }

  return `/${props.fileUrl}`;
});

// Krafa frá Skipulagsstofnun að pdf skjöl eigi alltaf að opnast í nýjum tab
const isPdf = computed(() => props.fileType === FileType.pdf);
</script>

<template>
  <component
    :is="download ? 'a' : 'span'"
    class="download-button regular"
    :href="downloadUrl"
    :download="isPdf ? undefined : download"
    :target="isPdf ? '_blank' : undefined"
  >
    <Icon
      class="mr-1 ml-1"
      icon="File"
      :file-type="fileType"
      :background="Colors.grey100"
    />
    <slot></slot>
    <div
      v-if="download"
      class="download-icon"
    >
      <Download />
    </div>
  </component>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
.download-button {
  display: flex;
  align-items: center;
  height: 6.4rem;
  width: 100%;
  border: 1px solid colors.$grey-300;
  border-radius: 2px;
  background-color: white;
  font-size: 1.6rem;
  color: colors.$grey-600;
  line-height: unset;
}

a.download-button {
  cursor: pointer;
}

.file {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
  height: 4.8rem;
  margin-right: 1.2rem;
  background-color: colors.$grey-100;

  &-PDF {
    color: colors.$red;
  }

  &-DOC {
    color: colors.$blue--dark;
  }

  &-MP4 {
    color: colors.$purple;
  }

  &-PPT {
    color: colors.$purple--dark;
  }

  &-XLW {
    color: colors.$green--dark;
  }

  &-TXT {
    color: black;
  }
}

.download-icon {
  margin-left: auto;
  margin-right: 1.2rem;
}
</style>
