<script lang="ts" setup>
import useDateFormatting from '@/features/composables/useDateFormatting';
import useNavigation from '@/features/navigation/useNavigation';
import DataTable from '@/features/theme/dataTables';
import { DataTableConfig, DataTableDataRow } from '@/features/theme/dataTables/dataTableTypes';
import { computed, ComputedRef, h, ref } from 'vue';
import { Issue, IssueTableViewModel } from '../models';
import parseISO from 'date-fns/parseISO';
import IssuePhasePill from './IssuePhasePill.vue';
import { PillSize } from '@/features/theme/base/models/PillProps';
import { extend } from '@vue/shared';
import Translate from '@/features/translations/Translate.vue';
import useIssuePhaseState from '../composables/useIssuePhaseState';
import { IssueStatusEnum, useDelegationTokenMutation } from '@/generated/graphql';
import { useAccessControl } from '@/features/auth/useAccessControl';
import Confirm from '@/features/theme/base/Confirm.vue';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import { useSignin } from '@/features/auth/useSignin';
import tokenService from '@/features/auth/tokenService';
import useAuthStore from '@/features/auth/useAuthStore';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    issues?: IssueTableViewModel[];
  }>(),
  {
    issues: () => [],
  }
);

const { canEditIssue } = useAccessControl();
const { user } = useAuthStore();
const { getProcessPhaseKey } = useProcessTranslation();
const { t } = useI18n();

type IssueDatasetItem = Pick<Issue, 'id' | 'issueNumber' | 'title'> & {
  issue: IssueTableViewModel;
  issueStatus: Maybe<IssueStatusEnum>;
  process: string;
  updatedAt?: Maybe<string>;
  delegationName?: Maybe<string>;
  delegation?: {
    id: Maybe<string>;
    entityName: Maybe<string>;
  };
  phase?: string;
};

const issueDataset: ComputedRef<IssueDatasetItem[]> = computed(() => {
  return [...props.issues].reverse().map((issue) => {
    const { issueStatusEnum } = useIssuePhaseState(
      computed(() => issue),
      computed(() => issue.currentPhase),
    );
    return {
      issue,
      id: issue.id,
      issueNumber: issue.issueNumber,
      delegationName: issue.delegation?.entityName,
      title: issue.title,
      process: issue.process.title,
      phase: issue.currentPhase ? issue.currentPhase?.order + ". " + t(getProcessPhaseKey(issue?.process, issue.currentPhase)) : '',
      issueStatus: issueStatusEnum.value,
      updatedAt: issue.currentPhase?.updatedAt,
    };
  });
});

const { formatDateLocalized } = useDateFormatting();
const issueDataTableConfig: DataTableConfig<IssueDatasetItem> = {
  headers: {
    issue: {
      hidden: true,
    },
    id: {
      hidden: true,
    },
    issueNumber: {
      formatter: 'issue.issue_no',
    },
    delegationName: {
      formatter: 'issue.delegation'
    },
    title: {
      formatter: 'issue.title',
    },
    process: {
      formatter: 'issue.process',
    },
    phase: {
      formatter: 'issue.phase.name'
    },
    issueStatus: {
      formatter: 'issue.status',
    },
    updatedAt: {
      formatter: 'issue.updatedAt'
    },
  },
  data: {
    issueNumber: {
      formatter: (val: unknown) => {
        const split = (val as string).split('/');
        return `${parseInt(split[0])}/${split[1]}`;
      },
      className: 'text-primary bold',
    },
    updatedAt: {
      formatter: (val: unknown) => {
        if (typeof val === 'string') {
          return formatDateLocalized(parseISO(val));
        }
        return val;
      },
      className: 'text-primary bold',
    },
    issueStatus: {
      component: (issueStatus: unknown) => {
        return {
          render() {
            return issueStatus
              ? h(extend(IssuePhasePill), {
                  pillSize: PillSize.small,
                  type: issueStatus
                })
              : '';
          },
        };
      },
    },
  },
  selectable: true,
};

const { pushActiveAreaRouteByName } = useNavigation();
const isIssueDatasetItem = (val: unknown): val is IssueDatasetItem => {
  const issue = val as IssueDatasetItem;
  return !!issue.id;
};

const selectedIssueDelegation = ref();
const issueRouteParams = ref();
const { executeMutation: createDelegationToken } = useDelegationTokenMutation();

const onChangeDelegationConfirm = async () => {
  const ticket = user?.delegations.find((d) => d.delegation?.id === selectedIssueDelegation.value.id);
  if (!ticket) {
    throw new Error('Ekki tókst að sækja umboð');
  }
  const { delegation } = ticket;

  const { data } = await createDelegationToken({ input: { delegationId: delegation?.id } });
  if (data?.delegationToken?.token) {
    tokenService.saveToken(data?.delegationToken?.token);
    const signin = useSignin();
    await signin.login();
  }
  pushActiveAreaRouteByName('Issue Details By Year', { ...issueRouteParams.value });
  clearSelectedIssueDelegation();
};

const onOpenIssueConfirm = async () => {
  pushActiveAreaRouteByName('Issue Details By Year', { ...issueRouteParams.value });
  clearSelectedIssueDelegation();
};

const onChangeDelegationCancel = () => {
  clearSelectedIssueDelegation();
};

const clearSelectedIssueDelegation = () => {
  selectedIssueDelegation.value = null;
  issueRouteParams.value = null;
};

const onIssueSelect = (issue: DataTableDataRow) => {
  if (isIssueDatasetItem(issue)) {
    const dataSetItem = issue as IssueDatasetItem;
    const params = {
      issueYear: dataSetItem.issue.createdDate.slice(0, 4) || '',
      issueYearOrder: dataSetItem.issue.issueYearOrder || '',
    };

    if (issue.issue.delegation && !canEditIssue(issue.issue) && user?.delegations.find((d) => d.delegation?.id === issue.issue.delegation?.id)) {
      issueRouteParams.value = params;
      selectedIssueDelegation.value = issue.issue.delegation;
    } else {
      pushActiveAreaRouteByName('Issue Details By Year', { ...params });
    }
  }
};

</script>
<template>
  <DataTable
    v-if="issueDataset.length > 0"
    :dataset="issueDataset"
    :config="issueDataTableConfig"
    @select="onIssueSelect"
  />
  <div
    class="issues-empty"
    v-else
  >
    <p class="regular mb-2">
      <Translate t="issue.table.no_results" />
    </p>
    <p class="regular">
      <Translate t="issue.table.help" />
    </p>
  </div>
  <Confirm
      :active="!!selectedIssueDelegation"
      @yes="onChangeDelegationConfirm"
      @no="onChangeDelegationCancel"
      yes-button-translation-key="dialog.yes"
    >
      <Alert :alert-type="AlertType.warning">
        <Translate 
          t="my.issues.user.change.delegation"
          :interpolations="{ delegation: selectedIssueDelegation?.entityName }" />
      </Alert>
      <template #extraButtons>
        <Button
            class="button"
            @click="onOpenIssueConfirm"
            prevent
            :type="ButtonType.quinary"
          >
            <Translate t="dialog.no" />
          </Button>
      </template>
  </Confirm>
</template>

<style lang="scss" scoped>
  .issues-empty {
    padding: 10rem 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
</style>
