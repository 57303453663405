import VectorSource from 'ol/source/Vector';
import { inject } from 'vue';
import { InjectionGetter, mapVectorSourceKey } from '@/features/map/models/mapSymbols';

export default function useVectorSource(): InjectionGetter<VectorSource> {
  const source = inject(mapVectorSourceKey);

  if (!source) {
    throw new Error('No vector source provided');
  }

  return source;
}
