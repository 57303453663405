import proj4 from 'proj4';
import { register } from 'ol/proj/proj4';
import { get as getProjection } from 'ol/proj';
import { ProjectionEnum, ProjectionProj4Defs } from './features/map/models/mapProjection';

// Register Icelandic projection, see https://epsg.io/3057 PROJ 4 definition
proj4.defs(ProjectionEnum.EPSG3057, ProjectionProj4Defs.EPSG3057);
register(proj4);

const proj3057 = getProjection(ProjectionEnum.EPSG3057);
if (proj3057) {
  proj3057.setExtent([0, 0, 1048576, 1048576]);
}
