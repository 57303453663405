<script lang="ts" setup>
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import Confirm from '@/features/theme/base/Confirm.vue';
import { IssueDetails } from '../../models';
import { computed, ref } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import useUserManagement from '@/features/userManagement/composables/useUserManagement';
import { User } from '@/features/auth/models';
import UserCombobox from './UserCombobox.vue';
import InputText from '@/features/theme/base/InputText.vue';
import useActiveIssueOperations from '@/features/issueProcess/composables/useActiveIssueOperations';
import useAuthStore from '@/features/auth/useAuthStore';
import { useAccessControl } from '@/features/auth/useAccessControl';

const props = defineProps<{
  active: boolean;
  issue: IssueDetails;
}>();

const emits = defineEmits<{
  (e: 'yes'): void;
  (e: 'no'): void;
}>();

const { isCommunity, activeDelegation } = useAuthStore();

const { users } = useUserManagement();

const { canUserEditIssue } = useAccessControl();

const { changeIssueResponsibleParty } = useActiveIssueOperations(computed(() => props.issue.id));

const selectedUser = ref<Maybe<User>>();

const userSearch = ref<string>();

const userSearchResults = ref<User[]>();

const userOptions = computed(() => {
  var options =  users.value.filter(u => canUserEditIssue(props.issue, u) && u.id !== props.issue.user.id?.toString());
  if (isCommunity) {
    return options.filter(o => o.delegations.map(d => d.delegation?.id).includes(activeDelegation?.delegation?.id));
  }
  return options;
});

const onYes = async () => {
  if (selectedUser.value) {
    await changeIssueResponsibleParty(props.issue.id, selectedUser.value?.id);
  }
  clearFormData();
  emits('yes');
};

const onNo = () => {
  clearFormData();
  emits('no');
};

const clearFormData = () => {
  selectedUser.value = undefined;
  userSearch.value = undefined;
  userSearchResults.value = undefined;
}

const onSearchUpdate = (v: string): void => {
  userSearch.value = v;
  const searchValue = userSearch.value.toLowerCase();
  userSearchResults.value = users.value.filter((item) => item.fullName.toLowerCase().includes(searchValue))
};

const onOptionSelected = (v: User): void => {
  selectedUser.value = v;
};

</script>

<template>
  <Confirm
    :active="active"
    @yes="onYes"
    @no="onNo"
  >
    <LayoutFormGroup title="issue.user.change">
      <template #explanation>
        <Translate value="issue.user.change.description" />
      </template>
      <LayoutInput :span="2">
        <UserCombobox
          :options="userOptions"
          @update:model-value="onSearchUpdate"
          :model-value="userSearch"
          @option:selected="onOptionSelected"
          scroll="small"
        />
      </LayoutInput>
      <LayoutInput :span="2">
        <InputText
          :fullwidth=true
          :model-value="issue.user.fullName"
          label="issue.user.current"
          disabled
        />
      </LayoutInput>
    </LayoutFormGroup>
  </Confirm>
</template>