interface UseModelChecker {
  isDate(v: unknown): v is Date;
  isString(v: unknown): v is string;
}

export default function useModelChecker(): UseModelChecker {
  const isDate = (v: unknown): v is Date => v instanceof Date;
  const isString = (v: unknown): v is string => typeof v === 'string';

  return {
    isDate,
    isString,
  };
}
