const messages = {
  'app.title': 'Skipulagsgátt',
  'app.address': 'Borgartún 7b, 105 Reykjavík',
  'app.phone': '595 4100',
  'app.organization': 'Skipulagsstofnun',

  'audits.operation': 'Aðgerð',
  'audits.timeStamp': 'Tími',
  'audits.type': 'Breytt eining',
  'audits.modifiedFieldName': 'Breyttur reitur',
  'audits.view.tooltip': 'Skoða breytingasögu máls',

  'auth.delegation': 'Umboð',
  'auth.select_account': 'Veldu aðgang',
  'auth.select_account.help': 'Aðgangar veita mismunandi réttindi til aðgerða',
  'auth.login.eid': 'Notast er við rafræn skilríki í farsíma við innskráningu.',
  'auth.login.eid.btn': 'Innskráning með rafrænum skilríkjum',
  'auth.prompt.title': 'Þú þarft að skrá þig inn',
  'auth.prompt.subtitle': 'Þú verður að vera skráð(ur) inn til að vakta mál',

  'base.app': 'Skipulagsgátt',
  'base.continue': 'Halda áfram',
  'base.continue.no.save': 'Halda áfram án þess að vista',
  'base.back': 'Til baka',
  'base.create': 'Stofna',
  'base.delete': 'Eyða',
  'base.add': 'Bæta við',
  'base.edit': 'Breyta',
  'base.login': 'Innskráning',
  'base.logout': 'Útskráning',
  'base.cancel': 'Hætta við',
  'base.ssn': 'Kennitala',
  'base.ssn.short': 'kt',
  'base.date.from': 'Dags. frá',
  'base.date.to': 'Dags. til',
  'base.save': 'Vista',
  'base.email': 'Netfang',
  'base.name': 'Nafn',
  'base.label': 'Heiti',
  'base.category': 'Flokkur',
  'base.community': 'Sveitarfélag',
  'base.community.filter': 'Sía eftir sveitarfélagi',
  'base.role': 'Hlutverk',
  'base.charlimit': '{n} af {max} stafbilum',
  'base.from': 'Frá',
  'base.to': 'Til',
  'base.location': 'Staðsetning',
  'base.publish': 'Birta',
  'base.ok': 'Allt í lagi',
  'base.loading': 'Augnablik, sæki gögn...',
  'base.saving': 'Augnablik, vista breytingar...',
  'base.search': 'Leita',
  'base.show_all': 'Sýna allt',
  'base.confirm': 'Staðfesta val',
  'base.warning': 'Viðvörun',
  'base.unsorted': 'Óflokkað',
  'base.settings': 'Stillingar',

  'contentful.Monitoring': 'Áskriftir',
  'contentful.Issues': 'Málalisti',
  'contentful.User management': 'Notendaumsýsla',
  'contentful.My Issues': 'Mín mál',

  'community.selected': 'Valið sveitarfélag',

  'errorpage.error.label': '404 VILLUSKILABOÐ',
  'errorpage.error.title': 'Þessi síða fannst ekki',
  'errorpage.error.subtitle': 'Því miður fundum við ekki síðuna sem þú leitar að. Við mælum með því að þú skoðir öll skipulagsmál í gáttinni.',
  'errorpage.error.button': 'Skoða öll skipulagsmál',

  'file.label': 'Bæta við fylgiskjali',
  'file.edit.tooltip': 'Breyta nafni á skjali',
  'file.error.large_file': 'Ekki tókst að bæta við einu eða fleiri fylgiskjölum. Hámarksstærð skjala er 70 MB.',

  'faq.routes.faq.title': 'Spurt og svarað',
  'faq.routes.faq.navTitle': 'Leiðbeiningar',
  'faq.routes.faq.description':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet quam ultricies, dapibus massa quis, dapibus sem. Sed et dui pellentesque libero mollis vestibulum sed vitae justo. Duis vitae fringilla nunc. Phasellus sed condimentum tortor. Aliquam erat volutpat. Nulla eu erat porta, imperdiet nunc a, bibendum ligula. Nulla facilisi. Fusce consectetur nulla non turpis egestas posuere. Morbi non lorem ut est sollicitudin congue vel vel magna.',

  'faq.routes.instructions.navTitle': 'Leiðbeiningar',
  'faq.routes.instructions.title': 'Leiðbeiningar',
  'faq.routes.instructions.description':
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi sit amet quam ultricies, dapibus massa quis, dapibus sem. Sed et dui pellentesque libero mollis vestibulum sed vitae justo. Duis vitae fringilla nunc. Phasellus sed condimentum tortor. Aliquam erat volutpat. Nulla eu erat porta, imperdiet nunc a, bibendum ligula. Nulla facilisi. Fusce consectetur nulla non turpis egestas posuere. Morbi non lorem ut est sollicitudin congue vel vel magna.',

  'frontpage.hero.button': 'Skoða mál í kortasjá',
  'frontpage.text_and_video.tag': 'Kennsluefni',
  'frontpage.text_and_video.button': 'Leiðbeiningar',
  'frontpage.faq.question.1': 'Hvað gerir Skipulagsgáttin?',
  'frontpage.faq.button': 'Sjá fleiri spurningar',
  'frontpage.issues_on_map.pill.text': 'kortasjá',
  'frontpage.issues_on_map.subtitle.1': 'Nákvæm kortasjá',
  'frontpage.issues_on_map.subtitle.2': 'Leita að málum í nærumhverfi',
  'frontpage.issues_on_map.subtitle.3': 'Sía eftir tegund mála',
  'frontpage.issues_on_map.button': 'Skoða kort',

  'issue.issue_no': 'Mál nr.',
  'issue.issue_no_small': 'nr.',
  'issue.create': 'Stofna mál',
  'issue.published': 'Birt',
  'issue.updatedAt': 'Síðast uppfært',
  'issue.delegation': 'Umboð',
  'issue.edit': 'Breyta máli',
  'issue.monitor': 'Vakta mál',
  'issue.can_edit': 'Þú hefur full réttindi til þess að uppfæra og breyta þessu máli.',
  'issue.title': 'Heiti máls',
  'issue.related_issues': 'Tengd mál',
  'issue.related_issues.search': 'Leita að tengdum málum',
  'issue.new_files': 'Óvistaðar skrár',
  'issue.garbage_files': 'Skrár sem munu eyðast við vistun gagna',
  'issue.view.tooltip.show': 'Birta mál á ytri vef',
  'issue.view.tooltip.hide': 'Fela mál á ytri vef',
  'issue.view.tooltip.view': 'Skoða mál á ytri vef',
  'issue.view.tooltip.delete': 'Eyða máli',
  'issue.view.tooltip.delete.warning': 'Að eyða máli er óafturkræf aðgerð og málið mun hverfa að eilífu. Ertu viss um að þú viljir eyða málinu?',
  'issue.created_by_me': 'Mín mál',
  'issue.editable_by_me': 'Mál sem ég má breyta',
  'issue.commented_by_me': 'Mál með athugasemdum',
  'issue.monitored_by_me': 'Vöktuð mál',
  'issue.status': 'Staða máls',
  'issue.table.no_results': 'Því miður fundust engar niðurstöður með þessari leit.',
  'issue.table.help': 'Vinsamlegast gakktu úr skugga um að öll orð séu rétt stafsett eða reyndu önnur (almennari) leitarorð',
  'issue.geometries': 'Staðsetningu á korti',
  'issue.close': 'Ljúka máli',
  'issue.contact.inquiries': 'Fyrirspurnir',
  'issue.contact.inquiries.to': 'Fyrirspurnir berist til',
  'issue.contact.inquiries.info': 'Hvert skal beina fyrirspurnum um málið. Ef tengiliður er ekki á listanum má bæta honum við hér.',
  'issue.contact.email': 'Netfang tengiliðs',
  'issue.contact.name': 'Nafn tengiliðs',
  'issue.contact.add': 'Bæta við tengilið',
  'issue.contact.list': 'Tengiliðir af lista',
  'issue.contact.confirm': 'Velja tengiliði',
  'issue.contact.missing_delegation.warning': 'Þetta mál hefur ekki umboð og því ekki hægt að hengja tengiliði á það',
  'issue.tooltip.save.contact': 'Vista tengilið',
  'issue.tooltip.delete.contact': 'Eyða tengilið',
  'issue.contact.input.error': 'Vinsamlegast klárið að fylla út innsláttarreiti',

  'issue.files.download': 'Sækja öll gögn ({n})',
  'issue.files.shapefile': 'Shapefile',
  'issue.files.shapefile.upload': 'Lesa inn Shapefile',
  'issue.files.tooltip': 'Hlaða niður öllum skrám fyrir þetta mál',
  'issue.files.csv': 'Sækja öll mál (csv)',

  'issue.information': 'Grunnupplýsingar',
  'issue.information.subtitle': 'Hér þarf að setja inn grunnupplýsingar um það mál sem þú ert að stofna. Það er hægt að breyta þessum texta síðar í ferlinu.',
  'issue.information.optional_phases.help': 'Hakið í fasa sem skal vera sleppt.',

  'issue.index.results': 'Niðurstöður',
  'issue.index.results_for': 'Niðurstöður fyrir',
  'issue.index.results.interpolated': 'Sýni niðurstöður fyrir {n} mál',
  'issue.index.filters.clear': 'Hreinsa allar síur',

  'issue.lifecycle': 'Staða ferlis',
  'issue.lifecycle.ready': 'Ekki byrjað',
  'issue.lifecycle.process_initialized': 'Ferli í gangi',
  'issue.lifecycle.done': 'Lokið',

  'issue.hidden': 'Málið er ekki sýnilegt á ytri vef',
  'issue.hidden.outer': 'Málið er ekki orðið sýnilegt fyrir almenna notendur, einungis fyrir þá sem hafa sérstök réttindi á málið',
  'issue.visible': 'Málið er sýnilegt á ytri vef',
  'issue.publish_click': 'Smelltu hér til þess að birta málið',
  'issue.hide_click': 'Smelltu hér til þess að fela málið',
  'issue.reopen': 'Enduropna mál',
  'issue.reopen.confirm': 'Ertu viss um að þú viljir opna málið aftur?',

  'issue.map': 'Kort',
  'issue.map.legend': 'Táknmyndir',
  'issue.map.legend.help': 'Litir og táknmyndir á korti tákna stöðu máls hverju sinni',
  'issue.map.draw.Point': 'Punktur',
  'issue.map.draw.LineString': 'Lína',
  'issue.map.draw.Polygon': 'Fláki',
  'issue.map.grayscale': 'Gráskalakort',
  'issue.map.image': 'Myndkort',
  'issue.map.osm': 'Götukort',
  'issue.map.import.tooltip': 'Lesa inn staðsetningu úr Shapefile',
  'issue.map.draw.tooltip': 'Teikna staðsetningu á kort',
  'issue.map.draw-cancel.tooltip': 'Hætta að teikna',
  'issue.map.draw.Point.tooltip': 'Teikna punkt',
  'issue.map.draw.LineString.tooltip': 'Teikna línu',
  'issue.map.draw.Polygon.tooltip': 'Teikna fláka',
  'issue.map.undo.tooltip': 'Hætta við síðustu aðgerð',
  'issue.map.redo.tooltip': 'Endurframkvæma síðustu aðgerð',
  'issue.map.save.tooltip': 'Vista',
  'issue.map.close.tooltip': 'Hætta við',
  'issue.map.expand': 'Stækka kort',
  'issue.map.minimize': 'Minnka kort',
  'issue.map.delete.tooltip': 'Eyða fláka',
  'issue.map.hide': 'Fela kort',
  'issue.map.show': 'Skoða á korti',
  'issue.map.addCommunityMapMarker': 'Bæta við afmörkun valins sveitarfélags',
  'issue.map.addCommunitiesMapMarker': 'Bæta við afmörkun valdra sveitarfélaga',

  'issue.phase.name': 'Fasi',
  'issue.phase.not_started': 'Mál ekki komið i vinnslu',
  'issue.phase.missing': 'Vantar upplýsingar um fasa',
  'issue.phase.description': 'Lýsing',
  'issue.phase.title': 'Titill',
  'issue.phase.statutory_reviewer': 'umsagnaraðila',
  'issue.phase.reviewers': 'Umsagnaraðilar',
  'issue.phase.reviewer.add': 'Bæta við umsagnaraðila',
  'issue.phase.file_input': 'Innsetning gagna',
  'issue.phase.reviewer.select': 'Velja umsagnaraðila',
  'issue.phase.complete': 'Ljúka fasa',
  'issue.phase.resolve': 'Ljúka afgreiðslu SLS',
  'issue.phase.next': 'Hefja næsta fasa',
  'issue.phase.start': 'Næsta skref',
  'issue.phase.files.tooltip': 'Hlaða niður skrám fyrir þetta mál',
  'issue.phase.revert': 'Bakka með fasa',
  'issue.phase.review.change_date':
    'Ef breyta þarf kynningartíma, svo sem til að framlengja frest til umsagna, er hægt að gera það hér. \nMeð því að breyta kynningartíma sendist tilkynning þess efnis til valinna umsagnaraðila og þeirra sem vakta málið.',
  'issue.phase.review.change_date.title': 'Breyta kynningartíma',

  'issue.phase.state': 'Staða máls',
  'issue.phase.state.ready': 'Ekki byrjað',
  'issue.phase.state.published': 'Í vinnslu',
  'issue.phase.state.closed': 'Lokið',

  'issue.phase.state.in_sls_review': 'Í yfirferð',
  'issue.phase.state.reviewing': 'Opið fyrir athugasemdir',
  'issue.phase.state.in_review_procedure': 'Í umsagnarferli',
  'issue.phase.state.in_progress': 'Í vinnslu',
  'issue.phase.state.rejected': 'Máli hafnað',
  'issue.phase.state.not_started': 'Ekki hafið',

  'issue.phase.step.send_to_review': 'Senda gögn til athugunar',
  'issue.phase.step.send_proposal_to_review': 'Senda tillögu til athugunar',
  'issue.phase.step.send_proposal_to_confirmation': 'Senda tillögu til staðfestingar',

  'issue.phase.step.further_info': 'Óska eftir frekari gögnum',
  'issue.phase.step.further_info.confirm': 'Staðfesta val',
  'issue.phase.step.start': 'Hefja næsta skref',
  'issue.phase.step.unimplemented': "'{step}' er ekki búið að útfæra",
  'issue.phase.step.confirm': 'Staðfestu að þú sért búin að setja inn öll gögn áður en haldið er áfram.',
  'issue.phase.step.start_reviews': 'Þú ert að hefja fasa með kynningartíma. Athugið að ekki er hægt að breyta kynningartíma fasa eftir að hann er hafinn.',
  'issue.phase.step.skip_reviewers':
    'Athugaðu að þú hefur valið að senda ekki út umsagnarbeiðnir um málið. Það á einungis við þegar verið er að vinna inn yfirstaðin ferli eða ef enginn lögbundinn umsagnaraðili þarf aðkomu að ferlinu að svo stöddu.',
  'issue.phase.skip': 'Sleppa fasa',
  'issue.phase.step.file.warning': 'Þú hefur ekki lagt fyrir nein fylgiskjöl',
  'issue.phase.step.textbox': 'Lýsing',
  'issue.phase.step.prevProposals': 'Fyrri tillögur',
  'issue.phase.step.prevProposalReviews': 'Fyrri umsagnir',
  'issue.phase.step.data_published_date': 'Birt: {date}',
  'issue.phase.step.data_history': 'Samskiptasaga',
  'issue.phase.step.data_not_published': 'Ekki birt',
  'issue.phase.step.data_no_date': 'Dagsetning óskráð',
  'issue.phase.step.prev.confirm.title': 'Tilfærsla máls um eitt skref aftur',
  'issue.phase.step.prev.confirm':
    'Eingöngu skal færa mál aftur um skref ef þess hefur verið óskað. Til dæmis ef setja skal kynningarferli í biðstöðu. Þegar það er sett aftur af stað berast völdum umsagnaraðilum aftur umsagnarbeiðni um málið. Gögn þeirra skrefa sem þegar voru hafin halda enn inni og birtast á ytri vef.',
  'issue.phase.save.warning.title': 'Óvistaðar breytingar',
  'issue.phase.save.warning.text': 'Vinsamlegast athugið að óvistaðar breytingar eru á núverandi síðu',

  'issue.phase.step.com_phase.illegal':
    'Þú hefur ekki lagt fyrir nein fylgiskjöl eða bætt við texta við skrefið. Til að halda áfram þurfa gögn eða upplýsingar um málið að vera lögð fram.',
  'issue.phase.step.com_phase.close': 'Athugaðu að þú ert að ljúka fasanum. Einungis skal halda áfram ef að yfirferð Skipulagsstofnunar liggur fyrir.',

  'issue.reviews': 'Umsagnir ({n})',
  'issue.review.reviewer': 'Umsagnaraðili',
  'issue.review.files': 'Meðfylgjandi gögn',
  'issue.review.submit': 'Skila inn umsögninni',
  'issue.review.delete': 'Eyða umsögn',
  'issue.review.delete.warning': 'Athugaðu að með því að halda áfram eyðir þú út þessari athugasemd/umsögn. Aðgerðin er óafturkræf.',
  'issue.review.hide': 'Fela umsögn',
  'issue.review.show': 'Sýna umsögn',
  'issue.review.reviewer_check': 'Skrifa umsögn fyrir hönd valins umsagnaraðila',
  'issue.reviewer.name': 'Nafn umsagnaraðila',
  'issue.reviewer.email': 'Netfang umsagnaraðila',
  'issue.reviewer.confirm': 'Velja',

  'issue.review.submit.error': 'Ekki tókst að vista umsögn!',
  'issue.status.error': 'Óþekkt staða',
  'issue.user.new': 'Nýr ábyrgðaraðili',
  'issue.user.current': 'Skráður ábyrgðaraðili',
  'issue.user.change': 'Skipta út ábyrgðaraðila',
  'issue.user.change.description':
    'Hér er hægt að breyta ábyrgðaraðila á máli. Sjálfkrafa er stofnandi málsins skráður sem ábyrgðaraðili, og fær allar tilkynningar um framgang þess. Hægt er að skipta ábyrgðaraðila út ef annar aðili hefur tekið við verkefninu.',

  'issues.sort.new': 'Nýjast',
  'issues.sort.updated': 'Síðast uppfært',
  'issues.sort.expiring': 'Umsagnarfrestur að renna út',

  'monitoring.prompt': 'Þú verður að vera skráð(ur) inn til að vakta mál',
  'monitoring.help': 'Hér er hægt að skrá vöktun og fá tilkynningar um stofnun nýrra mála út frá henni',
  'monitoring.header.id': 'ID',
  'monitoring.header.communities': 'Sveitarfélög',
  'monitoring.header.zips': 'Póstnúmer',
  'monitoring.header.map': 'Kort',
  'monitoring.alert.error': 'Ekki tókst að skrá áskrift',
  'monitoring.tooltip': 'Skrá í vöktun til að fá póstsendingar þegar mál uppfærist',
  'monitoring.map.tooltip': 'Velja staðsetningu á korti',
  'monitoring.tab.ferli': 'Ferlar',
  'monitoring.tab.sveitarfelog': 'Sveitarfélög',
  'monitoring.tab.postnr': 'Póstnúmer',
  'monitoring.tab.tags': 'Viðfangsefni',
  'monitoring.tab.ferli.oll_ferli': 'Vakta stofnun allra mála',

  'nav.menu': 'Valmynd',
  'nav.search': 'Leita að máli',
  'nav.issue_create': 'Stofna mál',
  'nav.instructions': 'Leiðbeiningar',
  'nav.faq': 'Spurt og svarað',
  'nav.change_account': 'Skipta um aðgang',
  'nav.Persónuvernd': 'Persónuverndarskilmálar',
  'nav.Cookies': 'Vafrakökur',

  'role.SUPER_ADMIN': 'SysAdmin',
  'role.ADMIN': 'Yfirumsjón',
  'role.PUBLIC': 'Almennur',
  'role.DELEGATION_ADMIN': 'Umsjón',
  'role.DELEGATION_NORMIE': 'Meðlimur',
  'role.SLS_EMPLOYEE_ADALSKIPULAGSSVID': 'Starfsmaður Skipulagsstofnunar: Aðalskipulagssvið',
  'role.SLS_EMPLOYEE_DEILISKIPULAGSSVID': 'Starfsmaður Skipulagsstofnunar: Deiliskipulagssvið',
  'role.SLS_EMPLOYEE_UMHVERFISMATSSVID': 'Starfsmaður Skipulagsstofnunar: Umhverfismatssvið',
  'role.error': 'Óþekkt hlutverk',

  'search.title': 'Að hvaða skipulagsmáli leitar þú?',
  'search.input': 'Leitaðu eftir heiti máls eða málsnúmeri',
  'search.results.title': 'Mál',
  'search.results.none': 'Engar niðurstöður',
  'search.results': 'Sjá {n} niðurstöður',
  'search.detailed.title': 'Ítarleg leit',
  'search.location': 'Staðsetning',
  'search.location.input': 'Sláðu inn heimilisfang eða annað örnefni',
  'search.date_period': 'Tímabil',
  'search.clear_filters': 'Hreinsa síur',
  'search.clear_community-filter': 'Hreinsa síu sveitarfélaga',
  'search.loading': 'Sæki niðurstöður',
  'search.geojson': 'Staðsetning',
  'search.issue_status': 'Staða máls',
  'search.issue_status.help': 'Þú getur leitað að málum út frá stöðu þeirra.',
  'search.period.one_week': '1 vika',
  'search.period.one_month': '1 mánuður',
  'search.period.three_months': '3 mánuðir',
  'search.period.six_months': '6 mánuðir',

  'user.role': 'Þú ert með hlutverkið {role}.',
  'user.role.help': 'Þú ert innskráð/ur sem {user} með hlutverkið {role}.',
  'user.create': 'Stofna notanda',
  'user.edit': 'Breyta notanda',
  'user.edit.error': 'Aðgerð mistókst',
  'user.created': 'Notandi "{user}" hefur verið vistaður',
  'user.failed': 'Ekki tókst að vista notanda',
  'user.name': 'Nafn',
  'user.national_id': 'kt.',
  'user.delegation': 'Umboð',
  'user.delegation.member': 'Umboð: {fullName}',
  'user.delegation.has': 'Hefur Umboð',
  'user.delegation.view': 'Skoða umboð',
  'user.delegation.create': 'Bæta við umboði',
  'user.delegation.on_behalf_of': 'Umboð fyrir {delegatorTypeName}',
  'user.delegation.type.REGIONAL_PLANNING_COMMITEE': 'Svæðisskipulagsnefnd',
  'user.delegation.type.COMMUNITY': 'Sveitarfélag',
  'user.fake': 'Gervimaður',
  'user.fake.role': 'Þú ert innskráð/ur sem {user} með hutverk {role}. Smelltu á notanda til að skrá þig inn sem sá hinn sami.',
  'user.fake.accounts': 'Gerviaðgangar',
  'user.delegation.single_issue_access.list': 'Skráðir umsjónaraðilar',
  'user.delegation.single_issue_access.search.input': 'Leitaðu eftir umsjónaraðila',
  'user.delegation.single_issue_access.search.results.title': 'Notendur',
  'user.delegation.single_issue_access.search.results.empty': 'Engar niðurstöður',
  'user.preferences.invalid_email': 'Netfang ólöglegt',
  'user.preferences.on_save.error': 'Ekki tókst að vista breytingar',
  'user.preferences.on_save.success': 'Breytingar vistaðar',
  'user.preferences.email.title': 'Netfang',
  'user.preferences.name.title': 'Nafn',

  'validation.required': '{attribute} vantar',
  'validation.min': 'Velja þarf amk. {min} {attribute}',
  'validation.invalid': "Innsláttur fyrir '{attribute}' er ekki gildur",

  'statutory_reviewer.form_title': 'Umsagnaraðili',
  'statutory_reviewer.created': 'Umsagnaraðili hefur verið stofnaður',
  'statutory_reviewer.updated': 'Umsagnaraðili hefur verið vistaður',
  'statutory_reviewer.deleted': 'Umsagnaraðila hefur verið eytt',
  'statutory_reviewer.failed': 'Aðgerð misheppnaðist',
  'statutory_reviewer.categories': 'Flokkar',
  'statutory_reviewer.categories.edit': 'Breyta flokkum',
  'statutory_reviewer.categories.new': 'Nýr flokkur',
  'statutory_reviewer.categories.create': 'Stofna',
  'statutory_reviewer.categories.success': 'Aðgerð heppnaðist!',
  'statutory_reviewer.categories.failed': 'Aðgerð misheppnaðist',

  // Ferlar
  'Nýtt deiliskipulag.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Nýtt deiliskipulag.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Breyting á deiliskipulagi.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Breyting á deiliskipulagi.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Nýtt aðalskipulag.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Nýtt aðalskipulag.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Breyting á aðalskipulagi.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Breyting á aðalskipulagi.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Óveruleg breyting á aðalskipulagi.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Óveruleg breyting á aðalskipulagi.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Rammahluti aðalskipulags.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Rammahluti aðalskipulags.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Nýtt svæðisskipulag.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Nýtt svæðisskipulag.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',

  'Frestun á gerð aðalskipulags.Endanleg gögn.Gögn til staðfestingar': 'Ákvörðun um frestun',
  'Frestun á gerð aðalskipulags.Endanleg gögn.Gögn til staðfestingar.name': 'Ákvörðun um frestun',

  'tags.type.SKIPULAG': 'Skipulag',
  'tags.type.MAT_A_UMHVERFISAHRIFUM': 'Mat á umhverfisáhrifum',

  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga.Fyrirliggjandi gögn': 'Kynning breytinga',

  'base.tags': 'Viðfangsefni',
  'Óveruleg breyting á aðalskipulagi.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram ákvörðun sína á því hvort staðfesta skuli óverulega breytingu á aðalskipulagi eða hvort fara skuli með málið líkt og um breytingu séð að ræða. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust, í samræmi við 2. mgr. 36. gr. skipulagslaga.',
  'Nýtt aðalskipulag.Kynning tillögu': 'Auglýsing tillögu',
  'Breyting á svæðisskipulagi.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram umsögn sína um tillögu að breytingu á svæðisskipulagi í samræmi við 3. mgr. 23. gr. skipulagslaga og gr. 3.6.3. í skipulagsreglugerð. Skipulagsstofnun skal koma á framfæri umsögn sinni innan fjögurra vikna frá því að gögn tillögunnar bárust.',
  'Breyting á svæðisskipulagi.Kynning tillögu': 'Auglýsing tillögu',
  'Rammahluti aðalskipulags.Kynning tillögu': 'Auglýsing tillögu',
  'Breyting á aðalskipulagi.Kynning tillögu': 'Auglýsing tillögu',
  'Nýtt deiliskipulag.Kynning tillögu': 'Auglýsing tillögu',
  'Nýtt deiliskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun inn álit sitt áþví að auglýst deiliskipulag hljóti staðfestingu. ',
  'Óveruleg breyting á deiliskipulagi.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar.help':
    'Hér leggur Skipulagsstofnun fram álit sitt á því hvort staðfesta beri skipulagið og birta í Stjórnartíðindum.',
  'frontpage.issues_on_map.paragraph.1':
    'Öll mál eru skráð með staðsetningu í kortasjá Skipulagsgáttarinnar. Því er hægt er að skoða öll mál með einföldum hætti á korti.',
  'frontpage.issues_on_map.paragraph.3': 'Öll mál eru skráð með tilliti til tegundar máls, hvar það er statt í ferlinu og helstu efnisatriðum.',
  'frontpage.issues_on_map.paragraph.2':
    'Ef þú vilt fylgjast með málum í götunni þinni, hverfinu þínu eða sveitarfélagi er auðvelt að leita uppi mál í næsta umhverfi.',
  'frontpage.issues_on_map.paragraph.4': 'Hægt er vakta mál út frá ákveðinni staðsetningu (hnitum) með afmörkun svæða.',
  'frontpage.num_of_issues.content.1':
    'Öll þessi mál eru á einhverju stigi vinnslu um allt land á vegum sveitarfélaga, annarra stjórnvalda og ýmissa framkvæmdaraðila.',
  'frontpage.num_of_issues.content.2':
    'Skipulagsgáttin er staðurinn fyrir almenning og umsagnaraðila til að fylgjast með þróun mála sem þau varða og koma athugasemdum á framfæri. Jafnframt er hægt að fylgjast með þeim umsögnum og athugasemdum sem þegar hafa borist og viðbrögðum við þeim.',
  'frontpage.faq.answer.1':
    'Skipulagsgáttin er samráðsgátt um skipulagsmál, mat á umhverfisáhrifum og framkvæmdaleyfi. Þar er hægt að finna upplýsingar um öll mál í vinnslu, setja fram athugasemdir og ábendingar við mál á kynningartíma og nálgast endanleg gögn og afgreiðslur. Í gegnum Skipulagsgáttina er aðgengi að skipulags- og umhverfismatsferlum einfaldað og almenningur getur fylgst með og látið sig málin varða milliliðalaust.',
  'frontpage.faq.answer.2':
    'Rafræn skilríki eru notuð fyrir alla notendur, bæði þá sem koma að stofnun og úrvinnslu mála og þá sem vilja setja fram athugasemdir eða ábendingar. Ef þú ert notandi Skipulagsgáttarinnar fyrir hönd sveitarfélags getur þú fengið umboð til þess að stofna mál og breyta málum. Einnig getur sveitarfélag eða annar umsjónaraðili veitt umboð til vinnslu einstakra mála.',
  'frontpage.faq.answer.3':
    'Ef að kynningartími máls er yfirstandandi er hægt að leggja fram athugasemd við það inn á Skipulagsgátt. Það er gert inn á síðu málsins með því að ýta á "bæta við umsögn". Í umsagnarglugganum er hægt að leggja fram athugasemd ýmist með því að skrifa hana í textaboxið eða með því að bæta henni við sem viðhengi. Þegar búið að útfylla umsagnarformið þarf síðan að ýta á "skila inn umsögninni" til þess að hún birtist undir málinu. Eingöngu innskráðir notendur geta lagt fram athugasemdir/umsagnir.\n\nVið úrvinnslu allra mála sem kynnt eru í Skipulagsgátt er farið yfir allar athugasemdir af viðkomandi stjórnvaldi eða eftir atvikum framkvæmdaraðila og tekin afstaða til þeirra við endanlega ákvarðanatöku. Það er mismunandi eftir tegundum mála og málsstigum hvort ábyrgðaraðilum máls sé skylt að svara athugasemdum.',
  'frontpage.faq.answer.4': 'Hafir þú athugasemd um Skipulagsgáttina eru allar ábendingar vel þegnar og má koma þeim á framfæri til skipulagsgatt@skipulag.is.',
  'process.create': 'Hvaða tegund máls vilt þú stofna?',
  'process.create.select': 'Hér eru ný mál stofnuð í Skipulagsgáttinni. ',
  'Nýtt strandsvæðisskipulag.Lýsing': 'Lýsing',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Nýtt strandsvæðisskipulag.Staðfesting': 'Staðfesting',
  'Nýtt strandsvæðisskipulag.Grunnupplýsingar.help':
    'Stofnun máls vegna nýs strandsvæðisskipulags í samræmi við 5. kafla laga um skipulag haf- og strandsvæða. \nHaf- og strandsvæði ná samkvæmt lögum frá netlögum að ytri mörkum efnahagslögsögunnar. Því skal við val á sveitarfélagi einungis velja kostinn „Haf- og strandsvæði utan netlaga“. \n',
  'Nýtt strandsvæðisskipulag.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fyrir hönd svæðisráðs fram lýsingu á verkefninu í samræmi við 11. gr. laga um skipulag haf- og strandsvæða og sem nánar er kveðið á um í 4. kafla reglugerðar um gerð strandsvæðisskipulags. Í skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar aðliggjandi sveitarfélaga um lýsinguna.',
  'Nýtt strandsvæðisskipulag.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt strandsvæðisskipulag.Lýsing.Niðurstaða umsagnarferlis.help': 'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim ef við á.',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Ef ætlunin er að kynna tillögu á vinnslustigi áður en endanleg tillaga er auglýst skal hún lögð fram hér til kynningar.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er tillögu að strandssvæðisskipulagi á vinnslustigi, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram leggja fram samantekt á umsögnum sem borist hafa vegna tillögu á vinnslustigi og viðbrögð við þeim ef við á.',
  'Nýtt strandsvæðisskipulag.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér skal lögð fram tillaga sú sem á að auglýsa ef leita á umsagnar um hana hjá Skipulagsstofnun áður en auglýst er.',
  'Nýtt strandsvæðisskipulag.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Skipulagsstofnun getur hér lagt fram ábendingar sínar varðandi þá tillögu sem til stendur að auglýsa.',
  'Nýtt strandsvæðisskipulag.Kynning tillögu': 'Auglýsing tillögu',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fyrir hönd svæðisráðs fram þá tillögu sem auglýsa skal, í samræmi við 12. gr. laga um skipulag haf- og strandsvæða. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera átta vikur hið minnsta.',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er tillögu að strandsvæðisskipulagi, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal Skipulagsstofnun fyrir hönd svæðisráðs setja fram samantekt á umsögnum sem borist hafa. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda.',
  'Nýtt strandsvæðisskipulag.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal Skipulagsstofnun fyrir hönd svæðisráðs setja fram tillögu að strandsvæðisskipulagi sem samþykkt hefur verið af svæðisráði, og óskað er staðfestingar á í samræmi við 3. mgr. 13. gr. laga um skipulag haf- og strandsvæða.',
  'Nýtt strandsvæðisskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér skal leggja fram endanleg gögn staðfests strandssvæðisskipulags ásamt fylgigögnum þess. Þegar auglýsing um nýtt strandsvæðisskipulag hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.',
  'Breyting á strandsvæðisskipulagi.Lýsing': 'Lýsing',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Breyting á strandsvæðisskipulagi.Staðfesting': 'Staðfesting',
  'Breyting á strandsvæðisskipulagi.Grunnupplýsingar.help':
    'Stofnun máls vegna breytingar á strandsvæðisskipulagi í samræmi við 14. gr. laga um skipulag haf- og strandsvæða. \nHaf- og strandsvæði ná samkvæmt lögum frá netlögum að ytri mörkum efnahagslögsögunnar. Því skal við val á sveitarfélagi einungis velja kostinn „Haf- og strandsvæði utan netlaga“.',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Ef ætlunin er að kynna tillögu á vinnslustigi áður en endanleg tillaga er auglýst skal hún lögð fram hér til kynningar.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram leggja fram samantekt á umsögnum sem borist hafa vegna tillögu á vinnslustigi og viðbrögð við þeim ef við á.',
  'Breyting á strandsvæðisskipulagi.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér skal lögð fram tillaga sú sem á að auglýsa ef leita á umsagnar um hana hjá Skipulagsstofnun áður en auglýst er.',
  'Breyting á strandsvæðisskipulagi.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Skipulagsstofnun getur hér lagt fram ábendingar sínar varðandi þá tillögu sem til stendur að auglýsa.',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu': 'Auglýsing tillögu',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fyrir hönd svæðisráðs fram þá tillögu að breytingu sem auglýsa skal, í samræmi við 12. gr. laga um skipulag haf- og strandsvæða. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera átta vikur hið minnsta. ',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er tillögu að strandsvæðisskipulagsbreytingu, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal Skipulagsstofnun fyrir hönd svæðisráðs setja fram samantekt á umsögnum sem borist hafa Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda.',
  'Breyting á strandsvæðisskipulagi.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal Skipulagsstofnun fyrir hönd svæðisráðs setja fram tillögu að strandsvæðisskipulagsbreytingu sem samþykkt hefur verið af svæðisráði, og óskað er staðfestingar á í samræmi við 3. mgr. 13. gr. laga um skipulag haf- og strandsvæða.',
  'Breyting á strandsvæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér skal leggja fram endanleg gögn staðfestrar breytingar á strandssvæðisskipulagi ásamt fylgigögnum hennar. Þegar auglýsing um nýtt strandsvæðisskipulag hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.',
  'Nýtt Framkvæmdarleyfi.Grunnupplýsingar.help':
    'Hér er hægt að stofna til umsóknar um framkvæmdaleyfi í samræmi við 13. gr. skipulagslaga. Athugið að stofna þarf ferli mats á umhverfisáhrifum eða tilkynningu til ákvörðunar um matsskyldu eftir því sem við á.',
  'Nýtt Framkvæmdarleyfi.Birting á samþykktu framkvæmdaleyfi.Fyrirliggjandi gögn.help':
    'Hér skal leggja fram gögn vegna umsóknar um framkvæmdaleyfi. Hafið samband við byggingarfulltrúa í viðkomandi sveitarfélagi áður en umsókn er hlaðið upp hér. Skipulags- og byggingarfulltrúar sveitarfélagsins eru einu umsagnaraðilar sem ber að skrá.',
  'Nýtt Framkvæmdarleyfi.Birting á samþykktu framkvæmdaleyfi.Fyrirliggjandi gögn.files': 'Hér skal hlaða upp umsókn og viðeigandi gögnum.',
  'Nýtt Framkvæmdarleyfi.Birting á samþykktu framkvæmdaleyfi.Niðurstaða umsagnarferlis.help':
    'Hér leggur sveitarstjórn fram ákvörðun sína um veitingu byggingarleyfis. ',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd.Fyrirliggjandi gögn.files':
    'Hér skal leggja fram gögn þeirrar framkvæmdar sem til stendur að kynna, þ.e. tilkynningu framkvæmdaraðila vegna fyrirhugaðrar framkvæmdar ásamt fylgigögnum ef við á. ',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd.Niðurstaða umsagnarferlis.help':
    'Hér skulu lögð fram viðbrögð framkvæmdaraðila við framkomnum umsögnum.',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar.help':
    'Hér leggur Skipulagsstofnun fram niðurstöðu sína um hvort tilkynningarskyld framkvæmd skuli háð umhverfismati eða setur fram ábendingar um tilhögun hennar ef svo reynist ekki, í samræmi við 20. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar': 'Kynning umhverfismatsskýrslu',
  'Nýtt deiliskipulag.Athugun fyrir auglýsingu.Tillaga til athugunar.help': 'Hér skal hlaðið upp tillögu.',
  'Nýtt deiliskipulag.Staðfesting.Gögn til staðfestingar.help': 'Hér skal hlaða upp lokaútgáfu samþykkts skipulags til yfirferðar Skipulagsstofnunar. ',
  'Breyting á deiliskipulagi.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram tillögu að deiliskipulagsbreytingu ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórn, og til stendur að afgreiða í samræmi við 1. mgr. 42. gr. skipulagslaga. \nSkipulagsstofnun skal þá taka tillöguna til athugunar og koma ábendingum sínum á framfæri innan þriggja vikna frá því að gögn tillögunnar bárust.',
  'Breyting á deiliskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram athugasemdir sínar um tillögu að deiliskipulagsbreytingu. Athugun Skipulagsstofnunar skal liggja fyrir innan þriggja vikna frá því að gögn tillögunnar bárust, í samræmi við 1. mgr. 42. gr. skipulagslaga. ',
  'issue.review.files.help': 'Hér er hægt að hlaða upp skjali ásamt skýringargögnum eftir því sem við á.',
  'issue.phase.reviewers.awaiting': 'Ókomnar umsagnir',
  'Kynning tillögu': 'Auglýsing tillögu',
  'issue.process': 'Tegund máls',
  'Lýsing vantar': 'Lýsingu vantar',
  'frontpage.new_issues.title': 'Nýjustu málin',
  'Nýtt strandsvæðisskipulag.Staðfesting.Staðfesting Skipulagsstofnunar': 'Endanleg gögn',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisráðs',
  'Breyting á strandsvæðisskipulagi.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fyrir hönd svæðisráðs fram lýsingu á verkefninu í samræmi við 11. gr. laga um skipulag haf- og strandsvæða og sem nánar er kveðið á um í 4. kafla reglugerðar um gerð strandsvæðisskipulags. Í skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar aðliggjandi sveitarfélaga um lýsinguna.',
  'Breyting á strandsvæðisskipulagi.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á strandsvæðisskipulagi.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisráðs',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er tillögu að strandssvæðisskipulagsbreytingu á vinnslustigi, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisráðs',
  'Breyting á strandsvæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar': 'Endanleg gögn',
  'issue.phase.state.draft': 'Í vinnslu',
  'Breyting á strandsvæðisskipulagi.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim ef við á.',
  'monitoring.header.tags': 'Efnisorð',
  'monitoring.header.types': 'Tegund mála',
  'search.issue_process': 'Ferli',
  'search.issue_process.help': 'Þú getur leitað að málum eftir tegund og núverandi fasa, þ.e. hvar það er statt í ferlinu.',
  'search.issue_type': 'Viðfangsefni',
  'search.issue_type.help': 'Þú getur leitað að málum út frá efnisorðum.',
  'search.issue_delegations': 'Umboð',
  'search.issue_delegations.help':
    'Mismunandi aðilar koma að stofnun og vinnslu mála inn á gáttinni. Hér er hægt að flokka mál út frá því hver kom að stofnun þeirra.',
  'search.delegations.COMMUNITY': 'Sveitarfélög',
  'search.delegations.REGIONAL_PLANNING_COMMITEE': 'Svæðisskipulagsnefnd',
  'search.delegations.SVAEDIS_RAD': 'Svæðisráð',
  'search.delegations.OTHER': 'Önnur umboð',

  'my.issues.user.role.help': 'Þú ert innskráð/ur sem notandinn {user}.',
  'my.issues.user.role.delegation.help': 'Þú ert innskráð/ur sem notandinn {user} í umboðinu: {delegation} ({role}).',
  'my.issues.user.change.delegation': 'Þetta mál er með umboðið {delegation}. Viltu skipta yfir í það umboð?',

  'issue.review.published': 'Umsögn birt ',
  'Nýtt svæðisskipulag.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Nýtt svæðisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Breyting á svæðisskipulagiLýsing.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram lýsingu á verkefninu í samræmi við 1. mgr. 23. gr. skipulagslaga og sem nánar er kveðið á um í 3. kafla skipulagsreglugerðar. Hægt er að nálgast leiðbeiningar um efni lýsingarinnar í 3.2.3. gr. skipulagsreglugerðar.\nHér að neðan er hægt að velja þá aðila sem skulu fá gögnin send til umsagnar og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um lýsinguna.',
  'Breyting á svæðisskipulagi.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Breyting á svæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga': 'Kynning breytinga',
  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að óverulegri aðalskipulagsbreytingu, og viðbrögð við þeim ef við á.',
  'Óveruleg breyting á aðalskipulagi.Ákvörðun Skipulagsstofnunar': 'Staðfesting',
  'Óveruleg breyting á aðalskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram rökstudda tillögu að breytingu aðalsskipulags ásamt tilheyrandi fylgigögnum, sem til stendur að staðfesta. \nSkipulagsstofnun skal þá taka tillöguna til afgreiðslu og taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 2. mgr. 36. gr. skipulagslaga. ',
  'Frestun á gerð aðalskipulags.Staðfesting': 'Málsmeðferð frestunar',
  'Frestun á gerð aðalskipulags.Staðfesting.Gögn til staðfestingar': 'Gögn til yfirferðar',
  'Frestun á gerð aðalskipulags.Staðfesting.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram gögn vegna beiðni sveitarstjórnar um samþykki ráðherra á frestun á gerð aðalskipulags í allt að fjögur ár fyrir ákveðið landsvæði ef óvissa eða ágreiningur ríkir um atriði sem geta haft veruleg áhrif á framkvæmd skipulagsins.',
  'Frestun á gerð aðalskipulags.Staðfesting.Staðfesting Skipulagsstofnunar': 'Umsögn Skipulagsstofnunar',
  'Frestun á gerð aðalskipulags.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Umsögn Skipulagsstofnunar',
  'Frestun á gerð aðalskipulags.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér skal Skipulagsstofnun leggja fram umsögn sína um frestun á gerð aðalskipulagsáætlunar fyrir ákveðið landsvæði, í samræmi við 33. gr. skipulagslaga.',
  'Nýtt deiliskipulag.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Breyting á deiliskipulagi.Staðfesting': 'Athugun Skipulagsstofnunar',
  'Breyting á deiliskipulagi.Staðfesting.Gögn til staðfestingar': 'Gögn til yfirferðar',
  'Breyting á deiliskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar': 'Athugun Skipulagsstofnunar',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga': 'Grenndarkynning',
  'Óveruleg breyting á deiliskipulagi.Ákvörðun Skipulagsstofnunar': 'Endanleg gögn',
  'Óveruleg breyting á deiliskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar': 'Endanleg gögn',
  'Nýtt strandsvæðisskipulag.Staðfesting.Gögn til staðfestingar': 'Gögn til staðfestingar',
  'Nýtt strandsvæðisskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Endanleg gögn',
  'Breyting á strandsvæðisskipulagi.Staðfesting.Gögn til staðfestingar': 'Gögn til staðfestingar',
  'Breyting á strandsvæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Endanleg gögn',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun': 'Kynning matsáætlunar',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.Niðurstaða umsagnarferlis.help': 'Hér skulu lögð fram viðbrögð framkvæmdaraðila við framkomnum umsögnum.',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar': 'Álit Skipulagsstofnunar',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help':
    'Ef Skipulagsstofnun kallar eftir frekari upplýsingum frá framkvæmdaraðila skal setja þau fram hér.',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar': 'Álit Skipulagsstofnunar',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar.name': 'Álit Skipulagsstofnunar',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar.help':
    'Hér leggur Skipulagsstofnun fram álit sitt á framlögðu umhverfismati framkvæmdar í samræmi við 24. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd.Niðurstaða umsagnarferlis.name': 'Viðbrögð framkvæmdaraðila',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar.Staðfesting Skipulagsstofnunar.name': 'Ákvörðun Skipulagsstofnunar',
  'Nýtt framkvæmdaleyfi.Grunnupplýsingar.help':
    'Hér er hægt stofna ferli fyrir nýtt framkvæmdaleyfi í samræmi við 13. gr. eða 14. gr. skipulagslaga.\nAthugið: Liggi vafi á því hvort framkvæmdin falli undir lög um umhverfismat framkvæmda og áætlana skal fá úr því skorið áður en leitast er eftir umsögnum. Ganga má úr skugga um mats- eða tilkynningarskyldu framkvæmdar með því að senda fyrirspurn á skipulag@skipulag.is. Gögn umsóknar skulu þá fylgja fyrirspurninni.',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga': 'Grenndarkynning framkvæmdaleyfis',
  'issue.files': 'Gögn málsins',
  'issue.files.help': 'Hægt er að hlaða niður einstaka skrám eða hlaða niður öllum neðangreindum gögnum í samþjappaðri möppu.',
  'issue.description': 'Upplýsingar um málið',
  'issue.review.statutory_reviewer_required':
    'Einungis er óskað eftir umsögnum sérvalinna umsagnaraðila. Vinsamlegast staðfestu að þú sért að senda inn umsögn fyrir hönd umsagnaraðila með viðeigandi vali úr lista. Tilkynning um matsskyldu ekki opin til athugasemda frá almenningi.',
  'monitoring.new': 'Skrá nýja áskriftarleið',
  'frontpage.issues_on_map.title': 'Hægt er að skoða öll mál og staðsetningu þeirra í kortasjánni',
  'frontpage.hero': 'Skoðaðu málið í Skipulags{hyphen}gáttinni',
  'frontpage.hero.subtitle':
    'Skipulagsgáttin er samráðsgátt um skipulagsmál, mat á umhverfisáhrifum og framkvæmdaleyfi. Hér getur þú fundið upplýsingar um mál í vinnslu, gert athugasemdir við mál á kynningartíma og nálgast endanleg gögn og afgreiðslur.',
  'frontpage.new_issues.button': 'Sjá öll mál',
  'frontpage.issues_on_map.subtitle.4': 'Vakta mál út frá hnitum',
  'frontpage.num_of_issues.title': ' mál í kynningu',
  'issue.review.write': 'Bæta við umsögn',
  'validation.submit': 'Úps! Vinsamlegast leiðréttið villur',
  'issue.phase.review_period': 'Kynningartími',
  'issue.review.header.help':
    'Vinsamlegast skrifaðu umsögn eða athugasemd þína í opna innsláttarreitinn hér. Einnig er hægt að skila inn umsögn/athugasemd í formi viðhengis og bæta við fylgigögnum hér að neðan.',
  'frontpage.faq': 'Algengar spurningar',
  'frontpage.faq.question.4': 'Ertu með ábendingu varðandi Skipulagsgátt?',
  'issue.phase.extrareviewer.add':
    'Ef umsagnaraðilinn er ekki á listanum má bæta honum við hér. Hér er einnig hægt að bæta við einstaklingum eða hagsmunaaðilum sem ætlunin er að leita umsagnar hjá.',
  'issue.phase.statutoryreviewer.add': 'Skráðu umsagnaraðila ',
  'issue.related_issues.help':
    'Ef önnur mál um sama viðfangsefni að hluta eða heild liggja einnig fyrir er hægt að tengja málin saman hér. Dæmi um þetta er ef verið er að kynna samhliða breytingu á aðalskipulagi og deiliskipulagi. Annað dæmi er þegar unnið er að skipulagsgerð vegna framkvæmdar sem er í umhverfismati.',
  'issue.tags.help':
    'Velja skal viðeigandi viðfangsefni fyrir málið. Nákvæm útlistun á viðfangsefnum auðveldar notendum að leita eftir málum út frá efnisorðum. Hægt er að velja fleiri en eitt viðfangsefni. ',
  'issue.map.help':
    'Staðsetja skal öll mál. Hér er hægt að teikna staðsetningu eða afmörkun viðkomandi skipulagssvæðis, en ef fyrir liggja .shp skjöl með afmörkun er æskilegt að nota þau. Fyrir mál sem varða heildarendurskoðun aðalskipulags eða svæðisskipulag er hægt að notast við afmörkun sveitarfélags.',
  'issue.phase.reviewers.help':
    'Veljið þá aðila sem fá tillöguna senda til umsagnar. Helstu umsagnaraðila er að finna í listanum hér að neðan en einnig má bæta við einstaka umsagnaraðilum að vild.\nEf verið er að vinna inn yfirstaðin ferli eða ef enginn lögbundinn umsagnaraðili þarf aðkomu að ferlinu má haka við "enginn umsagnaraðili á við" hér að neðan.',
  'Nýtt svæðisskipulag.Lýsing': 'Lýsing',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Nýtt svæðisskipulag.Athugun fyrir auglýsingu': 'Athugun fyrir auglýsingu',
  'Nýtt svæðisskipulag.Staðfesting': 'Staðfesting',
  'Nýtt svæðisskipulag.Endanleg gögn': 'Endanleg gögn',
  'Nýtt svæðisskipulag.Grunnupplýsingar.help':
    'Stofnun máls vegna nýs svæðisskipulags eða heildarendurskoðunar á svæðisskipulagi í samræmi við 6. kafla skipulagslaga. ',
  'Nýtt svæðisskipulag.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Nýtt svæðisskipulag.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt svæðisskipulag.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram lýsingu á verkefninu í samræmi við 1. mgr. 23. gr. skipulagslaga og sem nánar er kveðið á um í 3. kafla skipulagsreglugerðar. Tilgreina þarf hvenær samþykkt var að kynna lýsinguna í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni fyrirhugaðrar skipulagstillögu.\nÍ skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.\nHægt er að nálgast leiðbeiningar um efni skipulagslýsingarinnar í gr. 3.2.3. í skipulagsreglugerð.\n',
  'Nýtt svæðisskipulag.Lýsing.Fyrirliggjandi gögn.files': 'Hér er skipulagslýsingu og fylgiskjölum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt svæðisskipulag.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisskipulagsnefndar',
  'Nýtt svæðisskipulag.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 3.2.4. í skipulagsreglugerð skal svæðisskipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð svæðisskipulagstillögu.',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar ',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram vinnslutillögu sem kynna skal almenningi í samræmi við 2. mgr. 23. mgr. skipulagslaga, áður en hún er tekin til formlegrar afgreiðslu nefndarinnar. Nánar er kveðið á um kynningu vinnslutillögu í gr. 3.6.1. í skipulagsreglugerð.\nTilgreina þarf hvenær vinnslutillagan var samþykkt til kynningar í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni skipulagstillögu \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. ',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er tillögu að svæðisskipulagi á vinnslustigi, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisskipulagsnefndar',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að svæðisskipulagi á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Nýtt svæðisskipulag.Athugun fyrir auglýsingu.Tillaga til athugunar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Nýtt svæðisskipulag.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér setur svæðisskipulagsnefnd fram þá tillögu sem hlutaðeigandi sveitarstjórnir hafa samþykkt að auglýsa og Skipulagsstofnun tekur nú til athugunar fyrir auglýsingu, í samræmi við 3. mgr. 23. gr. skipulagslaga og gr. 3.6.3. í skipulagsreglugerð. Tilgreina þarf hvenær tillagan var samþykkt til auglýsingar í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni skipulagstillögu. \nLeggja skal fram greinargerð, umhverfismatsskýrslu, forsendur, fundabókanir og önnur gögn ef við á.',
  'Nýtt svæðisskipulag.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram umsögn sína um tillögu að svæðisskipulagi í samræmi við 3. mgr. 23. gr. skipulagslaga og gr. 3.6.3. í skipulagsreglugerð. Skipulagsstofnun skal koma á framfæri umsögn sinni innan fjögurra vikna frá því að gögn tillögunnar bárust.',
  'Nýtt svæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Nýtt svæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt svæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram þá tillögu sem auglýsa skal, í samræmi við 24. gr. skipulagslaga og gr. 3.6.3. í skipulagsreglugerð. Tilgreina þarf hvenær tillagan var samþykkt til auglýsingar í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Nýtt svæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er svæðisskipulagstillögu, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Nýtt svæðisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisskipulagsnefndar',
  'Nýtt svæðisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal svæðisskipulagsnefnd setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda.\nHlutaðeigandi sveitarstjórnir skulu þegar hafa fengið umsagnir nefndarinnar til umfjöllunar og afgreiðslu í samræmi við 2. mgr. 25. gr. skipulagslaga.',
  'Nýtt svæðisskipulag.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Nýtt svæðisskipulag.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal svæðisskipulagsnefnd setja fram tillögu að svæðisskipulagi ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórnum, og óskað er staðfestingar á í samræmi við 3. mgr. 25. gr. skipulagslaga. Tilgreina þarf hvenær tillagan var samþykkt í svæðisskipulagsnefnd og í viðkomandi sveitarstjórnum. Auk skipulagsgagna þarf að leggja fram afrit af auglýsingum skipulagstillögunnar þ.á.m. afrit af auglýsingu í Lögbirtingablaði. Ef athugasemdir bárust á auglýsingatíma þarf að setja hér afrit af svarbréfi sem sent var þeim sem gerðu athugasemdir og afrit af auglýsingu um niðurstöðu svæðisskipulagsnefndar.\nSkipulagsstofnun skal taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 4. mgr. 25. gr. skipulagslaga.',
  'Nýtt svæðisskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Nýtt svæðisskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram afgreiðslu sína á svæðisskipulagi. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust, í samræmi við 4. mgr. 25. gr. skipulagslaga.',
  'Nýtt svæðisskipulag.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram undirrituð gögn staðfests svæðisskipulags þegar þau hafa tekið gildi með birtingu auglýsingar í B-deild Stjórnartíðinda.',
  'Breyting á svæðisskipulagi.Lýsing': 'Lýsing',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Breyting á svæðisskipulagi.Athugun fyrir auglýsingu': 'Athugun fyrir auglýsingu',
  'Breyting á svæðisskipulagi.Staðfesting': 'Staðfesting',
  'Breyting á svæðisskipulagi.Endanleg gögn': 'Endanleg gögn',
  'Breyting á svæðisskipulagi.Grunnupplýsingar.help': 'Stofnun máls vegna breytingar á svæðisskipulagi í samræmi við 27. gr. skipulagslaga.',
  'Velja þarf amk. 1 Sveitarfélag': 'Velja þarf amk. 1 sveitarfélag',
  'Breyting á svæðisskipulagi.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Breyting á svæðisskipulagi.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á svæðisskipulagi.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram lýsingu á verkefninu í samræmi við 1. mgr. 23. gr. skipulagslaga og sem nánar er kveðið á um í 3. kafla skipulagsreglugerðar. Tilgreina þarf hvenær samþykkt var að kynna lýsinguna í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni fyrirhugaðrar skipulagstillögu.\nÍ skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.\nHægt er að nálgast leiðbeiningar um efni skipulagslýsingarinnar í gr. 3.2.3. í skipulagsreglugerð.',
  'Breyting á svæðisskipulagi.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á svæðisskipulagi.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisskipulagsnefndar ',
  'Breyting á svæðisskipulagi.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem á. Samkvæmt gr. 3.2.4. í skipulagsreglugerð skal svæðisskipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð svæðisskipulagstillögu. ',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram þá vinnslutillögu sem kynna skal almenningi í samræmi við 2. mgr. 23. gr. skipulagslaga, áður en hún er tekin til formlegrar afgreiðslu nefndarinnar. Nánar er kveðið á um kynningu vinnslutillögu í gr. 3.6.1. í skipulagsreglugerð. Tilgreina þarf hvenær vinnslutillagan var samþykkt til kynningar í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni skipulagstillögu\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er tillögu að svæðisskipulagi á vinnslustigi, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisskipulagsnefndar',
  'Breyting á svæðisskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu svæðisskipulagsbreytingar á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Breyting á svæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á svæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur svæðisskipulagsnefnd fram þá tillögu að breytingu sem auglýsa skal, í samræmi við 24. gr. skipulagslaga og gr. 3.6.3. í skipulagsreglugerð. Tilgreina þarf hvenær tillagan var samþykkt til auglýsingar í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni skipulagstillögu. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Breyting á svæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er svæðisskipulagstillögu, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Breyting á svæðisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Breyting á svæðisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisskipulagsnefndar',
  'Breyting á svæðisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal svæðisskipulagsnefnd setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda.\nHlutaðeigandi sveitarstjórnir skulu þegar hafa fengið umsagnir nefndarinnar til umfjöllunar og afgreiðslu í samræmi við 2. mgr. 25. gr. skipulagslaga. ',
  'Breyting á svæðisskipulagi.Staðfesting.Gögn til staðfestingar.name': ' Innsetning gagna',
  'Breyting á svæðisskipulagi.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal svæðisskipulagsnefnd setja fram tillögu að breytingu á svæðisskipulagi ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórnum, og óskað er staðfestingar á í samræmi við 3. mgr. 25. gr. skipulagslaga. Tilgreina þarf hvenær tillagan var samþykkt í svæðisskipulagsnefnd og sveitarstjórnum. Auk skipulagsgagna þarf að leggja fram afrit af auglýsingum skipulagstillögunnar þ.á.m. afrit af auglýsingu í Lögbirtingablaði. Ef athugasemdir bárust á auglýsingatíma þarf að setja hér afrit af svarbréfi sem sent var þeim sem gerðu athugasemdir og afrit af auglýsingu um niðurstöðu svæðisskipulagsnefndar. \nSkipulagsstofnun skal taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 4. mgr. 25. gr. skipulagslaga.',
  'Breyting á svæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Breyting á svæðisskipulagi.Athugun fyrir auglýsingu.Tillaga til athugunar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Breyting á svæðisskipulagi.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér setur svæðisskipulagsnefnd fram þá tillögu sem hlutaðeigandi sveitarstjórnir hafa samþykkt að auglýsa og Skipulagsstofnun tekur nú til athugunar fyrir auglýsingu, í samræmi við 3. mgr. 23. gr. skipulagslaga og gr. 3.6.3. í skipulagsreglugerð. Tilgreina þarf hvenær tillagan var samþykkt til auglýsingar í svæðisskipulagsnefnd ásamt stuttri lýsingu á viðfangsefni skipulagstillögu. \nLeggja skal fram greinargerð, umhverfismatsskýrslu, forsendur, fundarbókanir og önnur fylgigögn ef við á.',
  'Breyting á svæðisskipulagi.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram undirrituð gögn staðfestrar svæðisskipulagsbreytingar þegar þau hafa tekið gildi með birtingu auglýsingar í B-deild Stjórnartíðinda.',
  'Breyting á svæðisskipulagi.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Breyting á svæðisskipulagi.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Nýtt aðalskipulag.Lýsing': 'Lýsing',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu': 'Athugun fyrir auglýsingu',
  'Nýtt aðalskipulag.Staðfesting': 'Staðfesting',
  'Nýtt aðalskipulag.Endanleg gögn': 'Endanleg gögn',
  'Nýtt aðalskipulag.Grunnupplýsingar.help':
    'Stofnun máls vegna nýs aðalskipulags eða heildarendurskoðunar á aðalskipulagi í samræmi við 7. kafla skipulagslaga.',
  'Nýtt aðalskipulag.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Nýtt aðalskipulag.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt aðalskipulag.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á verkefninu í samræmi við 1. mgr. 30. gr. skipulagslaga og sem nánar er kveðið á um í 4. kafla skipulagsreglugerðar. \nTilgreina þarf hvenær samþykkt var að kynna lýsinguna í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni fyrirhugaðrar skipulagstillögu.\nÍ skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.\nHægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 4.2.2. – 4.2.4. í skipulagsreglugerð.',
  'Nýtt aðalskipulag.Lýsing.Fyrirliggjandi gögn.files': 'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp. ',
  'Nýtt aðalskipulag.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt aðalskipulag.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 4.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð aðalskipulagstillögu.',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 2. mgr. 30. gr. skipulagslaga, áður en hún er tekin til formlegrar afgreiðslu sveitarstjórnar. \nTilgreina þarf hvenær vinnslutillagan var samþykkt til kynningar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. \nNánar er kveðið á um kynningu vinnslutillögu í gr. 4.6.1. í skipulagsreglugerð.',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er aðalskipulagstillögu á vinnslustigi, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að aðalskipulagi á vinnslustigi, og viðbrögð við þeim eftir því sem við á. ',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér setur sveitarstjórn fram þá tillögu sem samþykkt hefur verið að auglýsa og Skipulagsstofnun tekur nú til athugunar fyrir auglýsingu, í samræmi við 3. mgr. 30. gr. skipulagslaga og gr. 4.6.2. í skipulagsreglugerð. \nTilgreina þarf hvenær tillagan var samþykkt til auglýsingar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\n\nLeggja skal fram: \n-\tGreinargerð\n-\tSkipulagsuppdrætti\n-\tUmhverfismatsskýrslu\n-\tForsendur\n-\tSkýringaruppdrætti\n-\tSkrá yfir aðalskipulagsgögn á landupplýsingaformi (.zip) skv. leiðbeiningum og gagnalýsingu\n-\tSkrá yfir vegi í náttúru Íslands á landupplýsingaformi (.zip)\n-\tNiðurstöðu flokkunar landbúnaðarlands samkvæmt leiðbeiningum í greinargerð og á landupplýsingaformi (.zip)\n-\tFundabókanir\n-\tÖnnur gögn ef við á ',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu.Tillaga til athugunar.name': 'Gögn til athugunar hjá Skipulagsstofnun ',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram umsögn sína um tillögu að aðalskipulagi í samræmi við 3. mgr. 30. gr. skipulagslaga og gr. 4.6.2. í skipulagsreglugerð. Skipulagsstofnun skal koma á framfæri umsögn sinni innan fjögurra vikna frá því að gögn tillögunnar bárust.',
  'Nýtt aðalskipulag.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Nýtt aðalskipulag.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt aðalskipulag.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá aðalskipulagstillögu sem auglýsa skal, í samræmi við 31. gr. skipulagslaga og gr. 4.6.3. í skipulagsreglugerð.\nTilgreina þarf hvenær tillagan var samþykkt til auglýsingar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Nýtt aðalskipulag.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er aðalskipulagstillögu, umhverfismatsskýrslu, forsendum, skrá yfir vegi í náttúru Íslands á landupplýsingaformi (.zip), niðurstöður flokkunar landbúnaðarlands samkvæmt leiðbeiningum í greinargerð og á landupplýsingaformi (.zip) og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Nýtt aðalskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt aðalskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal sveitarstjórn setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda í samræmi við 1. mgr. 32. gr. skipulagslaga.',
  'Nýtt aðalskipulag.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Nýtt aðalskipulag.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram tillögu að aðalsskipulagi ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af sveitarstjórn, og óskað er staðfestingar á í samræmi við 2. mgr. 32. gr. skipulagslaga. Tilgreina þarf hvenær tillagan var samþykkt í sveitarstjórn.\nAuk skipulagsgagna þarf að leggja fram afrit af auglýsingum skipulagstillögunnar þ.á.m. afrit af auglýsingu í Lögbirtingablaði. Ef athugasemdir bárust á auglýsingatíma þarf að setja hér afrit af svarbréfi sem sent var þeim sem gerðu athugasemdir og afrit af auglýsingu um niðurstöðu sveitarstjórnar. Hafi stafræn skipulagsgögn á landupplýsingaformi (.zip) verið uppfærð frá því fyrir auglýsingu tillögunnar þarf að setja þau fram hér.\nSkipulagsstofnun skal taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 3. mgr. 32. gr. skipulagslaga.',
  'Nýtt aðalskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Nýtt aðalskipulag.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram afgreiðslu sína á aðalskipulagi. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust, í samræmi við 3. mgr. 32. gr. skipulagslaga.',
  'Nýtt aðalskipulag.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram undirrituð gögn staðfests aðalskipulags þegar þau hafa tekið gildi með birtingu auglýsingar í B-deild stjórnartíðinda.',
  'Breyting á aðalskipulagi.Lýsing': 'Lýsing',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Breyting á aðalskipulagi.Athugun fyrir auglýsingu': 'Athugun fyrir auglýsingu',
  'Breyting á aðalskipulagi.Staðfesting': 'Staðfesting',
  'Breyting á aðalskipulagi.Grunnupplýsingar.help': 'Stofnun máls vegna breytingar á aðalskipulagi í samræmi við 1. mgr. 36. gr. skipulagslaga.',
  'Breyting á aðalskipulagi.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Breyting á aðalskipulagi.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á aðalskipulagi.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á verkefninu í samræmi við 1. mgr. 30. gr. skipulagslaga og sem nánar er kveðið á um í 4. kafla skipulagsreglugerðar. \nTilgreina þarf hvenær samþykkt var að kynna lýsinguna í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni fyrirhugaðrar skipulagstillögu.\nÍ skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.\nHægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 4.2.2. – 4.2.4. í skipulagsreglugerð.',
  'Breyting á aðalskipulagi.Lýsing.Fyrirliggjandi gögn.files': 'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) hlaðið upp. ',
  'Breyting á aðalskipulagi.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á aðalskipulagi.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 4.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð aðalskipulagstillögu.',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Gögn til kynningar': 'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 2. mgr. 30. gr. skipulagslaga, áður en hún er tekin til formlegrar afgreiðslu sveitarstjórnar.\nTilgreina þarf hvenær samþykkt var að kynna vinnslutillöguna í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.\nNánar er kveðið á um kynningu vinnslutillögu í gr. 4.6.1. í skipulagsreglugerð.',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er tillögu að aðalskipulagsbreytingu á vinnslustigi, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að aðalskipulagsbreytingu á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Breyting á aðalskipulagi.Athugun fyrir auglýsingu.Tillaga til athugunar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Breyting á aðalskipulagi.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér setur sveitarstjórn fram þá tillögu sem samþykkt hefur verið að auglýsa og Skipulagsstofnun tekur nú til athugunar fyrir auglýsingu, í samræmi við 3. mgr. 30. gr. skipulagslaga og gr. 4.6.2. í skipulagsreglugerð. \nTilgreina þarf hvenær tillagan var samþykkt til auglýsingar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\n\nLeggja skal fram: \n-\tGreinargerð\n-\tSkipulagsuppdrætti\n-\tUmhverfismatsskýrslu\n-\tForsendur\n-\tSkrá yfir aðalskipulagsgögn á landupplýsingaformi (.zip) skv. leiðbeiningum og gagnalýsingu\n-\tFundabókanir\n-\tÖnnur gögn ef við á',
  'Breyting á aðalskipulagi.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Breyting á aðalskipulagi.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram umsögn sína um tillögu að breytingu á aðalskipulagi í samræmi við 3. mgr. 30. gr. skipulagslaga og gr. 4.6.2. í skipulagsreglugerð. Skipulagsstofnun skal koma á framfæri umsögn sinni innan fjögurra vikna frá því að gögn tillögunnar bárust.',
  'Breyting á aðalskipulagi.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Breyting á aðalskipulagi.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á aðalskipulagi.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá tillögu að aðalskipulagsbreytingu sem auglýsa skal, í samræmi við 31. gr. skipulagslaga og gr. 4.6.3. í skipulagsreglugerð. Tilgreina þarf hvenær tillagan var samþykkt til auglýsingar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Breyting á aðalskipulagi.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er tillögu að aðalskipulagsbreytingu, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Breyting á aðalskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Breyting á aðalskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á aðalskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal sveitarstjórn setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda í samræmi við 1. mgr. 32. gr. skipulagslaga.',
  'Breyting á aðalskipulagi.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Breyting á aðalskipulagi.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram tillögu að aðalsskipulagsbreytingu ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af sveitarstjórn, og óskað er staðfestingar á í samræmi við 2. mgr. 32. gr. skipulagslaga. Tilgreina þarf hvenær tillagan var samþykkt í sveitarstjórn.\nAuk skipulagsgagna þarf að leggja fram afrit af auglýsingum skipulagstillögunnar þ.á.m. afrit af auglýsingu í Lögbirtingablaði. Ef athugasemdir bárust á auglýsingatíma þarf að setja hér afrit af svarbréfi sem sent var þeim sem gerðu athugasemdir og afrit af auglýsingu um niðurstöðu sveitarstjórnar. Hafi stafræn skipulagsgögn á landupplýsingaformi (.zip) verið uppfærð frá því fyrir auglýsingu tillögunnar þarf að setja þau fram hér.\nSkipulagsstofnun skal taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 3. mgr. 32. gr. skipulagslaga. ',
  'Breyting á aðalskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Breyting á aðalskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram afgreiðslu sína á breytingu aðalskipulags. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust, í samræmi við 3. mgr. 32. gr. skipulagslaga.',
  'Breyting á aðalskipulagi.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram undirrituð gögn staðfestrar aðalskipulagsbreytingar þegar þau hafa tekið gildi með birtingu auglýsingar í B-deild Stjórnartíðinda.',
  'Óveruleg breyting á aðalskipulagi.Endanleg gögn': 'Endanleg gögn',
  'Óveruleg breyting á aðalskipulagi.Grunnupplýsingar.help':
    'Stofnun máls vegna óverulegrar breytingar á aðalskipulagi í samræmi við 2. mgr. 36. gr. skipulagslaga.',
  'Rammahluti aðalskipulags.Lýsing': 'Lýsing',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Rammahluti aðalskipulags.Athugun fyrir auglýsingu': 'Athugun fyrir auglýsingu',
  'Rammahluti aðalskipulags.Staðfesting': 'Staðfesting',
  'Rammahluti aðalskipulags.Endanleg gögn': 'Endanleg gögn',
  'Rammahluti aðalskipulags.Grunnupplýsingar.help': 'Stofnun máls vegna nýs rammahluta aðalskipulags í samræmi við 4. mgr. 28. gr skipulagslaga.',
  'Rammahluti aðalskipulags.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Rammahluti aðalskipulags.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Rammahluti aðalskipulags.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á fyrirhuguðum rammahluta aðalskipulags í samræmi við 1. mgr. 30. gr. skipulagslaga og sem nánar er kveðið á um í 4. kafla skipulagsreglugerðar.\nTilgreina þarf hvenær samþykkt var að kynna lýsinguna í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni fyrirhugaðrar skipulagstillögu.\nÍ skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.\nHægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 4.2.2. – 4.2.4. í skipulagsreglugerð.',
  'Rammahluti aðalskipulags.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Rammahluti aðalskipulags.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Rammahluti aðalskipulags.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 4.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð aðalskipulagstillögu.',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 2. mgr. 30. gr. skipulagslaga, áður en hún er tekin til formlegrar afgreiðslu sveitarstjórnar.\nTilgreina þarf hvenær samþykkt var að kynna vinnslutillöguna í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.\nNánar er kveðið á um kynningu vinnslutillögu í gr. 4.6.1. í skipulagsreglugerð.',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er tillögu að rammahluta aðalskipulags á vinnslustigi, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Rammahluti aðalskipulags.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að rammahluta aðalskipulags á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Rammahluti aðalskipulags.Athugun fyrir auglýsingu.Tillaga til athugunar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Rammahluti aðalskipulags.Athugun fyrir auglýsingu.Tillaga til athugunar.help':
    'Hér setur sveitarstjórn fram þá tillögu sem samþykkt hefur verið að auglýsa og Skipulagsstofnun tekur nú til athugunar fyrir auglýsingu, í samræmi við 3. mgr. 30. gr. skipulagslaga og gr. 4.6.2. í skipulagsreglugerð.\nTilgreina þarf hvenær tillagan var samþykkt til auglýsingar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\n\nLeggja skal fram:\n-\tGreinargerð\n-\tSkipulagsuppdrætti\n-\tUmhverfismatsskýrslu\n-\tForsendur\n-\tSkrá yfir aðalskipulagsgögn á landupplýsingaformi (.zip) skv. leiðbeiningum og gagnalýsingu\n-\tFundabókanir\n-\tÖnnur gögn ef við á',
  'Rammahluti aðalskipulags.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Rammahluti aðalskipulags.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram umsögn sína um tillögu að rammahluta aðalskipulags í samræmi við 3. mgr. 30. gr. skipulagslaga og gr. 4.6.2. í skipulagsreglugerð. Skipulagsstofnun skal koma á framfæri umsögn sinni innan fjögurra vikna frá því að gögn tillögunnar bárust.',
  'Rammahluti aðalskipulags.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Rammahluti aðalskipulags.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Rammahluti aðalskipulags.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá tillögu að rammahluta aðalskipulags sem auglýsa skal, í samræmi við 31. gr. skipulagslaga og gr. 4.6.3. í skipulagsreglugerð. Tilgreina þarf hvenær tillagan var samþykkt til auglýsingar í sveitarstjórn ásamt stuttri lýsingu á viðfangsefni skipulagstillögu.\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Rammahluti aðalskipulags.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er tillögu að nýjum rammahluta aðalskipulags, umhverfismatsskýrslu, forsendum og öðrum fylgigögnum (ef við á) hlaðið upp.',
  'Rammahluti aðalskipulags.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Rammahluti aðalskipulags.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Rammahluti aðalskipulags.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal sveitarstjórn setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda í samræmi við 1. mgr. 32. gr. skipulagslaga.',
  'Rammahluti aðalskipulags.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna ',
  'Rammahluti aðalskipulags.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram tillögu að rammahluta aðalsskipulags ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af sveitarstjórn, og óskað er staðfestingar á í samræmi við 2. mgr. 32. gr. skipulagslaga. Tilgreina þarf hvenær tillagan var samþykkt í sveitarstjórn. \nAuk skipulagsgagna þarf að leggja fram afrit af auglýsingum skipulagstillögunnar þ.á.m. afrit af auglýsingu í Lögbirtingablaði. Ef athugasemdir bárust á auglýsingatíma þarf að setja hér afrit af svarbréfi sem sent var þeim sem gerðu athugasemdir og afrit af auglýsingu um niðurstöðu sveitarstjórnar. Hafi stafræn skipulagsgögn á landupplýsingaformi (.zip) verið uppfærð frá því fyrir auglýsingu tillögunnar þarf að setja þau fram hér.\nSkipulagsstofnun skal taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 3. mgr. 32. gr. skipulagslaga.',
  'Rammahluti aðalskipulags.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Rammahluti aðalskipulags.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram afgreiðslu sína á rammahluta aðalskipulags. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust, í samræmi við 3. mgr. 32. gr. skipulagslaga.',
  'Rammahluti aðalskipulags.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram undirrituð gögn staðfests rammahluta aðalskipulags þegar þau hafa tekið gildi með birtingu auglýsingar í B-deild Stjórnartíðinda.',
  'Frestun á gerð aðalskipulags.Grunnupplýsingar.help': 'Stofnun máls vegna frestunar á gerð aðalskipulags í samræmi við 33. gr. skipulagslaga.',
  'Mat á umhverfisáhrifum.Grunnupplýsingar.help':
    'Stofnun máls fyrir umhverfismat framkvæmda í samræmi við 21. – 24. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Fyrirliggjandi gögn': 'Matsáætlun til kynningar',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fram þá matsáætlun sem kynna skal vegna fyrirhugaðs mats á umhverfisáhrifum í samræmi við 21. gr. laga um umhverfismat framkvæmda og áætlana og sem nánar er kveðið á um í 11. gr. og 12. gr. reglugerðar um umhverfismat framkvæmda og áætlana. \nHér að neðan er hægt að velja þá aðila sem skulu fá gögnin send til umsagnar og skilgreina umsagnarfrest. Kynningartími skal vera fjórar vikur hið minnsta. Lögum samkvæmt skal leita umsagnar leyfisveitenda.',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Fyrirliggjandi gögn.files':
    'Hér skal leggja fram gögn þeirrar matsáætlunar sem til stendur að kynna, þ.e. sjálfa matsáætlunina ásamt fylgigögnum ef við á. ',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.Fyrirliggjandi gögn': 'Umhverfismatsskýrsla til kynningar',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.Fyrirliggjandi gögn.name': ' Gögn til kynningar',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fram þá umhverfismatsskýrslu sem kynna skal almenningi í samræmi við 23. gr. laga um umhverfismat framkvæmda og áætlana og sem nánar er kveðið á um í 16. gr. reglugerðar um umhverfismat framkvæmda og áætlana. Framlögð skýrsla skal uppfylla kröfur 22. gr. laga og getur Skipulagsstofnun leiðbeint um úrbætur ef svo er ekki.\nHér að neðan er hægt að velja þá aðila sem skulu fá gögnin send til umsagnar og skilgreina athugasemdarfrest. Kynningartími skal vera sex vikur hið minnsta. Lögum samkvæmt skal leita umsagnar leyfisveitenda.',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.Fyrirliggjandi gögn.files':
    'Hér skal leggja fram gögn þeirrar umhverfismatsskýrslu sem til stendur að kynna, þ.e. sjálft umhverfismatið ásamt fylgigögnum ef við á.',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.Niðurstaða umsagnarferlis.name': 'Viðbrögð framkvæmdaraðila',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar': 'Frekari gögn',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd': 'Tilkynning um framkvæmd',
  'Tilkynning til ákvörðunar um matsskyldu.Grunnupplýsingar.help':
    'Stofnun máls vegna tilkynningar til ákvörðunar um matsskyldu framkvæmdar í samræmi við 19. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd.Fyrirliggjandi gögn': 'Gögn til kynningar',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Tilkynning til ákvörðunar um matsskyldu.Tilkynning um framkvæmd.Fyrirliggjandi gögn.help':
    'Hér setur Skipulagsstofnun fram þá tilkynningu sem stofnuninni hefur borist vegna fyrirhugaðrar framkvæmdar og sem kynna skal í samræmi við 20. gr. laga um umhverfismat framkvæmda og áætlana. \nHér að neðan er hægt að velja þá aðila sem skulu fá gögnin send til umsagnar og skilgreina umsagnarfrest. Kynningartími skal vera fjórar vikur hið minnsta. Lögum samkvæmt skal leita umsagnar leyfisveitenda.',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.name': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help':
    'Stofnun máls vegna nýs framkvæmdaleyfis í samræmi við 13. gr. eða 14. gr. skipulagslaga.',
  'Nýtt framkvæmdaleyfi.Grunnupplýsingar.files':
    'Hér skal sveitarstjórn eða aðili í umboði hennar leggja fram umsókn um framkvæmdarleyfi, fyrir hönd hlutaðeigandi framkvæmdaraðila, ásamt þeim fylgigögnum sem tilgreind eru í 7. gr. reglugerðar um framkvæmdaleyfi.',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.Fyrirliggjandi gögn': 'Framkvæmdaleyfi til kynningar',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn eða aðili í umboði hennar fram gögn þeirrar framkvæmdar sem kynna skal næstu nágrönnum og öðrum hagsmunaaðilum, í samræmi við 44. gr. skipulagslaga (sjá einnig skipulagsreglugerð gr. 5.9.2 og 5.9.3). Ef umsagnir umsagnaraðila um framkvæmdina liggja ekki fyrir í skipulagi skal einnig óska eftir þeim í þessu skrefi, í samræmi við 5. mgr. 13. gr. skipulagslaga. Setja skal fram kynningargögn ásamt afriti af grenndarkynningarbréfi, og tilgreina hvaða hagsmunaaðilum gefst kostur á að tjá sig um tillöguna. Ef heimilt er að falla frá kynningu skal þessum lið (fasa) sleppt. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera fjórar vikur. Sé kynningartími styttur með skriflegri áritun skilgreindra hagsmunaaðila á kynningargögn, skal setja inn staðfestingu þess efnis og ljúka fasa.',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.Fyrirliggjandi gögn.files':
    'Hér er kynningargögnum vegna fyrirhugaðrar framkvæmdar ásamt afriti af grenndarkynningarbréfi hlaðið upp.',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.Niðurstaða umsagnarferlis.name': 'Niðurstaða kynningar',
  'Nýtt framkvæmdaleyfi.Ákvörðun Skipulagsstofnunar': 'Endanleg gögn',
  'Nýtt framkvæmdaleyfi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar': 'Endanleg gögn',
  'Nýtt framkvæmdaleyfi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Nýtt framkvæmdaleyfi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help':
    'Hér skal birta samþykkt framkvæmdaleyfi ásamt öllum tilheyrandi fylgigögnum í samræmi við ákvæði laga.',
  'frontpage.text_and_video.title': 'Leiðbeiningar um notkun Skipulags{hyphen}gáttar',
  'frontpage.text_and_video.heading.1': 'Aukið aðgengi að gögnum',
  'frontpage.text_and_video.heading.2': 'Rafræn stjórnsýsla',
  'frontpage.text_and_video.content.1':
    'Eitt af markmiðum Skipulagsgáttar er að auka aðgengi að gögnum við gerð skipulags og undirbúning framkvæmda. Þannig geta almenningur, umsagnaraðilar og allir sem láta sig mál á þessum sviðum varða kynnt sér þau með skilvirkari hætti og komið á framfæri umsögnum eða athugasemdum sínum. Hér að neðan er hægt að nálgast leiðbeiningar sem lýsa notkun á gáttinni fyrir almenning og umsagnaraðila.',
  'frontpage.text_and_video.content.2':
    'Innleiðing rafrænnar stjórnsýslu gefur tækifæri til að stytta boðleiðir, auka yfirsýn og gagnsæi og koma í veg fyrir tvíverknað við skipulagsgerð, umhverfismat og undirbúning framkvæmda.\nHér er hægt að nálgast leiðbeiningar sem lýsa notkun á Skipulagsgátt fyrir þau sem hafa rétt til þess að stofna til nýrra mála og vinna í málum innan hennar, þ.e. starfsfólk Skipulagsstofnunar auk þeirra aðila sem starfa í skipulagsmálum fyrir hönd sveitarfélaga.',
  'search.location.help': 'Þú getur leitað að málum innan afmarkaðs svæðis.',
  'search.date_period.help': 'Þú getur leitað að málum innan ákveðins tímabils.',
  'contentful.Statutory reviewers management': 'Umsjón umsagnaraðila',
  'statutory_reviewer.settings.title': 'Hér má finna lista af skráðum umsagnaraðilum í Skipulagsgáttinni.',
  'frontpage.faq.question.2': 'Hvernig skrái ég mig inn í Skipulagsgáttina?',
  'Nýtt strandsvæðisskipulag.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Nýtt strandsvæðisskipulag.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt strandsvæðisskipulag.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisráðs',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt strandsvæðisskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisráðs',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Nýtt strandsvæðisskipulag.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt strandsvæðisskipulag.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Breyting á strandsvæðisskipulagi.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Breyting á strandsvæðisskipulagi.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð svæðisráðs',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Breyting á strandsvæðisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á strandsvæðisskipulagi.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.Niðurstaða umsagnarferlis.help':
    'Hér skal lögð fram niðurstaða kynningar, svo sem bókun eða afgreiðsla sveitarstjórnar.',

  'Nýtt deiliskipulag.Lýsing': 'Lýsing',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Nýtt deiliskipulag.Grunnupplýsingar.help': 'Stofnun máls vegna nýs deiliskipulags í samræmi við 8. kafla skipulagslaga. ',
  'Nýtt deiliskipulag.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Nýtt deiliskipulag.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt deiliskipulag.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á verkefninu í samræmi við 1. mgr. 40. gr. skipulagslaga og sem nánar er kveðið á um í 5. kafla skipulagsreglugerðar. Hægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 5.2.2. – 5.2.4. í skipulagsreglugerð. Í skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst. Ef fallið er frá gerð skipulagslýsingar deiliskipulagsverkefnis í samræmi við 3. mgr. 40 gr. má sleppa þessum lið (fasa). \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.\n',
  'Nýtt deiliskipulag.Lýsing.Fyrirliggjandi gögn.files': 'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt deiliskipulag.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt deiliskipulag.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 5.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð deiliskipulagstillögu.',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 4. mgr. 40. gr. skipulagslaga, áður en hún skal tekin til formlegrar afgreiðslu sveitarstjórnar. Nánar er kveðið á um kynningu vinnslutillögu í gr. 5.6.1. í skipulagsreglugerð. Ef sveitarstjórn er heimilt að falla frá kynningu má sleppa þessum lið (fasa).\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er deiliskipulagstillögu á vinnslustigi, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að deiliskipulagi á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Nýtt deiliskipulag.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Nýtt deiliskipulag.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt deiliskipulag.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá deiliskipulagstillögu sem samþykkt hefur verið til kynningar í samræmi við 41. gr. skipulagslaga og gr. 5.6.2. í skipulagsreglugerð. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Nýtt deiliskipulag.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er deiliskipulagstillögu, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt deiliskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt deiliskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal sveitarstjórn setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda í samræmi við 3. mgr. 41. gr. skipulagslaga.',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum': 'Athugun eftir auglýsingu',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.Gögn til staðfestingar': 'Tillaga til athugunar',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.Gögn til staðfestingar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram deiliskipulag ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórn, og til stendur að afgreiða í samræmi við 1. mgr. 42. gr. skipulagslaga.\nLeggja skal fram skipulagsuppdrátt, greinargerð (ef við á), umhverfismatsskýrslu (ef skýrsla er sett fram í sér hefti), skýringaruppdrætti (ef við á), afrit af auglýsingum (ýmsir miðlar), upplýsingar um kynningarfundi, afrit og upplýsingar um samþykktir sveitarstjórnar, og önnur fylgigögn (t.d. húsakönnun og hljóðvistargreiningar).\nSkipulagsstofnun skal þá taka deiliskipulagið til yfirferðar og koma athugasemdum sínum á framfæri innan þriggja vikna frá því að gögn tillögunnar bárust.',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram athugasemdir og/eða ábendingar sínar um deiliskipulagið, ef á við. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan þriggja vikna frá því að gögn tillögunnar bárust, í samræmi við 1. mgr. 42. gr. skipulagslaga.',
  'Nýtt deiliskipulag.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér skal leggja fram endanleg gögn samþykkts deiliskipulags ásamt fylgigögnum þess. Þegar auglýsing um nýtt deiliskipulag hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.\nSenda þarf útprentuð og undirrituð gögn til Skipulagsstofnunar til varðveislu.',
  'Breyting á deiliskipulagi.Lýsing': 'Lýsing',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Breyting á deiliskipulagi.Endanleg gögn': 'Endanleg gögn',
  'Breyting á deiliskipulagi.Grunnupplýsingar.help': 'Stofnun máls vegna breytingar á deiliskipulagi í samræmi við 1. mgr. 43. gr. skipulagslaga.',
  'Breyting á deiliskipulagi.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Breyting á deiliskipulagi.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á deiliskipulagi.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á verkefninu í samræmi við 1. mgr. 40. gr. skipulagslaga og sem nánar er kveðið á um í 5. kafla skipulagsreglugerðar. Hægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 5.2.2. – 5.2.4. í skipulagsreglugerð. Í skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst. Ef fallið er frá gerð skipulagslýsingar deiliskipulagsverkefnis í samræmi við 3. mgr. 40 gr. má sleppa þessum lið (fasa). \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.',
  'Breyting á deiliskipulagi.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á deiliskipulagi.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á deiliskipulagi.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 5.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð deiliskipulagstillögu.',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er vinnslutillögu vegna breytingar á deiliskipulagi, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 4. mgr. 40. gr. skipulagslaga, áður en hún skal tekin til formlegrar afgreiðslu sveitarstjórnar. Nánar er kveðið á um kynningu vinnslutillögu í gr. 5.6.1. í skipulagsreglugerð. Ef sveitarstjórn er heimilt að falla frá kynningu má sleppa þessum lið (fasa).\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að deiliskipulagsbreytingu á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Breyting á deiliskipulagi.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Breyting á deiliskipulagi.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á deiliskipulagi.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram tillögu að deiliskipulagsbreytingu sem samþykkt hefur verið til kynningar í samræmi við 41. gr. skipulagslaga og gr. 5.6.2. í skipulagsreglugerð. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Breyting á deiliskipulagi.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er tillögu að deiliskipulagbreytingu, umhverfismatskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á deiliskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Breyting á deiliskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á deiliskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal sveitarstjórn setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda í samræmi við 3. mgr. 41. gr. skipulagslaga.',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum': 'Athugun eftir auglýsingu',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.Gögn til staðfestingar': 'Tillaga til athugunar',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.Gögn til staðfestingar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram deiliskipulagsbreytingu ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórn, og til stendur að afgreiða í samræmi við 1. mgr. 42. gr. skipulagslaga.\nLeggja skal fram skipulagsuppdrátt, greinargerð (ef við á), umhverfismatsskýrslu (ef skýrsla er sett fram í sér hefti), skýringaruppdrætti (ef við á), afrit af auglýsingum (ýmsir miðlar), upplýsingar um kynningarfundi, afrit og upplýsingar um samþykktir sveitarstjórnar, og önnur fylgigögn (t.d. húsakönnun og hljóðvistargreiningar).\nSkipulagsstofnun skal þá taka breytinguna til yfirferðar og koma athugasemdum sínum á framfæri innan þriggja vikna frá því að gögn tillögunnar bárust.',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram athugasemdir og/eða ábendingar sínar um deiliskipulagsbreytinguna. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan þriggja vikna frá því að gögn tillögunnar bárust, í samræmi við 1. mgr. 42. gr. skipulagslaga. ',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Breyting á deiliskipulagi.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér skal leggja fram endanleg gögn samþykktrar deiliskipulagbreytingar ásamt fylgigögnum. Þegar auglýsing um samþykkt breytingar á deiliskipulagi hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.\nSenda þarf útprentuð og undirrituð gögn til Skipulagsstofnunar til varðveislu.',
  'Óveruleg breyting á deiliskipulagi.Grunnupplýsingar.help':
    'Stofnun máls vegna óverulegrar breytingar á deiliskipulagi í samræmi við 2. mgr. 43. gr. skipulagslaga.',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.Fyrirliggjandi gögn': 'Kynning breytinga',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram tillögu að óverulegri breytingu á deiliskipulagi sem kynna skal næstu nágrönnum og öðrum hagsmunaaðilum, í samræmi við 2. mgr. 43. gr. skipulagslaga (sjá einnig skipulagsreglugerð gr. 5.9.2 og 5.9.3). Taka skal mið af því hvort tillagan víki frá notkun, nýtingarhlutfalli, útliti og formi svæðisins og fyrirliggjandi skipulagi. Setja skal fram kynningargögn ásamt afriti af grenndarkynningarbréfi, og tilgreina hvaða hagsmunaaðilum gefst kostur á að tjá sig um tillöguna. Ef sveitarstjórn er heimilt að falla frá kröfu um kynningu má sleppa þessum lið (fasa). \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera fjórar vikur. Sé kynningartími styttur með skriflegri áritun skilgreindra hagsmunaaðila á kynningargögn, skal setja inn staðfestingu þess efnis og ljúka fasa.',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.files':
    'Hér er kynningargögnum vegna óverulegrar breytingar á deiliskipulagi ásamt afriti af grenndarkynningarbéfi hlaðið upp.',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að óverulegri deiliskipulagsbreytingu, og viðbrögð við þeim eftir því sem við á.',
  'Óveruleg breyting á deiliskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Óveruleg breyting á deiliskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help':
    'Hér skal leggja fram endanleg gögn óverulegrar deiliskipulagsbreytingar ásamt tilheyrandi fylgigögnum. Þegar auglýsing um samþykki breytingarinnar hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.\nSenda þarf útprentuð og undirrituð gögn til Skipulagsstofnunar til varðveislu.',
  'issue.review.header': 'Bæta við umsögn',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Niðurstaða umsagnarferlis.name': 'Viðbrögð framkvæmdaraðila',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.Niðurstaða umsagnarferlis.help': 'Hér skulu lögð fram viðbrögð framkvæmdaraðila við framkomnum umsögnum.',
  'Mat á umhverfisáhrifum.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.name': 'Innsetning gagna',
  'user.delegation.single_issue_access.help':
    'Hér er listi yfir notendur sem hafa umsjónarréttindi skorðuð við þetta mál. Hægt er að bæta við fleiri aðilum til úrvinnslu þessa máls sem ekki hafa aðgengi að því nú þegar. Athuga skal að einungis er hægt að bæta við aðilum sem hafa hlutverkið “starfsmaður verkefnis“ og eru skráðir í umboði hlutaðeigandi sveitarfélags, eða svæðisskipulagsnefndar þegar það á við.',
  'Óveruleg breyting á aðalskipulagi.Staðfesting': 'Staðfesting',
  'Mat á umhverfisáhrifum.Staðfesting': 'Álit Skipulagsstofnunar um matsáætlun',
  'Mat á umhverfisáhrifum.Staðfesting.Gögn til staðfestingar': 'Frekari gögn',
  'Mat á umhverfisáhrifum.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Mat á umhverfisáhrifum.Staðfesting.Gögn til staðfestingar.help':
    'Ef Skipulagsstofnun kallar eftir frekari upplýsingum frá framkvæmdaraðila skal setja þau fram hér.',
  'Mat á umhverfisáhrifum.Staðfesting.Staðfesting Skipulagsstofnunar': 'Álit um matsáætlun',
  'Mat á umhverfisáhrifum.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Álit Skipulagsstofnunar',
  'Mat á umhverfisáhrifum.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér leggur Skipulagsstofnun fram álit sitt um matsáætlun í samræmi við 3. mgr. 21. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar': 'Álit um umhverfismat framkvæmdar',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.name': 'Álit Skipulagsstofnunar',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.help':
    'Hér leggur Skipulagsstofnun fram álit sitt um umhverfismat framkvæmdar í samræmi við 24. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum': 'Álit Skipulagsstofnunar um umhverfismatsskýrslu',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.Gögn til staðfestingar': 'Frekari gögn',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.Gögn til staðfestingar.help':
    'Ef Skipulagsstofnun kallar eftir frekari upplýsingum frá framkvæmdaraðila skal setja þau fram hér.',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.name': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.help':
    'Hér leggur Skipulagsstofnun fram niðurstöðu sína um hvort tilkynningarskyld framkvæmd skuli háð umhverfismati eða ekki í samræmi við 20. gr. laga um umhverfismat framkvæmda og áætlana.',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum': 'Ákvörðun Skipulagsstofnunar',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum.Gögn til staðfestingar': 'Frekari gögn',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum.Gögn til staðfestingar.name': 'Innsetning á gögnum',
  'Tilkynning til ákvörðunar um matsskyldu.Staðfesting með lokagögnum.Gögn til staðfestingar.help':
    'Ef Skipulagsstofnun kallar eftir frekari gögnum frá framkvæmdaraðila skal setja þau fram hér.',
  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.help':
    'Hér má setja fram tillögu að óverulegri breytingu aðalskipulags, ef ákveðið er að kynna hana íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum. Ef fallið er frá kröfu um kynningu má sleppa þessum lið (fasa).\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest.',
  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.files': 'Hér er gögnum vegna óverulegrar breytingar á aðalskipulagi hlaðið upp.',
  'Óveruleg breyting á aðalskipulagi.Kynning Breytinga.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Óveruleg breyting á aðalskipulagi.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Óveruleg breyting á aðalskipulagi.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram rökstudda tillögu að óverulegri breytingu á aðalsskipulagi ásamt tilheyrandi fylgigögnum. Á heimasíðu Skipulagsstofnunar má nálgast gátlista til að styðjast við rökstuðning á því hvort um óverulega breytingu sé að ræða. Tilgreina þarf hvenær tillagan var samþykkt í sveitarstjórn. \nLeggja skal fram greinargerð, skipulagsuppdrætti, umhverfismatsskýrslu, forsendur, skrá yfir aðalskipulagsgögn á landupplýsingaformi skv. leiðbeiningum og gagnalýsingu, rökstuðningur sveitarstjórnar með hliðsjón af gátlista, fundabókanir og önnur fylgigögn ef við á. Auglýsa skal niðurstöðu sveitarstjórnar og afrit af auglýsingu fylgi með skipulagsgögnum. Skipulagsstofnun skal þá taka tillöguna til afgreiðslu og taka ákvörðun innan fjögurra vikna um staðfestingu hennar í samræmi við 2. mgr. 36. gr. skipulagslaga.',
  'Óveruleg breyting á aðalskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Óveruleg breyting á aðalskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Óveruleg breyting á aðalskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram ákvörðun sína um hvort fallist sé á málsmeðferð óverulegar breytingar á aðalskipulagi í samræmi við 2. mgr. 36. gr. skipulagslaga. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust.',
  'Óveruleg breyting á aðalskipulagi.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér setur Skipulagsstofnun fram undirrituð gögn óverulegrar aðalskipulagsbreytingar þegar þau hafa tekið gildi með birtingu auglýsingar í B-deild Stjórnartíðinda.',
  'Breyting á svæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram afgreiðslu sína á breytingu svæðisskipulags. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan fjögurra vikna frá því að gögn tillögunnar bárust, í samræmi við 4. mgr. 25. gr. skipulagslaga.',
  'Óveruleg breyting á aðalskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef breyting verður kynnt skal haka við fasann „Kynning breytinga“ hér að neðan svo hann verði með í ferlinu (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'issue.optional_phases': 'Valkvæðir fasar',
  'Nýtt deiliskipulag.Grunnupplýsingar.optional_phases.help':
    'Ef skipulagslýsing verður gerð skal haka við fasann „Lýsing“ hér að neðan svo hann verði með í ferlinu (sjálfvalið). Hið sama á við um ef vinnslutillaga verður kynnt. Ef þessum liðum verður sleppt skal gæta þess að þeir séu ekki valdir.',
  'Breyting á deiliskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef skipulagslýsing verður gerð skal haka við fasann „Lýsing hér að neðan svo hann verði með í ferlinu (sjálfvalið). Hið sama á við um ef vinnslutillaga verður kynnt. Ef þessum liðum verður sleppt skal gæta þess að þeir séu ekki valdir.',
  'Óveruleg breyting á deiliskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef breyting verður grenndarkynnt skal haka við fasann „Grenndarkynning“ hér að neðan (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'issue.phase.statutory_reviewer.select': 'Bæta við umsagnaraðila af lista',
  'Nýtt strandsvæðisskipulag.Grunnupplýsingar.optional_phases.help':
    'Ef málið verður kynnt á vinnslustigi skal haka við fasann „Kynning tillögu á vinnslustigi“ hér að neðan svo hann verði með í ferlinu (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'Breyting á strandsvæðisskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef málið verður kynnt á vinnslustigi skal haka við fasann „Kynning tillögu á vinnslustigi“ hér að neðan svo hann verði með í ferlinu (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'Nýtt framkvæmdaleyfi.Grunnupplýsingar.optional_phases.help':
    'Ef framkvæmd verður grenndarkynnt skal haka við fasann „Grenndarkynning“ hér að neðan (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'issue.link': 'Skoða nánar ',
  'role.DELEGATION_CONSULTANT': 'Starfsmaður verkefnis',
  'app.email': 'skipulagsgatt@skipulag.is',
  'Frestun á gerð aðalskipulags.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér skal leggja fram ákvörðun ráðherra um frestun á gerð aðalskipulagsáætlunar fyrir ákveðið landsvæði, í samræmi við 33. gr. skipulagslaga.',
  'issue.phase.state.finalized': 'Máli lokið',
  'Frestun á gerð aðalskipulags.Endanleg gögn': 'Samþykki ráðherra',
  'user.preferences.email.help':
    'Til að notendur Skipulagsgáttar geti fengið tilkynningar þarf netfang að vera útfyllt. Tilkynningar berast m.a. vegna stofnun nýrra mála sem falla að þinni áskriftarleið eða vegna framgang vaktaðra mála. Hafir þú lagt fram athugasemd við mál berst þér einnig tilkynning um að hún hafi borist og þegar niðurstöður umsagnarferlis liggja fyrir, ef það á við. ',
  'Nýtt svæðisskipulag.Kynning tillögu': 'Auglýsing tillögu',
  'monitoring.map.location': 'Áskrift er með staðsetningu á korti',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu.Umsögn Skipulagsstofnunar.name': 'Athugun Skipulagsstofnunar',
  'base.created_by': 'Tengiliður og dags stofnað',
  'user.delegation.single_issue_access': 'Stakir umsjónaraðilar máls',
  'user.delegation.type.SVAEDIS_RAD': 'Svæðisráð',
  'user.delegation.type.OTHER': 'Önnur umboð',
  'base.beta-launch': ' ',
  'Breyting á deiliskipulagi.Kynning tillögu': 'Auglýsing tillögu',
  'issue.review.warning': 'Umsögn/athugasemd þín mun birtast á vefnum um leið og þú skilar henni inn. Gakktu úr skugga um að öll meðfylgjandi gögn séu rétt.',
  'issue.phase.state.unpublished': 'Mál ekki birt',
  'issue.phase.step.publish': 'Hefja kynningarferli',
  'Process.create.select': 'Hér eru ný mál stofnuð í Skipulagsgáttinni.',
  'Grenndarkynning byggingarleyfisumsóknar.Grunnupplýsingar.help':
    'Stofnun máls vegna grenndarkynningar byggingarleyfisumsóknar í samræmi við 1. mgr. 44. gr. skipulagslaga.',
  'Grenndarkynning byggingarleyfisumsóknar.Grunnupplýsingar.optional_phases.help':
    'Ef það byggingarleyfi sem sótt er um verður grenndarkynnt skal haka við fasann „Grenndarkynning byggingarleyfisumsóknar“ hér að neðan (sjálfvalið). Ef þeim lið verður sleppt, sbr. 3. mgr. 44. gr. skipulagslaga, skal gæta þess að hann sé ekki valinn. ',
  'Grenndarkynning byggingarleyfisumsóknar.Grunnupplýsingar.files':
    'Hér skal skipulagsnefnd leggja fram umsókn um byggingarleyfi, fyrir hönd hlutaðeigandi umsækjanda, ásamt öðrum fylgigögnum umsóknar (ef við á).',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga': 'Grenndarkynning byggingarleyfisumsóknar',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga.Fyrirliggjandi gögn': 'Byggingarleyfisumsókn til kynningar',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga.Fyrirliggjandi gögn.help':
    'Hér setur skipulagsnefnd, með aðstoð skipulagsfulltrúa, fram gögn þeirrar byggingarleyfisumsóknar sem kynna skal næstu nágrönnum og öðrum hagsmunaaðilum, í samræmi við 44. gr. skipulagslaga (sjá einnig skipulagsreglugerð gr. 5.9.2 og 5.9.3). Setja skal fram kynningargögn ásamt afriti af grenndarkynningarbréfi, og tilgreina hvaða hagsmunaaðilum gefst kostur á að tjá sig um tillöguna. Ef heimilt er að falla frá kynningu skal þessum lið (fasa) sleppt. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera fjórar vikur. Sé kynningartími styttur með skriflegri áritun skilgreindra hagsmunaaðila á kynningargögn, skal setja inn staðfestingu þess efnis og ljúka fasa.',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga.Fyrirliggjandi gögn.files':
    'Hér er kynningargögnum vegna byggingarleyfisumsóknar ásamt afriti af grenndarkynningarbréfi hlaðið upp.',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga.Niðurstaða umsagnarferlis.name': 'Niðurstaða kynningar',
  'Grenndarkynning byggingarleyfisumsóknar.Kynning Breytinga.Niðurstaða umsagnarferlis.help':
    'Hér skal lögð fram niðurstaða kynningar, svo sem bókun eða afgreiðsla sveitarstjórnar.',
  'Grenndarkynning byggingarleyfisumsóknar.Ákvörðun Skipulagsstofnunar': 'Endanleg gögn ',
  'Grenndarkynning byggingarleyfisumsóknar.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar': 'Endanleg gögn',
  'Grenndarkynning byggingarleyfisumsóknar.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Grenndarkynning byggingarleyfisumsóknar.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help': 'Hér er hægt að birta samþykkt byggingarleyfi.',
  'issue.phase.statutory_reviewer.skip': 'Enginn umsagnaraðili á við / umsagnarbeiðnir skulu ekki sendast á valda aðila (ef einhverjir).',
  'user.delegation.type': 'Gerð umboðs',
  'Nýtt aðalskipulag.Kynning tillögu.Niðurstaða umsagnarferlis': 'Niðurstaða umsagnarferlis',
  'Nýtt hverfisskipulag.Lýsing': 'Lýsing',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Nýtt hverfisskipulag.Endanleg gögn': 'Endanleg gögn',
  'Nýtt hverfisskipulag.Kynning tillögu': 'Auglýsing tillögu',
  'Nýtt hverfisskipulag.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Nýtt hverfisskipulag.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum': 'Athugun eftir auglýsingu',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum.Gögn til staðfestingar': 'Tillaga til athugunar',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Nýtt hverfisskipulag.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Nýtt hverfisskipulag.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Breyting á hverfisskipulagi.Lýsing': 'Lýsing',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi': 'Kynning tillögu á vinnslustigi',
  'Breyting á hverfisskipulagi.Endanleg gögn': 'Endanleg gögn',
  'Breyting á hverfisskipulagi.Kynning tillögu': 'Auglýsing tillögu',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum': 'Athugun eftir auglýsingu',
  'Breyting á hverfisskipulagi.Lýsing.Fyrirliggjandi gögn': 'Skipulagslýsing',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn': 'Vinnslutillaga í kynningu',
  'Breyting á hverfisskipulagi.Kynning tillögu.Fyrirliggjandi gögn': 'Skipulagstillaga í auglýsingu',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum.Gögn til staðfestingar': 'Tillaga til athugunar',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Breyting á hverfisskipulagi.Endanleg gögn.Gögn til staðfestingar': 'Endanleg gögn',
  'Breyting á hverfisskipulagi.Endanleg gögn.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Breyting á hverfisskipulagi.Grunnupplýsingar.help':
    'Stofnun máls vegna breytingar á hverfisskipulagi, í samræmi við 1. mgr. 43. gr. skipulagslaga. Við gerð hverfisskipulagsverkefnis er heimilt að víkja frá kröfum um framsetningu deiliskipulags t.d. hvað varðar skilmála og mkv. uppdrátta. Nánar er kveðið á um efni hverfisskipulags í gr. 5.3.3. í skipulagsreglugerð.',
  'Breyting á hverfisskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef fallið er frá gerð skipulagslýsingar hverfisskipulagsverkefnis skal haka við kostinn „Lýsing“ hér að neðan til að sleppa þeim lið. Hið sama á við um ef vinnslutillaga verður ekki kynnt. Ef ekki er hakað í boxin verða bæði „Lýsing“ og „Kynning tillögu á vinnslustigi“ með í ferlinu.',
  'Breyting á hverfisskipulagi.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á verkefninu í samræmi við 1. mgr. 40. gr. skipulagslaga og sem nánar er kveðið á um í 5. kafla skipulagsreglugerðar. Hægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 5.2.2. – 5.2.4. í skipulagsreglugerð. Í skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst. Ef fallið er frá gerð skipulagslýsingar deiliskipulagsverkefnis í samræmi við 3. mgr. má sleppa þessum lið (fasa). \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.',
  'Breyting á hverfisskipulagi.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á hverfisskipulagi.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á hverfisskipulagi.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á hverfisskipulagi.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 5.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð deiliskipulagstillögu.',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 4. mgr. 40. gr. skipulagslaga, áður en hún skal tekin til formlegrar afgreiðslu sveitarstjórnar. Nánar er kveðið á um kynningu vinnslutillögu í gr. 5.6.1. í skipulagsreglugerð. Ef sveitarstjórn er heimilt að falla frá kynningu má sleppa þessum lið (fasa).\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. ',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er vinnslutillögu að breytingu á hverfisskipulagi (greinargerð, uppdráttur umhverfismatsskýrsla) og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á hverfisskipulagi.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér má leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að hverfisskipulagsbreytingu á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Breyting á hverfisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Breyting á hverfisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram tillögu að hverfisskipulagsbreytingu sem samþykkt hefur verið til kynningar í samræmi við 41. gr. skipulagslaga og gr. 5.6.2. í skipulagsreglugerð. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Breyting á hverfisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Breyting á hverfisskipulagi.Kynning tillögu.Niðurstaða umsagnarferlis.help':
    'Hér skal sveitarstjórn setja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim. Þar skal tekin afstaða til þess hvort tillögunni verði breytt að teknu tilliti til athugasemda í samræmi við 3. mgr. 41. gr. skipulagslaga.',
  'Breyting á hverfisskipulagi.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er tillögu að hverfisskipulagbreytingu, umhverfismatskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum.Gögn til staðfestingar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram deiliskipulagsbreytingu ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórn, og til stendur að afgreiða í samræmi við 1. mgr. 42. gr. skipulagslaga. \nSkipulagsstofnun skal þá taka breytinguna til yfirferðar og koma athugasemdum sínum á framfæri innan þriggja vikna frá því að gögn tillögunnar bárust.',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Breyting á hverfisskipulagi.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram athugasemdir og/eða ábendingar sínar um hverfisskipulagsbreytinguna. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan þriggja vikna frá því að gögn tillögunnar bárust, í samræmi við 1. mgr. 42. gr. skipulagslaga.',
  'Breyting á hverfisskipulagi.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér skal leggja fram endanleg og undirrituð gögn samþykktrar hverfisskipulagbreytingar ásamt fylgigögnum. Þegar auglýsing um samþykkt breytingar á deiliskipulagi hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.\nSenda þarf útprentuð og undirrituð gögn til Skipulagsstofnunar til varðveislu.',
  'Nýtt hverfisskipulag.Grunnupplýsingar.help':
    'Stofnun máls vegna nýs deiliskipulags í þegar byggðu hverfi þar sem ekki eru áætlaðar verulegar breytingar á byggðamynstri (hverfisskipulag). Málsmeðferð skal vera í samræmi við 8. kafla skipulagslaga en þó er heimilt að víkja frá kröfum um framsetningu deiliskipulags t.d. hvað varðar skilmála og mkv. uppdrátta. Nánar er kveðið á um efni hverfisskipulags í gr. 5.3.3. í skipulagsreglugerð.',
  'Nýtt hverfisskipulag.Grunnupplýsingar.optional_phases.help':
    'Ef skipulagslýsing verður gerð skal haka við fasann „Lýsing“ hér að neðan svo hann verði með í ferlinu (sjálfvalið). Hið sama á við um ef vinnslutillaga verður kynnt. Ef þessum liðum verður sleppt skal gæta þess að þeir séu ekki valdir.',
  'Nýtt hverfisskipulag.Lýsing.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt hverfisskipulag.Lýsing.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram lýsingu á verkefninu í samræmi við 1. mgr. 40. gr. skipulagslaga og sem nánar er kveðið á um í 5. kafla skipulagsreglugerðar. Hægt er að nálgast leiðbeiningar um efni og framgang lýsingarinnar í gr. 5.2.2. – 5.2.4. í skipulagsreglugerð. Í skipulagslýsingu skulu koma fram áherslur, forsendur, fyrirliggjandi stefna og fyrirhugað skipulagsferli þar sem samráðs- og kynningarferli skal lýst. Ef fallið er frá gerð skipulagslýsingar deiliskipulagsverkefnis í samræmi við 3. mgr. má sleppa þessum lið (fasa). \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Lögum samkvæmt skal leita umsagnar Skipulagsstofnunar um skipulagslýsingu.',
  'Nýtt hverfisskipulag.Lýsing.Fyrirliggjandi gögn.files':
    'Hér er skipulagslýsingu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt hverfisskipulag.Lýsing.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt hverfisskipulag.Lýsing.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa og viðbrögð við þeim eftir því sem við á. Samkvæmt gr. 5.2.4. í skipulagsreglugerð skal skipulagsnefnd hafa til hliðsjónar framkomnar umsagnir að loknu kynningarferli lýsingar við gerð hverfisskipulagstillögu.',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá vinnslutillögu sem kynna skal íbúum hlutaðeigandi sveitarfélags og öðrum hagsmunaaðilum í samræmi við 4. mgr. 40. gr. skipulagslaga, áður en hún skal tekin til formlegrar afgreiðslu sveitarstjórnar. Nánar er kveðið á um kynningu vinnslutillögu í gr. 5.6.1. í skipulagsreglugerð. Ef sveitarstjórn er heimilt að falla frá kynningu má sleppa þessum lið (fasa).\nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.files':
    'Hér er hverfisskipulagstillögu á vinnslustigi, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að hverfisskipulagi á vinnslustigi, og viðbrögð við þeim eftir því sem við á.',
  'Nýtt hverfisskipulag.Kynning tillögu á vinnslustigi.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt hverfisskipulag.Kynning tillögu.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Nýtt hverfisskipulag.Kynning tillögu.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram þá hverfisskipulagstillögu sem samþykkt hefur verið til kynningar í samræmi við 41. gr. skipulagslaga og gr. 5.6.2. í skipulagsreglugerð. \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera sex vikur hið minnsta.',
  'Nýtt hverfisskipulag.Kynning tillögu.Fyrirliggjandi gögn.files':
    'Hér er hverfisskipulagstillögu, umhverfismatsskýrslu og öðrum fylgigögnum (ef við á) sem lögð eru fram til kynningar hlaðið upp.',
  'Nýtt hverfisskipulag.Kynning tillögu.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum.Gögn til staðfestingar.name': 'Gögn til athugunar hjá Skipulagsstofnun',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum.Gögn til staðfestingar.help':
    'Hér skal sveitarstjórn setja fram deiliskipulag ásamt tilheyrandi fylgigögnum, sem samþykkt hefur verið af hlutaðeigandi sveitarstjórn, og til stendur að afgreiða í samræmi við 1. mgr. 42. gr. skipulagslaga. \nSkipulagsstofnun skal þá taka hverfisskipulagið til yfirferðar og koma athugasemdum sínum á framfæri innan þriggja vikna frá því að gögn tillögunnar bárust.',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.name': 'Afgreiðsla Skipulagsstofnunar',
  'Nýtt hverfisskipulag.Staðfesting með lokagögnum.Staðfesting Skipulagsstofnunar.help':
    'Hér setur Skipulagsstofnun fram athugasemdir og/eða ábendingar sínar um hverfisskipulagið, ef á við. Afgreiðsla Skipulagsstofnunar skal liggja fyrir innan þriggja vikna frá því að gögn tillögunnar bárust, í samræmi við 1. mgr. 42. gr. skipulagslaga.',
  'Nýtt hverfisskipulag.Endanleg gögn.Gögn til staðfestingar.help':
    'Hér skal leggja fram endanleg og undirrituð gögn samþykkts hverfisskipulags ásamt fylgigögnum þess. Þegar auglýsing um nýtt deiliskipulag hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.\nSenda þarf útprentuð og undirrituð gögn til Skipulagsstofnunar til varðveislu.',
  'issue.phase.step.prev': 'Færa mál aftur um skref',
  'monitoring.new.btn': 'Ný áskrift',
  'issue.review.prompt':
    'Þú verður að vera skráð(ur) inn til að setja fram athugasemd eða umsögn við mál inn á Skipulagsgátt. Innskráning fer fram með rafrænum skilríkjum.',
  'issue.phase.revert.confirm':
    'Ertu viss um að þú viljir færa málið aftur um fasa? Eingöngu skal færa mál aftur um fasa ef verið er að leiðrétta skráningu á máli. Um óafturkræfa aðgerð er að ræða og gögn núverandi fasa eyðast endanlega út. \n',
  'Breyting á deiliskipulagi.Kynning tillögu.Fyrirliggjandi gögn.start_reviews':
    'Þú ert að hefja kynningarferli. Athugaðu að öll framkomin gögn séu rétt áður en haldið er áfram. Með því að halda áfram sendast út umsagnarbeiðnir á valda aðila. Málið verður opið fyrir athugasemdum um leið og kynningartími hefst.',
  'base.yes': 'Halda áfram',
  'base.no': 'Hætta við',
  'dialog.yes': 'Já',
  'dialog.no': 'Nei',

  'Nýtt aðalskipulag.Lýsing.Fyrirliggjandi gögn.start_reviews':
    'Þú ert að hefja kynningarferli. Athugaðu að öll framkomin gögn séu rétt áður en haldið er áfram. Með því að halda áfram sendast út umsagnarbeiðnir á valda aðila. Málið verður opið fyrir athugasemdum um leið og kynningartími hefst.',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.start_reviews':
    'Þú ert að hefja kynningarferli. Athugaðu að öll framkomin gögn séu rétt áður en haldið er áfram. Með því að halda áfram sendast út umsagnarbeiðnir á valda aðila. Málið verður opið fyrir athugasemdum um leið og kynningartími hefst.',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.Fyrirliggjandi gögn.start_reviews':
    'Þú ert að hefja kynningarferli. Athugaðu að öll framkomin gögn séu rétt áður en haldið er áfram. Með því að halda áfram sendast út umsagnarbeiðnir á valda aðila. Málið verður opið fyrir athugasemdum um leið og kynningartími hefst.',
  'Breyting á deiliskipulagi.Lýsing.Fyrirliggjandi gögn.start_reviews':
    'Þú ert að hefja kynningarferli. Athugaðu að öll framkomin gögn séu rétt áður en haldið er áfram. Með því að halda áfram sendast út umsagnarbeiðnir á valda aðila. Málið verður opið fyrir athugasemdum um leið og kynningartími hefst.',
  'Nýtt aðalskipulag.Kynning tillögu.Fyrirliggjandi gögn.start_reviews':
    'Þú ert að hefja kynningarferli. Athugaðu að öll framkomin gögn séu rétt áður en haldið er áfram. Með því að halda áfram sendast út umsagnarbeiðnir á valda aðila. Málið verður opið fyrir athugasemdum um leið og kynningartími hefst.',
  'Óveruleg breyting á aðalskipulagi.legal': 'Óveruleg breyting á aðalskipulagi skv. 2. mgr. 36. skipulagslaga\n',
  'Nýtt deiliskipulag.Lýsing.legal': 'Skipulagslýsing skv. 1. mgr. 40. gr. skipulagslaga',
  'Breyting á deiliskipulagi.legal': 'Veruleg breyting á deiliskipulagi skv. 1. mgr. 43 gr. skipulagslaga',
  'Nýtt deiliskipulag.Kynning tillögu á vinnslustigi.legal': 'Kynning vinnslutillögu skv. 4. mgr. 40 gr. skipulagslaga',
  'Nýtt deiliskipulag.Kynning tillögu.legal': 'Auglýsing tillögu skv. 41. gr. skipulagslaga',
  'Nýtt deiliskipulag.Staðfesting með lokagögnum.legal': 'Athugun skipulags eftir auglýsingu skv. 1. mgr. 42. gr. skipulagslaga',
  'Nýtt svæðisskipulag.Lýsing.legal': 'Skipulagslýsing skv. 1. mgr. 23. gr. skipulagslaga',
  'Nýtt svæðisskipulag.Kynning tillögu á vinnslustigi.legal': 'Kynning vinnslutillögu skv. 2. mgr. 23 gr. skipulagslaga',
  'Nýtt svæðisskipulag.Athugun fyrir auglýsingu.legal': 'Athugun tillögu fyrir auglýsingu skv. 3. mgr. 23. gr. skipulagslaga',
  'Nýtt svæðisskipulag.Kynning tillögu.legal': 'Auglýsing tillögu skv. 24. gr. skipulagslaga',
  'Nýtt svæðisskipulag.Staðfesting.legal':
    'Tillaga til staðfestingar skv. 3 mgr. 25. gr. skipulagslaga og afgreiðsla Skipulagsstofnunar skv. 4. mgr. 25. gr. skipulagslaga',
  'Nýtt aðalskipulag.Lýsing.legal': 'Skipulagslýsing skv. 1. mgr. 30. gr. skipulagslaga',
  'Nýtt aðalskipulag.Kynning tillögu á vinnslustigi.legal': 'Kynning vinnslutillögu skv. 2. mgr. 30 gr. skipulagslaga\n',
  'Nýtt aðalskipulag.Athugun fyrir auglýsingu.legal': 'Athugun tillögu fyrir auglýsingu skv. 3. mgr. 30. gr. skipulagslaga',
  'Nýtt aðalskipulag.Kynning tillögu.legal': 'Auglýsing tillögu skv. 31. gr. skipulagslaga',
  'Nýtt aðalskipulag.Staðfesting.legal':
    'Tillaga til staðfestingar skv. 2 mgr. 32. gr. skipulagslaga og afgreiðsla Skipulagsstofnunar skv. 3. mgr. 32. gr. skipulagslaga',
  'Breyting á deiliskipulagi.Lýsing.legal': 'Skipulagslýsing skv. 1. mgr. 40. gr. skipulagslaga',
  'Breyting á deiliskipulagi.Kynning tillögu á vinnslustigi.legal': 'Kynning vinnslutillögu skv. 4. mgr. 40 gr. skipulagslaga',
  'Breyting á deiliskipulagi.Kynning tillögu.legal': 'Auglýsing tillögu skv. 41. gr. skipulagslaga',
  'Breyting á deiliskipulagi.Staðfesting með lokagögnum.legal': 'Athugun skipulags eftir auglýsingu skv. 1. mgr. 42. gr. skipulagslaga',
  'Óveruleg breyting á deiliskipulagi.legal': 'Óveruleg breyting á deiliskipulagi skv. 2. mgr. 43. skipulagslaga',
  'Óveruleg breyting á deiliskipulagi.Kynning Breytinga.legal': 'Grenndarkynning skv. 2. mgr. 43. gr. skipulagslaga\n',
  'Nýtt framkvæmdaleyfi.Kynning Breytinga.legal': 'Grenndarkynning framkvæmdar skv. 44. gr. skipulagslaga',
  'Grenndarkynning byggingarleyfisumsóknar.legal': 'Grenndarkynning byggingarleyfisumsóknar skv. 44. gr. skipulagslaga',
  'Breyting á aðalskipulagi.legal': 'Veruleg breyting á aðalskipulagi skv. 1. mgr. 36. gr. skipulagslaga',
  'Breyting á aðalskipulagi.Lýsing.legal': 'Skipulagslýsing skv. 1. mgr. 30. gr. skipulagslaga',
  'Breyting á aðalskipulagi.Kynning tillögu á vinnslustigi.legal': 'Kynning vinnslutillögu skv. 2. mgr. 30 gr. skipulagslaga',
  'Breyting á aðalskipulagi.Athugun fyrir auglýsingu.legal': 'Athugun tillögu fyrir auglýsingu skv. 3. mgr. 30. gr. skipulagslaga',
  'Breyting á aðalskipulagi.Kynning tillögu.legal': 'Auglýsing tillögu skv. 31. gr. skipulagslaga',
  'Breyting á aðalskipulagi.Staðfesting.legal':
    'Tillaga til staðfestingar skv. 2 mgr. 32. gr. skipulagslaga og afgreiðsla Skipulagsstofnunar skv. 3. mgr. 32. gr. skipulagslaga',
  'Mat á umhverfisáhrifum.Kynning tillögu að áætlun.legal': 'Kynning matsáætlunar skv. 21. gr. laga um umhverfismat framkvæmda og áætlana',
  'Mat á umhverfisáhrifum.Staðfesting.legal': 'Álit Skipulagsstofnunar um matsáætlun skv. 3. mgr. 21. gr. laga um umhverfismat framkvæmda og áætlana',
  'Mat á umhverfisáhrifum.Kynning endanlegra áætlunar.legal': 'Kynning umhverfismatsskýrslu skv. 23. gr. laga um umhverfismat framkvæmda og áætlana',
  'Mat á umhverfisáhrifum.Staðfesting með lokagögnum.legal':
    'Álit Skipulagsstofnunar um umhverfismat framkvæmdar skv. 24. gr. laga um umhverfismat framkvæmda og áætlana',
  'Tilkynning til ákvörðunar um matsskyldu.legal': 'Tilkynning til ákvörðunar um matsskyldu skv. 19. gr. laga um umhverfismat framkvæmda og áætlana',
  'frontpage.faq.question.3': 'Hvernig legg ég fram athugasemd, og er tekið mark á henni?',
  'Óveruleg breyting á hverfisskipulagi.Grunnupplýsingar.help':
    'Stofnun máls vegna óverulegrar breytingar á hverfisskipulagi í samræmi við 2. mgr. 43. gr. skipulagslaga.',
  'Óveruleg breyting á hverfisskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef breyting verður grenndarkynnt skal haka við fasann „Grenndarkynning“ hér að neðan (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga': 'Grenndarkynning',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga.Fyrirliggjandi gögn': 'Kynning breytinga',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.name': 'Gögn til kynningar',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.help':
    'Hér setur sveitarstjórn fram tillögu að óverulegri breytingu á hverfisskipulagi sem kynna skal næstu nágrönnum og öðrum hagsmunaaðilum, í samræmi við 2. mgr. 43. gr. skipulagslaga (sjá einnig skipulagsreglugerð gr. 5.9.2 og 5.9.3). Taka skal mið af því hvort tillagan víki frá notkun, nýtingarhlutfalli, útliti og formi svæðisins og fyrirliggjandi skipulagi. Setja skal fram kynningargögn ásamt afriti af grenndarkynningarbréfi, og tilgreina hvaða hagsmunaaðilum gefst kostur á að tjá sig um tillöguna. Ef sveitarstjórn er heimilt að falla frá kröfu um kynningu má sleppa þessum lið (fasa). \nHér að neðan skal velja umsagnaraðila og skilgreina athugasemdafrest. Athugasemdafrestur skal vera fjórar vikur. Sé kynningartími styttur með skriflegri áritun skilgreindra hagsmunaaðila á kynningargögn, skal setja inn staðfestingu þess efnis og ljúka fasa.',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga.Fyrirliggjandi gögn.files':
    'Hér er kynningargögnum vegna óverulegrar breytingar á hverfisskipulagi ásamt afriti af grenndarkynningarbréfi hlaðið upp.',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga.Niðurstaða umsagnarferlis.name': 'Viðbrögð sveitarstjórnar',
  'Óveruleg breyting á hverfisskipulagi.Kynning Breytinga.Niðurstaða umsagnarferlis.help':
    'Hér er hægt að leggja fram samantekt á umsögnum sem borist hafa vegna tillögu að óverulegri hverfisskipulagsbreytingu, og viðbrögð við þeim eftir því sem við á.',
  'Óveruleg breyting á hverfisskipulagi.Ákvörðun Skipulagsstofnunar': 'Endanleg gögn',
  'Óveruleg breyting á hverfisskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar': 'Endanleg gögn',
  'Óveruleg breyting á hverfisskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.name': 'Endanleg gögn',
  'Óveruleg breyting á hverfisskipulagi.Ákvörðun Skipulagsstofnunar.Gögn til staðfestingar.help':
    'Hér skal leggja fram endanleg og undirrituð gögn óverulegrar hverfisskipulagsbreytingar ásamt tilheyrandi fylgigögnum. Þegar auglýsing um samþykki breytingarinnar hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.',
  'Breyting á aðalskipulagi.Endanleg gögn': 'Endanleg gögn',
  'Nýtt deiliskipulag.Endanleg gögn': 'Endanleg gögn',
  'Nýtt aðalskipulag.Staðfesting.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Nýtt svæðisskipulag.Staðfesting.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Breyting á svæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Breyting á aðalskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar': 'Viðbrögð Skipulagsstofnunar',
  'Óveruleg breyting á aðalskipulagi.Staðfesting.Gögn til staðfestingar': 'Gögn til staðfestingar',
  'Óveruleg breyting á strandsvæðisskipulagi.Grunnupplýsingar.help':
    'Stofnun máls vegna óverulegrar breytingar á strandsvæðisskipulagi í samræmi við 3. mgr. 14. gr. laga um skipulag haf- og strandsvæða.',
  'Óveruleg breyting á strandsvæðisskipulagi.Grunnupplýsingar.optional_phases.help':
    'Ef breyting verður kynnt skal haka við fasann „Kynning breytinga“ hér að neðan svo hann verði með í ferlinu (sjálfvalið). Ef þeim lið verður sleppt skal gæta þess að hann sé ekki valinn.',
  'Óveruleg breyting á strandsvæðisskipulagi.Staðfesting.Gögn til staðfestingar.help':
    'Hér skal Skipulagsstofnun fyrir hönd svæðisráðs setja fram tillögu að strandsvæðisskipulagsbreytingu sem samþykkt hefur verið af svæðisráði og óskað er staðfestingar á í samræmi við 3. mgr. 14. gr. laga um skipulag haf- og strandsvæða.\n\nTilgreina þarf hvenær tillagan var samþykkt.',
  'Óveruleg breyting á strandsvæðisskipulagi.Staðfesting.Gögn til staðfestingar.name': 'Innsetning gagna',
  'Óveruleg breyting á strandsvæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar': 'Endanleg gögn',
  'Óveruleg breyting á strandsvæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.name': 'Endanleg gögn',
  'Óveruleg breyting á strandsvæðisskipulagi.Staðfesting.Staðfesting Skipulagsstofnunar.help':
    'Hér skal leggja fram endanleg gögn staðfestrar breytingar á strandssvæðisskipulagi ásamt fylgigögnum hennar. Þegar auglýsing um nýtt strandsvæðisskipulag hefur verið birt í B-deild Stjórnartíðinda skal setja hér fram afrit af henni.\n\nHafi ráðherra ekki fallist á að um óverulega breytingu sé að ræða skal leggja hér fram þá niðurstöðu.',

  'tags.type.STRANDSVAEDI': 'Strandsvæðiskipulag',
};

export type TranslationKey = keyof typeof messages;

export default messages;
