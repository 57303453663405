<script lang="ts" setup>
import Feature from 'ol/Feature';
import useVectorSource from '@/features/map/composables/useVectorSource';
import { onMounted } from 'vue';

const props = defineProps<{
  features: Feature[];
}>();
const getVectorSource = useVectorSource();


const addFeatures = (features: Feature[]) => {
  getVectorSource().addFeatures(features);
};

onMounted(() => {
  addFeatures(props.features);
});

</script>
<template>
  <slot />
</template>
