<script lang="ts" setup>
import { MonitoringInput } from '@/generated/graphql';
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Translate from '@/features/translations/Translate.vue';
import MonitoringFormGroupProcesses from './MonitoringFormGroupProcesses.vue';
import MonitoringFormGroupTags from './MonitoringFormGroupTags.vue';
import PostCodeCheckboxForm from '@/features/communities/components/PostCodeCheckboxForm.vue';
import MonitoringFormGroupCommunities from './MonitoringFormGroupCommunities.vue';
import Tabs from '@/features/theme/base/Tabs.vue';
import Tab from '@/features/theme/base/Tab.vue';
import { ref } from 'vue';

const props = defineProps<{
  modelValue: MonitoringInput;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', model: MonitoringInput): void;
  (e: 'submit'): void;
}>();

const onSubmit = () => {
  emits('submit');
};

const onFieldUpdate = (field: keyof MonitoringInput, val: string[]) => {
  emits('update:modelValue', { ...props.modelValue, [field]: val });
};

const selectedTab = ref<string>('monitoring.tab.ferli');
const onTabClick = (tab: string) => {
  selectedTab.value = tab;
};
</script>
<template>
  <form @submit.prevent="onSubmit">
    <LayoutForm class="layout-container">
      <Tabs>
        <Tab @click="onTabClick" value="monitoring.tab.ferli" :active="selectedTab === 'monitoring.tab.ferli'" />
        <Tab @click="onTabClick" value="monitoring.tab.sveitarfelog" :active="selectedTab === 'monitoring.tab.sveitarfelog'" />
        <Tab @click="onTabClick" value="monitoring.tab.postnr" :active="selectedTab === 'monitoring.tab.postnr'" />
        <Tab @click="onTabClick" value="monitoring.tab.tags" :active="selectedTab === 'monitoring.tab.tags'" />
      </Tabs>
      <MonitoringFormGroupProcesses
        v-if="selectedTab === 'monitoring.tab.ferli'"
        :model-value="modelValue.issueProcessIds || []"
        @update:model-value="(val) => onFieldUpdate('issueProcessIds', val)"
      />
      <MonitoringFormGroupCommunities
        v-else-if="selectedTab === 'monitoring.tab.sveitarfelog'"
        :model-value="modelValue.communityIds || []"
        @update:model-value="(val) => onFieldUpdate('communityIds', val)"
      />
      <PostCodeCheckboxForm
        v-else-if="selectedTab === 'monitoring.tab.postnr'"
        :model-value="modelValue.postalCodes || []"
        title="monitoring.header.zips"
        @update:model-value="(val) => onFieldUpdate('postalCodes', val)"
      />
      <MonitoringFormGroupTags
        v-else-if="selectedTab === 'monitoring.tab.tags'"
        :model-value="modelValue.tags || []"
        @update:model-value="(val) => onFieldUpdate('tags', val)"
      />
      <LayoutFormGroup>
        <Button :type="ButtonType.primary">
          <Translate t="base.save" />
        </Button>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
</template>
