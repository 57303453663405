<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import InputText from '@/features/theme/base/InputText.vue';
import RichTextEditor from '@/features/theme/base/richTextEditor/RichTextEditor.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { computed, ref } from 'vue';
import { IssueStep } from '@/generated/graphql';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import Article, { ArticleBody, ArticleHeader } from '@/features/theme/base/article';
import useProcessObservers from '@/features/issueProcess/composables/useProcessObservers';
import { ProcessOperation } from '@/features/issueProcess/composables/useProcessStore';
import Icon from '@/features/theme/base/Icon.vue';
import Modal from '@/features/theme/base/Modal.vue';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { useI18n } from 'vue-i18n';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import ListItem from '@/features/theme/base/ListItem.vue';
import FormInputFilesSection from '@/features/files/components/FormInputFilesSection.vue';
import DatePickerInput from '@/features/theme/base/DatePickerInput.vue';
import { File as DomainFile } from '@/features/files/models';
import { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import Accordion from '@/features/components/Accordion.vue';
import { UpdatePhaseInput } from '../../models/UpdatePhaseInput';
import useActiveIssue from '../../composables/useActiveIssue';
import useIssuePhaseReviewDates from '@/features/issues/composables/useIssuePhaseReviewDates';
import { Delta } from '@vueup/vue-quill';

const props = defineProps<{
  step: IssueStep;
  phase: IssuePhase;
  issue: IssueDetails;
  translationKey: string;
  input: UpdatePhaseInput;
  ownFiles: DomainFile[];
  garbageFiles: DomainFile[];
  newFiles: DomainFile[];
}>();

const emits = defineEmits<{
  (e: 'update:input', input: UpdatePhaseInput): void;
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFilesInput', payload: Base64FilePayload[]): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
  (event: 'onNewFileCancel', file: DomainFile, index: number): void;
  (event: 'onNewFileEdit', name: string, index: number): void;
}>();

const { activePhaseSelected, activePhaseActiveStep, selectedStep } = useActiveIssue(computed(() => props.issue));

const onProcessStep = async () => {
  if (activePhaseSelected.value && !validate()) {
    throw new Error(t('validation.submit'));
  }
};

useProcessObservers({
  [ProcessOperation.Step]: [onProcessStep],
});

const statutoryReviewers = computed(() => props.issue.statutoryReviewers);
const selectingStatutoryReviewers = ref<boolean>(false);
const onStatutoryReviewerModalClose = () => {
  selectingStatutoryReviewers.value = false;
};

const onSelectStatutoryReviewers = () => {
  previousStatutoryReviewerIds.value = [...(props.input.statutoryReviewerIds || [])];
  selectingStatutoryReviewers.value = true;
};

const onReviewerSelected = (reviewerId: ID) => {
  let statutoryReviewerIds = [...(props.input.statutoryReviewerIds || [])];
  const idx = statutoryReviewerIds.indexOf(reviewerId.toString());
  if (idx !== -1) {
    statutoryReviewerIds!.splice(idx, 1);
  } else {
    if (!statutoryReviewerIds) {
      statutoryReviewerIds = [reviewerId.toString()];
    } else {
      statutoryReviewerIds.push(reviewerId.toString());
    }
  }
  clearError('statutoryReviewerIds');
  emits('update:input', { ...props.input, statutoryReviewerIds });
};

const isReviewerSelected = (reviewerId: ID) => {
  return props.input.statutoryReviewerIds?.includes(reviewerId.toString());
};

const onExtraRequiredReviewerRemove = (index: number) => {
  const extraRequiredReviewers = [...(props.input.extraRequiredReviewers || [])];
  if (index < extraRequiredReviewers.length) {
    extraRequiredReviewers.splice(index, 1);
    emits('update:input', { ...props.input, extraRequiredReviewers });
  }
};

const onExtraReviewerAdd = () => {
  const extraRequiredReviewers = [...(props.input.extraRequiredReviewers || [])];
  extraRequiredReviewers.push({ name: '', email: '' });
  emits('update:input', { ...props.input, extraRequiredReviewers });
};
const selectedStatutoryReviewers = computed(() => {
  return statutoryReviewers.value.filter((sr) => isReviewerSelected(sr.id));
});

const previousStatutoryReviewerIds = ref<Maybe<string[]>>([]);
const onStatutoryReviewerCancel = () => {
  const statutoryReviewerIds = [...(previousStatutoryReviewerIds.value || [])];
  emits('update:input', { ...props.input, statutoryReviewerIds });
  selectingStatutoryReviewers.value = false;
};

const onStatutoryReviewerConfirm = () => {
  selectingStatutoryReviewers.value = false;
};

const { t } = useI18n();
const phaseValidationErrors = ref<Errors<UpdatePhaseInput>>({});
const validateInput = (input: UpdatePhaseInput): Errors<UpdatePhaseInput> => {
  const errors: Errors<UpdatePhaseInput> = {};

  if (!input.title || input.title.length <= 0) {
    errors.title = [t('validation.required', { attribute: t('issue.phase.title') })];
  }

  if (!input.quillDelta?.ops?.length || input.quillDelta.ops.every((op) => !op.insert?.toString().trim())) {
    errors.description = [t('validation.required', { attribute: t('issue.phase.description') })];
  }

  const shouldValidateReviewers = !input.skipStatutoryReviewerValidation && props.phase.isStatutoryReviewersRequired;
  const hasNoStatutoryReviewers = !input.statutoryReviewerIds || input.statutoryReviewerIds.length === 0;
  const hasNoExtraReviewers = !input.extraRequiredReviewers || input.extraRequiredReviewers.length === 0;
  if (shouldValidateReviewers && hasNoStatutoryReviewers && hasNoExtraReviewers) {
    errors.statutoryReviewerIds = [t('validation.min', { attribute: t('issue.phase.statutory_reviewer'), min: 1 })];
  }

  return errors;
};

const validate = () => {
  phaseValidationErrors.value = validateInput(props.input);
  return Object.keys(phaseValidationErrors.value).length === 0;
};

const hasErrors = computed(() => Object.keys(phaseValidationErrors.value).length > 0);

const clearError = (field: keyof UpdatePhaseInput): void => {
  delete phaseValidationErrors.value[field];
};

const onFieldUpdate = (field: keyof UpdatePhaseInput, value: string) => {
  emits('update:input', { ...props.input, [field]: value });
  clearError(field);
};

const onRichTextUpdate = (delta: Maybe<Delta>) => {
  if (delta) {
    emits('update:input', { ...props.input, quillDelta: delta });
  }
};

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onFilesInput = (payload: Base64FilePayload[]) => {
  emits('onFilesInput', payload);
};

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};

const onNewFileCancel = (file: DomainFile, index: number) => {
  emits('onNewFileCancel', file, index);
};

const onNewFileEdit = (name: string, index: number) => {
  emits('onNewFileEdit', name, index);
};

const statutoryReviewerCategories = computed(() => {
  const categories = statutoryReviewers.value.map((sr) => sr.category);
  const unique = new Set(categories);
  return [...unique];
});

const skipStatutoryReviewerValidation = computed(() => props.input.skipStatutoryReviewerValidation || false);

const { isOpenForReview } = useIssuePhaseReviewDates(computed(() => props.issue.currentPhase));
</script>
<template>
  <Article>
    <ArticleHeader>
      <Translate
        :value="`${translationKey}.name`"
        :default-value="step.name"
      />
      <template #subtitle>
        <Translate
          :value="`${translationKey}.help`"
          default-value="Hér er einhver hjálpartexti"
        />
      </template>
    </ArticleHeader>
    <form @submit.prevent>
      <LayoutForm>
        <LayoutFormGroup>
          <LayoutInput :span="2">
            <InputText
              v-model="input.title"
              fullwidth
              label="issue.phase.title"
              :error-message="phaseValidationErrors.title?.[0]"
              @update:model-value="(val) => onFieldUpdate('title', val)"
            />
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup class="mb-4">
          <LayoutInput :span="2">
            <RichTextEditor
              translationKey="issue.phase.description"
              v-model="input.quillDelta"
              fullwidth
              :error-message="phaseValidationErrors.description?.[0]"
              @update:model-value="(val) => onRichTextUpdate(val)"
            />
          </LayoutInput>
        </LayoutFormGroup>
        <FormInputFilesSection
          :own-files="ownFiles"
          :garbage-files="garbageFiles"
          :new-files="newFiles"
          :translation-key="translationKey"
          @on-file-delete="onFileDelete"
          @on-file-restore="onFileRestore"
          @on-files-input="onFilesInput"
          @on-new-file-cancel="onNewFileCancel"
          @on-new-file-edit="onNewFileEdit"
        />
        <LayoutFormGroup
          class="mb-4"
          title="issue.phase.review_period"
        >
          <LayoutInput>
            <DatePickerInput
              v-model="input.reviewStartDate"
              :placeholder="t('base.date.from')"
              :disabled="isOpenForReview"
            />
          </LayoutInput>
          <LayoutInput>
            <DatePickerInput
              v-model="input.reviewEndDate"
              :placeholder="t('base.date.to')"
              :disabled="isOpenForReview"
            />
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup title="issue.phase.reviewers">
          <template #explanation>
            <Translate t="issue.phase.reviewers.help" />
          </template>
        </LayoutFormGroup>
        <LayoutFormGroup v-if="phase.hasStatutoryReviewers">
          <template #explanation>
            <Translate t="issue.phase.statutoryreviewer.add" />
          </template>
          <LayoutInput
            :span="1"
            v-for="reviewer in selectedStatutoryReviewers"
            :key="reviewer.id"
          >
            <ListItem>
              <div class="py-1 color-primary bold">{{ reviewer.name }}</div>
              <template #right>
                <Button
                  :type="ButtonType.tertiaryIconOnly"
                  @click="() => onReviewerSelected(reviewer.id)"
                >
                  <Icon icon="Delete" />
                </Button>
              </template>
            </ListItem>
          </LayoutInput>
        </LayoutFormGroup>
        <div class="hr"></div>
        <LayoutFormGroup v-if="phase.hasStatutoryReviewers">
          <LayoutInput :span="2">
            <Button
              @click="onSelectStatutoryReviewers"
              :type="ButtonType.secondary"
            >
              <span class="mr-1">
                <Translate t="issue.phase.statutory_reviewer.select" />
              </span>
              <Icon
                icon="Plus"
                :options="{ width: 24, height: 24, color: Colors.primaryDark }"
              />
            </Button>
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup v-if="phaseValidationErrors.statutoryReviewerIds">
          <LayoutInput :span="2">
            <Alert :alert-type="AlertType.danger">
              <Translate
                t="validation.min"
                :interpolations="{ attribute: t('issue.phase.statutory_reviewer'), min: 1 }"
              />
            </Alert>
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup>
          <template #explanation>
            <Translate t="issue.phase.extrareviewer.add" />
          </template>
        </LayoutFormGroup>
        <template v-if="input.extraRequiredReviewers">
          <LayoutFormGroup
            v-for="(reviewer, index) in input.extraRequiredReviewers"
            :key="index"
          >
            <LayoutInput>
              <InputText
                fullwidth
                v-model="reviewer.name"
                label="issue.reviewer.name"
              />
            </LayoutInput>
            <LayoutInput>
              <InputText
                fullwidth
                v-model="reviewer.email"
                label="issue.reviewer.email"
              />
            </LayoutInput>
            <LayoutInput>
              <Button
                @click="() => onExtraRequiredReviewerRemove(index)"
                :type="ButtonType.tertiaryIconOnly"
              >
                <Icon icon="Delete" />
              </Button>
            </LayoutInput>
          </LayoutFormGroup>
        </template>
        <LayoutFormGroup>
          <template
            #explanation
            v-if="!input.extraRequiredReviewers"
          >
            <Translate t="issue.phase.extrareviewer.add" />
          </template>
          <LayoutInput>
            <Button
              @click="onExtraReviewerAdd"
              :type="ButtonType.secondary"
            >
              <span class="mr-1">
                <Translate t="issue.phase.reviewer.add" />
              </span>
              <Icon
                icon="Plus"
                :options="{ width: 24, height: 24, color: Colors.primaryDark }"
              />
            </Button>
          </LayoutInput>
          <LayoutInput :span="3">
            <Checkbox
              @update:model-value="() => (input.skipStatutoryReviewerValidation = !skipStatutoryReviewerValidation)"
              :model-value="skipStatutoryReviewerValidation"
              class="mb-2"
              :id="phase.title"
            >
              <Translate t="issue.phase.statutory_reviewer.skip" />
            </Checkbox>
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup v-if="hasErrors">
          <LayoutInput :span="3">
            <Alert :alert-type="AlertType.danger">
              <Translate t="validation.submit" />
            </Alert>
          </LayoutInput>
        </LayoutFormGroup>
      </LayoutForm>
    </form>
    <Modal
      :open="selectingStatutoryReviewers"
      @close="onStatutoryReviewerModalClose"
      scrollable
    >
      <Article class="accordion-container">
        <ArticleHeader>
          <Translate t="issue.phase.reviewer.select" />
        </ArticleHeader>
        <ArticleBody>
          <Accordion
            v-for="category in statutoryReviewerCategories"
            :key="category"
          >
            <template #default="{ accordionToggle, accordionBodyVisible }">
              <div
                @click="accordionToggle"
                class="flex accordion-header cursor-pointer"
                :class="{ 'pb-1': !accordionBodyVisible }"
              >
                <h4 class="p--large">{{ category }}</h4>
                <Icon
                  class="icon"
                  icon="DropdownArrow"
                  :options="{ width: 24, height: 24, color: Colors.grey600 }"
                  :class="{ 'rotate-180': accordionBodyVisible }"
                />
              </div>
            </template>
            <template #body>
              <Checkbox
                :model-value="isReviewerSelected(reviewer.id)"
                @update:model-value="() => onReviewerSelected(reviewer.id)"
                class="mb-2"
                :id="`reviewer-${reviewer.id}`"
                v-for="reviewer in statutoryReviewers.filter((sr) => sr.category === category)"
                :key="reviewer.id"
              >
                {{ reviewer.name }}
              </Checkbox>
            </template>
          </Accordion>
          <div class="button-group">
            <Button
              @click="onStatutoryReviewerCancel"
              prevent
              :type="ButtonType.secondary"
              class="button"
            >
              <Translate t="base.cancel" />
            </Button>
            <Button
              @click="onStatutoryReviewerConfirm"
              prevent
              :type="ButtonType.primary"
              class="button"
            >
              <Translate t="issue.reviewer.confirm" />
            </Button>
          </div>
        </ArticleBody>
      </Article>
    </Modal>
  </Article>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;
@use '@/scss/design-tokens/colors' as colors;

.accordion-container {
  min-width: 60vw;
}
.button-group {
  margin-top: 1rem;
  flex: 1;
  display: flex;
  justify-content: space-between;
}

.button {
  min-width: calc(60vw / 5);
}
.hr {
  border-top: 1px solid colors.$grey-200;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
}

.icon {
  transition: all 0.3s;

  &.rotate-180 {
    transform: rotateX(180deg);
  }
}
</style>
