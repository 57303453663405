import { AuditSpecificationInput, useGetAuditsQuery } from '@/generated/graphql';
import { computed, ComputedRef } from 'vue';
import { Audit } from '../auditTypes';

interface UseAudits {
  audits: ComputedRef<Audit[]>;
  auditsLoading: ComputedRef<boolean>;
}

export default function useAudits(input: ComputedRef<AuditSpecificationInput>): UseAudits {
  const query = useGetAuditsQuery({
    variables: {
      input,
    },
  });

  const audits = computed(() => query.data.value?.audits || []);

  return {
    audits,
    auditsLoading: computed(() => query.fetching.value),
  };
}
