import { ref, Ref } from 'vue';
import { DataTableFilter } from './dataTableTypes';

interface UseDataTableSearch {
  dataTableSearch: Ref<Maybe<string>>;
  dataTableSearchFilter: DataTableFilter;
}

export default function useDataTableSearch(): UseDataTableSearch {
  const dataTableSearch = ref<Maybe<string>>();
  const dataTableSearchFilter: DataTableFilter = (dataset) => {
    if (!dataTableSearch.value || dataTableSearch.value === '') {
      return dataset;
    }
    const searchValue = dataTableSearch.value.toLowerCase();
    return dataset.filter((item) => JSON.stringify(item).toLowerCase().includes(searchValue));
  };

  return {
    dataTableSearch,
    dataTableSearchFilter,
  };
}
