<script lang="ts" setup>
import IssueCardList from '@/features/issues/components/issueList/IssueCardList.vue';
import useSidebar from '@/features/navigation/useSidebar';
import InputSearch from '@/features/theme/base/InputSearch.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import IssueDetailedSearch from './IssueDetailedSearch.vue';
import useIssueSearch from '../composables/useIssueSearch';
import { ref, onMounted } from 'vue';
import debounce from 'lodash-es/debounce';
import useNavigation from '@/features/navigation/useNavigation';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Icon from '@/features/theme/base/Icon.vue';
import Translate from '@/features/translations/Translate.vue';
import { IssueCardViewModel, IssueSearch } from '@/features/issues/models';
import { usePushIssueYearOrderRoute } from '@/features/issues/composables/usePushIssueYearOrder';

const { hideSidebar } = useSidebar();
const onCloseClick = () => {
  hideSidebar();
};

const showSearchResults = ref<boolean>(false);
const runDebouncedSearch = debounce(() => {
  performSearch();
}, 500);

const onSearchUpdate = (v: string): void => {
  const spec = { ...issueSearch.value.spec, search: v  };
  setSpecification({ ...issueSearch.value, spec: spec });
  if (issueSearch.value.spec.search && issueSearch.value.spec.search.length > 0) {
    showSearchResults.value = issueSearch.value.spec.search.length > 0;
  }
  runDebouncedSearch();
};

const { isRoute } = useNavigation();
const { 
  performSearch, 
  hasSearchResults, 
  searchResults,
  searchLoading,
  hasNoSpecification,
  clearSpecification,
  setSpecification,
  totalCount,
  issueSearch
} = useIssueSearch(isRoute('Issues'));

const onContainerClicked = () => {
  showSearchResults.value = false;
  searchFocused.value = false;
};

const { pushActiveAreaRouteByName } = useNavigation();
const { pushIssueYearOrderRoute } = usePushIssueYearOrderRoute();
const onIssueSelect = (issue: IssueCardViewModel) => {
  hideSidebar();
  pushIssueYearOrderRoute(issue);
};

const onSpecificationUpdate = (search: IssueSearch) => {
  setSpecification(search);
  runDebouncedSearch();
};

const searchFocused = ref<boolean>(false);
const onSearchFocus = () => {
  searchFocused.value = true;
};

const onResultsClick = () => {
  if (isRoute('Issues')) {
    hideSidebar();
  } else {
    pushActiveAreaRouteByName('Issues', undefined, { search: '1' });
  }
};

onMounted(() => {
  if (!isRoute('Issues')) {
    clearSpecification();
  }
});
</script>
<template>
  <div
    @click="onContainerClicked"
    class="search-container position-relative"
  >
    <div class="search-simple">
      <div class="search-title mb-2">
        <h4 class="p--large">
          <Translate t="search.title" />
        </h4>
        <span
          class="cursor-pointer"
          @click="onCloseClick"
        >
          <Icon
            icon="Close"
            :options="{ color: Colors.primary, width: 24, height: 24 }"
          />
        </span>
      </div>
      <div class="position-relative">
        <div
          class="input-search-container"
          :class="{ searchFocused }"
        >
          <InputSearch
            autofocus
            fullwidth
            :include-button="false"
            label="search.input"
            :icon-options="{ color: Colors.grey600 }"
            :model-value="issueSearch.spec.search"
            @update:model-value="onSearchUpdate"
            @focus="onSearchFocus"
            @click.stop
          />
        </div>
        <div
          v-if="issueSearch.spec.search && issueSearch.spec.search.length > 0"
          class="results-container scrollbar"
        >
          <h5 class="search-results-title px-2 py-1 bg-grey-200 p--large">
            <Translate t="search.results.title" />
          </h5>
          <div class="bg-white px-2 py-2">
            <div v-if="searchLoading">
              <Translate t="search.loading" />
              ...
            </div>
            <IssueCardList
              v-else-if="searchResults && searchResults.length > 0"
              :issues="searchResults"
              :card-size="null"
              :show-bookmark="false"
              :show-tags="false"
              icon-only
              id-placement="footer"
              card-style="border"
              @select="onIssueSelect"
            />
            <div v-else>
              <Translate t="search.results.none" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="detailed-search-container scrollbar">
      <IssueDetailedSearch
        :issue-search="issueSearch"
        @update:model-value="onSpecificationUpdate"
      />
    </div>
    <div class="search-footer bg-white">
      <Button
        :type="ButtonType.secondary"
        @click="clearSpecification"
        :disabled="hasNoSpecification"
      >
        <Translate t="search.clear_filters" />
        <Icon
          :options="{ color: hasNoSpecification ? Colors.grey200 : Colors.primaryDark }"
          class="icon icon-right"
          icon="Refresh"
        />
      </Button>
      <Button
        @click="onResultsClick"
        :disabled="!hasSearchResults"
      >
        <Translate
          t="search.results"
          :interpolations="{ n: totalCount }"
        />
      </Button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/z-indicies' as z;

.search__element {
  padding: 5rem 2rem 5rem 2rem;

  @include mq.tablet() {
    padding-right: 5rem;
    padding-left: 5rem;
  }
}

.search-container {
  height: 100%;
  background-color: colors.$white;
}

.search-simple {
  @extend .search__element;
  background-color: colors.$primary--xxlight;
}

.search-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-detailed-title {
  font-size: 2.2rem;
}

.search-results-title {
  text-transform: uppercase;
}

.results-container {
  position: absolute;
  top: 6.9rem;
  min-width: 100%;
  left: 0;
  box-shadow: 0px 24px 96px rgba(colors.$primary, 0.1);
  border-radius: 2px;
  max-height: 0;
  overflow: hidden auto;
  padding-right: 0;
  z-index: z.$z-index-sidebar-search-results;
}

.detailed-search-container {
  overflow: hidden auto;
  max-height: calc(100vh - 21.2rem);
  padding: 3rem 3rem 14rem 3rem;
  @include mq.mobile() {
    padding: 1rem 1rem 5rem 1rem;
  }
}

.search-footer {
  @extend .search__element;
  display: flex;
  gap: 2.4rem;
  justify-content: space-between;

  @include mq.mobile() {
    .icon {
      display: none;
    }
  }

  @include mq.tablet() {
    justify-content: flex-end;
  }
}

.input-search-container {
  max-width: 61.9rem;

  &.searchFocused + .results-container {
    max-height: 40vh;
  }
}

.search-footer {
  @extend .search__element;
  padding-top: 1rem;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: z.$z-index-sidebar-footer;
  border-top: 1px solid colors.$grey-200;
}
</style>
