import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { IssueSortEnum } from '@/generated/graphql';
import { issueCardsCache } from '../issueCardsCache';
import { IssueSearch } from '../models';

export const useIssueStore = defineStore('issues', () => {

  const _issueSearch = ref<IssueSearch>({
    spec: { sortBy: IssueSortEnum.LastUpdated }
  });

  const issueSearch = computed(() => {
    return _issueSearch.value;
  });

  const hasNoSpecification = computed<boolean>(() => {
    return (
      !issueSearch.value.spec.community && 
      !issueSearch.value.spec.search &&
      !issueSearch.value.spec.fromDate &&
      !issueSearch.value.spec.toDate &&
      (issueSearch.value.spec.processPhases || []).length === 0 &&
      (issueSearch.value.spec.tags || []).length === 0 &&
      !issueSearch.value.spec.geoJson &&
      (issueSearch.value.spec.postalCodes || []).length === 0 &&
      (issueSearch.value.spec.issueStatus || []).length === 0 &&
      (issueSearch.value.spec.delegationIds || []).length === 0 &&
      !issueSearch.value.spec.skipulagsstofnunDelegation
    );
  });

  const resetIssueSpecification = () => {
    putIssueSpecification({
      spec: { sortBy: _issueSearch.value.spec.sortBy || IssueSortEnum.LastUpdated },
      filter: {}
    });
  };

  const patchIssueSpecification = (search: IssueSearch) => {
    _issueSearch.value = {
      ..._issueSearch.value,
      ...search,
    };
    issueCardsCache.clear();
    after.value = undefined;
  };

  const putIssueSpecification = (search: IssueSearch) => {
    _issueSearch.value = search;
    issueCardsCache.clear();
    after.value = undefined;
  };

  const after = ref<string>();

  return {
    issueSearch,
    hasNoSpecification,
    resetIssueSpecification,
    putIssueSpecification,
    patchIssueSpecification,
    after,
  };
});

export default useIssueStore;
