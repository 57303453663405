import useCollectionSorting from '@/features/composables/useCollectionSorting';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import { useGetAllProcessCategoriesQuery } from '@/generated/graphql';
import { Dictionary, forEach } from 'lodash';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

export default function useAllIssueProcessCategories() {
  const { sortBy } = useCollectionSorting();
  const { data: categoriesData, error, fetching } = useGetAllProcessCategoriesQuery();
  const categories = computed(() => categoriesData.value?.allIssueProcessCategories || []);
  const categoriesSorted = computed(() => sortBy(categoriesData?.value?.allIssueProcessCategories || [], 'sortOrder', 'asc'));
  const { t } = useI18n();
  const { getProcessPhaseKey } = useProcessTranslation();

  const phaseTypesGroupedByCategories = computed(() => {
    const groupedByCategoryAndProcess: Dictionary<Dictionary<{ id: string; name: string }[]>> = {};
    forEach(categoriesSorted.value, (category) => {
      const processes: Dictionary<{ id: string; name: string }[]> = {};
      forEach(category.processes, (process) => {
        const phaseTypesTranslated: { id: string; name: string }[] = [];
        forEach(process.phaseTypes, (phaseType) => {
          phaseTypesTranslated.push({ id: phaseType.id, name: t(getProcessPhaseKey({ title: process.title }, { name: phaseType.name })) });
        });
        processes[process.title] = phaseTypesTranslated;
      });
      groupedByCategoryAndProcess[category.name] = processes;
    });

    return groupedByCategoryAndProcess;
  });

  return {
    categoriesFetching: fetching,
    categoriesError: error,
    categories,
    categoriesSorted,
    phaseTypesGroupedByCategories,
  };
}
