import useCollectionSearching from '@/features/composables/useCollectionSearching';
import { useGetMyIssuesQuery, useUnwatchIssueMutation, useWatchIssueMutation } from '@/generated/graphql';
import { computed, ComputedRef } from 'vue';
import { Issue } from '@/features/issues/models';

interface UseWatchedIssues {
  watchedIssues: ComputedRef<Issue[]>;

  isIssueWatched(issueId: ID): boolean;

  watchIssue(issueId: ID): Promise<ID>;
  unwatchIssue(issueId: ID): Promise<ID>;
  toggleWatchIssue(issueId: ID): Promise<ID>;
}

export default function useWatchedIssues(): UseWatchedIssues {
  const { data, executeQuery } = useGetMyIssuesQuery();
  const { findById } = useCollectionSearching();

  const watchedIssues = computed(() => {
    if (data.value?.me?.watchedIssues) {
      return data.value?.me?.watchedIssues;
    }

    return [];
  });

  const isIssueWatched = (issueId: ID) => {
    return !!findById(watchedIssues.value, issueId);
  };

  const { executeMutation: watchIssueMutation } = useWatchIssueMutation();
  const { executeMutation: unwatchIssueMutation } = useUnwatchIssueMutation();

  const watchIssue = async (issueId: ID): Promise<ID> => {
    const res = await watchIssueMutation({ issueId: issueId.toString() });

    if (!res.data?.watchIssue?.issue) {
      // Todo: Error handling
      throw new Error('Failed to watch issue');
    }

    executeQuery({ requestPolicy: 'network-only' });

    return res.data.watchIssue.issue.id;
  };

  const unwatchIssue = async (issueId: ID): Promise<ID> => {
    const res = await unwatchIssueMutation({ issueId: issueId.toString() });

    if (!res.data?.unwatchIssue?.issue) {
      // Todo: Error handling
      throw new Error('Failed to unwatch issue');
    }

    executeQuery({ requestPolicy: 'network-only' });

    return res.data.unwatchIssue.issue.id;
  };

  const toggleWatchIssue = async (issueId: ID): Promise<ID> => {
    return isIssueWatched(issueId) ? unwatchIssue(issueId) : watchIssue(issueId);
  };

  return {
    watchedIssues,
    isIssueWatched,

    watchIssue,
    unwatchIssue,
    toggleWatchIssue,
  };
}
