import { ButtonType } from './base/models/ButtonType';
import { Colors } from './base/models/Colors';
import type { MenuThemeIndex } from './models';

export const MenuThemes: MenuThemeIndex = {
  frontpage: {
    background: 'primary--dark',
    color: 'white',
    logoText: true,
    logoWidth: 248,
    menuButtonType: ButtonType.quaternary,
    menuButtonIconColor: Colors.white,
    loginTextClass: 'text-white',
  },
  login: {
    background: 'primary--xlight',
    color: 'primary',
    logoText: true,
    logoWidth: 248,
    menuButtonType: ButtonType.secondary,
    menuButtonIconColor: Colors.primaryDark,
  },
  default: {
    background: 'white',
    color: 'primary',
    logoText: false,
    logoWidth: 48,
    menuButtonType: ButtonType.secondary,
    menuButtonIconColor: Colors.primaryDark,
  },
};
