import { computed, Ref, ref } from 'vue';
import { Base64FilePayload } from '../components/FileInputBase64.vue';
import useFileStore from './useFileStore';
import { File as DomainFile } from '@/features/files/models';
import { AssetInput } from '../models/AssetInput';
import { IssuePhaseStepEnum } from '@/generated/graphql';

export default function useInputFiles(ownFiles: Ref<DomainFile[]>) {
  const fileStore = useFileStore();

  const inputAssets = ref<AssetInput[]>([]);
  const onFilesInput = (payload: Base64FilePayload[], stepType?: IssuePhaseStepEnum, iterationCounter?: number) => {
    inputAssets.value = [
      ...inputAssets.value,
      ...payload.map((item) => ({
        data: item.base64,
        name: item.file.name,
        encoding: 1,
        iterationCounter: iterationCounter || 0,
        stepType: stepType,
        size: item.size.toString()
      })),
    ];
  };

  const newFiles = computed<DomainFile[]>(() => {
    return inputAssets.value.map((file, index) => ({
      id: index,
      path: file.name,
      name: file.name,
      type: file.name.split('.')[-1],
      size: 9001,
    }));
  });

  const onNewFileCancel = (file: DomainFile, index: number) => {
    inputAssets.value = inputAssets.value.filter((_, i) => i !== index);
  };

  const onNewFileEdit = (name: string, index: number) => {
    var type = inputAssets.value[index].name.split('.')[1];
    inputAssets.value[index].name = name + '.' + type;
  };

  const remoteFiles = computed(() => {
    return ownFiles.value.filter((f) => !fileStore.garbage.includes(f.id.toString()));
  });

  const garbageFiles = computed(() => {
    return fileStore.garbage.reduce((acc, gfid) => {
      const gf = ownFiles.value.find((f) => f.id === gfid);
      if (gf) {
        acc.push(gf);
      }
      return acc;
    }, [] as DomainFile[]);
  });

  const onFileDelete = (file: DomainFile, index: number) => {
    fileStore.addToGarbage(file.id.toString());
  };

  const onFileRestore = async (file: DomainFile, index: number) => {
    fileStore.removeFromGarbage(file.id.toString());
  };

  const clearInputAssets = () => {
    inputAssets.value = [];
  };

  return {
    inputAssets,
    newFiles,
    garbageFiles,
    remoteFiles,

    onFilesInput,
    onNewFileCancel,
    onNewFileEdit,
    onFileDelete,
    onFileRestore,
    clearInputAssets,
  };
}
