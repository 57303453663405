<script setup lang="ts">
import FaqAccordionItem from './FaqAccordionItem.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType, ButtonSize } from '@/features/theme/base/models/ButtonType';
import Arrow from '@/features/theme/icons/Arrow.vue';
import DirectionType from '@/features/theme/icons/models/DirectionType';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { FaqRouteName } from '@/features/faq/FaqRouteName';
import { useRouter } from 'vue-router';

const router = useRouter();

const onMoreQuestionsClick = () => {
  router.push(`faq?name=${FaqRouteName.Faq}`);
};
</script>

<template>
  <div class="bg-primary--xxlight accordian">
    <h1 class="h6 accordian-header">
      <slot></slot>
    </h1>
    <div class="accordian-container">
      <FaqAccordionItem
        class="accordion-item"
        label="frontpage.faq.question.1"
        content="frontpage.faq.answer.1"
      ></FaqAccordionItem>
      <FaqAccordionItem
        class="accordion-item"
        label="frontpage.faq.question.2"
        content="frontpage.faq.answer.2"
      ></FaqAccordionItem>
      <FaqAccordionItem
        class="accordion-item"
        label="frontpage.faq.question.3"
        content="frontpage.faq.answer.3"
      ></FaqAccordionItem>
      <FaqAccordionItem
        class="accordion-item"
        label="frontpage.faq.question.4"
        content="frontpage.faq.answer.4"
      ></FaqAccordionItem>
      <div class="button-container">
        <Button
          @click="onMoreQuestionsClick"
          :type="ButtonType.tertiary"
          :size="ButtonSize.medium"
          class="button"
        >
          <Translate t="frontpage.faq.button" />
          <Arrow
            :custom-class="'icon-right'"
            :direction="DirectionType.right"
            :heigth="24"
            :width="24"
            :color="Colors.primary"
          ></Arrow>
        </Button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/media-queries' as media-queries;

.button-container {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;

  width: 100%;
}

.button {
  box-sizing: border-box;
  display: flex;
  align-self: flex-end;
  margin-top: 3rem;
}

.accordian {
  padding: 20rem 0;

  @include media-queries.laptop-down {
    padding: 15rem 0 15rem;
  }

  @include media-queries.mobile {
    padding: 8rem 0 8rem;
  }
}

.accordian-container {
  margin: auto;
  width: 60%;

  @include media-queries.mobile {
    width: 88%;
  }
}
.accordian-header {
  margin: auto;
  width: 60%;
  margin-bottom: 7rem;
  text-align: start;

  @include media-queries.mobile {
    margin-bottom: 4rem;
    width: 88%;
  }
}
.accordion-item:first-child {
  border-radius: 0.8rem 0.8rem 0 0;
}

.accordion-item:last-child {
  border-radius: 0 0 0.8rem 0.8rem;
}
</style>
