import { computed, ComputedRef } from 'vue';
import { IssueSpecificationInput, useGetSingleIssueQuery, useGetSingleIssuePhaseReviewsQuery } from '@/generated/graphql';
import { IssueDetails, IssueReview } from '../models';

export default function useSingleIssue(specs: ComputedRef<IssueSpecificationInput>) {
  const { data: getIssueData, fetching } = useGetSingleIssueQuery({
    variables: {
      input: specs,
    },
  });

  const { data: reviewsData, fetching: fetchingReviews } = useGetSingleIssuePhaseReviewsQuery({
    variables: {
      input: specs,
    },
  });

  const currentPhaseReviews = computed(() => {
    return reviewsData?.value?.singleIssue.currentPhase?.reviews || [];
  });

  const phaseReviewsById = computed(() => {
    return (
      reviewsData?.value?.singleIssue.phases.reduce((acc, p) => {
        acc[p.id] = p.reviews;
        return acc;
      }, {} as { [key: string]: IssueReview[] }) || {}
    );
  });

  const issue = computed(() => {
    if (!getIssueData.value?.singleIssue) {
      return null;
    }

    const { singleIssue } = getIssueData.value;

    const _issue: IssueDetails = {
      ...singleIssue,
      phases: singleIssue.phases.map(p => ({
        ...p,
        reviews: phaseReviewsById.value[p.id] || [],
      })),
      currentPhase: undefined,
    };

    if (singleIssue.currentPhase) {
      _issue.currentPhase = {
        ...singleIssue.currentPhase,
        reviews: currentPhaseReviews.value,
      };
    }

    return _issue;
  });

  return {
    issue,
    fetching,
    fetchingReviews,
  };
}
