<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { computed, ref, watch } from 'vue';
import { Delta, QuillEditor, Quill } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import useRichTextHoverEffect from './useRichTextHoverEffect';
import { useI18n } from 'vue-i18n';
import { defaultDocument } from '@vueuse/core';

const props = withDefaults(
  defineProps<{
    translationKey?: string;
    max?: number;
    modelValue?: Maybe<Delta>;
    disabled?: boolean;
    fullwidth?: boolean;
    errorMessage?: Maybe<string>;
  }>(),
  {
    modelValue: () => new Delta(),
    disabled: false,
    fullwidth: false,
    translationKey: 'Lýsing',
  }
);

const hasError = computed(() => !!props.errorMessage);

const { t } = useI18n();

const { addHoverEffect, removeHoverEffect } = useRichTextHoverEffect(hasError);

const emit = defineEmits<{
  (e: 'update:modelValue', value: Maybe<Delta>): void;
}>();

const isFocus = ref(false);
const localValue = ref<Delta>();
const inputValue = computed(() => localValue.value || props.modelValue);

function matchMsExtraLinebreaks(node: any, delta: Delta) {
  if (delta.ops.length) {
    delta.ops.forEach((op) => {
      if (typeof op?.insert === 'string') {
        const insert = op.insert;
        const rex = /^([-\.]|\(\d+\)|\d+\)|\d+\.)$/;
        const shouldAppend = insert.at(-1) === '\n' && !rex.test(insert.trim());

        op.insert = insert.replaceAll('\n', ' ');
        if (shouldAppend) {
          op.insert += '\n';
        }
      }
    });
  }
  return delta;
}

function matchImage(node: any, delta: Delta) {
  const Delta = Quill.import('delta');
  return new Delta().insert('');
}

const removeImages = () => {
  const parent = document.querySelector(".ql-editor p");
  if (parent?.childNodes) {
    for (let i = 0; i < parent?.childNodes.length; i++) {
      const child = parent?.childNodes[i];
      if (child?.nodeName === "IMG") {
        parent?.removeChild(child);
      }
    }
  }
}

const onInput = (delta: Delta) => {
  removeImages();
  localValue.value = delta;
  emit('update:modelValue', delta);
};

const editorOptions = {
  modules: {
    toolbar: [['bold', 'underline', 'link'], [{ list: 'bullet' }]],
    clipboard: {
      matchers: [
        ['p.MsoNormal', matchMsExtraLinebreaks],
        ['p.MsoListParagraphCxSpFirst', matchMsExtraLinebreaks],
        ['p.MsoListParagraphCxSpMiddle', matchMsExtraLinebreaks],
        ['p.MsoListParagraphCxSpLast', matchMsExtraLinebreaks],
        ['p.MsoListParagraph', matchMsExtraLinebreaks],
        ['p.msolistparagraph', matchMsExtraLinebreaks],
        ['img', matchImage],
        ['picture', matchImage],
        ['image', matchImage],
      ],
    },
  },
  formats: ['bold', 'underline', 'link', 'list'],
};

const editorRef = ref();
const isHover = ref(false);

const onMouseEnter = () => {
  isHover.value = true;
  addHoverEffect();
};

const onMouseLeve = () => {
  isHover.value = false;
  removeHoverEffect();
};

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      localValue.value = newValue || '';
    }
  }
);

watch(hasError, (current) => {
  if (editorRef.value) {
    if (current) {
      editorRef.value.getEditor().style.borderColor = '#db1e3e';
      editorRef.value.getToolbar().style.borderColor = '#db1e3e';
      editorRef.value.getToolbar().style.borderBottomColor = '#d0d0d0';
    } else {
      if (isHover) {
        editorRef.value.getEditor().style.borderColor = '#006f7e';
        editorRef.value.getToolbar().style.borderColor = '#006f7e';
        editorRef.value.getToolbar().style.borderBottomColor = '#d0d0d0';
      } else {
        editorRef.value.getEditor().style.borderColor = '#d0d0d0';
        editorRef.value.getToolbar().style.borderColor = '#d0d0d0';
      }
    }
  }
});

</script>

<template>
  <div
    class="quill_textarea_container"
    :class="{ highlighted: isFocus, fullwidth, 'has-error': errorMessage }"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeve"
  >
    <QuillEditor
      :ref="(ref) => (editorRef = ref)"
      :options="editorOptions"
      theme="snow"
      :content="inputValue"
      @update:content="onInput"
      contentType="delta"
      @focus="isFocus = true"
      @blur="isFocus = false"
      @ready="
        () => {
          if (editorRef) {
            editorRef.getToolbar().setAttribute('data-toolbarbefore', t(translationKey));
          }
        }
      "
    />
  </div>
  <Translate
    v-if="errorMessage"
    class="error-message"
    :value="errorMessage"
  />
</template>

<style lang="scss" scoped>
@use '../../../../scss/design-tokens/colors' as colors;

.quill_textarea_container {
  display: inline-block;
  width: fit-content;
  max-width: unset;
  border-radius: 0.2rem;

  &.fullwidth {
    width: 100%;
  }

  &.highlighted {
    outline: 0.1rem solid colors.$primary;
    border: 0;
  }

  &:hover {
    background-color: colors.$green--shadow;
    border-color: colors.$primary;
  }

  &.has-error {
    border-color: colors.$red;
    background-image: url('@/assets/Error.svg');
    background-repeat: no-repeat;
    background-position: calc(100% - 23px) 50%;
    &:hover,
    &:active,
    &.highlighted {
      outline: 0.1rem solid colors.$red;
    }

    + .error-message {
      display: block;
    }
  }
}

.number_count {
  margin-top: 0.8rem;
  text-align: right;
}
</style>
