<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import Option from './models/Option';
import DropdownArrow from '../icons/DropdownArrow.vue';
import { Colors } from '../base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import Icon, { IconType } from './Icon.vue';

const props = withDefaults(
  defineProps<{
    options?: Option[];
    label?: string;
    labelIcon?: IconType;
    labelClass?: string;
    fullwidth?: boolean;
    modelValue?: Maybe<string>;
    reverse?: boolean;
    startsOpen?: boolean;
    scrollbar?: boolean;
    arrowColor?: Colors;
    selectedOptionStyle?: boolean;
    errorMessage?: Maybe<string>;
  }>(),
  {
    options: () => [],
    fullwidth: false,
    reverse: false,
    labelClass: '',
    startsOpen: false,
    scrollbar: true,
    arrowColor: Colors.grey600,
  }
);

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const isOpen = ref(props.startsOpen);
const toggleOpen = () => {
  isOpen.value = !isOpen.value;
};
const close = () => {
  isOpen.value = false;
};

const localValue = ref<Maybe<string>>();
const selectOption = (index: number, name: string, value: string) => {
  localValue.value = value;
  emits('update:modelValue', localValue.value);
  close();
};
const selectedOption = computed<Maybe<Option>>(() => {
  if (!props.modelValue && !localValue.value) {
    return null;
  }

  return props.options.find(({ value }) => (localValue.value || props.modelValue) === value);
});
watch(
  () => props.modelValue,
  (newValue) => {
    localValue.value = newValue;
  }
);
</script>

<template>
  <div
    class="dropdown input_container"
    :class="{ active: !!selectedOption, open: isOpen, fullwidth, reverse, 'has-error': errorMessage }"
    :tabindex="0"
    @blur="close"
  >
    <div class="position-relative">
      <slot v-if="label !== undefined" name="label">
        <label @click="toggleOpen" class="text_label" :class="`${labelClass}`">
          <span v-if="labelIcon" class="icon-left">
            <Icon :icon="labelIcon" :options="{ color: Colors.primary, width: 22, height: 22 }" />
          </span>
          <Translate :value="label" />
        </label>
      </slot>
      <div class="arrow-container" :class="{ open: isOpen }" @click="toggleOpen">
        <DropdownArrow :heigth="24" :width="24" :color="arrowColor"></DropdownArrow>
      </div>
      <div :class="`select_input selected bold flex ${label ? '' : 'no-label'} ${selectedOption ? selectedOption.extraClass || '' : ''}`" @click="toggleOpen">
        <span v-if="selectedOption?.icon" class="icon-left">
          <Icon :icon="selectedOption.icon" />
        </span>
        <Translate v-if="selectedOption" :value="selectedOption.name" />
      </div>
      <div class="options w-100" :class="{ open: isOpen, scrollbar }">
        <slot>
          <div
            v-for="(option, index) in options"
            :key="index"
            @click="selectOption(index, option.name, option.value)"
            class="option"
            :class="`${option.extraClass ? option.extraClass : ''} ${option.name === selectedOption?.name && selectedOptionStyle ? 'selected-option' : ''}`"
          >
            <span v-if="option.icon" class="icon-left">
              <Icon :icon="option.icon" />
            </span>
            <Translate :value="option.name" />
          </div>
        </slot>
      </div>
    </div>
    <Translate v-if="errorMessage" class="error-message" :value="errorMessage" />
  </div>
</template>

<style lang="scss">
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/z-indicies' as zIndex;

.dropdown {
  cursor: pointer;
  position: relative;

  &.open {
    .select_input {
      border-color: colors.$primary;
      outline-color: colors.$primary;
    }
  }

  &.fullwidth {
    max-width: unset;
  }

  &.has-error {
    .select_input {
      border-color: colors.$red;
    }
    .error-message {
      display: block;
    }
  }
}
.options {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 1rem;
  margin-left: 0.1rem;
  background-color: white;
  cursor: default;
  box-shadow: drop-shadow(0px 24px 96px rgba(0, 111, 126, 0.1));
  z-index: zIndex.$z-index-dropdown-options;
  .option {
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 6rem;
    padding: 1.6rem;
    box-sizing: border-box;
    color: colors.$primary;

    &.selected-option {
      background-color: colors.$primary;
      color: colors.$white;
    }

    &:hover {
      background-color: colors.$primary--xlight;
      color: colors.$primary;
    }
  }
  &.scrollbar {
    overflow-y: scroll;
    max-height: 300px;
  }
}

.open {
  display: block;
}

.reverse {
  .options {
    top: unset;
    bottom: 8rem;
  }

  .arrow-container {
    transform: rotateX(180deg);

    &.open {
      transform: rotateX(360deg);
    }
  }
}

.arrow-container {
  position: absolute;
  top: calc(50% - 1.2rem);
  right: 1.6rem;
  height: 2.4rem;
  transition: all 0.3s;

  &.open {
    transform: rotateX(180deg);
  }
}
</style>
