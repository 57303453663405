<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    toggle?: boolean;
  }>(),
  {
    disabled: false,
    toggle: false,
  }
);

const emits = defineEmits<{
  (e: 'update:toggle', toggle: boolean): void;
}>();

const onToggle = () => {
  emits('update:toggle', !props.toggle);
};

const { t } = useI18n();
</script>
<template>
  <div class="flex">
    <Button
      :disabled="disabled"
      v-tooltip="{ content: t('issue.map.delete.tooltip'), theme: 'primary' }"
      @click="onToggle"
      :type="ButtonType.secondary"
    >
      <Icon
        icon="Delete"
        :options="{ color: Colors.primary, width: 24, height: 24 }"
        :fill="toggle ? Colors.primary : 'none'"
      />
    </Button>
  </div>
</template>
