<script lang="ts" setup>
import VectorSource from 'ol/source/Vector';
import ClusterSource, { Options } from 'ol/source/Cluster';
import { onMounted, provide, shallowRef } from 'vue';
import useVectorLayer from '@/features/map/composables/useVectorLayer';
import { mapVectorSourceKey } from '@/features/map/models/mapSymbols';

const props = withDefaults(
  defineProps<{
    options?: Options;
  }>(),
  {
    options: () => ({
      minDistance: 20,
      distance: 25,
    }),
  }
);

const getVectorLayer = useVectorLayer();

const vectorSource = shallowRef(new VectorSource());
provide(mapVectorSourceKey, () => vectorSource.value);

const clusterSource = new ClusterSource({
  source: vectorSource.value,
  ...props.options,
});

onMounted(() => {
  getVectorLayer().setSource(clusterSource);
});
</script>
<template>
  <slot />
</template>
