import useNavigation from '@/features/navigation/useNavigation';
import { issueYearOrderPair } from '../issueYearOrderPath';
import { IssueCardViewModel } from '../models';

export const usePushIssueProcessYearOrder = () => {
  const { pushActiveAreaRouteByName } = useNavigation();

  const pushIssueProcessYearOrder = (issue: IssueCardViewModel) => {
    const pair = issueYearOrderPair(issue);
    pushActiveAreaRouteByName('Process By Year', { ...pair });
  };

  return {
    pushIssueProcessYearOrder,
  };
};
