<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { FaqRoute } from '../FaqRoute';
import { FaqRouteName } from '../FaqRouteName';

const props = defineProps<{
  faqRoutes: FaqRoute[];
}>();

const emits = defineEmits<{
  (e: 'on-faq-route-click', routePathName: FaqRouteName): void;
}>();

const onFaqRouteClick = (route: FaqRoute) => {
  emits('on-faq-route-click', route.name);
};
</script>

<template>
  <div class="faq-nav-container">
    <ul class="faq-nav mb-1">
      <li
        class="nav"
        v-for="route in faqRoutes"
        :key="route.name"
        :class="{
          active: route.isActive,
        }"
        @click="() => onFaqRouteClick(route)"
      >
        <div class="nav-text">
          <div class="nav-name p--medium grey-600 bold"><Translate :t="route.titleKey" /></div>
        </div>
      </li>
    </ul>
    <ul class="faq-nav-bar">
      <li
        v-for="route in faqRoutes"
        class="nav-bar"
        :class="{ done: route.isActive }"
        :key="route.name"
      ></li>
    </ul>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as media;

.faq-nav-container {
  display: flex;
  flex-direction: column;
}

.faq-nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.faq-nav-bar {
  display: flex;
}

.nav-bar {
  flex: 1 1 auto;
  height: 0.4rem;
  background-color: colors.$grey-200;

  &.done {
    background-color: colors.$primary;
  }
}

.nav {
  display: flex;
  align-items: center;
  @include media.laptop() {
    flex-basis: 50%;
    flex-grow: 1;
  }
  gap: 0.8rem;
  position: relative;
  cursor: pointer;

  &.active {
    .nav-name {
      color: colors.$primary;
    }
  }
}

.nav-index {
  background-color: colors.$grey-200;
  color: colors.$grey-600;
}

.nav-text {
  display: none;
  justify-content: space-between;
  flex-direction: column;
  align-content: center;

  @include media.laptop() {
    display: flex;
  }
}
</style>
