import { defineStore } from 'pinia';
import { Ref, ref } from 'vue';
import { UpdatePhaseStepFieldInput } from './models/UpdatePhaseStepFieldInput';

export const useStepFieldInputsStore = defineStore('stepFieldInputs', () => {
  const stepFieldInputsByPhaseId: Ref<{ [key: string]: UpdatePhaseStepFieldInput }> = ref({});

  const inputByPhaseId = (phaseId: string) => {
    let input = stepFieldInputsByPhaseId.value[phaseId];

    if (!input) {
      input = {
        key: '',
        value: '',
        iterationCounter: 0,
        phaseStepType: null,
      };

      stepFieldInputsByPhaseId.value = {
        ...stepFieldInputsByPhaseId.value,
        [phaseId]: input,
      };
    }

    return input;
  };

  return {
    stepFieldInputsByPhaseId,

    inputByPhaseId,
  };
});
