<script lang="ts" setup>
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { IssuePhaseFileTypes, IssuePhaseStepEnum, IssueStepSpecificationInput, useGetPhaseStepDataQuery } from '@/generated/graphql';
import { computed, reactive, ref, watch } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import useActiveIssue from '../../composables/useActiveIssue';
import { IssueDetails } from '@/features/issues/models';
import useActivePhaseOperations from '@/features/issueProcess/composables/useActivePhaseOperations';
import { File as DomainFile } from '@/features/files/models';
import FileList from '@/features/files/components/FileList.vue';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import CommunicationPhaseHistoryGroup from './CommunicationPhaseHistoryGroup.vue';
import { UpdatePhaseStepFieldInput } from '@/features/issueProcess/models/UpdatePhaseStepFieldInput';
import { Delta } from '@vueup/vue-quill';
import RichTextEditor from '@/features/theme/base/richTextEditor/RichTextEditor.vue';
import useProcessObservers, { isProcessSaveArgs } from '../../composables/useProcessObservers';
import { ProcessOperation } from '../../composables/useProcessStore';
import { useStepFieldInputsStore } from '../../useStepFieldInputsStore';
import useAuthStore from '@/features/auth/useAuthStore';
import Button from '@/features/theme/base/Button.vue';
import useCommunicationStep from '../../composables/useCommunicationStep';

const props = defineProps<{
  activeIssue: IssueDetails;
  phaseId: string;
  phaseStepType: IssuePhaseStepEnum;
  fieldKey: string;
  garbageFiles: DomainFile[];
  fileType: IssuePhaseFileTypes;
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
  (event: 'onCommentDelete', stepType: IssuePhaseStepEnum, iterationCounter: number): void;
}>();

const activeIssue = computed(() => props.activeIssue);

const store = useStepFieldInputsStore();

const { isSLSStep, isComPhaseAdmin } = useCommunicationStep(activeIssue);

const phaseId = computed(() => props.phaseId);

const { selectedPhase, selectedStep } = useActiveIssue(activeIssue);
const { addFileToPhase, updatePhaseStepField, updateCommunicationPhase } = useActivePhaseOperations(activeIssue);

const { data, then } = useGetPhaseStepDataQuery({
  variables: {
    input: computed<IssueStepSpecificationInput>(() => {
      return {
        issuePhaseId: props.phaseId,
        stepType: selectedStep.value?.type,
      };
    }),
    phaseId: computed(() => props.phaseId),
  },
  requestPolicy: 'network-only',
});

then(() => {
  stepFieldInput.key = props.fieldKey;
  stepFieldInput.quillDelta = delta.value;
  stepFieldInput.iterationCounter = iterationCounter.value;
  stepFieldInput.phaseStepType = props.phaseStepType;
});

const onRTEUpdate = (delta: Maybe<Delta>) => {
  if (delta) {
    stepFieldInput.quillDelta = delta;
  }
};

const stepFieldInput = store.inputByPhaseId(phaseId.value);
stepFieldInput.phaseStepType = props.phaseStepType;

const phaseStepData = computed(() => {
  return data.value?.phase.stepData;
});

const iterationCounter = computed(() => {
  return phaseStepData.value?.currentStepData?.iterationCounter ?? 0;
});

watch(phaseStepData, (value, oldValue) => {
  if (value?.currentStepData?.iterationCounter !== oldValue?.currentStepData?.iterationCounter) {
    stepFieldInput.key = props.fieldKey;
    stepFieldInput.quillDelta = delta.value;
    stepFieldInput.iterationCounter = iterationCounter.value;
    stepFieldInput.phaseStepType = props.phaseStepType;
  }
});

const stepFiles = computed(() => {
  if (data.value?.phase.stepData?.currentStepData?.stepFiles != undefined) {
    return data.value.phase.stepData.currentStepData.stepFiles.map(x => x.data).filter(x => !props.garbageFiles.find(y => y.id == x.id));
  }
  return [];
});

const maybeDelta = ref<string | undefined>(phaseStepData.value?.currentStepData?.stepField?.richTextValue);

const inputValue = ref<string | undefined>(phaseStepData.value?.currentStepData?.stepField?.value);

const ops = computed<[]>(() => {
  let arr = [];
  if (maybeDelta.value) {
    arr = JSON.parse(maybeDelta.value);
  } else if (inputValue.value) {
    arr.push({ insert: inputValue.value });
  }
  return arr;
});

const delta = computed<Delta>(() => {
  return new Delta(ops.value);
});

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onCommentDelete = (stepType: IssuePhaseStepEnum, iterationCounter: number) => {
  emits('onCommentDelete', stepType, iterationCounter);
};

const onProcessSave = async (args: unknown) => {
  if (stepFieldInput.quillDelta?.length() && stepFieldInput.quillDelta?.length() > 1) {
    await updatePhaseStepField(selectedPhase.value?.id.toString(), {
      ...stepFieldInput,
    });
  }

  var updateSelectedStep = isProcessSaveArgs(args) ? args.updateSelectedStep : false;

  if (selectedStep.value) {
    await updateCommunicationPhase(selectedStep.value.type, updateSelectedStep);
  }
};

useProcessObservers({
  [ProcessOperation.Save]: [onProcessSave],
  [ProcessOperation.Close]: [onProcessSave],
});

const onFilesInput = (payload: Base64FilePayload[]) => {
  for (const item of payload) {
    if (selectedPhase.value) {
      addFileToPhase({
        phaseId: selectedPhase.value.id.toString(),
        data: item.base64,
        name: item.file.name,
        fileType: props.fileType,
        encoding: 1,
        iterationCounter: iterationCounter.value,
        stepType: props.phaseStepType,
      });
    } else {
      console.error("Can't upload if selected phase has no value!");
    }
  }
};
</script>

<template>
  <div v-if="selectedPhase?.state != 'closed' && (!isSLSStep || isComPhaseAdmin)">
    <LayoutFormGroup class="mb-4">
      <LayoutInput :span="3">
        <RichTextEditor
          translationKey="issue.phase.description"
          :model-value="stepFieldInput.quillDelta"
          @update:model-value="onRTEUpdate"
          fullwidth
        />
      </LayoutInput>
    </LayoutFormGroup>
    <LayoutFormGroup v-if="stepFiles.length > 0">
      <LayoutInput :span="3">
        <FileList
          :files="stepFiles"
          @delete="onFileDelete"
          editable
          deletable
        />
      </LayoutInput>
    </LayoutFormGroup>
    <LayoutFormGroup>
      <LayoutInput>
        <FileInputBase64 @files="onFilesInput" />
      </LayoutInput>
    </LayoutFormGroup>
    <LayoutFormGroup v-if="garbageFiles.length > 0">
      <template #explanation>
        <Translate t="issue.garbage_files" />
      </template>
      <LayoutInput :span="3">
        <FileList
          :files="garbageFiles"
          deletable
          @delete="onFileRestore"
        />
      </LayoutInput>
    </LayoutFormGroup>
  </div>
  <div v-else></div>
  <CommunicationPhaseHistoryGroup
    :active-issue="props.activeIssue"
    :phase-id="props.phaseId"
    :read-only="false"
    @on-file-delete="onFileDelete"
    @on-comment-delete="onCommentDelete"
  />
</template>
