<script setup lang="ts">
import { PillSize } from '../theme/base/models/PillProps';
import IssuePhasePill from '../issues/components/IssuePhasePill.vue';
import { IssueCardViewModel } from '../issues/models';
import Translate from '../translations/Translate.vue';
import useIssue from '../issues/composables/useIssue';
import { computed } from 'vue';
import IssueTags from '../issues/components/IssueTags.vue';
import { issueYearOrderPath } from '@/features/issues/issueYearOrderPath';

const props = defineProps<{
  issue: IssueCardViewModel;
}>();

const { issueNumberFormatted, issuePublishDateFormatted } = useIssue(computed(() => props.issue));
</script>

<template>
  <RouterLink :to="issueYearOrderPath(issue)">
    <div class="card green-shadow--s">
      <div class="card-phase-pill">
        <IssuePhasePill
          :issue="issue"
          :phase="issue.currentPhase"
          :pill-size="PillSize.small"
        ></IssuePhasePill>
      </div>
      <p class="card-issue-info text--small">
        <Translate t="issue.issue_no" />
        {{ issueNumberFormatted }}
        <span class="px-1">&bull;</span>
        <Translate t="issue.published" />
        {{ issuePublishDateFormatted }}
      </p>
      <div
        v-if="issue.title"
        class="flex"
      >
        <h1
          v-if="issue.title.length > 75"
          class="subtitle card-header"
        >
          {{ issue.title.slice(0, 75) }}&hellip;
        </h1>
        <h1
          v-else
          class="subtitle card-header"
        >
          {{ issue.title }}
        </h1>
      </div>
      <div class="card-pills-container">
        <IssueTags
          :issue="issue"
          :tag-limit="1"
          :size="PillSize.small"
        />
      </div>
    </div>
  </RouterLink>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/media-queries' as media-queries;

.card {
  background-color: color.$white;
  display: flex;
  flex-direction: column;
  border-radius: 0.4rem;
  padding: 2.8rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 100%;

  @include media-queries.laptop-down {
    padding: 2rem;
  }
}

.card:hover {
  background-color: color.$grey-100;
  box-shadow: 0px 1rem 2rem rgba(0, 65, 90, 0.3);
  transform: scale(1.02);
}

.card-phase-pill {
  margin-bottom: 2.4rem;

  @include media-queries.mobile {
    margin-bottom: 0.8rem;
  }
}

.card-issue-info {
  text-transform: uppercase;
  color: color.$primary;
  @include font.font-default-bold;
  margin-bottom: 2rem;

  @include media-queries.mobile {
    margin-bottom: 1.6rem;
  }
}

.card-header {
  margin-bottom: 2rem;

  @include media-queries.mobile {
    margin-bottom: 1.6rem;
  }
}

.card-pills-container {
  overflow: hidden;
  margin-top: auto;
}
</style>
