<script lang="ts" setup>
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import DataTable from '@/features/theme/dataTables';
import { DataTableConfig } from '@/features/theme/dataTables/dataTableTypes';
import Translate from '@/features/translations/Translate.vue';
import { StatutoryReviewer } from '@/generated/graphql';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  reviewers: StatutoryReviewer[];
  selected?: Maybe<string>;
}>();

const emits = defineEmits<{
  (e: 'select', reviewerId: string): void;
  (e: 'create'): void;
  (e: 'edit'): void;
  (e: 'delete'): void;
  (e: 'edit:categories'): void;
}>();

type StatutoryReviewerTableItem = StatutoryReviewer & { __typename: string };

const { t } = useI18n();

const statutoryReviewerDataTableConfig: Partial<DataTableConfig<StatutoryReviewerTableItem>> = {
  headers: {
    id: {
      hidden: true,
    },
    __typename: {
      hidden: true,
    },
    name: {
      formatter: 'base.label',
    },
    email: {
      formatter: 'base.email',
    },
    category: {
      formatter: 'base.category',
    },
  },
  data: {
    name: {
      className: 'text-primary bold',
    },
    email: {
      className: 'text-primary bold',
    },
    category: {
      className: 'bold',
    },
  },
  selectable: true,
};

const { findById } = useCollectionSearching();

const onReviewerSelect = (row: unknown) => {
  const sr = row as StatutoryReviewer;
  if (!!sr.id) {
    const reviewer = findById(props.reviewers, sr.id);
    if (reviewer) {
      emits('select', sr.id);
    }
  }
};
const activeReviewer = computed(() => {
  if (!props.selected) {
    return null;
  }

  return findById(props.reviewers, props.selected);
});

const onCreateClick = () => {
  emits('create');
};
const onEditClick = () => {
  emits('edit');
};
const onDeleteClick = () => {
  emits('delete');
};
const onEditCategoriesClick = () => {
  emits('edit:categories');
};
</script>
<template>
  <DataTable :dataset="reviewers" :config="statutoryReviewerDataTableConfig" @select="onReviewerSelect" :selected="activeReviewer">
    <template #widget-top-left>
      <Button @click="onCreateClick" :type="ButtonType.primary" v-tooltip="{ content: t('base.create'), theme: 'primary' }">
        <Icon icon="Plus" :options="{ width: 24, height: 24, color: Colors.white }" />
      </Button>
      <Button @click="onEditClick" :type="ButtonType.primary" v-tooltip="{ content: t('base.edit'), theme: 'primary' }" v-if="selected">
        <Icon icon="Edit" :options="{ width: 24, height: 24, color: Colors.white }" />
      </Button>
      <Button @click="onDeleteClick" :type="ButtonType.primary" v-tooltip="{ content: t('base.delete'), theme: 'primary' }" v-if="selected">
        <Icon icon="Delete" :options="{ width: 24, height: 24, color: Colors.white }" />
      </Button>
    </template>
    <template #widget-top-right>
      <Button @click="onEditCategoriesClick">
        <Translate t="statutory_reviewer.categories.edit" />
      </Button>
    </template>
  </DataTable>
</template>
