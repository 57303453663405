<script lang="ts" setup>
import usePhaseStepMapping from '@/features/issueProcess/composables/usePhaseStepMapping';
import useActiveIssue from '@/features/issueProcess/composables/useActiveIssue';
import { computed, watch, onMounted } from 'vue';
import useProcessTranslation from '../../composables/useProcessTranslation';
import { File as DomainFile } from '@/features/files/models';
import { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import { UpdatePhaseInput } from '../../models/UpdatePhaseInput';
import { IssueDetails } from '@/features/issues/models';
import { IssuePhaseStepEnum } from '@/generated/graphql';

const props = defineProps<{
  activeIssue: IssueDetails;
  ownFiles: DomainFile[];
  garbageFiles: DomainFile[];
  newFiles: DomainFile[];
  input: UpdatePhaseInput;
}>();

const { selectedPhase, selectedStep } = useActiveIssue(computed(() => props.activeIssue));
const { getPhaseStepComponent } = usePhaseStepMapping();
const { getPhaseStepKey } = useProcessTranslation();
const translationKey = computed(() => getPhaseStepKey(props.activeIssue.process, selectedPhase.value, selectedStep.value));

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFilesInput', payload: Base64FilePayload[], stepType?: IssuePhaseStepEnum): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
  (event: 'onNewFileCancel', file: DomainFile, index: number): void;
  (event: 'update:input', input: UpdatePhaseInput): void;
  (event: 'onCommentDelete', stepType: IssuePhaseStepEnum, iterationCounter: number): void;
}>();

onMounted(() => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
});

watch(
  () => selectedStep.value,
  (old, current) => {
    if (old?.id !== current?.id) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
);

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onFilesInput = (payload: Base64FilePayload[]) => {
  emits('onFilesInput', payload, selectedStep.value?.type);
};

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};

const onNewFileCancel = (file: DomainFile, index: number) => {
  emits('onNewFileCancel', file, index);
};

const onUpdateInput = (input: UpdatePhaseInput) => {
  emits('update:input', input);
};

const onCommentDelete = (stepType: IssuePhaseStepEnum, iterationCounter: number) => {
  emits('onCommentDelete', stepType, iterationCounter);
};
</script>
<template>
  <component
    :is="getPhaseStepComponent(selectedStep!.type)"
    v-bind="{
      step: selectedStep,
      phase: selectedPhase,
      issue: activeIssue,
      input,
      translationKey,
      ownFiles,
      garbageFiles,
      newFiles,
    }"
    @on-file-delete="onFileDelete"
    @on-files-input="onFilesInput"
    @on-file-restore="onFileRestore"
    @on-new-file-cancel="onNewFileCancel"
    @update:input="onUpdateInput"
    @on-comment-delete="onCommentDelete"
  />
</template>
