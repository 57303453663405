<script lang="ts" setup>
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import AuditLogsTable from './AuditLogsTable.vue';

const route = useRoute();
const issueId = computed<Maybe<string>>(() => {
  if (typeof route.params.issueId !== 'string') {
    return null;
  }
  return route.params.issueId;
});

if (!issueId.value) {
  throw new Error('404');
}
</script>
<template>
  <LayoutCentered>
    <AuditLogsTable v-if="issueId" :issue-id="issueId" />
  </LayoutCentered>
</template>
