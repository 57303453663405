import { computed, ComputedRef, Ref, unref } from 'vue';
import { IssuePhase, IssueReview, IssueReviewer } from '../models';

interface UseIssuePhaseReviews {
  requiredReviews: ComputedRef<IssueReview[]>;
  extraRequiredReviews: ComputedRef<IssueReview[]>;
  requiredReviewsCombined: ComputedRef<IssueReview[]>;

  requiredReviewersWithComment: ComputedRef<IssueReviewer[]>;
  requiredReviewersWithoutComment: ComputedRef<IssueReviewer[]>;
  extraRequiredReviewersWithComment: ComputedRef<IssueReviewer[]>;
  extraRequiredReviewersWithoutComment: ComputedRef<IssueReviewer[]>;

  requiredReviewersWithCommentCombined: ComputedRef<IssueReviewer[]>;
  requiredReviewersWithoutCommentCombined: ComputedRef<IssueReviewer[]>;
}

export default function useIssuePhaseReviews(phase: IssuePhase | Ref<IssuePhase>): UseIssuePhaseReviews {
  const requiredReviews = computed(() => unref(phase).reviews.filter((review) => review.statutoryReviewer));
  const extraRequiredReviews = computed(() => unref(phase).reviews.filter((review) => review.extraRequiredReviewer));
  const requiredReviewsCombined = computed(() => [...requiredReviews.value, ...extraRequiredReviews.value]);

  const requiredReviewersWithComment = computed(() => requiredReviews.value.map(({ statutoryReviewer }) => statutoryReviewer!));
  const extraRequiredReviewersWithComment = computed(() => extraRequiredReviews.value.map(({ extraRequiredReviewer }) => extraRequiredReviewer!));
  const requiredReviewersWithCommentCombined = computed(() => [...requiredReviewersWithComment.value, ...extraRequiredReviewersWithComment.value]);

  const requiredReviewersWithoutComment = computed(() =>
    unref(phase).requiredReviewers.filter((reviewer) => {
      return !requiredReviewersWithComment.value.find((reviewerWithComment) => reviewerWithComment?.id === reviewer.id);
    })
  );
  const extraRequiredReviewersWithoutComment = computed(() =>
    unref(phase).extraRequiredReviewers.filter((reviewer) => {
      return !extraRequiredReviewersWithComment.value.find((reviewerWithComment) => reviewerWithComment?.id === reviewer.id);
    })
  );
  const requiredReviewersWithoutCommentCombined = computed(() => [...requiredReviewersWithoutComment.value, ...extraRequiredReviewersWithoutComment.value]);

  return {
    requiredReviews,
    extraRequiredReviews,
    requiredReviewsCombined,

    requiredReviewersWithComment,
    extraRequiredReviewersWithComment,
    requiredReviewersWithCommentCombined,

    requiredReviewersWithoutComment,
    extraRequiredReviewersWithoutComment,
    requiredReviewersWithoutCommentCombined,
  };
}
