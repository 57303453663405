<script lang="ts" setup>
import Feature from '@/features/map/components/features/Feature.vue';
import { Issue, IssueDetails } from '@/features/issues/models';
import useIssueFeatures from '@/features/issues/composables/useIssueFeatures';
import { computed } from 'vue';
import useMap from '@/features/map/composables/useMap';
import { default as FeatureType } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { watch, ref } from 'vue';
import { featureCmpKey } from '../../issueGeometriesBlacklist';
import useVectorSource from '@/features/map/composables/useVectorSource';
import { FeatureDecorated } from '@/features/map/models/mapModels';

const props = defineProps<{
  issue: Issue | IssueDetails;
  additionalFeatures?: FeatureType<Geometry>[];
}>();
const getVectorSource = useVectorSource();
const { getIssueMarkerFeature, getIssueFeatures, getIssueFeatureExtent } = useIssueFeatures();

const issue = computed(() => props.issue);

const issueMarker = computed(() => {
  const value = issue.value;
  return getIssueMarkerFeature(value);
});
const issueFeatures = computed(() => [...(props.additionalFeatures || []), ...getIssueFeatures(props.issue)]);

const featuresToRemove = ref<Maybe<FeatureType<Geometry>[]>>();

watch(issueFeatures, (newVal, oldVal) => {
  // if we are removing a feature
  if (oldVal.length > newVal.length) {
    // get the feature that we want to remove and send it as a prop to Feature.vue
    var features = oldVal.filter((item) => !newVal.includes(item));
    featuresToRemove.value = features;
  }
});

const removeFeatureFromVectorSource = (feature: FeatureDecorated<Geometry>) => {
  const source = getVectorSource();
  const sourceFeatures = source.getFeatures();
  const removed = sourceFeatures.filter((sf) => {
    const sfKey = featureCmpKey(sf);
    const removedKey = featureCmpKey(feature);
    return sfKey === removedKey;
  });

  removed.forEach((r) => source.removeFeature(r));
};

const vectorSourceContainsFeature = (feature: FeatureDecorated<Geometry>) => {
  const source = getVectorSource();
  const sourceFeatures = source.getFeatures();
  return sourceFeatures.some((sf) => featureCmpKey(sf) === featureCmpKey(feature));
};

const getMap = useMap();
const onMarkerAdded = () => {
  // Temp timeout hack
  setTimeout(() => {
    getMap()
      .getView()
      .fit(getIssueFeatureExtent(props.issue), { duration: 1000, padding: Array(4).fill(100), maxZoom: 6 });
  }, 200);
};

defineExpose({ removeFeatureFromVectorSource, vectorSourceContainsFeature });
</script>
<template>
  <Feature
    @add="onMarkerAdded"
    v-if="issueMarker"
    :feature="issueMarker"
    key="-1"
  />
  <Feature
    :features-to-remove="featuresToRemove"
    v-for="(feature, index) in issueFeatures"
    :key="index"
    :feature="feature"
  />
</template>
