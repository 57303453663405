import { useGetCommunityGeographiesQuery } from '@/generated/graphql';
import { computed } from 'vue';

export default function useCommunityGeographies(ids: MaybeRef<string | string[]>) {
  const { data, error, fetching } = useGetCommunityGeographiesQuery({
    variables: {
      ids,
    },
  });

  const communities = computed(() => data.value?.communities || []);
  const communitiesError = computed(() => error.value);
  const communitiesLoading = computed(() => fetching.value);

  return {
    communities,
    communitiesError,
    communitiesLoading,
  };
}
