<script lang="ts" setup>
import XYZ, { Options } from 'ol/source/XYZ';
import { shallowRef } from 'vue';
import Source from './Source.vue';

const props = defineProps<{
  options: Options;
}>();

const source = shallowRef(new XYZ(props.options));
</script>
<template>
  <Source :source="source">
    <slot />
  </Source>
</template>
