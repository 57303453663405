<script lang="ts" setup>
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import Alert from '@/features/theme/base/Alert.vue';
import Article from '@/features/theme/base/article/Article.vue';
import ArticleHeader from '@/features/theme/base/article/ArticleHeader.vue';
import StatutoryReviewersTable from './StatutoryReviewersTable.vue';
import StatutoryReviewerCategoryForm from './StatutoryReviewerCategoryForm.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import { ref } from 'vue';
import Modal from '@/features/theme/base/Modal.vue';
import Translate from '@/features/translations/Translate.vue';
import { TranslationKey } from '@/features/translations/defaultMessages';
import useNavigationStore from '@/features/navigation/useNavigationStore';

import StatutoryReviewerForm from './StatutoryReviewerForm.vue';

import useStatutoryReviewersManagement, { ActiveForm } from '../composables/useStatutoryReviewersManagement';

const { activeReviewerId, reviewers, reviewerInput, saveChanges, activeForm, setActiveForm, deleteActiveReviewer } = useStatutoryReviewersManagement();

const shouldShowReviewerModal = ref(false);
const shouldShowCategoriesModal = ref(false);

const openReviewerModal = () => {
  clearAlert();
  shouldShowReviewerModal.value = true;
};

const onCreateClick = () => {
  setActiveForm(ActiveForm.CreateReviewer);
  openReviewerModal();
};

const onEditClick = () => {
  setActiveForm(ActiveForm.EditReviewer);
  openReviewerModal();
};

const onDeleteClick = () => {
  deleteActiveReviewer();
};

const onEditCategoriesClick = () => {
  clearAlert();
  shouldShowCategoriesModal.value = true;
};

const onReviewerSelected = (reviewerId: string) => {
  clearAlert();
  activeReviewerId.value = reviewerId;
};

const getAlertMessage = () => {
  switch (activeForm.value) {
    case ActiveForm.CreateReviewer:
      return 'statutory_reviewer.created';
    case ActiveForm.EditReviewer:
      return 'statutory_reviewer.updated';
    default:
      return 'statutory_reviewer.failed';
  }
};

const onSubmitReviewer = () => {
  clearAlert();
  saveChanges()
    .then((response) => {
      if (response?.data?.reviewer) {
        alertMessage.value = getAlertMessage();
        alertType.value = AlertType.success;
      } else {
        alertMessage.value = 'statutory_reviewer.failed';
        alertType.value = AlertType.danger;
      }
    })
    .catch((e) => {
      alertMessage.value = 'statutory_reviewer.failed';
      alertType.value = AlertType.danger;
    });
};

const onCategoriesFormAlert = (success: boolean, message: TranslationKey) => {
  alertMessage.value = message;
  alertType.value = success ? AlertType.success : AlertType.danger;
};

const alertType = ref<Maybe<AlertType>>();
const alertMessage = ref<Maybe<TranslationKey>>();
const clearAlert = () => {
  alertType.value = null;
  alertMessage.value = null;
};

const onReviewerModalClose = () => (shouldShowReviewerModal.value = false);
const onCategoriesModalClose = () => (shouldShowCategoriesModal.value = false);

const navStore = useNavigationStore();
navStore.setMainMenuMargin();
navStore.setMainFooterMargin();
</script>
<template>
  <LayoutCentered>
    <div class="mb-2">
      <Alert>
        <Translate :value="'statutory_reviewer.settings.title'" />
      </Alert>
    </div>
    <Modal :open="shouldShowReviewerModal" @close="onReviewerModalClose" scrollable>
      <Article article-size="medium">
        <ArticleHeader>
          <Translate t="statutory_reviewer.form_title" />
        </ArticleHeader>
        <StatutoryReviewerForm v-if="reviewerInput" class="mb-2" v-model="reviewerInput" @submit="onSubmitReviewer" />
        <Alert v-if="alertMessage && alertType" :alert-type="alertType">
          <Translate :t="alertMessage" />
        </Alert>
      </Article>
    </Modal>
    <Modal :open="shouldShowCategoriesModal" @close="onCategoriesModalClose" scrollable>
      <Article article-size="medium">
        <ArticleHeader>
          <Translate t="statutory_reviewer.categories" />
        </ArticleHeader>
        <StatutoryReviewerCategoryForm class="mb-2" @alert="onCategoriesFormAlert" />
        <Alert v-if="alertMessage && alertType" :alert-type="alertType">
          <Translate :t="alertMessage" />
        </Alert>
      </Article>
    </Modal>
    <StatutoryReviewersTable
      :selected="activeReviewerId"
      @select="onReviewerSelected"
      v-if="reviewers.length > 0"
      :reviewers="reviewers"
      @create="onCreateClick"
      @edit="onEditClick"
      @delete="onDeleteClick"
      @edit:categories="onEditCategoriesClick"
    />
  </LayoutCentered>
</template>
