import Map from 'ol/Map';
import { inject } from 'vue';
import { InjectionGetter, mapKey } from '@/features/map/models/mapSymbols';

export default function useMap(): InjectionGetter<Map> {
  const map = inject(mapKey);

  if (!map) {
    throw new Error('No map provided');
  }

  return map;
}
