import useNavigation from '@/features/navigation/useNavigation';
import { issueYearOrderPair } from '../issueYearOrderPath';
import { IssueCardViewModel } from '../models';

export const usePushIssueYearOrderRoute = () => {
  const { pushActiveAreaRouteByName } = useNavigation();

  const pushIssueYearOrderRoute = (issue: IssueCardViewModel) => {
    const pair = issueYearOrderPair(issue);
    pushActiveAreaRouteByName('Issue Details By Year', { ...pair });
  };

  return {
    pushIssueYearOrderRoute,
  };
};
