<script lang="ts" setup>
import InputFile from '@/features/theme/base/InputFile.vue';
import useBase64 from '@/features/files/composables/useBase64';
import Confirm from '@/features/theme/base/Confirm.vue';
import { ref } from 'vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import Translate from '@/features/translations/Translate.vue';
import Alert from '@/features/theme/base/Alert.vue';

export type Base64FilePayload = {
  base64: string;
  file: File;
  size: number;
};

defineProps<{
  id?: string;
  label?: string;
}>();

const emits = defineEmits<{
  (e: 'files', payload: Base64FilePayload[]): void;
}>();

const { fileToBase64String } = useBase64();
const showUploadError = ref(false);

const onFilesInput = (files: FileList) => {
  emitBase64Files(Array.from(files));
};

const emitBase64Files = async (files: File[]) => {
  const payload: Base64FilePayload[] = [];

  for (const file of files) {
    if (file.size < 73400320) {
      payload.push({ file, size: file.size, base64: await fileToBase64String(file) });
    } else {
      showUploadError.value = true;
    }
  }
  emits('files', payload);
};

</script>
<template>
  <InputFile
    :id="id"
    :label="label"
    @files="onFilesInput"
  />
  <div>
    <Confirm
      :active="showUploadError"
      type="warning"
      @ok="() => (showUploadError = false)"
    >
      <Alert
        class="alert"
        title="base.warning"
        :alert-type="AlertType.danger"
      >
        <Translate t="file.error.large_file" />
      </Alert>
    </Confirm>
  </div>
</template>
