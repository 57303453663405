import { onMounted, Ref, ref } from 'vue';

export default function useRichTextHoverEffect(hasError: Ref<boolean>) {
  const quillContainers = ref<HTMLElement[]>([]);

  onMounted(() => {
    quillContainers.value = [document.querySelector('.ql-container')!, document.querySelector('.ql-toolbar')!];
  });

  const addHoverEffect = () => {
    quillContainers.value.forEach((qc) => {
      qc.style.backgroundColor = '#f4fefc';
      if (!hasError.value) {
        qc.style.borderColor = '#006f7e';
      }
    });

    const toolbar = quillContainers.value.at(-1);
    if (toolbar) {
      toolbar.style.borderBottomColor = '#d0d0d0';
    }
  };

  const removeHoverEffect = () => {
    quillContainers.value.forEach((qc) => {
      qc.style.backgroundColor = '#fff';
      if (!hasError.value) {
        qc.style.borderColor = '#d0d0d0';
      }
    });
  };

  return {
    addHoverEffect,
    removeHoverEffect,
  };
}
