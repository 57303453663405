const enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  quinary = 'quinary',
  tertiaryIconOnly = 'tertiary-icon-only',
}

enum ButtonSize {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

const enum FileType {
  pdf = 'PDF',
  doc = 'DOC',
  mp4 = 'MP4',
  ppt = 'PPT',
  xlw = 'XLW',
  txt = 'TXT',
  shp = 'SHP',
}

export { ButtonType, ButtonSize, FileType };
