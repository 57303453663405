import { computed, ComputedRef } from 'vue';
import useBodyScrolling from '../composables/useBodyScrolling';
import useNavigationStore from './useNavigationStore';

interface UseSidebar {
  sidebarVisible: ComputedRef<boolean>;
  showSidebar(): void;
  hideSidebar(): void;
  toggleSidebar(): void;
}

export default function useSidebar(): UseSidebar {
  const store = useNavigationStore();
  const { enableBodyScroll, disableBodyScroll } = useBodyScrolling();

  const sidebarVisible = computed(() => store.sidebar);

  const showSidebar = () => {
    store.showSideBar();
    disableBodyScroll();
  };

  const hideSidebar = () => {
    store.showSideBar(false);
    enableBodyScroll();
  };

  const toggleSidebar = () => {
    if (sidebarVisible.value) {
      hideSidebar();
    } else {
      showSidebar();
    }
  };

  return {
    sidebarVisible,

    showSidebar,
    hideSidebar,
    toggleSidebar,
  };
}
