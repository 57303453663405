<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import { useI18n } from 'vue-i18n';

const emits = defineEmits<{
  (e: 'click'): void;
}>();

const onImportClick = () => {
  emits('click');
};

const { t } = useI18n();
</script>
<template>
  <Button v-tooltip="{ content: t('issue.map.import.tooltip'), theme: 'primary' }" :type="ButtonType.secondary" @click="onImportClick">
    <Icon icon="File" file-type="shp" :background-color="null" :options="{ color: Colors.primary, width: 24, height: 24 }" />
  </Button>
</template>
