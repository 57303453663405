<script lang="ts" setup>
import DataTableDataRow from './DataTableDataRow.vue';
import type { DataTableConfigStrict, DataTableDataRow as DataTableDataRowType } from './dataTableTypes';

const props = defineProps<{
  dataset: DataTableDataRowType[];
  config: DataTableConfigStrict<DataTableDataRowType>;
  selectedIndex?: number;
}>();

const emits = defineEmits<{
  (e: 'row:click', row: DataTableDataRowType, index: number): void;
}>();

const onRowClick = (row: DataTableDataRowType, index: number) => {
  emits('row:click', row, index);
};
</script>
<template>
  <tbody class="data-table__body">
    <DataTableDataRow
      v-for="(row, index) in dataset"
      :key="index"
      :row="row"
      :config="config"
      @click="() => onRowClick(row, index)"
      :selected="index === selectedIndex"
    />
  </tbody>
</template>
