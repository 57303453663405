<script lang="ts" setup></script>
<template>
  <ul class="tabs">
    <slot></slot>
  </ul>
</template>
<style lang="scss">
@use '@/scss/design-tokens/media-queries' as mq;

.tabs {
  width: 100%;
  display: flex;
  gap: 2.8rem;
  justify-content: flex-start;

  @include mq.laptop-down() {
    flex-flow: row wrap;
  }

  @include mq.desktop() {
    gap: 5.6rem;
  }
}
</style>
