import store from '@/features/store';
import { useNavigationStore } from './useNavigationStore';
import { RouteLocationNormalized } from 'vue-router';
import useBodyScrolling from '../composables/useBodyScrolling';

const setNavigationDefaultsMiddleware = (to: RouteLocationNormalized, from: RouteLocationNormalized): void => {
  useNavigationStore(store).resetDefaults();
  const { enableBodyScroll } = useBodyScrolling();
  enableBodyScroll();
};

export default setNavigationDefaultsMiddleware;
