import { PostalCode, useGetPostalCodesQuery } from '@/generated/graphql';
import { computed } from 'vue';
import groupBy from 'lodash-es/groupBy';
import forEach from 'lodash-es/forEach';
import { Dictionary } from 'lodash';
import { useI18n } from 'vue-i18n';

export default function usePostalCodes() {
  const { t } = useI18n();
  const { data: postalCodesData } = useGetPostalCodesQuery();
  const postalCodes = computed(() => postalCodesData.value?.postalCodes || []);
  
  const postalCodesGroupedByRegion = computed(() => {
    const groupedByRegion = groupBy(postalCodes.value, 'region');
    const groupedByRegionAndCity: Dictionary<Dictionary<PostalCode[]>> = {};
    forEach(groupedByRegion, (regionPostalCodes, regionKey) => {
      const groupedByCity = groupBy(regionPostalCodes, (o) => o.city || t('base.unsorted'));
      groupedByRegionAndCity[regionKey] = groupedByCity;
    });
    return groupedByRegionAndCity;
  });

  return {
    postalCodes,
    postalCodesGroupedByRegion,
  };
}
