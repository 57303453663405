import { StatutoryReviewerInput } from '@/generated/graphql';
import { computed, ref } from 'vue';

import {
  useGetAvailableStatutoryReviewersQuery,
  useCreateStatutoryReviewerMutation,
  useUpdateStatutoryReviewerMutation,
  useDeleteStatutoryReviewerMutation,
} from '@/generated/graphql';

export const enum ActiveForm {
  None = 'None',
  CreateReviewer = 'CreateReviewer',
  EditReviewer = 'EditReviewer',
}

export default function useStatutoryReviewersManagement() {
  const { data } = useGetAvailableStatutoryReviewersQuery();
  const { executeMutation: create } = useCreateStatutoryReviewerMutation();
  const { executeMutation: update } = useUpdateStatutoryReviewerMutation();
  const { executeMutation: deleteById } = useDeleteStatutoryReviewerMutation();

  const activeForm = ref<ActiveForm>(ActiveForm.None);

  const reviewers = computed(() => {
    return data.value?.reviewers || [];
  });

  const activeReviewerId = ref<Maybe<string>>(null);
  const reviewerInput = ref<Maybe<StatutoryReviewerInput>>(null);

  const setActiveForm = (active: ActiveForm) => {
    if (active === ActiveForm.CreateReviewer) {
      activeReviewerId.value = null;
      reviewerInput.value = {};
    } else if (active === ActiveForm.EditReviewer) {
      const active = reviewers.value.find((r) => r.id === activeReviewerId.value);
      if (active) {
        reviewerInput.value = {
          name: active.name,
          category: active.category,
          email: active.email,
        };
      }
    } else if (active === ActiveForm.None) {
      reviewerInput.value = null;
      activeReviewerId.value = null;
    }

    activeForm.value = active;
  };

  const saveChanges = async () => {
    if (!reviewerInput.value) {
      return;
    }

    switch (activeForm.value) {
      case ActiveForm.CreateReviewer:
        return await create({ input: reviewerInput.value });
      case ActiveForm.EditReviewer:
        if (activeReviewerId.value) {
          return await update({ id: activeReviewerId.value, input: reviewerInput.value });
        }
      default:
        break;
    }
  };

  const deleteActiveReviewer = async () => {
    if (activeReviewerId.value) {
      return await deleteById({ id: activeReviewerId.value });
    }
  };

  return {
    reviewers,
    activeReviewerId,
    reviewerInput,

    activeForm,
    setActiveForm,

    saveChanges,
    deleteActiveReviewer,
  };
}
