<script lang="ts" setup>
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import Article, { ArticleHeader, ArticleBody, ArticleTop } from '@/features/theme/base/article';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Alert from '@/features/theme/base/Alert.vue';
import Button from '@/features/theme/base/Button.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Icon from '@/features/theme/base/Icon.vue';
import Translate from '@/features/translations/Translate.vue';
import useDownloadFiles from '@/features/files/composables/useDownloadFiles';
import { computed } from 'vue';
import RichText from '@/features/theme/base/richTextEditor/RichText.vue';
import CommunicationPhaseHistoryGroup from '@/features/issueProcess/components/phaseSteps/CommunicationPhaseHistoryGroup.vue';
import IssueOverviewSteps from '@/features/issues/components/issueDetails/IssueOverviewSteps.vue';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
}>();
const { getArchivesFilesUrl } = useDownloadFiles();

const publisedPhaseFiles = computed(() => props.phase.files.filter(f => f.published) || []);
const downloadUrl = computed(() =>
  getArchivesFilesUrl({
    filePaths: publisedPhaseFiles.value.map(f => f.data.path),
    zipName: `${props.phase.title.replaceAll('.', '').trim()} Gögn`,
  })
);
</script>

<template>
  <Article>
    <ArticleTop v-if="$slots.top">
      <slot name="top" />
    </ArticleTop>
    <ArticleHeader>{{ phase.title }}</ArticleHeader>
    <ArticleBody>
      <Alert
        class="my-3"
        title="issue.description"
      >
        <RichText
          :opsJson="issue.richTextDescription"
          :rawText="issue.description"
        />
      </Alert>
    </ArticleBody>
    <div v-if="phase.isCommunicationPhase">
      <CommunicationPhaseHistoryGroup
        :active-issue="issue"
        :phase-id="phase.id.toString()"
        :readOnly="true"
      />
    </div>
    <div v-else>
      <IssueOverviewSteps
        :issue="issue"
        :phase="phase"
      />
      <ArticleHeader class="mb-2">
        <Translate
          t="issue.files"
          :interpolations="{ n: publisedPhaseFiles.length || 0 }"
        />
      </ArticleHeader>
      <p class="mb-2 regular">
        <Translate t="issue.files.help" />
      </p>
    </div>
    <Button
      tag="a"
      class="download-button"
      :href="downloadUrl"
      :type="ButtonType.secondary"
      :download="true"
    >
      <Translate
        t="issue.files.download"
        :interpolations="{ n: publisedPhaseFiles.length || 0 }"
      />
      <Icon
        icon="Download"
        :options="{ color: Colors.blue }"
        class="icon-right"
      />
    </Button>
  </Article>
</template>
<style lang="scss" scoped>
.download-button {
  width: fit-content;
}
</style>
