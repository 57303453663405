<script lang="ts" setup>
import Accordion from '@/features/components/Accordion.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Icon from '@/features/theme/base/Icon.vue';
import Translate from '@/features/translations/Translate.vue';
import { useInstructions } from '../composables/useInstructions';

const { categories } = useInstructions();
</script>

<template>
  <h1 class="h5 heading">
    <Translate t="faq.routes.instructions.title" />
  </h1>
  <p class="regular paragraph mt-2 mb-2">
    <Translate t="faq.routes.instructions.description" />
  </p>
  <Accordion
    v-for="category in categories"
    :key="category.id"
    :animated="false"
    :animate-initially="false"
    :show-body="true"
  >
    <template #default="{ accordionToggle, accordionBodyVisible }">
      <div
        @click="accordionToggle"
        class="flex accordion-header cursor-pointer"
        :class="{ 'pb-1': !accordionBodyVisible }"
      >
        <p class="p--large">{{ category.title }}</p>
        <Icon
          class="icon"
          icon="DropdownArrow"
          :options="{ width: 24, height: 24, color: Colors.grey600 }"
          :class="{ 'rotate-180': accordionBodyVisible }"
        />
      </div>
    </template>
    <template #body>
      <ul class="my-2 pl-4">
        <li>
          <a
            class="mb-2 link"
            v-for="instruction in category.instructions"
            :key="instruction.id"
            :href="instruction.href"
            target="_blank"
          >
            {{ instruction.title }}
          </a>
        </li>
      </ul>
    </template>
  </Accordion>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;
@use '@/scss/design-tokens/colors' as colors;

.heading {
  color: colors.$primary;
}

.link {
  color: colors.$primary;
  display: block;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
}

.icon {
  transition: all 0.3s;

  &.rotate-180 {
    transform: rotateX(180deg);
  }
}
</style>
