import { IssuePhaseFileTypes, IssuePhaseStepEnum } from '@/generated/graphql';
import { IssuePhase, IssueStep } from '@/features/issues/models';
import { computed, unref } from 'vue';
import groupBy from 'lodash-es/groupBy';

function mapStepType(stepType: Maybe<IssuePhaseStepEnum>): IssuePhaseFileTypes {
  switch (stepType) {
    case IssuePhaseStepEnum.NidurstadaUmsagnarferlis:
      return IssuePhaseFileTypes.Vidbrogd;
    case IssuePhaseStepEnum.StadfestingSkipulagsstofnunar:
      return IssuePhaseFileTypes.Afgreidsla;
    case IssuePhaseStepEnum.UmsognSkipulagsstofnunar:
      return IssuePhaseFileTypes.Umsogn;
    default:
      return IssuePhaseFileTypes.Almennt;
  }
}

/**
 * Using the logic in phase operations to know which files belong to which step.
 *
 * @see useActivePhaseOperations
 */
export default function useIssuePhaseStepFiles(issuePhase: MaybeRef<IssuePhase>, issuePhaseStep: MaybeRef<IssueStep>) {
  const issuePhaseFiles = computed(() => groupBy(unref(issuePhase).files, 'type'));
  return computed(() => (issuePhaseFiles.value[mapStepType(unref(issuePhaseStep).type)] || []).map(ipf => ({ ...ipf.data, ...ipf })));
}
