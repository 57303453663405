<script lang="ts" setup></script>
<template>
  <div class="layout-with-aside container--medium grid">
    <div class="layout__content">
      <slot />
    </div>
    <div class="layout__aside">
      <slot name="aside" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.layout-with-aside {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  .layout__content {
    order: 2;
  }

  @include mq.laptop() {
    display: grid;
    gap: 3.2rem;

    .layout__content {
      grid-column: 1 / span 9;
      order: 1;
    }

    .layout__aside {
      grid-column: 10 / span 3;
      order: 2;
    }
  }
}
</style>
