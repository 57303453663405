import orderBy from 'lodash-es/orderBy';

type SortOrder = 'asc' | 'desc';
type SortableByOrder = { order: number };

interface UseCollectionSorting {
  sortBy<T>(items: T[], field: keyof T, order?: SortOrder): T[];
  sortByOrder<T extends SortableByOrder>(items: T[]): T[];
}

export default function useCollectionSorting(): UseCollectionSorting {
  const sortByOrder = <T extends SortableByOrder>(items: T[]) => {
    const ordered = [...items];
    ordered.sort((a, b) => a.order - b.order);
    return ordered;
  };

  const sortBy = <T>(items: T[], field: keyof T, sortOrder: SortOrder = 'desc') => {
    return orderBy(items, field, sortOrder);
  };

  return { sortByOrder, sortBy };
}
