import { computed, ComputedRef, unref } from 'vue';
import { IssueDetails, IssuePhase, IssueStep } from '@/features/issues/models';
import useProcessStore from './useProcessStore';
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import useCollectionSorting from '@/features/composables/useCollectionSorting';
import { IssuePhaseStepEnum } from '@/generated/graphql';

interface UseActiveIssue {
  activeIssuePhases: ComputedRef<IssuePhase[]>;
  activePhase: ComputedRef<Maybe<IssuePhase>>;
  activePhaseOrder: ComputedRef<number>;
  activeIssuePhasesOrdered: ComputedRef<IssuePhase[]>;
  nextActivePhase: ComputedRef<Maybe<IssuePhase>>;
  prevActivePhase: ComputedRef<Maybe<IssuePhase>>;
  lastActivePhase: ComputedRef<Maybe<IssuePhase>>;

  activePhaseSteps: ComputedRef<IssueStep[]>;
  activePhaseStepsOrdered: ComputedRef<IssueStep[]>;
  activePhaseActiveStep: ComputedRef<Maybe<IssueStep>>;
  activePhaseNextStep: ComputedRef<Maybe<IssueStep>>;
  activePhasePrevStep: ComputedRef<Maybe<IssueStep>>;
  activePhaseLastStep: ComputedRef<Maybe<IssueStep>>;

  selectPhase: (phase: Maybe<ID>) => Maybe<ID>;
  selectedPhase: ComputedRef<Maybe<IssuePhase>>;
  selectedPhaseSteps: ComputedRef<IssueStep[]>;
  basicInfoPhaseSelected: ComputedRef<boolean>;
  activePhaseSelected: ComputedRef<boolean>;
  lastPhaseOrder: ComputedRef<number>;
  resetSelectedPhase: () => void;

  selectedPhaseStepsOrdered: ComputedRef<IssueStep[]>;
  selectStep: (step: Maybe<ID>) => Maybe<ID>;
  selectedPhaseActiveStep: ComputedRef<Maybe<IssueStep>>;
  selectedPhaseLastStep: ComputedRef<Maybe<IssueStep>>;
  selectedPhaseNextStep: ComputedRef<Maybe<IssueStep>>;
  selectedPhasePrevStep: ComputedRef<Maybe<IssueStep>>;
  selectedStep: ComputedRef<Maybe<IssueStep>>;
  selectedStepOrder: ComputedRef<number>;
  lastStepOrder: ComputedRef<number>;

  isNextStepReviews: ComputedRef<boolean>;
}

export default function useActiveIssue(activeIssue: MaybeRef<IssueDetails | undefined>): UseActiveIssue {
  // Reactive phases
  const activePhase = computed(() => {
    const issue = unref(activeIssue);
    if (issue === undefined) {
      return null;
    }
    return issue.currentPhase;
  });
  const activeIssuePhases = computed(() => {
    const issue = unref(activeIssue);
    if (issue === undefined) {
      return [];
    }
    return issue.phases;
  });
  const activePhaseOrder = computed<number>(() => activePhase.value?.order || 0);
  const activeIssuePhasesOrdered = computed<IssuePhase[]>(() => sortByOrder(activeIssuePhases.value));
  const nextActivePhase = computed<Maybe<IssuePhase>>(() => {
    if (!activePhase.value && activeIssuePhasesOrdered.value.length > 0) {
      return activeIssuePhasesOrdered.value[0];
    }
    return findNextById(activeIssuePhasesOrdered.value, activePhase.value?.id);
  });
  const prevActivePhase = computed<Maybe<IssuePhase>>(() => findPrevById(activeIssuePhasesOrdered.value, activePhase.value?.id));
  const lastActivePhase = computed<Maybe<IssuePhase>>(() => activeIssuePhasesOrdered.value.at(-1) || null);

  // Collection imports
  const { findNextById, findPrevById } = useCollectionSearching();
  const { sortByOrder } = useCollectionSorting();

  // Reactive phase steps
  const activePhaseSteps = computed<IssueStep[]>(() => activePhase.value?.steps || []);
  const activePhaseStepsOrdered = computed<IssueStep[]>(() => sortByOrder(activePhaseSteps.value));
  const activePhaseActiveStep = computed<Maybe<IssueStep>>(() => activePhase.value?.currentStep || null);
  const activePhaseNextStep = computed<Maybe<IssueStep>>(() => findNextById(activePhaseStepsOrdered.value, activePhaseActiveStep.value?.id));
  const activePhasePrevStep = computed<Maybe<IssueStep>>(() => findPrevById(activePhaseStepsOrdered.value, activePhaseActiveStep.value?.id));
  const activePhaseLastStep = computed<Maybe<IssueStep>>(() => activePhaseStepsOrdered.value.at(-1) || null);

  // Pinia selected phase
  const store = useProcessStore();
  const selectPhase = (phase: Maybe<ID>) => {
    if (phase === store.selectedPhaseId) {
      return phase;
    }

    store.setSelectedStepId(null);
    return store.setSelectedPhaseId(phase);
  };
  function resetSelectedPhase() {
    selectPhase(undefined);
  }
  const selectedPhase = computed<Maybe<IssuePhase>>(() => activeIssuePhases.value.find(({ id }) => id === store.selectedPhaseId) || activePhase.value);
  const selectedPhaseSteps = computed<IssueStep[]>(() => selectedPhase.value?.steps || []);
  const basicInfoPhaseSelected = computed<boolean>(() => activePhase.value === null || !selectedPhase.value || store.selectedPhaseId === null);
  const activePhaseSelected = computed<boolean>(() => activePhase.value?.id === selectedPhase.value?.id);
  const lastPhaseOrder = computed<number>(() => activeIssuePhases.value.at(-1)?.order || 0);

  // Pinia selected phase step
  const selectedPhaseActiveStep = computed<Maybe<IssueStep>>(() => selectedPhase.value?.currentStep || null);
  const selectedPhaseStepsOrdered = computed<IssueStep[]>(() => sortByOrder(selectedPhaseSteps.value));
  const selectStep = (step: Maybe<ID>) => store.setSelectedStepId(step);
  const selectedStep = computed<Maybe<IssueStep>>(
    () => selectedPhaseSteps.value.find(({ id }) => id === store.selectedStepId) || selectedPhaseActiveStep.value
  );
  const selectedStepOrder = computed<number>(() => selectedStep.value?.order || 0);
  const selectedPhaseLastStep = computed<Maybe<IssueStep>>(() => selectedPhaseStepsOrdered.value.at(-1) || null);
  const lastStepOrder = computed<number>(() => selectedPhaseLastStep.value?.order || 0);
  const selectedPhaseNextStep = computed<Maybe<IssueStep>>(() => findNextById(selectedPhaseStepsOrdered.value, selectedStep.value?.id));
  const selectedPhasePrevStep = computed<Maybe<IssueStep>>(() => findPrevById(selectedPhaseStepsOrdered.value, selectedStep.value?.id));

  const isNextStepReviews = computed(() => {
    return selectedPhaseNextStep.value?.type === IssuePhaseStepEnum.UmsjonUmsagna;
  });

  return {
    activeIssuePhases,
    activePhase,
    activeIssuePhasesOrdered,
    activePhaseOrder,
    nextActivePhase,
    lastActivePhase,
    prevActivePhase,

    activePhaseSteps,
    activePhaseStepsOrdered,
    activePhaseActiveStep,
    activePhaseNextStep,
    activePhasePrevStep,
    activePhaseLastStep,

    selectPhase,
    selectedPhase,
    selectedPhaseSteps,
    basicInfoPhaseSelected,
    activePhaseSelected,
    lastPhaseOrder,
    resetSelectedPhase,

    selectStep,
    selectedPhaseStepsOrdered,
    selectedPhaseActiveStep,
    selectedPhaseLastStep,
    selectedPhaseNextStep,
    selectedPhasePrevStep,
    selectedStep,
    selectedStepOrder,
    lastStepOrder,

    isNextStepReviews,
  };
}
