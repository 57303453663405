<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    id: string;
    name?: string;
    disabled?: boolean;
    modelValue?: Maybe<string>;
    value: string;
  }>(),
  {
    name: '',
    disabled: false,
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();
const onInputChange = () => {
  emit('update:modelValue', props.value);
};
</script>

<template>
  <label class="radio-button" :for="id" :class="{ disabled }">
    <input type="radio" :id="id" :name="name" :value="value" :checked="!!modelValue && modelValue === value" @change="onInputChange" :disabled="disabled" />
    <span class="radio-indicator" :class="{ disabled }"></span>
    <span class="radio-label" :class="{ disabled }"><slot></slot></span>
  </label>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/fonts' as font;

.radio-button {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  user-select: none;
  padding-top: 3px;
  &.disabled {
    cursor: no-drop;
  }
}

.radio-button:hover {
  color: color.$blue-lagoon;
  .radio-indicator {
    border: solid 2px color.$blue-lagoon;
    &.disabled {
      border: solid 2px color.$grey-200;
    }
  }
}

.radio-button input:checked ~ .radio-indicator {
  background-color: color.$white;
  border: solid 2px color.$blue-lagoon;
}

.radio-button input:checked ~ .radio-label {
  color: color.$blue-lagoon !important;
  @include font.bold-font-style;
}

/* Custom dot/circle */
.radio-button .radio-indicator:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 25px;
  background: color.$blue-lagoon;
}

/* Show dot/circle when checked */
.radio-button input:checked ~ .radio-indicator:after {
  display: block;
}

/* Custom radio button */
.radio-indicator {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: color.$white;
  border-radius: 25px;
  border: solid 2px color.$grey-700;
  &.disabled {
    border: solid 2px color.$grey-200;
  }
}

/* Create dot/circle (hide when not checked) */
.radio-indicator:after {
  content: '';
  position: absolute;
  display: none;
}
.radio-label {
  &.disabled {
    color: color.$grey-600;
  }
}
</style>
