import { Issue, IssueProcessCategoryEnum, Route, UserRoleType } from '@/generated/graphql';
import { IssueDetails, IssueTableViewModel } from '../issues/models';
import useAuthStore from './useAuthStore';
import { User } from './models';

export type IssueAccess = {
  id: string;
  delegationId?: string | null | undefined;
  process: { category?: { type: IssueProcessCategoryEnum } | null };
};

export enum Resources {
  Issue = 'issue',
}

export enum CRUD {
  All = 'all',
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export function useAccessControl() {
  const authStore = useAuthStore();

  function canAccessResource(resource: Resources, op: CRUD) {
    if (authStore.isCommunity || authStore.isAdmin || authStore.isUmhverfismatssvidsEmployee) {
      return true;
    }

    return false;
  }

  function canEditIssue(issue: Issue | IssueDetails | IssueAccess | IssueTableViewModel): boolean {
    if (!issue) {
      return false;
    }

    if (authStore.isAdmin) {
      return true;
    }

    if (authStore.isSlsEmployee) {
      const categoryType = issue?.process?.category?.type;
      if (!categoryType) {
        return false;
      }

      if (authStore.activeRole === UserRoleType.SlsEmployeeAdalskipulagssvid || authStore.activeRole === UserRoleType.SlsEmployeeDeiliskipulagssvid) {
        return (
          categoryType === IssueProcessCategoryEnum.Adalskipulag ||
          categoryType === IssueProcessCategoryEnum.Svaedisskipulag ||
          categoryType === IssueProcessCategoryEnum.Deiliskipulag
        );
      } else if (authStore.activeRole === UserRoleType.SlsEmployeeUmhverfismatssvid) {
        return categoryType === IssueProcessCategoryEnum.MatAUmhverfisahrifum;
      } else {
        return false;
      }
    }

    const delegationId = issue?.delegationId;
    const ticket = authStore.activeDelegation;
    if (authStore.isCommunity && delegationId === ticket?.delegation?.id) {
      if (authStore.activeRole === UserRoleType.DelegationConsultant) {
        return !!ticket?.issueIds?.includes(issue.id.toString());
      } else {
        return true;
      }
    }

    return false;
  }

  function canUserEditIssue(issue: Issue | IssueDetails | IssueAccess | IssueTableViewModel, user: User): boolean {
    if (user.role == UserRoleType.Admin) {
      return true;
    }
    if (authStore.isUserSlsEmployee(user)) {
      return true;
    }

    const delegationId = issue?.delegationId;
    
    if (user.delegations.find(d => d.delegation?.id == delegationId)) {
      return true;
    }
    return false;
  }

  const canAccessRoute = (route: Route) => {
    return route.requiredRoles.length === 0 || route.requiredRoles.includes(authStore.activeRole || '');
  };

  return {
    canAccessResource,
    canAccessRoute,
    canEditIssue,
    canUserEditIssue,
  };
}
