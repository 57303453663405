<script lang="ts" setup>
import useMap from '@/features/map/composables/useMap';
import Overlay, { Options } from 'ol/Overlay';
import { onMounted, onUnmounted, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    options?: Options;
  }>(),
  {
    options: () => ({}),
  }
);

const getMap = useMap();

let overlay = new Overlay(props.options);
const overlayRef = ref<HTMLElement>();

onMounted(() => {
  if (overlayRef.value) {
    overlay.setElement(overlayRef.value);
    getMap().addOverlay(overlay);
  }
});

onUnmounted(() => {
  if (overlayRef.value) {
    getMap().removeOverlay(overlay);
  }
});

watch(
  () => props.options,
  (newValue, oldValue) => {
    if (overlayRef.value) {
      getMap().removeOverlay(overlay);
      overlay = new Overlay(newValue);
      overlay.setElement(overlayRef.value);
      getMap().addOverlay(overlay);
    }
  }
);
</script>
<template>
  <div ref="overlayRef" class="overlay">
    <slot />
  </div>
</template>
