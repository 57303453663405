import Map from 'ol/Map';
import Tile from 'ol/layer/Tile';
import { InjectionKey } from 'vue';
import TileSource from 'ol/source/Tile';
import Vector from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Layer from 'ol/layer/Layer';
import OverviewMap from 'ol/control/OverviewMap';

export type InjectionGetter<T> = () => T;
export type MapInjectionKey<T> = InjectionKey<InjectionGetter<T>>;

export const mapKey: MapInjectionKey<Map> = Symbol('Map');

export const mapLayerKey: MapInjectionKey<Layer> = Symbol('Layer');
export const mapTileLayerKey: MapInjectionKey<Tile<TileSource>> = Symbol('TileLayer');
export const mapVectorLayerKey: MapInjectionKey<Vector<VectorSource>> = Symbol('VectorLayer');

export const mapVectorSourceKey: MapInjectionKey<VectorSource> = Symbol('VectorSource');

export const mapOverviewKey: MapInjectionKey<OverviewMap> = Symbol('OverviewMap');
