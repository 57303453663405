<script lang="ts" setup>
import { LoftmyndirCapability } from '@/features/loftmyndir/models';
import LayerTile from '@/features/map/components/layers/LayerTile.vue';
import SourceOSM from '@/features/map/components/sources/SourceOSM.vue';
import LoftmyndirLayer from '@/features/loftmyndir/LoftmyndirLayer.vue';
import { LayerZIndex } from '@/features/issues/models/issueMaps';
import Layer from 'ol/layer/Layer';

defineProps<{
  loftmyndir: Maybe<LoftmyndirCapability>;
}>();

const emits = defineEmits<{
  (e: 'change', layer: Layer): void;
}>();

const onLayerMounted = (layer: Layer) => {
  emits('change', layer);
};
</script>

<template>
  <LayerTile v-if="!loftmyndir" :options="{ zIndex: LayerZIndex.Raster }" @mounted="onLayerMounted">
    <SourceOSM />
  </LayerTile>
  <LoftmyndirLayer v-else :options="{ zIndex: LayerZIndex.Raster }" :capability="loftmyndir" @mounted="onLayerMounted" />
</template>
