<script lang="ts" setup>
import Accordion from '@/features/components/Accordion.vue';
import useIssueTags from '@/features/issues/composables/useIssueTags';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import Icon from '@/features/theme/base/Icon.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
  }>(),
  {
    modelValue: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

const { tagsByType } = useIssueTags();

const isSelected = (tag: string): boolean => {
  return props.modelValue.includes(tag);
};

const updateSelected = (tag: string, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, tag]);
  } else {
    const idx = props.modelValue.findIndex((t) => t === tag);
    if (idx !== -1) {
      emit('update:modelValue', [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)]);
    }
  }
};
</script>
<template>
  <LayoutFormGroup title="monitoring.header.tags" fullwidth>
    <LayoutInput>
      <Accordion v-for="(tags, type) in tagsByType" :key="type">
        <template #default="{ accordionToggle, accordionBodyVisible }">
          <div @click="accordionToggle" class="flex accordion-header cursor-pointer" :class="{ 'pb-1': !accordionBodyVisible }">
            <h4 class="p--large"><Translate :value="`tags.type.${type}`" /></h4>
            <Icon
              class="icon"
              icon="DropdownArrow"
              :options="{ width: 24, height: 24, color: Colors.grey600 }"
              :class="{ 'rotate-180': accordionBodyVisible }"
            />
          </div>
        </template>
        <template #body>
          <ul class="my-1">
            <li class="mb-2" v-for="tag in tags" :key="tag.value">
              <Checkbox :model-value="isSelected(tag.value)" @update:model-value="(val) => updateSelected(tag.value, val)" :id="`tag-${tag.value}`">
                {{ tag.value }}
              </Checkbox>
            </li>
          </ul>
        </template>
      </Accordion>
    </LayoutInput>
  </LayoutFormGroup>
</template>
<style lang="scss" scoped>
.accordion-header {
  display: flex;
  justify-content: space-between;
}

.icon {
  transition: all 0.3s;

  &.rotate-180 {
    transform: rotateX(180deg);
  }
}
</style>
