<script lang="ts" setup></script>
<template>
  <div class="flex article-top">
    <slot />
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.article-top {
  margin-bottom: 3.2rem;
  align-items: center;
  justify-content: space-between;

  @include mq.mobile {
    align-items: revert;
  }
}
</style>
