<script lang="ts" setup>
import LayerVector from '@/features/map/components/layers/LayerVector.vue';
import { LayerZIndex } from '@/features/issues/models/issueMaps';
import SourceVector from '@/features/map/components/sources/SourceVector.vue';
import Feature from '@/features/map/components/features/Feature.vue';
import { default as FeatureType } from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { ref } from 'vue';

const props = defineProps<{
  features: FeatureType<Geometry>[];
}>();

const featuresToRemove = ref<Maybe<FeatureType<Geometry>[]>>();
</script>
<template>
  <LayerVector :options="{ zIndex: LayerZIndex.Top }">
    <SourceVector>
      <Feature :features-to-remove="featuresToRemove" v-for="(feature, index) in features" :key="index" :feature="feature" />
    </SourceVector>
  </LayerVector>
</template>
