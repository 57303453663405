import { RelatedIssueViewModel } from './models';

export interface IssueYearOrderPair {
  issueYear: string;
  issueYearOrder: string;
}

export const issueYearOrderPair = (issue: RelatedIssueViewModel) => {
  return {
    issueYear: issue.createdDate.slice(0, 4) || '',
    issueYearOrder: issue.issueYearOrder || '',
  };
};

export const issueYearOrderPath = (issue: RelatedIssueViewModel) => {
  const { issueYear, issueYearOrder } = issueYearOrderPair(issue);
  return `/issues/${issueYear}/${issueYearOrder}`;
};

export const issueProcessYearOrderPath = (issue: RelatedIssueViewModel) => {
  const { issueYear, issueYearOrder } = issueYearOrderPair(issue);
  return `/issues/${issueYear}/${issueYearOrder}/process`;
};
