<script lang="ts" setup>
import { Issue } from '@/features/issues/models';
import Button from '@/features/theme/base/Button.vue';
import Arrow from '@/features/theme/icons/Arrow.vue';
import DirectionType from '@/features/theme/icons/models/DirectionType';
import IssuePhasePill from '@/features/issues/components/IssuePhasePill.vue';
import Card from '@/features/theme/base/Card.vue';
import { PillSize } from '@/features/theme/base/models/PillProps';
import Overlay from '@/features/map/components/Overlay.vue';
import { Coordinate } from 'ol/coordinate';
import useNavigation from '@/features/navigation/useNavigation';
import Translate from '@/features/translations/Translate.vue';
import useIssue from '../../composables/useIssue';
import { computed } from 'vue';
import IssueTags from '../IssueTags.vue';
import { issueYearOrderPath } from '@/features/issues/issueYearOrderPath';

const props = defineProps<{
  issue: Issue;
  position: Coordinate;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const onCardClose = () => {
  emits('close');
};

const currentPath = computed(() => {
  return window.location.pathname;
});

const { issueNumberFormatted, issuePublishDateFormatted } = useIssue(computed(() => props.issue));
</script>

<template>
  <Overlay
    class="black-shadow"
    :options="{ position, positioning: 'bottom-center', offset: [0, -20] }"
  >
    <Card
      size="small"
      closable
      @close="onCardClose"
    >
      <template #header>
        <div class="card-title">
          <IssuePhasePill
            :pill-size="PillSize.small"
            :issue="issue"
            :icon-height="16"
            :icon-width="16"
          />
        </div>
      </template>
      <div class="card-description">
        <div class="color-primary eyebrow eyebrow--small bold issue-date text-uppercase">
          <Translate t="issue.issue_no" />
          {{ issueNumberFormatted }}
          <span>•&nbsp;</span>
          <span>
            <Translate t="issue.published" />
            {{ issuePublishDateFormatted }}
          </span>
        </div>
        <div class="issue-title bold">
          {{ issue.title || '[Heiti máls]' }}
        </div>
        <IssueTags
          :issue="issue"
          :tag-limit="2"
        />
      </div>
    </Card>
    <RouterLink :to="issueYearOrderPath(issue)">
      <Button class="w-100 no-outline">
        <Translate t="issue.link" />
        <Arrow
          :direction="DirectionType.right"
          :heigth="24"
          :width="24"
          :color="'#fff'"
        ></Arrow>
      </Button>
    </RouterLink>
  </Overlay>
</template>

<style lang="scss" scoped>
.card-descripton {
  padding: 2.4rem;
}

.issue-date {
  margin-bottom: 0.8rem;
}

.issue-title {
  font-size: 1.8rem;
  margin-bottom: 2.4rem;
}

.card-tags {
  margin-bottom: 0.4rem;
  gap: 2rem;
}
</style>
