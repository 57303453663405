<script lang="ts" setup>
import Pills from '@/features/theme/base/Pills.vue';
import Edit from '@/features/theme/icons/Edit.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Results from '@/features/theme/icons/Results.vue';
import Time from '@/features/theme/icons/Time.vue';
import { PillSize } from '@/features/theme/base/models/PillProps';
import { computed } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import { IssuePhasePillViewModel } from '../models';
import useIssuePhaseState from '@/features/issues/composables/useIssuePhaseState';
import Icon from '@/features/theme/base/Icon.vue';
import { IssueProcessEnum, IssueStatusEnum } from '@/generated/graphql';

const props = withDefaults(
  defineProps<{
    issue?: Maybe<{ isHidden?: Maybe<boolean>; currentPhase?: Maybe<IssuePhasePillViewModel>; process?: { type?: IssueProcessEnum }}>;
    phase?: Maybe<IssuePhasePillViewModel>;
    icon?: boolean;
    iconHeight?: number;
    iconWidth?: number;
    pillSize?: PillSize;
    fullwidth?: boolean;
    type?: IssueStatusEnum;
  }>(),
  {
    icon: false,
    iconHeight: 24,
    iconWidth: 24,
    pillSize: PillSize.large,
    fullwidth: false,
    type: IssueStatusEnum.InProgress,
  }
);

const { issueClosed, issueOpenForReview, issueUnpublished, issuePhaseStateString } = useIssuePhaseState(
  computed(() => props.issue),
  computed(() => (props.phase ? props.phase : props.issue?.currentPhase)),
  computed(() => props.type)
);

</script>

<template>
  <div
    class="pill-container"
    v-if="issueUnpublished"
  >
    <Pills
      :fullwidth="fullwidth"
      :color="Colors.redLight"
      :size="pillSize"
    >
      <Icon
        icon="Error"
        :height="iconHeight"
        :width="iconWidth"
        :color="Colors.red"
      />
      <span
        class="bold pill-text color-red--dark"
        :class="`text--${pillSize}`"
      >
        <Translate :t="issuePhaseStateString" />
      </span>
    </Pills>
  </div>
  <div
    v-else-if="issueClosed"
    class="pill-container"
  >
    <Pills
      :fullwidth="fullwidth"
      :color="Colors.greenLight"
      :size="pillSize"
    >
      <Results
        :heigth="iconHeight"
        :width="iconWidth"
      ></Results>
      <span
        class="bold pill-text color-green--dark"
        :class="`text--${pillSize}`"
      >
        <Translate :t="issuePhaseStateString" />
      </span>
    </Pills>
  </div>
  <div
    v-else-if="issueOpenForReview"
    class="pill-container"
  >
    <Pills
      :fullwidth="fullwidth"
      :color="Colors.orangeLight"
      :size="pillSize"
    >
      <Time
        :heigth="iconHeight"
        :width="iconWidth"
      ></Time>
      <span
        class="bold pill-text color-orange--dark"
        :class="`text--${pillSize}`"
      >
        <Translate :t="issuePhaseStateString" />
      </span>
    </Pills>
  </div>
  <!-- Issue in progress -->
  <div
    v-else
    class="pill-container"
  >
    <Pills
      :fullwidth="fullwidth"
      :color="Colors.blueLight"
      type="blue"
      :size="pillSize"
    >
      <Edit
        :heigth="iconHeight"
        :width="iconWidth"
        :color="Colors.blueDark"
      ></Edit>
      <span
        class="bold pill-text color-blue--dark"
        :class="`text--${pillSize}`"
      >
        <Translate :t="issuePhaseStateString" />
      </span>
    </Pills>
  </div>
</template>

<style lang="scss">
@use '@/scss/design-tokens/colors' as colors;

.pill-text {
  margin: 0 1rem;
  text-transform: uppercase;
  font-size: 12px;
}

.pill-container {
  box-sizing: border-box;
}
</style>
