<script lang="ts" setup>
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Button from '@/features/theme/base/Button.vue';
import DirectionType from '@/features/theme/icons/models/DirectionType';
import Arrow from '@/features/theme/icons/Arrow.vue';
import useActiveIssue from '@/features/issueProcess/composables/useActiveIssue';
import Translate from '@/features/translations/Translate.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import { computed, ref, watch } from 'vue';
import Spinner from '@/features/theme/base/Spinner.vue';
import { IssuePhaseEnum, IssuePhaseStepEnum, IssueProcessEnum } from '@/generated/graphql';
import Confirm from '@/features/theme/base/Confirm.vue';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import useProcessTranslation from '../../composables/useProcessTranslation';
import { useI18n } from 'vue-i18n';
import useProcessObservers from '../../composables/useProcessObservers';
import { ProcessOperation } from '../../composables/useProcessStore';
import { IssueDetails } from '@/features/issues/models';
import useAuthStore from '@/features/auth/useAuthStore';
import useCommunicationStep from '../../composables/useCommunicationStep';

const props = defineProps<{
  activeIssue: IssueDetails;
  fileWarning: boolean;
  hasGarbageFiles: boolean;
  skipStatutoryReviewerValidation: Maybe<boolean>;
}>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'step', stepId: ID): void;
  (e: 'prevStep', stepId: ID): void;
  (e: 'communicationStep', selectedStepType: IssuePhaseStepEnum): void;
  (e: 'next'): void;
  (e: 'prev'): void;
  (e: 'publish'): void;
  (e: 'save'): void;
  (e: 'draft'): void;
}>();

const activeIssue = computed(() => props.activeIssue);

const {
  activePhase,
  activePhaseNextStep,
  activePhasePrevStep,
  selectedStepOrder,
  lastStepOrder,
  selectedPhaseNextStep,
  selectedPhasePrevStep,
  selectStep,
  activePhaseSelected,
  selectedPhaseActiveStep,
  selectedPhase,
  basicInfoPhaseSelected,
  lastPhaseOrder,
  selectedStep,
  isNextStepReviews,
} = useActiveIssue(activeIssue);

const { verifyCommunicationStepDataInput } = useCommunicationStep(activeIssue);

const draftConfirm = ref<Maybe<() => void>>();

const onDraftConfirm = () => {
  if (draftConfirm.value) {
    draftConfirm.value();
  }
  draftConfirm.value = null;
};

const onDraftCancel = () => {
  draftConfirm.value = null;
};

const onBackClick = () => {
  if (selectedPhasePrevStep.value) {
    selectStep(selectedPhasePrevStep.value.id);
  }
};

const isComPhaseDataVerified = ref(true);
const onComPhaseDataVerification = () => {
  isComPhaseDataVerified.value = true;
};

const onNextClick = async () => {
  // in communication phases both steps are "active "at the same time, so we need to account for
  // being in the first step even though the currently active step is the last step
  if (isCommunicationsPhase.value) {
    const isVerified = await verifyCommunicationStepDataInput();
    if (selectedStep.value && !isVerified) {
      isComPhaseDataVerified.value = false;
      return;
    }

    isComPhaseDataVerified.value = true;
    draftConfirm.value = () => {
      if (selectedStep.value) {
        emits('communicationStep', selectedStep.value.type);
      } else {
        emits('publish');
      }
    };
  } else if (selectedPhaseNextStep.value) {
    if (selectedPhaseNextStep.value.id === activePhaseNextStep.value?.id) {
      draftConfirm.value = () => {
        emits('publish');
        if (activePhaseNextStep.value) {
          emits('step', activePhaseNextStep.value.id);
        }
      };
    } else if (activePhaseSelected.value && selectedPhaseNextStep.value?.order <= (selectedPhaseActiveStep.value?.order || 0)) {
      selectStep(selectedPhaseNextStep.value.id);
    } else if (!activePhaseSelected.value) {
      selectStep(selectedPhaseNextStep.value.id);
    }
  }
};

const authStore = useAuthStore();

const prevConfirm = ref(false);

const onPrevClick = () => {
  prevConfirm.value = true;
};

const onPrevConfirm = () => {
  emits('prev');
  prevConfirm.value = false;
};

const onPrevCancel = () => {
  prevConfirm.value = false;
};

const canStartPreviousPhase = computed(() => {
  return authStore.isAdmin && activePhase.value;
});

const prevPhaseTranslationKey = computed(() => {
  return props.activeIssue.lifecycle == 'done' ? 'issue.reopen' : 'issue.phase.revert';
});

const issuePhaseRevertTranslationKey = computed(() => {
  return props.activeIssue.lifecycle == 'done' ? 'issue.reopen.confirm' : 'issue.phase.revert.confirm';
});

const prevStepConfirm = ref(false);

const onPrevStepClick = () => {
  if (isCommunicationsPhase.value) {
    prevStepConfirm.value = true;
  } else if (selectedPhasePrevStep.value) {
    if (selectedPhasePrevStep.value.id === activePhasePrevStep.value?.id) {
      prevStepConfirm.value = true;
    }
  }
};

const onPrevStepConfirm = () => {
  if (isCommunicationsPhase.value) {
    if (selectedStep.value) {
      emits('communicationStep', selectedStep.value.type);
    }
  } else if (activePhasePrevStep.value) {
    if (activePhasePrevStep.value.order == 1) {
      emits('draft');
    }
    emits('prevStep', activePhasePrevStep.value.id);
  }
  prevStepConfirm.value = false;
};

const onPrevStepCancel = () => {
  prevStepConfirm.value = false;
};

const onEndClick = () => {
  if (basicInfoPhaseSelected.value) {
    emits('next');
  } else {
    draftConfirm.value = () => {
      emits('close');
      emits('next');
    };
  }
};

const closing = ref<boolean>(false);
const didCloseCommunicationsPhase = ref(false);

const handleClose = () => {
  closing.value = true;
  emits('close');
};

const isComPhaseAdmin = computed(() => {
  return authStore.isAdmin || authStore.isAdalOrDeilisEmployee;
});

const onCloseClick = () => {
  if (isCommunicationsPhase.value && !isComPhaseAdmin.value) {
    didCloseCommunicationsPhase.value = true;
  } else {
    handleClose();
  }
};

const onCloseCommunicationsPhaseConfirm = () => {
  didCloseCommunicationsPhase.value = false;
  handleClose();
};

const onCloseCommunicationsPhaseCancel = () => {
  didCloseCommunicationsPhase.value = false;
};

watch(
  () => selectedStep.value,
  () => {
    closing.value = false;
  }
);

const prevStepText = computed(() => {
  return isCommunicationsPhase.value ? 'issue.phase.step.further_info' : 'issue.phase.step.prev';
});

const issueStepPrevTranslationKey = computed(() => {
  return isCommunicationsPhase.value ? 'issue.phase.step.further_info.confirm' : 'issue.phase.step.prev.confirm';
});

const issueStepPrevTitleTranslationKey = computed(() => {
  return isCommunicationsPhase.value ? 'issue.phase.step.further_info' : 'issue.phase.step.prev.confirm.title';
});

const nextStepText = computed(() => {
  if (isCommunicationsPhase.value && selectedPhase.value?.state !== 'closed') {
    if (selectedStep.value?.type === IssuePhaseStepEnum.FyrirliggjandiGogn) {
      return 'issue.phase.step.publish';
    } else if (selectedStep.value?.type === IssuePhaseStepEnum.TillagaTilAthugunar) {
      if (activePhase.value?.type === IssuePhaseEnum.AthugunFyrirAuglysingu) {
        return 'issue.phase.step.send_proposal_to_review';
      } else if (activePhase.value?.type === IssuePhaseEnum.Akvordun) {
        return 'issue.phase.step.send_to_review';
      }
    } else if (selectedStep.value?.type === IssuePhaseStepEnum.GognTilStadfestingar) {
      if (
        props.activeIssue.process.type == IssueProcessEnum.NyttDeiliskipulag ||
        props.activeIssue.process.type == IssueProcessEnum.BreytingADeiliskipulagi ||
        props.activeIssue.process.type == IssueProcessEnum.NyttHverfisskipulag ||
        props.activeIssue.process.type == IssueProcessEnum.BreytingAHverfisskipulagi
      ) {
        return 'issue.phase.step.send_to_review';
      }
      return 'issue.phase.step.send_proposal_to_confirmation';
    }
  } else if (selectedStep.value?.type === IssuePhaseStepEnum.FyrirliggjandiGogn && selectedStep.value?.id === selectedPhaseActiveStep.value?.id) {
    return 'issue.phase.step.publish';
  } else if (selectedPhaseNextStep.value?.id === activePhaseNextStep.value?.id) {
    return 'issue.phase.step.start';
  }
  return 'base.continue';
});

const isCommunicationsPhase = computed(() => {
  return !!activePhase.value?.isCommunicationPhase;
});

const shouldShowCommunicationPhaseButtons = computed(() => {
  return isCommunicationsPhase.value && authStore.isAdalOrDeilisEmployee;
});

const completePhaseText = computed(() => {
  return isCommunicationsPhase.value ? 'issue.phase.resolve' : 'issue.phase.complete';
});

const { getPhaseStepKey } = useProcessTranslation();
const translationKey = computed(() => getPhaseStepKey(props.activeIssue.process, selectedPhase.value, selectedStep.value));
const { t } = useI18n();

const onSaveClick = () => {
  emits('save');
};

const { activeOp } = useProcessObservers();
const saving = computed(() => activeOp.value === ProcessOperation.Save);

const showSaveButton = computed(() => {
  return (
    // Button should not be displayed in basic phase or communication phase unless it's the active phase, and not when the step is UmsjonUmsagna
    basicInfoPhaseSelected.value ||
    (!selectedPhase.value?.isCommunicationPhase && selectedStep.value?.type !== IssuePhaseStepEnum.UmsjonUmsagna) ||
    (selectedPhase.value?.isCommunicationPhase && props.activeIssue.currentPhase?.id === selectedPhase.value?.id)
  );
});
</script>
<template>
  <div class="btns-container">
    <div class="layout-left">
      <Button
        @click="onBackClick"
        v-if="!basicInfoPhaseSelected && selectedStepOrder > 1"
        :type="ButtonType.secondary"
      >
        <Arrow
          :custom-class="'icon-left'"
          :direction="DirectionType.left"
          :heigth="24"
          :width="24"
          :color="'#00415A'"
        />
        <Translate t="base.back" />
      </Button>
      <Button
        v-if="canStartPreviousPhase"
        @click="onPrevClick"
      >
        <Translate :t="prevPhaseTranslationKey" />
        <Icon
          class="icon-right"
          :height="24"
          :width="24"
          color="#ffffff"
          icon="Undo"
        />
      </Button>
    </div>
    <div class="flex flex-wrap gap-1">
      <Button
        :type="ButtonType.primary"
        v-if="showSaveButton"
        v-tooltip="{ content: t('base.save'), theme: 'primary' }"
        @click="onSaveClick"
        :disabled="saving"
      >
        <Icon
          v-if="!saving"
          icon="Floppy"
          :options="{ color: Colors.white }"
        />
        <Spinner v-else />
      </Button>
      <template v-if="basicInfoPhaseSelected && !activePhase">
        <Button
          @click="onEndClick"
          :type="ButtonType.primary"
        >
          <Translate t="issue.phase.start" />
          <Arrow
            :custom-class="'icon-right'"
            :direction="DirectionType.right"
            :heigth="24"
            :width="24"
            :color="'#fff'"
          />
        </Button>
      </template>
      <template v-else-if="!basicInfoPhaseSelected">
        <Button
          @click="onPrevStepClick"
          v-if="(authStore.isAdmin || shouldShowCommunicationPhaseButtons) && selectedStepOrder > 1 && activePhaseSelected && selectedPhase?.state != 'closed'"
          :type="ButtonType.primary"
        >
          <Arrow
            :custom-class="'icon-left'"
            :direction="DirectionType.left"
            :heigth="24"
            :width="24"
            :color="'#fff'"
          />
          <Translate :t="prevStepText" />
        </Button>
        <Button
          @click="onNextClick"
          v-if="selectedStepOrder < lastStepOrder"
          :type="ButtonType.primary"
        >
          <Translate :t="nextStepText" />
          <Arrow
            :custom-class="'icon-right'"
            :direction="DirectionType.right"
            :heigth="24"
            :width="24"
            :color="'#fff'"
          />
        </Button>
        <template v-else-if="selectedPhase && activePhaseSelected">
          <Button
            @click="onCloseClick"
            v-if="selectedPhase.state !== 'closed' && selectedPhase.order !== lastPhaseOrder"
            :type="ButtonType.primary"
          >
            <template v-if="!closing">
              <Translate :t="completePhaseText" />
              <Icon
                class="icon-right"
                icon="Checkmark"
                :options="{ color: Colors.white }"
              />
            </template>
            <Spinner
              v-else
              :color="Colors.white"
            />
          </Button>
          <Button
            @click="onEndClick"
            v-if="selectedPhase.order !== lastPhaseOrder"
            :type="ButtonType.primary"
          >
            <Translate t="issue.phase.next" />
            <Arrow
              :custom-class="'icon-right'"
              :direction="DirectionType.right"
              :heigth="24"
              :width="24"
              :color="'#fff'"
            />
          </Button>
          <template v-else>
            <Button
              @click="onEndClick"
              v-if="selectedPhase.state !== 'closed'"
              :type="ButtonType.primary"
            >
              <Translate t="issue.close" />
              <Arrow
                :custom-class="'icon-right'"
                :direction="DirectionType.right"
                :heigth="24"
                :width="24"
                :color="'#fff'"
              />
            </Button>
          </template>
        </template>
      </template>
    </div>
    <Confirm
      :active="!isComPhaseDataVerified"
      @ok="onComPhaseDataVerification"
      type="warning"
    >
      <Alert
        title="base.warning"
        :alert-type="AlertType.warning"
      >
        <Translate :t="'issue.phase.step.com_phase.illegal'" />
      </Alert>
    </Confirm>
    <Confirm
      :active="didCloseCommunicationsPhase"
      @yes="onCloseCommunicationsPhaseConfirm"
      @no="onCloseCommunicationsPhaseCancel"
    >
      <Alert
        title="base.warning"
        :alert-type="AlertType.danger"
      >
        <Translate :t="'issue.phase.step.com_phase.close'" />
      </Alert>
    </Confirm>
    <Confirm
      :active="!!draftConfirm"
      @yes="onDraftConfirm"
      @no="onDraftCancel"
    >
      <Alert
        class="alert"
        title="base.confirm"
        :alert-type="AlertType.success"
      >
        <Translate
          :value="`${translationKey}.confirm`"
          :default-value="t('issue.phase.step.confirm')"
        />
      </Alert>
      <Alert
        v-if="isNextStepReviews"
        class="alert"
        title="base.warning"
        :alert-type="AlertType.warning"
      >
        <Translate
          :value="`${translationKey}.start_reviews`"
          :default-value="t('issue.phase.step.start_reviews')"
        />
      </Alert>
      <Alert
        v-if="skipStatutoryReviewerValidation"
        class="alert"
        title="base.warning"
        :alert-type="AlertType.warning"
      >
        <Translate
          :value="`${translationKey}.skip_reviewers`"
          :default-value="t('issue.phase.step.skip_reviewers')"
        />
      </Alert>
      <Alert
        v-if="fileWarning"
        class="alert"
        title="base.warning"
        :alert-type="AlertType.warning"
      >
        <Translate
          :value="`${translationKey}.warning`"
          :default-value="t('issue.phase.step.file.warning')"
        />
      </Alert>
    </Confirm>
    <Confirm
      :active="!!prevConfirm"
      @yes="onPrevConfirm"
      @no="onPrevCancel"
    >
      <Alert :alert-type="AlertType.danger">
        <Translate :t="issuePhaseRevertTranslationKey" />
      </Alert>
    </Confirm>
    <Confirm
      :active="!!prevStepConfirm"
      @yes="onPrevStepConfirm"
      @no="onPrevStepCancel"
    >
      <Alert
        :title="issueStepPrevTitleTranslationKey"
        :alert-type="isCommunicationsPhase ? AlertType.success : AlertType.danger"
      >
        <Translate :t="issueStepPrevTranslationKey" />
      </Alert>
      <Alert
        v-if="fileWarning && isCommunicationsPhase"
        class="alert"
        title="base.warning"
        :alert-type="AlertType.warning"
      >
        <Translate
          :value="`${translationKey}.warning`"
          :default-value="t('issue.phase.step.file.warning')"
        />
      </Alert>
    </Confirm>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.btns-container {
  display: flex;
  justify-content: space-between;

  @include mq.mobile {
    flex-direction: column;
    gap: 1rem;
  }
}

.layout-left {
  display: flex;
  gap: 1.6rem;
}
</style>
