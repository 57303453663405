import { User } from '@/features/auth/models';
import {
  useCreateUserMutation,
  useAddDelegationMemberMutation,
  DelegationTicketInput,
  UserInput,
  useUpdateUserMutation,
  useRemoveDelegationMemberMutation,
  useCreateFakeUserMutation,
} from '@/generated/graphql';

interface UseUserManagementOperations {
  createUser(input: UserInput): Promise<User>;
  createFakeUser(input: UserInput): Promise<User>;
  updateUser(id: ID, input: UserInput): Promise<User>;
  addDelegationMember(userId: ID, input: DelegationTicketInput): Promise<User>;
  removeDelegationMember(ticketId: string): Promise<User>;
}

export default function useUserManagementOperations(): UseUserManagementOperations {
  const { executeMutation: createUserMutation } = useCreateUserMutation();
  const createUser = async (input: UserInput): Promise<User> => {
    const { data } = await createUserMutation({ input });

    if (!data?.createUser?.user) {
      // Todo: Error handling
      throw new Error('Ekki tókst að stofna notanda');
    }

    return data.createUser.user;
  };

  const { executeMutation: createFakeUserMutation } = useCreateFakeUserMutation();
  const createFakeUser = async (input: UserInput): Promise<User> => {
    const { data } = await createFakeUserMutation({ input });

    if (!data?.createFakeUser?.user) {
      // Todo: Error handling
      throw new Error('Ekki tókst að stofna notanda');
    }

    return data.createFakeUser.user;
  };

  const { executeMutation: updateUserMutation } = useUpdateUserMutation();
  const updateUser = async (id: ID, input: UserInput): Promise<User> => {
    const { data } = await updateUserMutation({ userId: id.toString(), input });

    if (!data?.updateUser?.user) {
      // Todo: Error handling
      throw new Error('Ekki tókst að uppfæra notanda');
    }

    return data.updateUser.user;
  };

  const { executeMutation: addDelegationMemberMutation } = useAddDelegationMemberMutation();
  const addDelegationMember = async (userId: ID, input: DelegationTicketInput): Promise<User> => {
    const { data } = await addDelegationMemberMutation({ userId: userId.toString(), input });

    if (!data?.addDelegationMember.user) {
      // Todo: Error handling
      throw new Error('Ekki tókst að breyta notanda hlutverki');
    }

    return data.addDelegationMember.user;
  };

  const { executeMutation: removeDelegationMemberMutation } = useRemoveDelegationMemberMutation();
  const removeDelegationMember = async (ticketId: string): Promise<User> => {
    const { data } = await removeDelegationMemberMutation({ ticketId });

    if (!data?.removeDelegationMember.user) {
      // Todo: Error handling
      throw new Error('Ekki tókst að eyða umboði notanda');
    }

    return data.removeDelegationMember.user;
  };

  return {
    createUser,
    createFakeUser,
    updateUser,
    addDelegationMember,
    removeDelegationMember,
  };
}
