<script setup lang="ts">
import LayoutSplit from '@/features/theme/base/layouts/LayoutSplit.vue';
import IssuePill from '../issues/components/IssuePill.vue';
import { IssueType } from '../theme/base/models/PillProps';
import Button from '../theme/base/Button.vue';
import { ButtonSize } from '../theme/base/models/ButtonType';
import Translate from '../translations/Translate.vue';
import useNavigation from '../navigation/useNavigation';

const { pushActiveAreaRouteByName } = useNavigation();

const onBtnClick = () => {
  pushActiveAreaRouteByName('Issues');
};
</script>

<template>
  <LayoutSplit :container-type="'container'" class="flex layout-container">
    <template #left>
      <img src="@/assets/issues-map.png" class="issues-map-image hidden-in-laptop" alt="issues on map" />
    </template>
    <template #right>
      <div class="issues-map-pill">
        <IssuePill :pill-type="IssueType.map">
          <span class="pill-text bold color-primary">
            <Translate t="frontpage.issues_on_map.pill.text" />
          </span>
        </IssuePill>
      </div>
      <h1 class="h3 issues-map-title">
        <Translate t="frontpage.issues_on_map.title" />
      </h1>
      <div class="issues-map-list-container">
        <div class="flex issues-map-list-heading-container">
          <div class="issues-map-list-number subtitle bold">01</div>
          <div>
            <h2 class="subtitle issues-map-list-title">
              <Translate t="frontpage.issues_on_map.subtitle.1" />
            </h2>
            <p class="subtitle issues-map-list-paragraph">
              <Translate t="frontpage.issues_on_map.paragraph.1" />
            </p>
          </div>
        </div>
        <div class="flex issues-map-list-heading-container">
          <div class="issues-map-list-number subtitle bold">02</div>
          <div>
            <h2 class="subtitle issues-map-list-title"><Translate t="frontpage.issues_on_map.subtitle.2" /></h2>
            <p class="subtitle issues-map-list-paragraph">
              <Translate t="frontpage.issues_on_map.paragraph.2" />
            </p>
          </div>
        </div>
        <div class="flex issues-map-list-heading-container">
          <div class="issues-map-list-number subtitle bold">03</div>
          <div>
            <h2 class="subtitle issues-map-list-title"><Translate t="frontpage.issues_on_map.subtitle.3" /></h2>
            <p class="subtitle issues-map-list-paragraph"><Translate t="frontpage.issues_on_map.paragraph.3" /></p>
          </div>
        </div>
        <div class="flex issues-map-list-heading-container">
          <div class="issues-map-list-number subtitle bold">04</div>
          <div>
            <h2 class="subtitle issues-map-list-title"><Translate t="frontpage.issues_on_map.subtitle.4" /></h2>
            <p class="subtitle issues-map-list-paragraph">
              <Translate t="frontpage.issues_on_map.paragraph.4" />
            </p>
          </div>
        </div>
      </div>
      <div class="button-container">
        <Button @click="onBtnClick" :size="ButtonSize.large"><Translate t="frontpage.issues_on_map.button" /></Button>
      </div>
    </template>
  </LayoutSplit>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/spacing' as spacing;
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/media-queries' as media;

.layout-container {
  // padding: 15rem 10rem 10rem 0;
  padding-top: 25rem;
  padding-bottom: 10rem;
  padding-right: 7.5%;
  @include media.laptop-down {
    padding-left: 7.5%;
  }

  @include media.mobile {
    padding: 10rem 2rem 5rem;
  }
}

.issues-map-title {
  margin-bottom: 8rem;
  @include media.mobile {
    margin-bottom: 4rem;
  }
}

.issues-map-list-container {
  margin-bottom: 12rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.2rem;

  @include media.mobile {
    grid-template-columns: 1fr;
    margin-bottom: 6rem;
  }
}

.issues-map-image {
  border-radius: 0 0.8rem 0.8rem 0;
  box-shadow: 0px 40px 80px rgba(0, 111, 126, 0.3);

  @include media.laptop-down {
    border-radius: 0.8rem;
    box-shadow: none;
  }

  @include media.mobile {
    border-radius: 0.8rem;
  }
}
.issues-map-pill {
  margin-bottom: 5.5rem;
}

.issues-map-list-number {
  color: color.$primary;
  margin-right: 3rem;
}

.issues-map-list-heading-container {
  align-items: baseline;
}

.issues-map-list-paragraph {
  @include font.font-default-regular;
}

.issues-map-list-title {
  margin-bottom: 2.4rem;
}

.button-container {
  margin-bottom: 3rem;
}
</style>
