<script setup lang="ts">
import { ButtonType, ButtonSize } from '../../theme/base/models/ButtonType';
import DirectionType from '../../theme/icons/models/DirectionType';
import Translate from '../../translations/Translate.vue';
import { Colors } from '../../theme/base/models/Colors';
import Button from '../../theme/base/Button.vue';
import Arrow from '../../theme/icons/Arrow.vue';
import useNavigation from '../../navigation/useNavigation';

const { pushActiveAreaRouteByName } = useNavigation();

const onIssueListClick = () => {
  pushActiveAreaRouteByName('Issues');
};
</script>

<template>
  <div class="flex content-container">
    <div class="text-container mt-6">
      <div class="ml-3 mb-3">
        <p class="color-primary mb-3"><Translate t="errorpage.error.label" /></p>
        <h1 class="h1 color-primary mb-3"><Translate t="errorpage.error.title" /></h1>
        <p class="regular subtitle color-black mb-8"><Translate t="errorpage.error.subtitle" /></p>
        <Button @click="onIssueListClick" :type="ButtonType.primary" :size="ButtonSize.medium" class="issues-btn">
          <Translate t="errorpage.error.button" />
          <Arrow :custom-class="'icon-right'" :direction="DirectionType.right" :heigth="24" :width="24" :color="Colors.white"></Arrow>
        </Button>
      </div>
    </div>
  </div>
  <div class="background-lines-img"></div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media-queries;
@use '@/scss/design-tokens/z-indicies' as z-index;

.content-container {
  @include media-queries.desktop-down {
    margin-right: 2rem;
  }
}

.text-container {
  margin-left: 14rem;
  max-width: 55rem;
  overflow: hidden;
  word-wrap: break-word;
  margin-bottom: 30rem;

  @include media-queries.desktop-down {
    margin-left: 0;
  }
}

.background-lines-img {
  background: url('@/assets/Dashed-lines-primary.svg') no-repeat;
  background-size: contain;
  position: absolute;
  inset: 0;
  background-position-x: right;

  @include media-queries.desktop-down {
    opacity: 0.2;
  }
}

.issues-btn {
  z-index: z-index.$z-index-404-btn;
}
</style>
