<script lang="ts" setup>
import useDateFormatting from '@/features/composables/useDateFormatting';
import DataTable from '@/features/theme/dataTables';
import { DataTableConfig } from '@/features/theme/dataTables/dataTableTypes';
import LoadingPage from '@/features/theme/LoadingPage.vue';
import { computed } from 'vue';
import { Audit } from '../auditTypes';
import useAudits from '../composables/useAudits';

const props = defineProps<{
  issueId: ID;
}>();

type AuditItem = Omit<Audit, 'author' | '__typename'> & {
  nationalId: string;
  fullName: string;
};

const { parseDateJson, formatDateTimeLocalized } = useDateFormatting();

const { audits, auditsLoading } = useAudits(
  computed(() => ({
    issueId: props.issueId.toString(),
  }))
);
const auditDataset = computed<AuditItem[]>(() => {
  return audits.value.map((au) => ({
    nationalId: au.author.nationalId,
    fullName: au.author.fullName,
    operation: au.operation,
    timeStamp: formatDateTimeLocalized(parseDateJson(au.timeStamp), 2),
    type: au.type,
    modifiedFieldName: au.modifiedFieldName,
  }));
});
const auditConfig: DataTableConfig<AuditItem> = {
  headers: {
    nationalId: {
      formatter: 'base.ssn',
    },
    fullName: {
      formatter: 'base.name',
    },
    operation: {
      formatter: 'audits.operation',
    },
    timeStamp: {
      formatter: 'audits.timeStamp',
    },
    type: {
      formatter: 'audits.type',
    },
    modifiedFieldName: {
      formatter: 'audits.modifiedFieldName',
    },
  },
};
</script>
<template>
  <LoadingPage v-if="auditsLoading" />
  <DataTable v-else :dataset="auditDataset" :config="auditConfig" />
</template>
