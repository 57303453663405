<script lang="ts" setup>
import { computed } from 'vue';
import DataTableDataItem from './DataTableDataItem.vue';
import type { DataTableConfigStrict, DataTableDataRow } from './dataTableTypes';
import pickBy from 'lodash-es/pickBy';

const props = defineProps<{
  row: DataTableDataRow;
  config: DataTableConfigStrict<DataTableDataRow>;
  selected?: boolean;
}>();

const emits = defineEmits<{
  (e: 'click', row: DataTableDataRow): void;
}>();

const visibleItems = computed(() => {
  return pickBy(props.row, (_, itemKey) => !props.config.headers[itemKey]?.hidden);
});

const onClick = () => {
  if (props.config.selectable) {
    emits('click', props.row);
  }
};
</script>

<template>
  <tr
    @click="onClick"
    class="data-table__data-row"
    :class="{ 'data-table__data-row--selectable': config.selectable, 'data-table__data-row--selected': selected }"
  >
    <DataTableDataItem
      v-for="(item, itemKey) in visibleItems"
      :config="config?.data[itemKey]"
      :item="item"
      :row="row"
    />
  </tr>
</template>
<style lang="scss">
@use '@/scss/design-tokens/colors' as colors;

.data-table__data-row {
  background: colors.$white;
  padding-right: 2rem;
  padding-left: 2rem;

  .data-table__data-item {
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;

    &:first-child {
      border-left: 1px solid transparent;
    }

    &:last-child {
      border-right: 1px solid transparent;
    }
  }

  &--selectable {
    cursor: pointer;
  }

  &--selected {
    .data-table__data-item {
      border-top: 1px solid colors.$primary;
      border-bottom: 1px solid colors.$primary;

      &:first-child {
        border-left: 1px solid colors.$primary;
      }

      &:last-child {
        border-right: 1px solid colors.$primary;
      }
    }
  }
}
</style>
