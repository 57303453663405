<script lang="ts" setup>
import IssuePill from './IssuePill.vue';
import { IssueType } from '@/features/theme/base/models/PillProps';
import { IssueDetails, IssueTagsViewModel } from '../models';
import { computed } from 'vue';

const props = defineProps<{
  issue: IssueTagsViewModel | IssueDetails;
  size?: string;
  charLimit?: number;
  tagLimit?: number;
}>();

const tagTooltip = computed(() => {
  return [props.issue.process.title, ...props.issue.tags].join(', ');
});

const communitiesIssue = computed(() => {
  //@ts-ignore
  const { communities } = props.issue;
  if (communities?.length) {
    return communities.map((c: { name: IssueDetails }) => c.name);
  } else {
    return [];
  }
});
</script>
<template>
  <div class="tag-list">
    <div
      v-for="community in communitiesIssue"
      :key="community.id"
      class="mr-1"
    >
      <IssuePill
        :pill-type="IssueType.none"
        :pill-size="size"
      >
        <span class="pill-text bold color-primary">{{ community }}</span>
      </IssuePill>
    </div>
    <div class="mr-1">
      <IssuePill
        :pill-type="IssueType.case"
        :pill-size="size"
      >
        <span
          v-if="charLimit"
          class="pill-text bold color-primary"
        >
          {{ issue.process.title.slice(0, charLimit) }}&hellip;
        </span>
        <span
          v-else
          class="pill-text bold color-primary"
        >
          {{ issue.process.title }}
        </span>
      </IssuePill>
    </div>
    <div
      v-for="tag in issue.tags.slice(0, tagLimit ? tagLimit - 1 : undefined)"
      :key="tag"
      class="mr-1"
    >
      <IssuePill
        :pill-type="IssueType.planning"
        :pill-size="size"
      >
        <span class="pill-text bold text-primary">{{ tag.trim() }}</span>
      </IssuePill>
    </div>
    <div
      v-if="tagLimit && issue.tags.length >= tagLimit"
      v-tooltip="{ theme: 'primary', content: tagTooltip }"
    >
      <IssuePill
        :pill-type="'ellipsis'"
        :pill-size="size"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.tag-list {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem;
}
</style>
