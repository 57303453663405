import { Area, Route } from '@/generated/graphql';
import { RouteRecordRaw } from 'vue-router';
import PageLoader from '@/features/rendering/PageLoader.vue';
import { h } from 'vue';

export type BackendRouteProps = {
  areaId: ID;
  pageId: ID;
};

interface UseAreaRouteMapper {
  mapRoutePath(area: Area, path: string): string;
  mapRouteName(area: Area, route: Route): string;
  mapRoute(area: Area, route: Route): RouteRecordRaw;
  mapRoutes(area: Area, routes: Route[]): RouteRecordRaw[];
  mapAreaToRoutes(area: Area): RouteRecordRaw[];
  mapAreasToRoutes(areas: Area[]): RouteRecordRaw[];
}

export default function useAreaRouteMapper(): UseAreaRouteMapper {
  const mapRoutePath = (area: Area, path: string): string => {
    const pathParts = area.navigation.path
      .split('/')
      .concat(path.split('/'))
      .filter((s: string) => s.length > 0);
    return `/${pathParts.join('/')}`;
  };

  const mapRouteName = (area: Area, route: Route): string => {
    return route.name && route.name.length > 0 ? `${area.id}_${route.name}` : `${area.id}_${route.pageId}`;
  };

  const mapRoute = (area: Area, route: Route): RouteRecordRaw => {
    const path = mapRoutePath(area, route.path);
    const name = mapRouteName(area, route);
    const meta = { areaId: area.id, pageId: route.pageId, requiredRoles: route.requiredRoles ?? [] };
    const props: BackendRouteProps = { areaId: area.id, pageId: route.pageId };
    const component = (p: BackendRouteProps) => h(PageLoader, p);
    component.displayName = route.name;

    return { path, name, meta, component, props };
  };

  const mapRoutes = (area: Area, routes: Route[]): RouteRecordRaw[] => {
    if (routes.length === 0) {
      return [];
    }

    return routes.flatMap((route) => {
      return [mapRoute(area, route), ...mapRoutes(area, route.children)];
    });
  };

  const mapAreaToRoutes = (area: Area): RouteRecordRaw[] => {
    if (area.navigation.routes.length <= 0) {
      return [];
    }

    const frontPageRoute = mapRoute(area, area.navigation.frontPageRoute || area.navigation.routes[0]);
    frontPageRoute.path = `/${area.navigation.path}`;
    frontPageRoute.name = `${area.id}_0`;

    return [frontPageRoute, ...mapRoutes(area, area.navigation.routes)];
  };

  const mapAreasToRoutes = (areas: Area[]): RouteRecordRaw[] => {
    return areas.flatMap(mapAreaToRoutes);
  };

  return {
    mapRouteName,
    mapRoutePath,
    mapRoute,
    mapRoutes,
    mapAreaToRoutes,
    mapAreasToRoutes,
  };
}
