<script lang="ts" setup>
import { ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    value: string;
    selected?: boolean;
  }>(),
  {
    selected: false,
  }
);

const emits = defineEmits<{
  (e: 'click', value: string): void;
}>();

const onClick = () => {
  emits('click', props.value);
};

const optionElement = ref();
watch(
  () => props.selected,
  (newValue) => {
    if (newValue && optionElement.value) {
      optionElement.value.scrollIntoView({ block: 'nearest', behavior: 'smooth' });
    }
  }
);
</script>
<template>
  <li @click="onClick" class="option" ref="optionElement" :class="{ selected }">
    {{ value }}
  </li>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 1.6rem;
  box-sizing: border-box;
  color: colors.$primary;

  &.selected {
    background-color: colors.$primary;
    color: colors.$white;
  }

  &:not(.selected):hover {
    background-color: colors.$primary--xlight;
    color: colors.$primary;
  }
}
</style>
