<script lang="ts" setup>
import Accordion from '@/features/components/Accordion.vue';
import Translate from '@/features/translations/Translate.vue';
import { useQuestionsAndAnswers } from '../composables/useQuestionsAndAnswers';

const { questionsAndAnswers } = useQuestionsAndAnswers();
</script>

<template>
  <h1 class="h5 heading"><Translate t="faq.routes.faq.title" /></h1>
  <p class="regular paragraph mt-2 mb-2">
    <Translate t="faq.routes.faq.description" />
  </p>
  <Accordion
    v-for="qna in questionsAndAnswers"
    :key="qna.question"
    :animated="false"
    :animate-initially="false"
    :show-body="true"
  >
    <template #default="{ accordionBodyVisible }">
      <div
        class="flex accordion-header"
        :class="{ 'pb-1': !accordionBodyVisible }"
      >
        <p class="paragraph regular bold">{{ qna.question }}</p>
      </div>
    </template>
    <template #body>
      <p class="paragraph regular my-2">{{ qna.answer }}</p>
    </template>
  </Accordion>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;
@use '@/scss/design-tokens/colors' as colors;

.heading {
  color: colors.$primary;
}

.accordion-header {
  display: flex;
  justify-content: space-between;
}
</style>
