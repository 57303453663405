<script lang="ts" setup>
import useMonitoringRepository from '../composables/useMonitoringRepository';
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import MonitoringTable from './MonitoringTable.vue';
import Modal from '@/features/theme/base/Modal.vue';
import MonitoringForm from './MonitoringForm.vue';
import { computed, ref } from 'vue';
import Button from '@/features/theme/base/Button.vue';
import Article, { ArticleHeader } from '@/features/theme/base/article';
import { AlertType } from '@/features/theme/base/models/AlertType';
import Alert from '@/features/theme/base/Alert.vue';
import { MonitoringInput } from '@/generated/graphql';
import { Monitoring } from '@/features/monitoring/models';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { TranslationKey } from '@/features/translations/defaultMessages';
import MonitoringMap from './MonitoringMap.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { useI18n } from 'vue-i18n';

const { monitorings, getMonitorings, createMonitoring, deleteMonitoring } = useMonitoringRepository();
getMonitorings();

const monitoringInput = ref<MonitoringInput>({
  communityIds: [],
  tags: [],
  postalCodes: [],
  issueProcessIds: [],
});

const onSubmit = () => {
  createMonitoring(monitoringInput.value)
    .then(() => {
      monitoringInput.value = {
        communityIds: [],
        tags: [],
        postalCodes: [],
        issueProcessIds: [],
      };
      onModalClose();
    })
    .catch((e) => {
      alertType.value = AlertType.danger;
      alertMessage.value = 'monitoring.alert.error';
    });
};

const modalOpen = ref<boolean>(false);
const onModalClose = () => {
  modalOpen.value = false;
  currentAreaGeoJson.value = '';
  monitoringInput.value = {
    communityIds: [],
    tags: [],
    postalCodes: [],
    issueProcessIds: [],
  };
};
const onCreateClick = () => {
  clearAlert();
  mapReadMode.value = false;
  modalOpen.value = true;
};

const alertType = ref<Maybe<AlertType>>();
const alertMessage = ref<Maybe<TranslationKey>>();
const clearAlert = () => {
  alertType.value = null;
  alertMessage.value = null;
};

const onMonitoringDelete = (monitoring: Monitoring) => {
  deleteMonitoring(monitoring.id);
};

const currentAreaGeoJson = ref('');

const onOpenReadMap = (geoJson: string) => {
  clearAlert();
  mapView.value = false;
  mapReadMode.value = false;
  modalOpen.value = true;
  currentAreaGeoJson.value = geoJson;
  queueMicrotask(() => {
    mapReadMode.value = true;
  });
};

const mapView = ref(false);
const onMapSave = (areaGeoJson: string) => {
  if (areaGeoJson) {
    monitoringInput.value.areaGeoJson = areaGeoJson;
  }
  mapView.value = false;
};
const onMapCancel = () => {
  mapView.value = false;
};
const onMapBtnClick = () => {
  mapView.value = true;
};

const mapReadMode = ref(false);
const mapViewOrRead = computed(() => mapView.value || mapReadMode.value);

const { t } = useI18n();
</script>
<template>
  <LayoutCentered>
    <div class="flex mb-2">
      <Button @click="onCreateClick" class="mr-2">
        <Icon class="plus-icon" icon="Plus" :options="{ width: 24, height: 24, color: Colors.white }" />
        <Translate t="monitoring.new.btn" />
      </Button>
    </div>
    <Alert :alert-type="AlertType.info">
      <Translate t="monitoring.help" />
    </Alert>
    <Modal :open="modalOpen" @close="onModalClose" scrollable :disable-background-click="mapView && !mapReadMode">
      <div class="modal-wrapper" :class="{ 'has-map': mapViewOrRead }">
        <Article v-if="!mapViewOrRead" class="monitoring__form-wrapper">
          <ArticleHeader>
            <div class="article__header">
              <Translate t="monitoring.new" />
              <Button
                class="map-btn"
                @click="onMapBtnClick"
                :type="ButtonType.secondary"
                v-tooltip="{ content: t(`monitoring.map.tooltip`), theme: 'primary' }"
              >
                <Icon
                  icon="MapIcon"
                  :options="{ width: 24, height: 24, color: Colors.primary }"
                  :fill="monitoringInput.areaGeoJson ? Colors.primary : 'none'"
                />
              </Button>
            </div>
          </ArticleHeader>
          <MonitoringForm v-model="monitoringInput" @submit="onSubmit" />
          <Alert v-if="alertMessage && alertType" :alert-type="alertType">
            <Translate :t="alertMessage" />
          </Alert>
        </Article>
        <MonitoringMap v-else @cancel="onMapCancel" @save="onMapSave" class="map" :geoJson="currentAreaGeoJson" :disabled="mapReadMode" />
      </div>
    </Modal>
    <MonitoringTable @delete="onMonitoringDelete" @open_map="onOpenReadMap" :monitorings="monitorings" />
  </LayoutCentered>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;

.plus-icon {
  margin-right: 1rem;
}

.article__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monitoring__form-wrapper {
  width: 100%;
}

.modal-wrapper {
  width: 80vw;
  margin: auto;
  max-width: 108rem;
}

.modal-wrapper.has-map {
  box-sizing: border-box;
  height: 80vh;
  padding: 6rem;
  background-color: #fff;

  .map {
    height: 100%;
    width: 100%;
  }
}

.map-btn {
  display: none;
  @include media.laptop {
    display: initial;
  }
}
</style>
