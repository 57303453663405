import useAuthStore from './useAuthStore';
import { authService } from './authService';
import tokenService from './tokenService';
import { ref } from 'vue';

async function fetchEndSessionUrl() {
  const address = import.meta.env.DEV ? import.meta.env.VITE_END_SESSION_URL : `${window.location.origin}/auth/end-session-url`;
  const response = await fetch(address);
  const json = await response.json();

  const url = new URL(json.link);
  return url.toString();
}

export function useSignin() {
  const authStore = useAuthStore();

  const endSessionUrl = ref('');

  fetchEndSessionUrl()
    .then((url) => {
      endSessionUrl.value = url;
    })
    .catch(() => {
      console.error('fetchEndSessionUrl failed... logout is impossible!');
    });

  function clearLocalUser() {
    tokenService.clearToken();
    authStore.clearUser();
  }

  function logout() {
    clearLocalUser();

    window.location.href = endSessionUrl.value;
  }

  async function login() {
    try {
      const user = await authService.getUser();
      if (!user) {
        throw new Error('authMiddleware.ts::authMiddleware.routeGuards: authService.getUser() -> ' + user);
      }

      authStore.setUser(user);
    } catch (error) {
      clearLocalUser();
      console.error(error);
    }
  }

  return {
    clearLocalUser,

    logout,
    login,
  };
}
