<script lang="ts" setup>
import { LoftmyndirCapability } from './models';
import { Options } from 'ol/layer/BaseTile';
import TileSource from 'ol/source/Tile';
import LayerTile from '@/features/map/components/layers/LayerTile.vue';
import SourceXYZ from '@/features/map/components/sources/SourceXYZ.vue';
import useProjection from '@/features/map/composables/useProjection';
import TileGrid from 'ol/tilegrid/TileGrid';
import { Options as XYZOptions } from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';

const props = withDefaults(
  defineProps<{
    baseUrl?: Maybe<string>;
    capability?: LoftmyndirCapability;
    options?: Options<TileSource>;
    resolutions?: number[];
  }>(),
  {
    baseUrl: 'https://ms.map.is/mapcache/tms/1.0.0/',
    capability: LoftmyndirCapability.Grayscale,
    resolutions: () => [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25, 0.125, 0.0625],
  }
);

const emits = defineEmits<{
  (e: 'mounted', layer: TileLayer<TileSource>): void;
  (e: 'unmounted', layer: TileLayer<TileSource>): void;
}>();

const projection = useProjection();

const xyzDefaults = {
  projection: projection.getCode(),
  tileGrid: new TileGrid({ extent: projection.getExtent(), resolutions: props.resolutions }),
};

const capabilityOptions = (capability: LoftmyndirCapability): XYZOptions => {
  return { ...xyzDefaults, url: `${props.baseUrl}${capability}/{z}/{x}/{-y}.jpg` };
};

const onLayerMounted = (layer: TileLayer<TileSource>) => {
  emits('mounted', layer);
};

const onLayerUnmounted = (layer: TileLayer<TileSource>) => {
  emits('unmounted', layer);
};
</script>
<template>
  <LayerTile @mounted="onLayerMounted" @unmounted="onLayerUnmounted" :options="options">
    <template v-for="c in Object.values(LoftmyndirCapability)" :key="c">
      <SourceXYZ v-if="c === capability" :options="capabilityOptions(c)" />
    </template>
  </LayerTile>
</template>
