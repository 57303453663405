<script lang="ts" setup>
import { RemoteUser } from '../../composables/useRemoteUserSearch';
import UserCard from './UserCard.vue';

const props = withDefaults(
  defineProps<{
    users?: RemoteUser[];
    selected?: string;
    cardStyle?: 'margin' | 'margin-small' | 'border';
  }>(),
  {
    issues: () => [],
    cardStyle: 'margin',
  }
);

const emits = defineEmits<{
  (e: 'select', issueId: string): void;
  (e: 'deselect'): void;
}>();

const onCardClick = (issueId: string) => {
  if (issueId === props.selected) {
    emits('deselect');
  } else {
    emits('select', issueId);
  }
};
</script>
<template>
  <div class="user-card-list" :class="cardStyle">
    <UserCard
      :selected="selected === user.id"
      card-size="medium"
      @click="() => onCardClick(user.id.toString())"
      class="user-card-item"
      v-for="user in users"
      :key="user.id"
      :user="user"
    />
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.user-card-list {
  display: flex;
  flex-direction: column;

  &.margin {
    gap: 3.2rem;
  }

  &.margin-small {
    gap: 2rem;
  }

  &.border {
    .user-card-item:not(:last-child) {
      border-bottom: 1px solid colors.$grey-200;
      margin-bottom: 3.2rem;
    }
  }
}

.user-card-item {
  cursor: pointer;
}
</style>
