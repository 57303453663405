<script lang="ts" setup>
import Checkbox from '@/features/theme/base/Checkbox.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { useGetCommunitiesQuery } from '@/generated/graphql';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
  }>(),
  {
    modelValue: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

// leave out "Allt landið" which has the id 74 and "Haf- og strandsvæði utan netlaga" which has id 76
const { data: communityData } = useGetCommunitiesQuery();

const communities = computed(() => communityData.value?.communities.filter((x) => x.id != '74').filter((x) => x.id != '76') || []);

// Haf- og strandsvæði utan netlaga
const offGrid = computed(() => communityData.value?.communities.filter((x) => x.id == '76') || []);
const isSelected = (communityId: string): boolean => {
  return props.modelValue.includes(communityId);
};

const updateSelected = (communityId: string, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, communityId]);
  } else {
    const idx = props.modelValue.findIndex((id) => id === communityId);
    if (idx !== -1) {
      emit('update:modelValue', [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)]);
    }
  }
};
</script>
<template>
  <div class="checkbox-list" v-if="offGrid?.length == 1">
    <Checkbox :model-value="isSelected(offGrid[0].id)" @update:model-value="(val) => updateSelected(offGrid[0].id, val)" :id="`community-${offGrid[0].id}`">
      {{ offGrid[0].name }}
    </Checkbox>
  </div>
  <LayoutFormGroup title="monitoring.header.communities" fullwidth>
    <LayoutInput>
      <div class="checkbox-list">
        <!-- leave out "Allt landið" which has the id 74 and "Haf- og strandsvæði utan netlaga" which has id 76 -->
        <Checkbox
          :model-value="isSelected(community.id)"
          @update:model-value="(val) => updateSelected(community.id, val)"
          :id="`community-${community.id}`"
          v-for="community in communities"
          :key="community.id"
        >
          {{ community.name }}
        </Checkbox>
      </div>
    </LayoutInput>
  </LayoutFormGroup>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;

.checkbox-list {
  display: grid;
  row-gap: 2rem;
  grid-template-columns: repeat(2, 1fr);
  @include media.laptop {
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
