import { computed } from 'vue';
import { useAddIssueContactMutation, useRemoveIssueContactMutation, IssueContactInput, useGetAvailableIssueContactsQuery } from '@/generated/graphql';

export default function useIssueContacts(issueId: string) {
  const { data, fetching, error, executeQuery: refreshAvailableIssueContactsQuery } = useGetAvailableIssueContactsQuery({ variables: { issueId } });
  const contacts = computed(() => data?.value?.availableIssueContacts || []);
  const contactsLoading = computed(() => fetching.value);
  const contactsError = computed(() => error.value);

  const { executeMutation: addContactToIssueMutation } = useAddIssueContactMutation();
  const addContactToIssue = async (contact: IssueContactInput, issueId: string): Promise<string> => {
    const { data } = await addContactToIssueMutation({ contact, issueId });

    if (!data?.addIssueContact?.issue?.id) {
      throw new Error('Ekki tókst að bæta við tengilið');
    }

    return data?.addIssueContact?.issue?.id;
  };

  const { executeMutation: removeContactFromIssueMutation } = useRemoveIssueContactMutation();
  const removeContactFromIssue = async (issueContactId: string, issueId: string): Promise<string> => {
    const { data } = await removeContactFromIssueMutation({ issueContactId, issueId });

    if (!data?.removeIssueContact?.issue?.id) {
      throw new Error('Ekki tókst að fjarlægja tengilið');
    }

    return data?.removeIssueContact?.issue?.id;
  };

  return {
    addContactToIssue,
    removeContactFromIssue,
    refreshAvailableIssueContactsQuery,
    contacts,
    contactsLoading,
    contactsError,
  };
}
