<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import { useI18n } from 'vue-i18n';

withDefaults(
  defineProps<{
    disabled?: boolean;
  }>(),
  {
    disabled: true,
  }
);

const emits = defineEmits<{
  (e: 'save'): void;
}>();

const onSave = () => {
  emits('save');
};

const { t } = useI18n();
</script>
<template>
  <div class="flex">
    <Button :disabled="disabled" v-tooltip="{ content: t('issue.map.save.tooltip'), theme: 'primary' }" @click="onSave" :type="ButtonType.secondary">
      <Icon icon="Floppy" :options="{ color: Colors.primary, width: 24, height: 24 }" />
    </Button>
  </div>
</template>
