<script lang="ts" setup>
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
type DateLike = Date | string;

const props = withDefaults(
  defineProps<{
    modelValue?: Maybe<DateLike>;
    disabled?: boolean;
    clearable?: boolean;
  }>(),
  {}
);

const emits = defineEmits<{
  (e: 'update:modelValue', value: Maybe<Date>): void;
}>();

const onUpdateModelValue = (date: Maybe<Date>) => {
  emits('update:modelValue', date);
};
</script>

<template>
  <Datepicker
    :model-value="modelValue"
    @update:model-value="onUpdateModelValue"
    :enable-time-picker="false"
    locale="is"
    format="dd/MM/yyyy"
    auto-apply
    :clearable="clearable"
    :disabled="disabled"
  ></Datepicker>
</template>
