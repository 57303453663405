<script lang="ts" setup>
import Article, { ArticleText, ArticleHeader } from '@/features/theme/base/article';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import { IssuePhaseStepEnum, IssuePhaseFileTypes } from '@/generated/graphql';
import Translate from '@/features/translations/Translate.vue';
import IssuePhaseStepComments from '@/features/issueProcess/components/phaseSteps/IssuePhaseStepComments.vue';
import { File as DomainFile } from '@/features/files/models';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
  translationKey: string;
  ownFiles: DomainFile[];
  garbageFiles: DomainFile[];
}>();

const emits = defineEmits<{
  (event: 'onFileDelete', file: DomainFile, index: number): void;
  (event: 'onFileRestore', file: DomainFile, index: number): void;
  (event: 'onCommentDelete', stepType: IssuePhaseStepEnum, iterationCounter: number): void;
}>();

const onFileRestore = (file: DomainFile, index: number) => {
  emits('onFileRestore', file, index);
};

const onFileDelete = (file: DomainFile, index: number) => {
  emits('onFileDelete', file, index);
};

const onCommentDelete = (stepType: IssuePhaseStepEnum, iterationCounter: number) => {
  emits('onCommentDelete', stepType, iterationCounter);
};

</script>
<template>
  <Article>
    <ArticleHeader>
      <Translate
        :value="`${translationKey}.name`"
        default-value="Innsetning á gögnum"
      />
    </ArticleHeader>
    <ArticleText>
      <Translate
        :value="`${translationKey}.help`"
        default-value="Setja inn gögn lokagögn"
      />
    </ArticleText>
    <IssuePhaseStepComments
      :issue=props.issue  
      :phase=props.phase
      :own-files=props.ownFiles
      :garbage-files=props.garbageFiles
      :file-type="IssuePhaseFileTypes.Almennt"
      :step-type="IssuePhaseStepEnum.GognTilStadfestingar"
      @on-file-delete="onFileDelete"
      @on-file-restore="onFileRestore"
      @on-comment-delete="onCommentDelete"
    />
  </Article>
</template>

