<script lang="ts" setup>
import { computed, inject } from 'vue';
import DataTableHeader from './DataTableHeader.vue';
import { dataTableSortKey, dataTableSortToggleKey } from './dataTableSymbols';
import type { DataTableConfigStrict, DataTableDataRow, DataTableHeader as DataTableHeaderType } from './dataTableTypes';

const props = defineProps<{
  headers: DataTableHeaderType[];
  config: DataTableConfigStrict<DataTableDataRow>;
}>();

const sortBy = inject(dataTableSortKey, undefined);
const sortToggle = inject(dataTableSortToggleKey, undefined);

const onHeaderClick = (header: DataTableHeaderType) => {
  if (sortToggle && props.config.headers[header]?.sortable !== false) {
    sortToggle(header);
  }
};

const visibleHeaders = computed(() => props.headers.filter((header) => !props.config.headers[header]?.hidden));
</script>
<template>
  <thead class="data-table__head">
    <tr class="data-table__header-row">
      <DataTableHeader
        v-for="header in visibleHeaders"
        :key="header"
        :header="header"
        :config="config.headers[header]"
        @click="onHeaderClick"
        :sort-order="sortBy && sortBy[header]"
      />
    </tr>
  </thead>
</template>
