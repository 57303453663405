<script lang="ts" setup>
import Icon, { IconType } from '@/features/theme/base/Icon.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { Colors } from '../theme/base/models/Colors';

const props = withDefaults(
  defineProps<{
    to?: string;
    prefix?: string;
    icon?: IconType;
    disabled?: boolean;
  }>(),
  {
    icon: 'QuestionMark',
    prefix: '/',
    disabled: false,
  }
);

const emits = defineEmits<{
  (e: 'click'): void;
}>();

const router = useRouter();

const routerLinkPath = computed<string>(() => `${props.prefix}${props.to}`);
const onRouterLinkClicked = () => {
  if (!props.disabled) {
    if (props.to) {
      router.push(`${props.prefix}${props.to}`);
    }
    emits('click');
  }
};
</script>
<template>
  <a @click.prevent="onRouterLinkClicked" :to="routerLinkPath" class="router-link" :class="{ disabled }" :disabled="disabled">
    <slot name="icon">
      <Icon :icon="icon" :options="{ width: 20, height: 20 }" :color="disabled ? Colors.grey400 : Colors.primary" />
    </slot>
    <p class="router-text"><slot /></p>
  </a>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.router-link {
  height: 60px;
  color: colors.$primary;
  display: flex;
  align-items: center;
  padding-left: 20px;
  font-size: 1.6rem;
  &:not(.disabled):hover {
    background-color: colors.$primary--xlight;
  }

  &.disabled {
    color: colors.$grey-400;
    cursor: default;
  }
}

.router-text {
  margin-left: 14px;
}
</style>
