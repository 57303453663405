import tokenService from '@/features/auth/tokenService';
import { IssuePhaseFileTypes } from '@/generated/graphql';
import { AssetInput } from '../models/AssetInput';
import { AssetInputList } from '../models/AssetInputList';
import { IssuePhaseAssetInput } from '../models/IssuePhaseAssetInput';

const fixUrl = (path: string) => (import.meta.env.VITE_BACKEND_URL ? import.meta.env.VITE_BACKEND_URL + path : path);

const convertFileType = (fileType: IssuePhaseFileTypes) => {
  switch (fileType) {
    case IssuePhaseFileTypes.Vidbrogd:
      return 1;
    case IssuePhaseFileTypes.Almennt:
      return 2;
    case IssuePhaseFileTypes.Afgreidsla:
      return 3;
    case IssuePhaseFileTypes.Umsogn:
      return 4;
    default:
      return 2;
  }
};

export default function useUploadFiles() {
  const post = async (url: string, input: object) => {
    const result = await fetch(url, {
      method: 'POST',
      headers: { Authorization: `Bearer ${tokenService.getToken()}`, 'Content-Type': 'application/json' },
      body: JSON.stringify(input),
    });

    const json = await result.json();
    return json;
  };

  const uploadIssueFiles = async (issueId: string, assets: AssetInput[] = []) => {
    const url = fixUrl(`/files/issues/${encodeURIComponent(issueId)}`);
    const assetList: AssetInputList = {
      assets,
    };

    const response = await post(url, assetList);
    return response;
  };

  const uploadIssueActivePhaseFile = async (issueId: string, input: IssuePhaseAssetInput) => {
    const url = fixUrl(`/files/issues/${encodeURIComponent(issueId)}/active_phase`);
    const payload = {
      ...input,
      fileType: convertFileType(input.fileType)
    };

    const response = await post(url, payload);
    return response;
  };

  const uploadReviewFiles = async (reviewId: string, assets: AssetInput[] = []) => {
    const url = fixUrl(`/files/reviews/${encodeURIComponent(reviewId)}`);
    const assetList: AssetInputList = {
      assets,
    };

    const response = await post(url, assetList);

    return response;
  };

  return {
    uploadIssueActivePhaseFile,
    uploadIssueFiles,
    uploadReviewFiles,
  };
}
