<script lang="ts" setup>
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import UserSettingsForm from '@/features/navigation/UserSettingsForm.vue';
import { useRouter } from 'vue-router';
import { SpecialAuthPaths } from '../auth/authMiddleware';

const router = useRouter();

const onSubmit = () => {
  const from = router.currentRoute.value.query['from']?.toString();
  router.replace(from ?? SpecialAuthPaths.HOME);
};
</script>
<template>
  <LayoutCentered>
    <UserSettingsForm :span="2" :button-text="'base.continue'" @submit="onSubmit" />
  </LayoutCentered>
</template>
