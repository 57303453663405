import useUploadFiles from '@/features/files/composables/useUploadFiles';
import { ReviewInput, useAddReviewToIssuePhaseMutation, useDeleteReviewMutation, useUpdateReviewMutation } from '@/generated/graphql';
import { IssueReview } from '../models';
import { AddReviewInput } from '../models/AddReviewInput';

interface UseIssueReviewOperations {
  addReviewToIssuePhase(issueId: ID, issuePhaseId: ID, input: AddReviewInput): Promise<IssueReview>;
  deleteReview(issueId: ID, reviewId: ID): Promise<void>;
  updateReview(issueId: ID, reviewId: ID, input: ReviewInput): Promise<IssueReview>;
}

export default function useIssueReviewOperations(): UseIssueReviewOperations {
  const { executeMutation: addReviewToIssuePhaseMutation } = useAddReviewToIssuePhaseMutation();
  const { executeMutation: deleteReviewMutation } = useDeleteReviewMutation();
  const { executeMutation: updateReviewMutation } = useUpdateReviewMutation();
  const { uploadIssueFiles } = useUploadFiles();

  const addReviewToIssuePhase = async (issueId: ID, issuePhaseId: ID, input: AddReviewInput) => {
    const { files, ...reviewInput } = input;

    if (files?.length) {
      const fileResponse = await uploadIssueFiles(issueId.toString(), files);
      if (!fileResponse.success) {
        // Todo: Error handling
        console.error('Failed to upload issue file!');
      } else {
        reviewInput.fileIds = fileResponse.fileIds;
      }
    }

    const _issuePhaseId = issuePhaseId.toString();
    const response = await addReviewToIssuePhaseMutation({ issuePhaseId: _issuePhaseId, input: reviewInput });

    if (!response.data?.addReviewToIssuePhase?.review) {
      // Todo: Error handling
      throw new Error('Failed to add review');
    }

    return response.data.addReviewToIssuePhase.review;
  };

  const deleteReview = async (issueId: ID, reviewId: ID) => {
    const response = await deleteReviewMutation({ reviewId: reviewId.toString() });

    if (!response.data?.deleteReview.issuePhase) {
      throw new Error('No issue phase returned');
    }
  };

  const updateReview = async (issueId: ID, reviewId: ID, input: ReviewInput) => {
    const response = await updateReviewMutation({
      reviewId: reviewId.toString(),
      input,
    });

    if (!response.data?.updateReview) {
      throw new Error('No issue review returned');
    }

    return response.data.updateReview;
  };

  return {
    addReviewToIssuePhase,
    deleteReview,
    updateReview,
  };
}
