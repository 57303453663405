<script lang="ts" setup>
import Source from 'ol/source/Source';
import { onMounted } from 'vue';
import useLayer from '@/features/map/composables/useLayer';

const getLayer = useLayer();

const props = defineProps<{
  source: Source;
}>();

onMounted(() => {
  getLayer().setSource(props.source);
});
</script>
<template>
  <slot />
</template>
