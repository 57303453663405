<script lang="ts" setup>
import OSM from 'ol/source/OSM';
import { shallowRef } from 'vue';
import Source from './Source.vue';

const source = shallowRef(new OSM());
</script>
<template>
  <Source :source="source">
    <slot />
  </Source>
</template>
