import { computed, ComputedRef, onMounted, onUnmounted } from 'vue';
import useProcessStore, { ProcessObserver, ProcessObservers, ProcessOperation, ProcessSaveArgs } from './useProcessStore';

interface UseProcessObservers {
  addProcessObserver(op: ProcessOperation, observer: ProcessObserver): void;
  removeProcessObserver(op: ProcessOperation, observer: ProcessObserver): void;
  triggerProcessObservers(op: ProcessOperation, ...args: unknown[]): Promise<void>;
  subscribeToProcessOp(op: ProcessOperation): ComputedRef<number>;
  activeOp: ComputedRef<Maybe<ProcessOperation>>;
}

export const isProcessSaveArgs = (val: unknown): val is ProcessSaveArgs => {
  const args = val as ProcessSaveArgs;

  return typeof args?.autosave === 'boolean';
};

export default function useProcessObservers(autoObservers?: Maybe<ProcessObservers>): UseProcessObservers {
  const store = useProcessStore();

  const opCounts = {
    [ProcessOperation.Save]: computed(() => store.opSaveCount),
    [ProcessOperation.Publish]: computed(() => store.opPublishCount),
    [ProcessOperation.Step]: computed(() => store.opStepCount),
    [ProcessOperation.Close]: computed(() => store.opCloseCount),
    [ProcessOperation.Draft]: computed(() => store.opDraftCount),
  };

  const incOpCount = (op: ProcessOperation) => {
    switch (op) {
      case ProcessOperation.Save:
        store.opSaveCount += 1;
        break;
      case ProcessOperation.Publish:
        store.opPublishCount += 1;
        break;
      case ProcessOperation.Step:
        store.opStepCount += 1;
        break;
      case ProcessOperation.Close:
        store.opCloseCount += 1;
        break;
      case ProcessOperation.Draft:
        store.opDraftCount += 1;
        break;
      default:
        console.error('ProcessOperation unrecognized!');
        break;
    }
  };

  const subscribeToProcessOp = (op: ProcessOperation) => {
    return opCounts[op];
  };

  const triggerProcessObservers = async (op: ProcessOperation, ...args: unknown[]) => {
    store.activeOp = op;
    incOpCount(op);
    let err: Maybe<unknown> = null;
    try {
      await Promise.all((store.processObservers[op] || []).map((observer) => observer(...args)));
    } catch (e: unknown) {
      err = e;
    } finally {
      if (store.activeOp === op) {
        store.activeOp = null;
      }
    }

    if (err) {
      throw err;
    }
  };

  if (autoObservers) {
    onMounted(() => {
      Object.keys(ProcessOperation).forEach((op) => {
        (autoObservers[op as ProcessOperation] || []).forEach((observer) => {
          store.addProcessObserver(op as ProcessOperation, observer);
        });
      });
    });

    onUnmounted(() => {
      Object.keys(ProcessOperation).forEach((op) => {
        (autoObservers[op as ProcessOperation] || []).forEach((observer) => {
          store.removeProcessObserver(op as ProcessOperation, observer);
        });
      });
    });
  }

  return {
    addProcessObserver: store.addProcessObserver,
    removeProcessObserver: store.removeProcessObserver,
    triggerProcessObservers,
    subscribeToProcessOp,
    activeOp: computed(() => store.activeOp),
  };
}
