<script lang="ts" setup>
import useMap from '@/features/map/composables/useMap';
import { MapIssue } from '@/features/issues/models';
import FeatureType from 'ol/Feature';
import { Point, Geometry } from 'ol/geom';
import { boundingExtent } from 'ol/extent';
import { LayerZIndex } from '@/features/issues/models/issueMaps';
import { computed } from '@vue/reactivity';
import useIssueFeatures from '@/features/issues/composables/useIssueFeatures';
import LayerCluster from '@/features/map/components/layers/LayerCluster.vue';
import SourceCluster from '@/features/map/components/sources/SourceCluster.vue';
import ManyFeatures from '@/features/map/components/features/ManyFeatures.vue';

const props = withDefaults(
  defineProps<{
    issues?: MapIssue[];
  }>(),
  {
    issues: () => [],
  }
);

const emits = defineEmits<{
  (e: 'cluster'): void;
  (e: 'feature', feature: FeatureType<Point>): void;
}>();

const getMap = useMap();

const onClusterClick = (feature: FeatureType<Geometry>) => {
  const features: FeatureType<Point>[] = feature.get('features');
  if (features.length > 1) {
    const zoom = getMap().getView().getZoom();
    if (zoom && zoom >= 10) {
      features.forEach((f, i) => {
        const geom = f.getGeometry();
        if (geom) {
          const m = (2 * Math.PI) / features.length;
          const n = 300;
          const dx = n * Math.cos(i * m);
          const dy = n * Math.sin(i * m);
          const x = geom.getCoordinates()[0];
          const y = geom.getCoordinates()[1];
          geom.setCoordinates([x + dx, y + dy]);
        }
      });
    } else {
      const extent = boundingExtent(features.map((r) => r.getGeometry()!.getCoordinates()));
      emits('cluster');
      getMap()
        .getView()
        .fit(extent, { duration: 1000, padding: Array(4).fill(25), maxZoom: 10 });
    }
  } else {
    emits('feature', features[0]);
  }
};

const { getIssueMarkerFeature } = useIssueFeatures();
const issueMarkers = computed(() => props.issues.map(getIssueMarkerFeature).filter((m) => !!m) as FeatureType<Point>[]);
</script>
<template>
  <LayerCluster
    @cluster-click="onClusterClick"
    :options="{ zIndex: LayerZIndex.Feature }"
  >
    <SourceCluster>
      <ManyFeatures
        v-if="issueMarkers?.length"
        :features="issueMarkers"
      />
    </SourceCluster>
  </LayerCluster>
</template>
