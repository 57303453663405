<script lang="ts" setup>
import Article, { ArticleText, ArticleHeader } from '@/features/theme/base/article';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import FileList from '@/features/files/components/FileList.vue';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import useActivePhaseOperations from '@/features/issueProcess/composables/useActivePhaseOperations';
import { IssuePhaseFileTypes, IssuePhaseStepEnum } from '@/generated/graphql';
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import Translate from '@/features/translations/Translate.vue';
import useActiveIssue from '../../composables/useActiveIssue';
import { computed } from 'vue';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
  translationKey: string;
}>();

const { selectedPhase } = useActiveIssue(computed(() => props.issue));
const { addFileToPhase, getStepPhaseFiles } = useActivePhaseOperations(computed(() => props.issue));

const onFilesInput = (payload: Base64FilePayload[]) => {
  for (const item of payload) {
    if (selectedPhase.value) {
      addFileToPhase({
        phaseId: selectedPhase.value.toString(),
        data: item.base64,
        name: item.file.name,
        encoding: 1,
        iterationCounter: 0,
        fileType: IssuePhaseFileTypes.Vidbrogd,
        stepType: IssuePhaseStepEnum.InnsetningLokagagna,
      });
    } else {
      console.error("Can't upload if selected phase has no value!");
    }
  }
};
</script>
<template>
  <Article>
    <ArticleHeader>
      <Translate
        :value="`${translationKey}.name`"
        default-value="Innsetning lokagagna"
      />
    </ArticleHeader>
    <ArticleText>
      <Translate
        :value="`${translationKey}.help`"
        default-value="Hér geturu sett inn lokagögn"
      />
    </ArticleText>
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput :span="3">
          <FileList
            editable
            deletable
            :files="getStepPhaseFiles(IssuePhaseStepEnum.InnsetningLokagagna)"
          />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput>
          <FileInputBase64 @files="onFilesInput" />
        </LayoutInput>
      </LayoutFormGroup>
    </LayoutForm>
  </Article>
</template>
