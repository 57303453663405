<script lang="ts" setup>
import Style, { StyleFunction } from 'ol/style/Style';
import { Options } from 'ol/layer/BaseVector';
import VectorSource from 'ol/source/Vector';
import { RegularShape, Stroke, Fill, Text } from 'ol/style';
import Layer from './Layer.vue';
import VectorLayer from 'ol/layer/Vector';
import { provide, shallowRef } from 'vue';
import { mapVectorLayerKey } from '@/features/map/models/mapSymbols';
import useMap from '@/features/map/composables/useMap';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import useMapModelChecker from '@/features/map/composables/useMapModelChecker';

const props = withDefaults(
  defineProps<{
    options?: Options<VectorSource>;
  }>(),
  {
    options: () => ({}),
  }
);

const emits = defineEmits<{
  (e: 'mounted'): void;
  (e: 'unmounted'): void;
  (e: 'clusterClick', feature: Feature<Geometry>): void;
}>();

const { isStyleLikeStyle } = useMapModelChecker();

const styleCache: Record<number, Style> = {};

const styleFn: StyleFunction = (featureLike) => {
  const features: Feature<Geometry>[] = featureLike.get('features');
  const size: number = features.length;

  if (size === 1) {
    const featureStyle = features[0].getStyle();
    if (isStyleLikeStyle(featureStyle)) {
      return featureStyle;
    }
  }

  const cacheStyle = styleCache[size];

  if (cacheStyle) {
    return cacheStyle;
  }

  const style = new Style({
    image: new RegularShape({
      radius: 25,
      points: 4,
      angle: Math.PI / 4,
      stroke: new Stroke({
        color: '#006F7E',
      }),
      fill: new Fill({
        color: '#FFF',
      }),
    }),
    text: new Text({
      text: size.toString(),
      font: '700 14px Inter',
      fill: new Fill({
        color: '#006F7E',
      }),
    }),
  });

  styleCache[size] = style;

  return style;
};

const clusterLayer = shallowRef(
  new VectorLayer({
    ...props.options,
    style: styleFn,
  })
);

provide(mapVectorLayerKey, () => clusterLayer.value);

const getMap = useMap();
const onMapClick = (e: MapBrowserEvent<UIEvent>) => {
  clusterLayer.value.getFeatures(e.pixel).then((clickedFeatures) => {
    if (clickedFeatures.length) {
      emits('clusterClick', clickedFeatures[0]);
    }
  });
};
const onLayerMounted = () => {
  getMap().on('click', onMapClick);
  emits('mounted');
};

const onLayerUnmounted = () => {
  getMap().un('click', onMapClick);
  emits('unmounted');
};
</script>
<template>
  <Layer
    @mounted="onLayerMounted"
    @unmounted="onLayerUnmounted"
    :layer="clusterLayer"
  >
    <slot />
  </Layer>
</template>
