import { computed, ComputedRef, ref, Ref } from 'vue';
import { DataTableFilter } from './dataTableTypes';
import uniq from 'lodash-es/uniq';
import sortBy from 'lodash-es/sortBy';

interface UseDataTablePagination {
  dataTablePaginationLimit: Ref<number>;
  dataTablePaginationOffset: ComputedRef<number>;
  dataTablePaginationPage: Ref<number>;
  dataTablePaginationMaxPage: ComputedRef<number>;
  dataTablePaginationCandidates: ComputedRef<number[]>;
  dataTablePaginationFilter: DataTableFilter;
}

export default function useDataTablePagination(initialLimit: number = 25, initalPage: number = 1): UseDataTablePagination {
  const itemCount = ref<number>(1);
  const dataTablePaginationPage = ref<number>(initalPage);
  const dataTablePaginationMaxPage = computed(() => Math.ceil(itemCount.value / dataTablePaginationLimit.value));
  const dataTablePaginationLimit = ref<number>(initialLimit);
  const dataTablePaginationOffset = computed<number>(() => (dataTablePaginationPage.value - 1) * dataTablePaginationLimit.value);
  const dataTablePaginationCandidates = computed<number[]>(() => {
    const maxPage = dataTablePaginationMaxPage.value;
    const currPage = dataTablePaginationPage.value;
    return sortBy(uniq([1, maxPage, currPage - 1, currPage, currPage + 1].filter((page) => page > 0 && page <= maxPage)));
  });
  const dataTablePaginationFilter: DataTableFilter = (dataset) => {
    itemCount.value = dataset.length;
    return dataset.slice(dataTablePaginationOffset.value, dataTablePaginationOffset.value + dataTablePaginationLimit.value);
  };

  return {
    dataTablePaginationLimit,
    dataTablePaginationOffset,
    dataTablePaginationPage,
    dataTablePaginationMaxPage,
    dataTablePaginationCandidates,
    dataTablePaginationFilter,
  };
}
