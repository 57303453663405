import { IssueDetails } from '@/features/issues/models';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export enum ProcessOperation {
  Save = 'Save',
  Publish = 'Publish',
  Close = 'Close',
  Step = 'Step',
  Draft = 'Draft',
}

export type ProcessObserver = (...args: unknown[]) => Promise<void>;
export type ProcessObservers = {
  -readonly [key in keyof typeof ProcessOperation]?: ProcessObserver[];
};
export type ProcessSaveArgs = {
  autosave: boolean;
  updateSelectedStep: boolean;
};

export const useProcessStore = defineStore('process', () => {
  const opSaveCount = ref(0);
  const opPublishCount = ref(0);
  const opCloseCount = ref(0);
  const opStepCount = ref(0);
  const opDraftCount = ref(0);
  // flag for user has changes
  const isDirty = ref(false);

  const selectedPhaseId = ref<Maybe<ID>>(undefined);
  const selectedStepId = ref<Maybe<ID>>(undefined);

  const clearSelection = () => {
    selectedPhaseId.value = undefined;
    selectedStepId.value = undefined;
  };

  const setSelectedPhaseId = (phase: Maybe<ID>) => (selectedPhaseId.value = phase);
  const setSelectedStepId = (step: Maybe<ID>) => (selectedStepId.value = step);

  const processObservers = ref<ProcessObservers>({});

  const addProcessObserver = (op: ProcessOperation, observer: ProcessObserver) => {
    if (processObservers.value[op] === undefined) {
      processObservers.value[op] = [];
    }

    processObservers.value[op]!.push(observer);
  };

  const removeProcessObserver = (op: ProcessOperation, observer: ProcessObserver) => {
    if (processObservers.value[op] === undefined) {
      processObservers.value[op] = [];
    }

    const idx = processObservers.value[op]!.findIndex((ob) => ob === observer);
    if (idx !== -1) {
      processObservers.value[op]!.splice(idx, 1);
    }
  };

  const activeOp = ref<Maybe<ProcessOperation>>(null);

  return {
    clearSelection,
    setSelectedPhaseId,
    selectedPhaseId,
    setSelectedStepId,
    selectedStepId,
    processObservers,
    addProcessObserver,
    removeProcessObserver,

    isDirty,

    opSaveCount,
    opPublishCount,
    opCloseCount,
    opStepCount,
    activeOp,
    opDraftCount,
  };
});

export default useProcessStore;
