import { useSearchUsersQuery, SearchUsersQuery, UserSpecificationInput, UserRoleType } from '@/generated/graphql';
import { computed, ref } from 'vue';

export type RemoteUser = SearchUsersQuery['users'][0];

export default function useRemoteUserSearch() {
  const searchRef = ref<UserSpecificationInput>({
    delegationId: null,
    search: '',
  });

  const { data, executeQuery } = useSearchUsersQuery({
    variables: {
      input: searchRef,
    },
    requestPolicy: 'cache-and-network',
    pause: true,
  });

  const searchResults = computed<RemoteUser[]>(() => {
    const users = data?.value?.users || [];
    return users.filter((u) => {
      const ticket = u.delegations.find((d) => d.delegation?.id === searchRef.value.delegationId);
      return ticket?.role === UserRoleType.DelegationConsultant;
    });
  });

  const performSearch = async (input: UserSpecificationInput) => {
    searchRef.value = input;
    await executeQuery();
  };

  return {
    searchResults,
    performSearch,
  };
}
