<script lang="ts" setup></script>
<template>
  <li class="list-item">
    <template v-if="$slots['left']">
      <span class="left">
        <slot name="left" />
      </span>
    </template>
    <span class="list-item__content">
      <slot />
    </span>
    <template v-if="$slots['right']">
      <span class="right">
        <slot name="right" />
      </span>
    </template>
  </li>
</template>
<style lang="scss" scoped>
@use '@/scss/utilities/helpers';
@use '@/scss/design-tokens/colors' as colors;

.list-item {
  display: flex;
  align-items: center;
  position: relative;

  &:not(:last-child) {
    @extend .mb-1;
  }
}

.list-item__content {
  @extend .px-2;
  @extend .py-1;
  @extend .gap-1;
  border: 1px solid colors.$grey-300;
  flex: 1 1 auto;
  width: 100%;
}

.right {
  position: absolute;
  right: 0;
}
</style>
