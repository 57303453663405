<script lang="ts" setup>
import Layer from 'ol/layer/Layer';
import { onMounted, onUnmounted, provide, watch } from 'vue';
import useMap from '@/features/map/composables/useMap';
import { mapLayerKey } from '@/features/map/models/mapSymbols';
import useOverviewMap from '@/features/map/composables/useOverviewMap';

const props = defineProps<{
  layer: Layer;
}>();

const getMap = useMap();

const { addLayerToOverview, removeLayerFromOverview, hasOverviewMap } = useOverviewMap();

const emits = defineEmits<{
  (e: 'mounted'): void;
  (e: 'unmounted'): void;
}>();

onMounted(() => {
  if (hasOverviewMap.value) {
    addLayerToOverview(props.layer);
  } else {
    getMap().addLayer(props.layer);
  }

  emits('mounted');
});

onUnmounted(() => {
  if (hasOverviewMap.value) {
    removeLayerFromOverview(props.layer);
  } else {
    getMap().removeLayer(props.layer);
  }

  emits('unmounted');
});

provide(mapLayerKey, () => props.layer);

watch(
  () => props.layer,
  (newValue, oldValue) => {
    getMap().removeLayer(oldValue);
    getMap().addLayer(newValue);
  }
);
</script>
<template>
  <slot />
</template>
