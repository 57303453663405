export enum StyleEnum {
  Confirmed = 'Confirmed',
  Created = 'Created',
  InProgress = 'InProgress',
  Rejected = 'Rejected',
  ToReview = 'ToReview',
}

export type MarkerStyle = {
  icon: string;
};

export type MarkerStyles = {
  [key in StyleEnum]: MarkerStyle;
};

export type FeatureStyle = {
  fillColor: string;
  strokeColor: string;
};

export type FeatureStyles = {
  [key in StyleEnum]: FeatureStyle;
};
