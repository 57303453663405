<script lang="ts" setup>
import IssueReviews from '@/features/issues/components/issueReviews/IssueReviews.vue';
import useIssuePhaseReviews from '@/features/issues/composables/useIssuePhaseReviews';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import Article, { ArticleBody } from '@/features/theme/base/article';
import Translate from '@/features/translations/Translate.vue';
import { computed } from 'vue';
import List from '@/features/theme/base/List.vue';
import ListItem from '@/features/theme/base/ListItem.vue';

const props = defineProps<{
  issue: IssueDetails;
  phase: IssuePhase;
}>();

const phase = computed(() => props.phase);
const issue = computed(() => props.issue);

const { requiredReviewersWithoutCommentCombined } = useIssuePhaseReviews(phase);
</script>
<template>
  <div>
    <Article class="mb-1">
      <ArticleBody>
        <h4 class="h6">
          <Translate t="issue.phase.reviewers.awaiting" />
        </h4>
      </ArticleBody>
      <ArticleBody>
        <List>
          <ListItem
            v-for="(reviewer, index) in requiredReviewersWithoutCommentCombined"
            :key="index"
          >
            <div class="reviewer">
              <div class="reviewer__name">{{ reviewer.name }}</div>
              <div class="reviewer__email">{{ reviewer.email }}</div>
            </div>
          </ListItem>
        </List>
      </ArticleBody>
    </Article>
    <IssueReviews
      canAlwaysWriteReview
      :phase="phase"
      :issue="issue"
    />
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/utilities/helpers';

.reviewer {
  .reviewer__name {
    @extend .text-primary;
    @extend .bold;
    margin-bottom: 0.5rem;
  }
}
</style>
