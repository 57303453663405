interface UseProcessTranslation {
  getProcessKey(issueProcess: Maybe<{ title: Maybe<string> }>): string;
  getProcessInformationKey(issueProcess: Maybe<{ title: Maybe<string> }>, relative?: Maybe<string>): string;
  getProcessPhaseKey(issueProcess: Maybe<{ title: Maybe<string> }>, issuePhase: Maybe<{ name: Maybe<string> }>): string;
  getPhaseStepKey(issueProcess: Maybe<{ title: Maybe<string> }>, issuePhase: Maybe<{ name: Maybe<string> }>, issueStep: Maybe<{ name: Maybe<string> }>): string;
}

export default function useProcessTranslation(): UseProcessTranslation {
  const getProcessKey = (issueProcess: Maybe<{ title: Maybe<string> }>): string => {
    return issueProcess?.title || 'process';
  };

  const getProcessInformationKey = (issueProcess: Maybe<{ title: Maybe<string> }>, relative?: Maybe<string>): string => {
    const key = `${getProcessKey(issueProcess)}.Grunnupplýsingar`;

    if (relative) {
      return `${key}.${relative}`;
    }

    return key;
  };

  const getProcessPhaseKey = (issueProcess: Maybe<{ title: Maybe<string> }>, issuePhase: Maybe<{ name: Maybe<string> }>): string => {
    return `${getProcessKey(issueProcess)}.${issuePhase?.name || 'phase'}`;
  };

  const getPhaseStepKey = (
    issueProcess: Maybe<{ title: Maybe<string> }>,
    issuePhase: Maybe<{ name: Maybe<string> }>,
    issueStep: Maybe<{ name: Maybe<string> }>
  ): string => {
    return `${getProcessPhaseKey(issueProcess, issuePhase)}.${issueStep?.name || 'step'}`;
  };

  return {
    getProcessKey,
    getProcessInformationKey,
    getProcessPhaseKey,
    getPhaseStepKey,
  };
}
