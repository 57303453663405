<script lang="ts" setup>
import Accordion from '@/features/components/Accordion.vue';
import useIssueProcessCategories from '@/features/issues/composables/useIssueProcessCategories';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import Icon from '@/features/theme/base/Icon.vue';
import Translate from '@/features/translations/Translate.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import { computed } from 'vue';
import useAllIssueProcessCategories from '@/features/issues/composables/useAllIssueProcessCategories';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
  }>(),
  {
    modelValue: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string[]): void;
}>();

const { categoriesSorted } = useAllIssueProcessCategories();

const processesCount = computed<Number>(() => {
  var count = 0;
  categoriesSorted.value.forEach((element) => {
    count += element.processes.length;
  });
  return count;
});

const isSelected = (processId: string): boolean => {
  return props.modelValue.includes(processId);
};

const updateSelected = (processId: string, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, processId]);
  } else {
    const idx = props.modelValue.findIndex((id) => id === processId);
    if (idx !== -1) {
      emit('update:modelValue', [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)]);
    }
  }
};

const selectAllProcesses = () => {
  if (isEverythingSelected()) {
    props.modelValue.length = 0;
  } else {
    categoriesSorted.value.forEach((category) => {
      category.processes.forEach((process) => {
        if (!props.modelValue.includes(process.id)) {
          props.modelValue.push(process.id);
        }
      });
    });
  }
  emit('update:modelValue', [...props.modelValue]);
};

const isEverythingSelected = (): boolean => {
  return props.modelValue.length == processesCount.value;
};
</script>
<template>
  <Checkbox
    @update:model-value="selectAllProcesses"
    :model-value="isEverythingSelected()"
    id="all-processes"
  >
    <Translate t="monitoring.tab.ferli.oll_ferli" />
  </Checkbox>
  <LayoutFormGroup
    title="monitoring.header.types"
    fullwidth
  >
    <LayoutInput>
      <Accordion
        v-for="category in categoriesSorted"
        :key="category.type"
        class="pb-1"
      >
        <template #default="{ accordionToggle, accordionBodyVisible }">
          <!-- <div @click="accordionToggle" class="flex accordion-header cursor-pointer" :class="{ 'pb-1': !accordionBodyVisible }"> -->
          <div
            @click="accordionToggle"
            class="flex accordion-header cursor-pointer"
          >
            <h4 class="p--large">{{ category.name }}</h4>
            <Icon
              class="icon"
              icon="DropdownArrow"
              :options="{ width: 24, height: 24, color: Colors.grey600 }"
              :class="{ 'rotate-180': accordionBodyVisible }"
            />
          </div>
        </template>
        <template #body>
          <ul class="monitoring__accordion-list">
            <li
              v-for="process in category.processes"
              :key="process.id"
            >
              <Checkbox
                :model-value="isSelected(process.id)"
                @update:model-value="(val) => updateSelected(process.id, val)"
                :id="`process-${process.id}`"
                :key="process.id"
              >
                {{ process.title }}
              </Checkbox>
            </li>
          </ul>
        </template>
      </Accordion>
    </LayoutInput>
  </LayoutFormGroup>
</template>
<style lang="scss" scoped>
.accordion-header {
  display: flex;
  justify-content: space-between;
}

.icon {
  transition: all 0.3s;

  &.rotate-180 {
    transform: rotateX(180deg);
  }
}

.monitoring__accordion-list {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>
