<script lang="ts" setup>
import IssueOverviewStep from '@/features/issues/components/issueDetails/IssueOverviewStep.vue';
import useIssuePhaseSteps from '@/features/issues/composables/useIssuePhaseSteps';
import { IssuePhase, Issue } from '@/features/issues/models';
import { IssuePhaseStepEnum } from '@/generated/graphql';
import { computed } from 'vue';

const props = defineProps<{
  phase: IssuePhase;
  issue: Issue;
}>();

const { orderedSteps } = useIssuePhaseSteps(computed(() => props.phase));
const filteredSteps = computed(() => orderedSteps.value.filter(({ type }) => type !== IssuePhaseStepEnum.UmsjonUmsagna));
</script>
<template>
  <ul>
    <IssueOverviewStep
      v-for="step in filteredSteps"
      :key="step.id"
      :step="step"
      :phase="phase"
      :issue="issue"
    />
  </ul>
</template>
