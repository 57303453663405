import { computed, inject } from 'vue';
import { InjectionGetter, mapOverviewKey } from '@/features/map/models/mapSymbols';
import Layer from 'ol/layer/Layer';
import OverviewMap from 'ol/control/OverviewMap';

export default function useOverviewMap() {
  let getOverviewMap: Maybe<InjectionGetter<OverviewMap>> = inject(mapOverviewKey, undefined);

  const addLayerToOverview = (layer: Layer) => {
    if (getOverviewMap) {
      getOverviewMap().getOverviewMap().addLayer(layer);
    }
  };

  const removeLayerFromOverview = (layer: Layer) => {
    if (getOverviewMap) {
      getOverviewMap().getOverviewMap().removeLayer(layer);
    }
  };

  const hasOverviewMap = computed(() => !!getOverviewMap);

  return {
    hasOverviewMap,
    addLayerToOverview,
    removeLayerFromOverview,
  };
}
