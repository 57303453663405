<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { computed, ref } from 'vue';
import Button from './Button.vue';
import Icon, { IconOptions, IconType } from './Icon.vue';
import { Colors } from './models/Colors';
const props = withDefaults(
  defineProps<{
    modelValue?: Maybe<string>;
    label?: string;
    disabled?: boolean;
    fullwidth?: boolean;
    includeButton?: boolean;
    iconOptions?: IconOptions;
    icon?: IconType;
    autofocus?: boolean;
  }>(),
  {
    modelValue: '',
    disabled: false,
    fullwidth: false,
    includeButton: true,
    iconOptions: (): IconOptions => ({}),
    icon: 'Search',
    autofocus: false,
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'inputSubmit'): void;
  (e: 'focus'): void;
  (e: 'focusout'): void;
  (e: 'change', value: string): void;
}>();
const isFocus = ref(false);
const isActive = computed(() => isFocus.value || props.modelValue !== '');
const onInput = (e: Event) => emit('update:modelValue', (e.target as HTMLInputElement).value || '');
const iconOptionsCombined = computed<IconOptions>(() => ({
  color: Colors.black,
  height: 24,
  width: 24,
  ...props.iconOptions,
}));

const onFocus = () => {
  emit('focus');
  isFocus.value = true;
};

const onFocusOut = () => {
  emit('focusout');
  isFocus.value = false;
};

const onChange = (e: Event) => {
  emit('change', (e.target as HTMLInputElement).value || '');
};
</script>

<template>
  <div class="input_container" :class="{ active: isActive, disabled, fullwidth }">
    <slot v-if="label !== undefined" name="label">
      <label class="text_label" :for="label">
        <Translate :value="label" />
      </label>
    </slot>
    <Icon class="search-icon" :icon="icon" :options="iconOptionsCombined" />
    <input
      v-focus="autofocus"
      v-bind="$attrs"
      :value="modelValue"
      @input="onInput"
      class="input-search"
      type="text"
      @focus="onFocus"
      @focusout="onFocusOut"
      :disabled="disabled"
      :id="label"
      @change="onChange"
    />
    <button v-if="includeButton" class="search-btn" @click="$emit('inputSubmit')">
      <Translate t="base.search" />
    </button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.input_container {
  position: relative;
  display: flex;
  height: 6.4rem;
  max-width: 40.4rem;
  width: 100%;
  .search-icon {
    position: absolute;
    top: 20px;
    left: 23px;
  }
  .text_label {
    left: 6.8rem;
  }

  .input-search {
    padding-left: 6.7rem;
    max-width: 40.4rem;
    width: 100%;
  }

  &.fullwidth {
    max-width: unset;

    .input-search {
      max-width: unset;
    }
  }
}

.search-btn {
  position: absolute;
  bottom: 1px;
  right: 1px;
  padding: 1.7rem 3.2rem 1.8rem 3.2rem;
  color: colors.$primary;
  background-color: colors.$primary--light;
}
</style>
