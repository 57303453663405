<script lang="ts" setup>
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import { IssueStatusEnum } from '@/generated/graphql';
import useAuthUser from '@/features/auth/useAuthUser';
import IssueStatusCheckbox from './IssueStatusCheckbox.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import useIssueStatus from '@/features/issues/composables/useIssueStatus';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    modelValue: IssueStatusEnum[];
    filter: string[];
    title?: string;
  }>(),
  {
    modelValue: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: IssueStatusEnum[], filter: string[]): void;
}>();

const { mapIssueStatusTranslationKey } = useIssueStatus();
const { t } = useI18n();

const { isAuthenticated } = useAuthUser();

const isSelected = (status: IssueStatusEnum): boolean => {
  return props.modelValue.includes(status);
};

const updateSelected = (status: IssueStatusEnum, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, status], [...props.filter, t(mapIssueStatusTranslationKey(status))]);
  } else {
    const idx = props.modelValue.findIndex(pc => pc === status);
    if (idx !== -1) {
      emit(
        'update:modelValue',
        [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)],
        [...props.filter.slice(0, idx), ...props.filter.slice(idx + 1)]
      );
    }
  }
};
</script>
<template>
  <LayoutFormGroup fullwidth>
    <div
      class="grid-container"
      :class="{ 'cols-1': !isAuthenticated }"
    >
      <div class="grid-item">
        <IssueStatusCheckbox
          :is-selected="isSelected(IssueStatusEnum.InProgress)"
          :issue-status="IssueStatusEnum.InProgress"
          @update:model-value="val => updateSelected(IssueStatusEnum.InProgress, val)"
          icon-type="Edit"
          :icon-color="Colors.blueDark"
          custom-class="in-progress"
        />
      </div>
      <div class="grid-item">
        <IssueStatusCheckbox
          :is-selected="isSelected(IssueStatusEnum.Complete)"
          :issue-status="IssueStatusEnum.Complete"
          @update:model-value="val => updateSelected(IssueStatusEnum.Complete, val)"
          icon-type="Results"
          custom-class="complete"
        />
      </div>
      <div class="grid-item">
        <IssueStatusCheckbox
          :is-selected="isSelected(IssueStatusEnum.Reviewing)"
          :issue-status="IssueStatusEnum.Reviewing"
          @update:model-value="val => updateSelected(IssueStatusEnum.Reviewing, val)"
          icon-type="Time"
          custom-class="reviewing"
        />
      </div>
      <div class="grid-item">
        <IssueStatusCheckbox
          :is-selected="isSelected(IssueStatusEnum.InSlsReview)"
          :issue-status="IssueStatusEnum.InSlsReview"
          @update:model-value="val => updateSelected(IssueStatusEnum.InSlsReview, val)"
          icon-type="Edit"
          :icon-color="Colors.blueDark"
          custom-class="in-sls-review"
        />
      </div>
      <div
        v-if="isAuthenticated"
        class="grid-item"
      >
        <IssueStatusCheckbox
          :is-selected="isSelected(IssueStatusEnum.Unpublished)"
          :issue-status="IssueStatusEnum.Unpublished"
          @update:model-value="val => updateSelected(IssueStatusEnum.Unpublished, val)"
          icon-type="Error"
          :icon-color="Colors.red"
          custom-class="unpublished"
        />
      </div>
      <div class="grid-item">
        <IssueStatusCheckbox
          :is-selected="isSelected(IssueStatusEnum.InReviewProcedure)"
          :issue-status="IssueStatusEnum.InReviewProcedure"
          @update:model-value="val => updateSelected(IssueStatusEnum.InReviewProcedure, val)"
          icon-type="Time"
          custom-class="reviewing"
        />
      </div>
    </div>
  </LayoutFormGroup>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include mq.mobile() {
    grid-template-columns: repeat(1, 1fr);
  }

  &.cols-1 {
    @include mq.desktop() {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.grid-item {
  display: inline-flex;
  padding: 1rem 0 1rem 0;
  align-items: center;
  @include mq.mobile() {
    padding: 0.5rem 0 0.5rem 0;
  }
}
</style>
