<script lang="ts" setup></script>
<template>
  <div class="layout-form">
    <div v-if="$slots.header" class="layout-form-header">
      <slot name="header" />
    </div>
    <slot />
    <div v-if="$slots.footer" class="layout-form-footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;

.layout-form {
  display: flex;
  flex-direction: column;
  gap: spacing.$gutter-large;
}
</style>
