<script lang="ts" setup>
import useNavigation from '../navigation/useNavigation';
import Article from '../theme/base/article';
import Button from '../theme/base/Button.vue';
import Modal from '../theme/base/Modal.vue';
import { ButtonType } from '../theme/base/models/ButtonType';
import Translate from '../translations/Translate.vue';

withDefaults(
  defineProps<{
    prompt?: boolean;
  }>(),
  {
    prompt: false,
  }
);

const emits = defineEmits<{
  (e: 'cancel'): void;
}>();

const onCancel = () => {
  emits('cancel');
};

const { pushActiveAreaRouteByName } = useNavigation();
const onLogin = () => {
  pushActiveAreaRouteByName('Login');
};
</script>
<template>
  <Modal :open="prompt" @close="onCancel">
    <Article class="flex flex-column">
      <div class="flex flex-column mb-4">
        <h4 class="h4 text-primary mb-2">
          <Translate t="auth.prompt.title" />
        </h4>
        <p v-if="$slots.help" class="regular login-text">
          <slot name="help" />
        </p>
      </div>
      <div class="flex flex-column mb-4">
        <Button class="mb-2" @click="onLogin" :type="ButtonType.primary">
          <Translate t="base.login" />
        </Button>
        <Button @click="onCancel" :type="ButtonType.secondary">
          <Translate t="base.cancel" />
        </Button>
      </div>
    </Article>
  </Modal>
</template>

<style lang="scss" scoped>
.flex-column {
  flex-direction: column;
}

.login-text {
  font-size: 1.8rem;
}
</style>
