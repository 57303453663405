import { useI18n } from 'vue-i18n';
import { QuestionAndAnswer } from '../QuestionAndAnswer';
import { computed } from 'vue';

export const useQuestionsAndAnswers = () => {
  const { messages: localizedMessages, locale } = useI18n();
  const PREFIX = 'faq.routes.faq.qna.';

  const questionsAndAnswers = computed(() => {
    const messages = localizedMessages.value[locale.value];
    const qnasKeys = Object.keys(messages).filter((m) => m.startsWith(PREFIX));
    const qnas: any = {};

    qnasKeys.forEach((key) => {
      const rex = /^faq.routes.faq.qna.(?<type>question|answer).(?<id>\d+)$/;
      const match = rex.exec(key);
      if (match?.groups) {
        const { type, id } = match.groups;
        if (qnas[id]) {
          qnas[id][type] = messages[key];
        } else {
          qnas[id] = {
            [type]: messages[key],
          };
        }
      }
    });

    return Object.values(qnas) as QuestionAndAnswer[];
  });

  return {
    questionsAndAnswers,
  };
};
