<script lang="ts" setup>
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { DrawTool } from '@/features/issues/models/issueMaps';
import Button from '@/features/theme/base/Button.vue';
import Icon, { IconType } from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  modelValue?: Maybe<DrawTool>;
  toggle?: boolean;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', tool?: Maybe<DrawTool>): void;
  (e: 'update:toggle', toggle: boolean): void;
}>();

const onDrawToolClick = (tool?: Maybe<DrawTool>) => {
  emits('update:modelValue', tool);
};

const onDrawToggleClick = () => {
  emits('update:toggle', !props.toggle);

  if (!props.toggle) {
    emits('update:modelValue');
  }
};

const drawToolMap: Record<DrawTool, Partial<IconType>> = {
  LineString: 'Linestring',
  Point: 'Point',
  Polygon: 'Polygon',
};

const { t } = useI18n();
</script>

<template>
  <div class="flex">
    <template v-if="toggle">
      <Button
        v-for="tool in Object.values(DrawTool)"
        :key="tool"
        @click="() => onDrawToolClick(tool)"
        :type="ButtonType.secondary"
        v-tooltip="{ content: t(`issue.map.draw.${tool}.tooltip`), theme: 'primary' }"
      >
        <Icon
          :icon="drawToolMap[tool] || 'Draw'"
          :options="{ color: Colors.primary, width: 24, height: 24 }"
        />
      </Button>
    </template>
    <Button
      v-tooltip="{ content: !toggle ? t('issue.map.draw.tooltip') : t('issue.map.draw-cancel.tooltip'), theme: 'primary' }"
      :type="ButtonType.secondary"
      @click="onDrawToggleClick"
    >
      <Icon
        icon="Draw"
        :options="{ color: Colors.primary, width: 24, height: 24 }"
        :fill="toggle ? Colors.primary : 'none'"
      />
    </Button>
  </div>
</template>
