<script setup lang="ts">
import IssuePill from '../issues/components/IssuePill.vue';
import { IssueType } from '../theme/base/models/PillProps';
import Button from '../theme/base/Button.vue';
import Icon from '../theme/base/Icon.vue';
import Translate from '../translations/Translate.vue';
import { FaqRouteName } from '@/features/faq/FaqRouteName';
import { useRouter } from 'vue-router';

const router = useRouter();

const onInstructionsClick = () => {
  router.push(`faq?name=${FaqRouteName.Instructions}`);
};
</script>

<template>
  <div class="content-container bg-white">
    <div class="text-container">
      <div class="pill-container">
        <IssuePill :pill-type="IssueType.document">
          <span class="pill-text bold color-primary">
            <Translate t="frontpage.text_and_video.tag" />
          </span>
        </IssuePill>
      </div>
      <h1 class="h3 heading">
        <Translate
          t="frontpage.text_and_video.title"
          :interpolations="{ hyphen: '&shy;' }"
        />
      </h1>
      <div class="subheading-icon-container">
        <Icon
          icon="Clock"
          :height="18"
          :width="18"
          class="icon"
        />
        <h2 class="subtitle">
          <Translate t="frontpage.text_and_video.heading.1" />
        </h2>
      </div>
      <p class="regular p--large paragraph">
        <Translate t="frontpage.text_and_video.content.1" />
      </p>
      <div class="ml-3 mb-4"></div>
      <div class="subheading-icon-container">
        <Icon
          icon="Refresh"
          :height="18"
          :width="18"
          class="icon"
        />
        <h2 class="subtitle"><Translate t="frontpage.text_and_video.heading.2" /></h2>
      </div>
      <p class="regular p--large paragraph">
        <Translate t="frontpage.text_and_video.content.2" />
      </p>
      <div class="ml-3 mb-4">
        <Button
          class="instruction-button"
          @click="onInstructionsClick"
        >
          <Translate t="frontpage.text_and_video.button" />
        </Button>
      </div>
    </div>
    <div class="videos-container">
      <div class="video-column-left">
        <div class="video-container">
          <!-- <button class="video-button">
            <Icon icon="Video" :height="35" :width="35"></Icon>
          </button> -->
          <img
            src="@/assets/Image01.png"
            class="video-img long left mb-3"
            alt="instruction video image 1"
          />
        </div>
        <div class="video-container">
          <!-- <button class="video-button">
            <Icon icon="Video" :height="35" :width="35"></Icon>
          </button> -->
          <img
            src="@/assets/Image03.png"
            class="video-img short left"
            alt="instruction video image 2"
          />
        </div>
      </div>
      <div class="video-column-right hidden-in-laptop hidden-in-tablet hidden-in-laptop-to-desktop">
        <img
          src="@/assets/Image02.png"
          class="video-img short right mb-3"
          alt="instruction video image 3"
        />
        <img
          src="@/assets/Image04.png"
          class="video-img long right"
          alt="instruction video image 4"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/spacing' as spacing;
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/media-queries' as media-queries;

.content-container {
  display: grid;
  padding-left: 7.5%;
  grid-template-columns: repeat(2, 1fr);
  gap: 4%;
  @include media-queries.laptop-down {
    grid-template-columns: 1fr;
    padding: 10rem 7.5%;
    gap: 0;
  }
  @include media-queries.mobile {
    flex-direction: column;
    align-items: center;
    padding: 8rem 2rem;
  }
}

.text-container {
  display: flex;
  flex-direction: column;
  max-width: 80rem;
  margin-top: auto;
  margin-bottom: auto;
}

.pill-container {
  margin-bottom: spacing.$space-medium * 5.5;
  @include media-queries.laptop-down {
    margin-bottom: spacing.$space-default;
  }
}

.btn_container {
  display: flex;
  gap: spacing.$space-large;

  @include media-queries.mobile {
    flex-direction: column;
  }
}

.heading {
  margin-bottom: spacing.$space-large * 4;
  overflow: hidden;

  @include media-queries.laptop-down {
    margin-bottom: spacing.$space-default * 3;
  }
}

.subheading-icon-container {
  display: flex;
  align-items: center;
  margin-bottom: spacing.$space-default;

  @include media-queries.laptop-down {
    margin-bottom: spacing.$space-medium;
  }
}

.icon {
  margin-right: spacing.$space-medium;
}

.paragraph {
  margin-left: spacing.$space-default * 2;
  max-width: 55rem;

  @include media-queries.laptop-down {
    margin-bottom: spacing.$space-default * 2;
    margin-right: spacing.$space-large;
  }
}

.instruction-button {
  margin-top: spacing.$space-x-large;
  width: fit-content;

  @include media-queries.laptop-down {
    margin-top: spacing.$space-default;
    margin-bottom: 2rem;
  }
}

.videos-container {
  display: flex;
  justify-content: flex-end;
  gap: 4%;
  padding: 10rem 0;

  @include media-queries.laptop-down {
    padding: 0;
    flex-direction: column;
    position: static;
  }
}

.video-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.video-column-left {
  width: 100%;
  max-width: 52.2rem;
  margin-top: spacing.$space-large * 5;

  @include media-queries.desktop-down {
    margin-top: spacing.$space-large;
    margin-right: 0;
  }
}

.video-column-right {
  width: 100%;
  max-width: 26rem;
  margin-top: spacing.$space-large;

  @include media-queries.desktop-down {
    max-width: 52.2rem;
    margin-top: 0;
  }
  @include media-queries.mobile {
    z-index: 0;
  }
}

.video-img {
  display: block;
  object-fit: cover;
  box-shadow: 0px 40px 80px rgba(0, 111, 126, 0.3);

  @include media-queries.laptop-down {
    margin-bottom: spacing.$space-default;
    filter: none;
    box-shadow: none;
  }
}
.long {
  width: 100%;
  min-height: 72rem;
  @include media-queries.laptop-down {
    min-height: 40rem;
  }
}

.short {
  width: 100%;
  min-height: 44rem;
  @include media-queries.laptop-down {
    min-height: 40rem;
  }
}

.left {
  border-radius: 0.8rem;
}

.right {
  border-radius: 0.8rem 0 0 0.8rem;
  @include media-queries.desktop-down {
    border-radius: 0.8rem;
  }
}

.video-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: color.$white;
  width: 7rem;
  height: 7rem;
  position: absolute;
  top: 2.4rem;
  right: 2.4rem;
  border-radius: 0.2rem;
  z-index: 1;
}
</style>
