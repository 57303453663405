import { useGetIssueTagsQuery, IssueTagTypes } from '@/generated/graphql';
import groupBy from 'lodash-es/groupBy';
import sortBy from 'lodash-es/sortBy';
import { computed } from 'vue';

function tagTypeRank(type: string): number {
  switch (type) {
    case 'MAT_A_UMHVERFISAHRIFUM':
      return 2;
    case 'SKIPULAG':
      return 1;
    case 'STRANDSVAEDI':
      return 3;
    default:
      return 99;
  }
}

export default function useIssueTags(tagType?: IssueTagTypes) {
  const { data, error, fetching } = useGetIssueTagsQuery();

  const tags = computed(() => {
    let _tags = data.value?.issueTags || [];
    if (tagType) {
      _tags = _tags.filter(t => t.tagType === tagType);
    }

    return _tags.map(t => t.value);
  });
  const tagsLoading = computed(() => fetching.value);
  const tagsError = computed(() => error.value);
  const tagsByType = computed(() => groupBy(data.value?.issueTags || [], 'tagType'));
  const tagTypeKeysSorted = computed(() => sortBy(Object.keys(tagsByType.value), tagTypeRank));

  return {
    tags,
    tagsLoading,
    tagsError,
    tagsByType,
    tagTypeKeysSorted,
  };
}
