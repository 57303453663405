import { IssueStatusEnum } from '@/generated/graphql';
import { TranslationKey } from '@/features/translations/defaultMessages';

export default function useIssueStatus() {
  const mapIssueStatusTranslationKey = (issueStatus: IssueStatusEnum): TranslationKey => {
    switch (issueStatus) {
      case IssueStatusEnum.InProgress:
        return 'issue.phase.state.published';
      case IssueStatusEnum.Complete:
        return 'issue.phase.state.closed';
      case IssueStatusEnum.Reviewing:
        return 'issue.phase.state.reviewing';
      case IssueStatusEnum.Unpublished:
        return 'issue.phase.state.unpublished';
      case IssueStatusEnum.InSlsReview:
        return 'issue.phase.state.in_sls_review';
      case IssueStatusEnum.InReviewProcedure:
        return 'issue.phase.state.in_review_procedure';
      default:
        return 'issue.status.error';
    }
  };
  return {
    mapIssueStatusTranslationKey,
  };
}
