<script lang="ts" setup>
import { ButtonType } from './models/ButtonType';
import Button from './Button.vue';
import Icon from './Icon.vue';
import { Colors } from './models/Colors';
import Translate from '@/features/translations/Translate.vue';

withDefaults(
  defineProps<{
    id?: string;
    label?: string;
  }>(),
  {
    label: 'file.label',
  }
);

const emits = defineEmits<{
  (e: 'files', filelist: FileList): void;
}>();

const onFileInput = (e: Event) => {
  const files = (e.target as HTMLInputElement).files;
  if (files) {
    emits('files', files);
    (e.target as HTMLInputElement).value = '';
  }
};

const onFileDrop = (e: DragEvent) => {
  if (e.dataTransfer?.files) {
    emits('files', e.dataTransfer.files);
  }
};
</script>
<template>
  <span
    @drop.prevent="onFileDrop"
    @dragover.prevent
  >
    <Button
      tag="label"
      :type="ButtonType.secondary"
    >
      <input
        :id="id"
        @input="onFileInput"
        class="hidden"
        type="file"
        multiple
      />
      <span :style="{ 'margin-right': '1rem' }">
        <Translate :value="label" />
      </span>
      <Icon
        icon="Plus"
        :options="{ width: 24, height: 24, color: Colors.primaryDark }"
      />
    </Button>
  </span>
</template>
<style lang="scss" scoped>
.btn {
  position: relative;
  font-weight: 700;
}
</style>
