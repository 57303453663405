<script lang="ts" setup>
import Checkbox from '@/features/theme/base/Checkbox.vue';
import useDelegations from '../composables/useDelegations';
import Translate from '@/features/translations/Translate.vue';
import Accordion from '@/features/components/Accordion.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';

const props = withDefaults(
  defineProps<{
    modelValue: string[];
    filter: string[];
    skipulagsstofnunDelegation?: boolean;
  }>(),
  {
    modelValue: () => [],
  }
);

const emit = defineEmits<{
  (e: 'update:modelValue', delegationIds: string[], delegationNames: string[]): void;
  (e: 'update:skipulagsstofnunDelegation', selected: boolean, delegationNames: string[]): void;
}>();

const { delegationsGroupedByType } = useDelegations();

const isSelected = (delegationId: string): boolean => {
  return props.modelValue.includes(delegationId);
};

const updateSelected = (delegationId: string, delegationName: string, selected: boolean): void => {
  if (selected) {
    emit('update:modelValue', [...props.modelValue, delegationId], [...props.filter, delegationName]);
  } else {
    const idx = props.modelValue.findIndex((pc) => pc === delegationId);
    if (idx !== -1) {
      emit('update:modelValue', [...props.modelValue.slice(0, idx), ...props.modelValue.slice(idx + 1)], [...props.filter.slice(0, idx), ...props.filter.slice(idx + 1)]);
    }
  }
};

const onSkipulagsstofnunDelegationChange = (selected: boolean) => {
  if (selected) {
    emit('update:skipulagsstofnunDelegation', selected, [...props.filter, "Skipulagsstofnun"]);
  } else {
    const idx = props.filter.findIndex((pc) => pc === "Skipulagsstofnun");
    if (idx !== -1) {
      emit('update:skipulagsstofnunDelegation', selected, [...props.filter.slice(0, idx), ...props.filter.slice(idx + 1)]);
    }
  }
};

</script>
<template>
  <div v-for="(delegations, type) in delegationsGroupedByType" >  
    <Accordion :show-body="true">
      <template #default="{ accordionToggle, accordionBodyVisible }">
        <div 
          @click="accordionToggle" 
          class="flex accordion-header cursor-pointer my-1"
        >
          <h5 class="accordion-caption">
            <Translate :value="`search.delegations.${type}`" />
          </h5>
          <Icon 
            class="icon" 
            icon="DropdownArrow" 
            :options="{ width: 24, height: 24, color: Colors.grey600 }" 
            :class="{ 'rotate-180': accordionBodyVisible }" 
          />
        </div>
      </template>
      <template #body>
        <div class="accordion-body">
          <div 
            class="grid-container"
            :class="{'communities' : type == 'COMMUNITY'}"
          >
            <div 
              v-for="delegation in delegations" 
              class="grid-item"
            >
              <Checkbox
                :id="delegation.id"
                :model-value="isSelected(delegation.id)"
                @update:model-value="(val) => updateSelected(delegation.id, delegation.entityName, val)"
              >
                {{ delegation.entityName }}
              </Checkbox>
            </div>
            <div 
              v-if="type == 'OTHER'" 
              class="grid-item"
            >
              <Checkbox
                id="skipulagsstofnun"
                :model-value="skipulagsstofnunDelegation"
                @update:model-value="onSkipulagsstofnunDelegationChange"
              >
                Skipulagsstofnun
              </Checkbox>
            </div>
          </div>
        </div>
      </template>
    </Accordion>
  </div> 
</template>

<style lang="scss" scoped>
  @use '@/scss/design-tokens/media-queries' as mq;
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    
    &.communities {
      @include mq.desktop() {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @include mq.mobile() {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .grid-item {
    display: inline-flex;
    padding: 0.75rem;
    align-items: center;
    @include mq.mobile() {
      padding: 0.5rem;
      width: 90%;
    }
  }

  .accordion-header {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    @include mq.mobile() {
      font-size: 1.5rem;
      margin-top: 1rem;
    }
  }

  .icon {
    transition: all 0.3s;
    &.rotate-180 {
      transform: rotateX(180deg);
    }
  }

  .accordion-body {
    box-sizing: border-box;
  }

  .accordion-caption {
    font-size: 1.8rem;
    @include mq.mobile() {
      font-size: 1.6rem;
    }
  }
</style>
