<script lang="ts" setup>
import { computed, ref } from 'vue';
import type { IssueDetails, IssuePhase } from '@/features/issues/models';
import LayoutWithAside from '@/features/theme/base/layouts/LayoutWithAside.vue';
import IssueAside from './IssueAside.vue';
import IssueProgress from './IssueProgress.vue';
import IssueMap from '@/features/issues/components/issueMap/IssueMap.vue';
import IssueOverview from './IssueOverview.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import useWatchedIssues from '@/features/issues/composables/useWatchedIssues';
import useAuthUser from '@/features/auth/useAuthUser';
import Icon from '@/features/theme/base/Icon.vue';
import useNavigation from '@/features/navigation/useNavigation';
import SubMenu from '@/features/navigation/SubMenu.vue';
import { useAccessControl } from '@/features/auth/useAccessControl';
import IssueReviews from '@/features/issues/components/issueReviews/IssueReviews.vue';
import IssueTags from '../IssueTags.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType, ButtonSize } from '@/features/theme/base/models/ButtonType';
import LoginPrompt from '@/features/auth/LoginPrompt.vue';
import Translate from '@/features/translations/Translate.vue';
import { useI18n } from 'vue-i18n';
import { IssuePhaseStepEnum } from '@/generated/graphql';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import { issueProcessYearOrderPath } from '@/features/issues/issueYearOrderPath';
import LoadingComponent from '@/features/theme/LoadingComponent.vue';

const props = defineProps<{
  issue: IssueDetails;
  fetchingReviews: boolean;
}>();

const issue = computed(() => props.issue);
const issueId = computed(() => props.issue.id);

const { findById } = useCollectionSearching();

const { isAuthenticated } = useAuthUser();

const { toggleWatchIssue, isIssueWatched } = useWatchedIssues();
const onBookmarkClick = () => {
  if (isAuthenticated.value && issue.value) {
    toggleWatchIssue(issue.value.id);
  } else {
    loginPrompt.value = true;
  }
};

const { pushActiveAreaRouteByName } = useNavigation();
const { user } = useAuthUser();
const { canEditIssue } = useAccessControl();

const isBookmarked = computed(() => !!(issue.value && isIssueWatched(issue.value.id)));

const loginPrompt = ref<boolean>(false);
const onLoginCancel = () => {
  loginPrompt.value = false;
};

const { t } = useI18n();

const selectedPhaseId = ref<ID>();
const selectedPhase = computed<Maybe<IssuePhase>>(() => {
  if (issue.value && selectedPhaseId.value) {
    const phase = findById(issue.value.phases, selectedPhaseId.value);

    if (phase) {
      return phase;
    }
  }
  return issue.value?.currentPhase;
});

const hasReviews = computed<boolean>(() => {
  if (!selectedPhase.value) {
    return false;
  }

  const hasReviewStep = selectedPhase.value.steps.find(step => step.type === IssuePhaseStepEnum.UmsjonUmsagna);
  return !!hasReviewStep;
});

const onHistoryClick = () => {
  if (issueId.value) {
    pushActiveAreaRouteByName('Audits', { issueId: issueId.value });
  }
};
</script>
<template>
  <div>
    <SubMenu
      type="warning"
      v-if="user && canEditIssue(issue)"
    >
      <p class="regular edit-notice flex flex-center">
        <span class="notice-text">
          <span class="bold">
            <Translate
              t="user.role"
              :interpolations="{
              role: t(`role.${user!.role}`)
            }"
            />
          </span>
          <Translate t="issue.can_edit" />
          <RouterLink
            class="edit-link--mobile"
            :to="issueProcessYearOrderPath(issue)"
          >
            <Icon
              icon="Edit"
              :options="{ width: 24, height: 24, color: Colors.primary }"
            />
            <span class="color-primary flex flex-center bold ml-1">
              <Translate t="issue.edit" />
            </span>
          </RouterLink>
        </span>
        <RouterLink
          class="edit-link"
          :to="issueProcessYearOrderPath(issue)"
        >
          <Icon
            icon="Edit"
            :options="{ width: 24, height: 24, color: Colors.primary }"
          />
          <span class="color-primary flex flex-center bold ml-1">
            <Translate t="issue.edit" />
          </span>
        </RouterLink>
      </p>
    </SubMenu>
    <div class="container--medium progress-container">
      <IssueProgress
        :issue="issue"
        v-model="selectedPhaseId"
      />
    </div>
    <LayoutWithAside>
      <div class="mb-5 bg-white">
        <Alert
          v-if="issue.isHidden"
          :alert-type="AlertType.warning"
        >
          <Translate t="issue.hidden.outer" />
        </Alert>
        <IssueOverview
          v-if="selectedPhase"
          :issue="issue"
          :phase="selectedPhase"
        >
          <template #top>
            <div class="top-container">
              <IssueTags :issue="issue" />
              <div class="btns-container">
                <Button
                  @click="onBookmarkClick"
                  :type="ButtonType.secondary"
                  :size="ButtonSize.medium"
                  v-tooltip="{ content: t('monitoring.tooltip'), theme: 'primary' }"
                >
                  <span class="mr-2">
                    <Translate t="issue.monitor" />
                  </span>
                  <Icon
                    icon="Bookmark"
                    :options="{ height: 22, width: 22 }"
                    :filled="isBookmarked"
                  />
                </Button>
                <Button
                  @click="onHistoryClick"
                  :type="ButtonType.secondary"
                  :size="ButtonSize.medium"
                  v-tooltip="{ content: t('audits.view.tooltip'), theme: 'primary' }"
                  v-if="user && canEditIssue(issue)"
                >
                  <Icon
                    icon="History"
                    :options="{ height: 22, width: 22 }"
                  />
                </Button>
              </div>
            </div>
          </template>
        </IssueOverview>
      </div>
      <div
        class="mb-5 bg-white"
        v-if="hasReviews"
      >
        <span v-if="selectedPhase">
          <LoadingComponent
            v-if="fetchingReviews"
            loading-message="Augnablik, sæki umsagnir..."
          />
          <IssueReviews
            v-else
            :phase="selectedPhase"
            :issue="issue"
          />
        </span>
      </div>
      <div class="mb-5 bg-white">
        <div class="map-container">
          <IssueMap
            v-if="issueId"
            class="map"
            :issue-id="issueId.toString()"
          />
        </div>
      </div>
      <template #aside>
        <IssueAside
          :issue="issue"
          :editing="false"
        />
      </template>
    </LayoutWithAside>
    <LoginPrompt
      :prompt="loginPrompt"
      @cancel="onLoginCancel"
    >
      <template #help>
        <Translate t="monitoring.prompt" />
      </template>
    </LoginPrompt>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;
@use '@/scss/design-tokens/colors' as color;

.top-container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  @include media.mobile {
    gap: 1rem;
    flex-wrap: wrap;
  }
}
.btns-container {
  white-space: nowrap;
  display: flex;
  gap: 1rem;

  @include media.mobile {
    width: 100%;
  }
}

.progress-container {
  margin-bottom: 1.2rem;

  @include media.laptop() {
    margin-bottom: 6.9rem;
  }
}

.map {
  height: 60vh;
}

.mini-block {
  margin-bottom: 2rem;
}

.icon-right {
  margin-left: 1.5rem;
}

.edit-notice {
  gap: 1.6rem;
  padding-left: 1.6rem;
  padding-right: 1.6rem;
}

.notice-text {
  display: flex;
  flex-flow: row wrap;
  gap: 0.8rem;
}

.edit-link {
  padding-left: 1.9rem;
  border-left: 1px solid color.$grey-300;
  display: none;
  justify-content: center;
  align-items: center;

  @include media.laptop() {
    display: flex;
  }

  &--mobile {
    display: flex;
    @include media.laptop() {
      display: none;
    }
  }
}
</style>
