<template>
  <RendererLoading v-if="fetching" />
  <PageRenderer v-else-if="page !== null" :page="page" />
  <PageError v-else v-bind="error" />
</template>
<script lang="ts" setup>
import PageError from './pages/PageError.vue';
import RendererLoading from './RendererLoading.vue';
import PageRenderer from './PageRenderer.vue';
import { useGetPageQuery } from '@/generated/graphql';
import { computed } from 'vue';

const props = defineProps<{ pageId: ID }>();

const pageVariables = computed(() => ({ id: props.pageId.toString() }));
const { data, error, fetching } = useGetPageQuery({ variables: pageVariables, requestPolicy: 'cache-and-network' });
const page = computed(() => data.value?.page || null);
</script>
