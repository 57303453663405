<script lang="ts" setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { IssueSpecificationInput } from '@/generated/graphql';
import IssueDetailsContent from '@/features/issues/components/issueDetails/IssueDetailsContent.vue';
import PageError from '@/features/rendering/pages/PageError.vue';
import LoadingPage from '@/features/theme/LoadingPage.vue';
import useSingleIssue from '../../composables/useSingleIssue';

const route = useRoute();

const specs = computed<IssueSpecificationInput>(() => {
  if (typeof route.params.issueId === 'string') {
    return {
      issueId: route.params.issueId,
    };
  } else if (typeof route.params.issueYear === 'string' && typeof route.params.issueYearOrder === 'string') {
    return {
      issueYearOrderInput: {
        year: route.params.issueYear,
        order: route.params.issueYearOrder,
      },
    };
  }
  return {};
});

if (!Object.keys(specs.value).length) {
  throw new Error('404');
}

const { issue, fetching, fetchingReviews } = useSingleIssue(specs);
</script>
<template>
  <LoadingPage v-if="fetching" />
  <IssueDetailsContent
    v-else-if="issue"
    :issue="issue"
    :fetching-reviews="fetchingReviews"
  />
  <PageError v-else />
</template>
