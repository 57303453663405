<script lang="ts" setup>
import Tabs from '@/features/theme/base/Tabs.vue';
import Tab from '@/features/theme/base/Tab.vue';
import useActiveIssue from '@/features/issueProcess/composables/useActiveIssue';
import { IssueDetails, IssueStep } from '@/features/issues/models';
import { computed, ref } from 'vue';
import { useProcessStore } from '@/features/issueProcess/composables/useProcessStore';

const props = defineProps<{
  activeIssue: IssueDetails;
}>();

const emits = defineEmits<{
  (e: 'tabChange'): void;
  (e: 'triggerIssueSaveWarning', intendedtab: ID): void;
}>();

const { selectedPhaseActiveStep, selectedStep, selectStep, selectedPhaseStepsOrdered, selectedPhase } = useActiveIssue(computed(() => props.activeIssue));
const processStore = useProcessStore();

const moveStep = async (step: IssueStep) => {
  if (canClickStepTab(step)) {
    if (processStore.isDirty) {
      emits('triggerIssueSaveWarning', step.id || '');
      return;
    }

    selectStep(step.id);
  }
};

const onTabClick = (tab: string) => {
  const step = selectedPhaseStepsOrdered.value.find(({ name }) => name === tab);
  if (selectedPhase.value?.isCommunicationPhase) {
    emits('tabChange');
  }

  if (step) {
    moveStep(step);
  }
};

const canClickStepTab = (step: IssueStep): boolean => {
  return selectedPhase.value?.isCommunicationPhase || !!(selectedPhaseActiveStep.value && step.order <= selectedPhaseActiveStep.value.order);
};

const stepLabel = (step: IssueStep): string => {
  return `${props.activeIssue.process.title || 'process'}.${selectedPhase.value?.name || 'phase'}.${step.name}`;
};
</script>
<template>
  <div class="container--medium">
    <Tabs>
      <Tab
        v-for="step in selectedPhaseStepsOrdered"
        :class="{ bold: selectedPhaseActiveStep?.id === step.id }"
        @click="() => onTabClick(step.name)"
        :key="step.id"
        :active="step.id === selectedStep?.id"
        :value="stepLabel(step)"
        :default-value="step.name"
        :disabled="!canClickStepTab(step)"
        :clickable="canClickStepTab(step)"
      />
    </Tabs>
  </div>
</template>
