<script setup lang="ts">
import Button from '../theme/base/Button.vue';
import { ButtonType, ButtonSize } from '../theme/base/models/ButtonType';
import LayoutSplit from '@/features/theme/base/layouts/LayoutSplit.vue';
import DirectionType from '../theme/icons/models/DirectionType';
import { Colors } from '../theme/base/models/Colors';
import Arrow from '../theme/icons/Arrow.vue';
import Translate from '../translations/Translate.vue';
import useNavigation from '../navigation/useNavigation';

const { pushActiveAreaRouteByName } = useNavigation();

const onIssueListClick = () => {
  pushActiveAreaRouteByName('Issues');
};
</script>

<template>
  <div class="bg-primary--dark hero-container">
    <div>
      <h1 class="h1 color-white hero-title">
        <Translate
          t="frontpage.hero"
          :interpolations="{ hyphen: '&shy;' }"
        />
      </h1>
      <p class="regular subtitle color-white hero-subtitle"><Translate t="frontpage.hero.subtitle" /></p>
      <Button
        @click="onIssueListClick"
        :type="ButtonType.quinary"
        :size="ButtonSize.medium"
        class="mb-2"
      >
        <Translate t="frontpage.hero.button" />
        <Arrow
          :custom-class="'icon-right'"
          :direction="DirectionType.right"
          :heigth="24"
          :width="24"
          :color="Colors.primary"
        ></Arrow>
      </Button>
    </div>
    <div>
      <div class="hero-images">
        <img
          src="@/assets/map-outlines.png"
          class="hero-image1"
          alt="topographic landscape image 1"
        />
        <img
          src="@/assets/map-outlines2.png"
          class="hero-image2"
          alt="topographic landscape image 2"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as media;

.hero-container {
  min-height: calc(100vh - 11.2rem);
  background-image: url('@/assets/Line-left.svg');
  background-repeat: no-repeat;
  background-position: right -14rem;
  padding: 10rem 0 20rem 14.4rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3.2rem;

  @include media.desktop-down {
    padding: 10rem 25rem 20rem 14.4rem;
    grid-template-columns: 1fr;
  }

  @include media.laptop-down {
    padding-right: 10rem;
  }

  @include media.mobile {
    padding: 5rem 2rem;
    background-position: right -5rem;
    background-image: url('@/assets/Line-left-opacity.svg');
    height: calc(100vh + 8rem);
  }

  @include media.mobile-small {
    padding-top: 3rem;
    height: calc(100vh + 12rem);
  }
}
.hero-title {
  margin-bottom: 2rem;
  overflow: hidden;
  word-wrap: break-word;
}

.hero-subtitle {
  margin-bottom: 6rem;
  overflow: hidden;
  word-wrap: break-word;
  @include media.laptop-down {
    margin-bottom: 2rem;
  }
}

.hero-images {
  display: flex;
  justify-content: flex-end;
  gap: 3.2rem;

  @include media.desktop-down {
    display: none;
  }
}
.hero-image1 {
  box-shadow: 0 4rem 8rem rgba(0, 111, 126, 0.3);
  border-radius: 8px;
}
.hero-image2 {
  box-shadow: 0 4rem 8rem rgba(0, 111, 126, 0.3);
  border-radius: 0.8rem 0 0 0.8rem;
}
</style>
