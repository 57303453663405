<script lang="ts" setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import useAuthUser from '../auth/useAuthUser';
import { TranslationKey } from './defaultMessages';

const props = withDefaults(
  defineProps<{
    t?: TranslationKey;
    value?: Maybe<string>;
    interpolations?: Record<string | number, string | number | undefined>;
    defaultValue?: string;
  }>(),
  {
    interpolations: () => ({}),
  }
);

const { isAdmin } = useAuthUser();
const { t } = useI18n();

const translationKey = computed<Maybe<string>>(() => {
  if (props.t) {
    return props.t;
  }

  if (props.value && t(props.value) !== props.value) {
    return props.value;
  }

  return null;
});

const translationTitle = computed(() => {
  return isAdmin.value ? (translationKey.value ? translationKey.value : props.value) : undefined;
});

const onTranslationCtrlClick = () => {
  if (translationTitle.value) {
    navigator.clipboard.writeText(translationTitle.value);
  }
};
</script>
<template>
  <span @click.ctrl="onTranslationCtrlClick" :title="translationTitle || undefined">
    {{ translationKey ? t(translationKey, interpolations) : defaultValue || value }}
  </span>
</template>
