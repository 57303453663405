import Geometry from 'ol/geom/Geometry';
import { computed, Ref, ref } from 'vue';
import { FeatureDecorated } from '@/features/map/models/mapModels';
import { isFeatureCoordinatesEqual } from '../issueGeometriesBlacklist';

export default function useMapFeatureStack() {
  const featureStack: Ref<FeatureDecorated<Geometry>[]> = ref([]);
  const redoStack: Ref<FeatureDecorated<Geometry>[]> = ref([]);

  const pushFeature = (feature: FeatureDecorated<Geometry>) => {
    redoStack.value = [];
    if (!featureStack.value.includes(feature)) {
      featureStack.value.push(feature);
    }
  };

  const deleteFeature = (feature: FeatureDecorated<Geometry>) => {
    featureStack.value.forEach((item, index) => {
      if (isFeatureCoordinatesEqual(item, feature)) {
        featureStack.value.splice(index, 1);
      }
    });
  };

  const undoFeature = () => {
    if (featureStack.value.length > 0) {
      const feature = featureStack.value.splice(-1)[0];
      if (feature.delete) {
        feature.delete();
      }
      redoStack.value.push(feature);
    }
  };

  const redoFeature = () => {
    if (redoStack.value.length > 0) {
      const feature = redoStack.value.splice(-1)[0];
      if (feature.render) {
        feature.render();
      }
      featureStack.value.push(feature);
    }
  };

  const clearFeatures = () => {
    redoStack.value = [];
    featureStack.value = [];
  };

  return {
    features: computed(() => featureStack.value.map(({ feature }) => feature)),
    canUndo: computed(() => featureStack.value.length > 0),
    canRedo: computed(() => redoStack.value.length > 0),
    pushFeature,
    undoFeature,
    redoFeature,
    clearFeatures,
    deleteFeature,
  };
}
