import { useGetMyIssuesQuery } from '@/generated/graphql';
import { computed, ComputedRef } from 'vue';
import { Issue, IssueTableViewModel } from '../models';

interface UseMyIssues {
  loadingMyIssues: ComputedRef<boolean>;
  issuesEditableByMe: ComputedRef<IssueTableViewModel[]>;
  issuesReviewedByMe: ComputedRef<IssueTableViewModel[]>;
  issuesCreatedByMe: ComputedRef<IssueTableViewModel[]>;
}

export default function useMyIssues(): UseMyIssues {
  const { data, fetching } = useGetMyIssuesQuery();

  const issuesEditableByMe = computed<IssueTableViewModel[]>(() => data?.value?.me.editableIssues || []);
  const issuesReviewedByMe = computed<IssueTableViewModel[]>(() => data?.value?.me.reviewedIssues || []);
  const issuesCreatedByMe = computed<IssueTableViewModel[]>(() => data?.value?.me.createdIssues || []);

  return {
    issuesCreatedByMe,
    issuesReviewedByMe,
    issuesEditableByMe,
    loadingMyIssues: computed(() => fetching.value),
  };
}
