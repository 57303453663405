<script setup lang="ts">
import { computed } from 'vue';
import NewIssuesCard from '../frontpage/NewIssuesCard.vue';
import Button from '../theme/base/Button.vue';
import { ButtonType, ButtonSize } from '../theme/base/models/ButtonType';
import Arrow from '../theme/icons/Arrow.vue';
import DirectionType from '../theme/icons/models/DirectionType';
import { Colors } from '../theme/base/models/Colors';
import Translate from '../translations/Translate.vue';
import useNavigation from '../navigation/useNavigation';
import { IssueCardViewModel } from '../issues/models';

const props = withDefaults(
  defineProps<{
    issues?: IssueCardViewModel[];
  }>(),
  {
    issues: () => [],
  }
);

const newIssues = computed(() => props.issues.slice(0, 4));
const newIssuesLaptop = computed(() => props.issues.slice(4, 6));
const newIssuesDesktop = computed(() => props.issues.slice(6, 8));

const { pushActiveAreaRouteByName } = useNavigation();

const onIssueListClick = () => {
  pushActiveAreaRouteByName('Issues');
};
</script>

<template>
  <div class="flex flex-column new-issues-container">
    <div class="new-issues-header-card-container">
      <h1 class="h6 color-white new-issues-title">
        <Translate t="frontpage.new_issues.title" />
      </h1>
      <div class="new-issues-cards">
        <NewIssuesCard
          v-for="issue in newIssues"
          :key="issue.id"
          :issue="issue"
        />
        <NewIssuesCard
          v-for="issue in newIssuesLaptop"
          :key="issue.id"
          :issue="issue"
          class="hidden-in-mobile"
        />
        <NewIssuesCard
          v-for="issue in newIssuesDesktop"
          :key="issue.id"
          :issue="issue"
          class="hidden-in-mobile hidden-in-laptop hidden-in-laptop-to-desktop"
        />
      </div>
    </div>
    <Button
      @click="onIssueListClick"
      :type="ButtonType.tertiary"
      :size="ButtonSize.medium"
      class="new-issues-btn"
    >
      <Translate t="frontpage.new_issues.button" />
      <Arrow
        :custom-class="'icon-right'"
        :direction="DirectionType.right"
        :heigth="24"
        :width="24"
        :color="Colors.primary"
      ></Arrow>
    </Button>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/spacing' as spacing;
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/media-queries' as media;

.new-issues-container {
  padding: 0 14.4rem;
  display: flex;
  align-items: center;
  margin-top: -20rem;

  @include media.mobile {
    margin-top: -14rem;
    padding: 0 2rem;
  }
}

.new-issues-header-card-container {
  @include media.laptop-down {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.new-issues-title {
  margin-bottom: 4.2rem;

  @include media.laptop-down {
    margin-bottom: 2.4rem;
  }
}
.new-issues-cards {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 3.2rem;

  @include media.desktop-down {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  @include media.laptop-down {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  @include media.mobile {
    grid-template-columns: 1fr;
  }
}

.new-issues-btn {
  display: flex;
  align-self: flex-end;
  margin-top: 4.4rem;
}
</style>
