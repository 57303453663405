import Layer from 'ol/layer/Layer';
import { inject } from 'vue';
import { InjectionGetter, mapLayerKey } from '@/features/map/models/mapSymbols';

export default function useLayer(): InjectionGetter<Layer> {
  const layer = inject(mapLayerKey);

  if (!layer) {
    throw new Error('No Layer provided');
  }

  return layer;
}
