<script lang="ts" setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import InputText from '@/features/theme/base/InputText.vue';
import { User } from '@/features/auth/models';
import UserComboboxOption from './UserComboboxOption.vue';
import UserComboboxOptionsContainer from './UserComboboxOptionsContainer.vue';

const props = withDefaults(
  defineProps<{
    label?: string;
    modelValue?: Maybe<string>;
    errorMessage?: Maybe<string>;
    scroll?: 'small' | 'medium' | 'large';
    options?: User[];
    editable?: boolean;
  }>(),
  {
    modelValue: '',
    options: () => [],
    editable: true,
  }
);

const emits = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'option:selected', option: User): void;
}>();

const localValue = ref<string>();

const inputValue = computed<Maybe<string>>(() => (typeof props.modelValue === 'string' ? props.modelValue : localValue.value));

const onModelValueUpdate = (value: string) => {
  updateSelectedValue(value);
  resetSelectionCursor();
  showOptions();
};

const updateSelectedValue = (value: string): void => {
  localValue.value = value;
  emits('update:modelValue', value);
};

const selectionCursor = ref<Maybe<number>>();
const resetSelectionCursor = () => {
  selectionCursor.value = displayOptions.value.length === 1 ? 0 : null;
  if (props.modelValue) {
    const idx = displayOptions.value.findIndex((v) => v.fullName === props.modelValue);
    if (idx !== -1) {
      selectionCursor.value = idx;
    }
  }
};

const optionsExpanded = ref<boolean>(false);

const showOptions = () => {
  optionsExpanded.value = true;
};

const hideOptions = () => {
  optionsExpanded.value = false;
};

const onOptionClick = (option: User) => {
  updateSelectedValue(option.fullName);
  resetSelectionCursor();
  hideOptions();
  emits('option:selected', option);
};

const displayOptions = computed(() => {
  if (!inputValue.value) {
    return props.options;
  }
  const searchValue = inputValue.value.toLowerCase();
  return props.options.filter((opt) => opt.fullName.toLowerCase().includes(searchValue));
});

const inputFocused = ref<boolean>(false);
const onInputFocus = (focus: boolean) => {
  inputFocused.value = focus;
  if (focus) {
    resetSelectionCursor();
    showOptions();
  }
};

const onInputKeyUp = () => {
  if (optionsExpanded.value) {
    if (typeof selectionCursor.value === 'number' && selectionCursor.value > 0) {
      selectionCursor.value = selectionCursor.value - 1;
    } else {
      resetSelectionCursor();
      hideOptions();
    }
  }
};

const onInputKeyDown = () => {
  if (!optionsExpanded.value) {
    showOptions();
  }
  if (typeof selectionCursor.value !== 'number') {
    selectionCursor.value = 0;
  } else if (typeof selectionCursor.value === 'number' && selectionCursor.value < displayOptions.value.length - 1) {
    selectionCursor.value = selectionCursor.value + 1;
  }
};

const onInputKeyEnter = () => {
  if (optionsExpanded.value && typeof selectionCursor.value === 'number' && selectionCursor.value < displayOptions.value.length) {
    updateSelectedValue(displayOptions.value[selectionCursor.value].fullName);
    resetSelectionCursor();
    hideOptions();
  }
};

const onInputKeyEsc = () => {
  resetSelectionCursor();
  hideOptions();
};

const onInputKeyTab = () => {
  resetSelectionCursor();
  hideOptions();
};

const onInputClick = () => {
  showOptions();
};

const onDocumentClick = () => {
  hideOptions();
};

onMounted(() => {
  document.addEventListener('click', onDocumentClick);
});

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick);
});

</script>
<template>
  <div
    @click.stop
    class="combobox-container"
    tabindex="-1"
  >
    <InputText
      @focus="onInputFocus"
      :error-message="errorMessage"
      :model-value="inputValue"
      @update:model-value="onModelValueUpdate"
      fullwidth
      label="issue.user.new"
      @keyup.up="onInputKeyUp"
      @keyup.down="onInputKeyDown"
      :readonly="!editable"
      @keyup.enter="onInputKeyEnter"
      @keyup.esc="onInputKeyEsc"
      @keydown.tab="onInputKeyTab"
      @click="onInputClick"
    />
    <UserComboboxOptionsContainer
      :scroll="scroll"
      v-if="optionsExpanded"
    >
      <UserComboboxOption
        v-for="(option, index) in displayOptions"
        @click="onOptionClick(option)"
        :key="option.fullName"
        :value="option"
        :selected="index === selectionCursor"
      />
    </UserComboboxOptionsContainer>
  </div>
</template>

<style lang="scss" scoped>
.combobox-container {
  position: relative;
}
</style>
