<script lang="ts" setup>
import useCommunityFilterModels from '@/features/communities/composables/useCommunityFilterModels';
import useMediaQuery from '@/features/composables/useMediaQuery';
import useIssueStore from '@/features/issues/composables/useIssueStore';
import Button from '@/features/theme/base/Button.vue';
import Combobox from '@/features/theme/base/combobox/Combobox.vue';
import DropDown from '@/features/theme/base/DropDown.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonSize, ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import Option from '@/features/theme/base/models/Option';
import Translate from '@/features/translations/Translate.vue';
import { IssueSortEnum } from '@/generated/graphql';
import { useRouteQuery } from '@vueuse/router';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';

withDefaults(
  defineProps<{
    totalCount?: number;
  }>(),
  {
    totalCount: 0,
  }
);

const emits = defineEmits<{
  (e: 'change'): void;
}>();

const store = useIssueStore();

function onSpecificationClear() {
  store.resetIssueSpecification();
  communityInput.value = '';
  emits('change');
}

const { communitiesExcludingDisabled: communities } = useCommunityFilterModels();
const communityOptions = computed(() => communities.value.map(community => community.name));
const communitySelectedOption = useRouteQuery<Maybe<string>>('community');
const communityInput = ref(communitySelectedOption.value ?? '');
function onCommunityOptionSelected(option: string) {
  communitySelectedOption.value = option;
  const spec = { ...store.issueSearch.spec, community: option };
  store.patchIssueSpecification({ ...store.issueSearch, spec: spec });
  emits('change');
}

const { t } = useI18n();

const sortOptions = computed<Option[]>(() => {
  return ['new', 'updated', 'expiring'].map(opt => ({
    name: t(`issues.sort.${opt}`),
    value: opt,
  }));
});
const selectedSortOption = ref<'new' | 'updated' | 'expiring'>('updated');
const selectedSortOptionEnum = computed<IssueSortEnum>(() => {
  if (selectedSortOption.value === 'new') {
    return IssueSortEnum.Created;
  } else if (selectedSortOption.value === 'updated') {
    return IssueSortEnum.LastUpdated;
  } else if (selectedSortOption.value === 'expiring') {
    return IssueSortEnum.ReviewDateExpiring;
  }

  return IssueSortEnum.LastUpdated;
});
function onSortOptionChange(opt: string) {
  if (opt !== selectedSortOption.value) {
    if (opt === 'new' || opt === 'updated' || opt === 'expiring') {
      selectedSortOption.value = opt;
    }
    const spec = { ...store.issueSearch.spec, sortBy: selectedSortOptionEnum.value };
    store.patchIssueSpecification({ ...store.issueSearch, spec: spec });
    emits('change');
  }
}

const mobileToggleOptions = ref(false);
function toggleMobileOpen() {
  mobileToggleOptions.value = !mobileToggleOptions.value;
}
</script>

<template>
  <div :class="['issue-index__options', { 'issue-index__options--mobile-toggle': !mobileToggleOptions }]">
    <div class="issue-index__option">
      <div class="issue-index__total-count">
        <div>
          <span
            v-if="!store.issueSearch.spec.search"
            class="bold mb-1"
          >
            <Translate t="issue.index.results" />
          </span>
          <span v-else>
            <Translate t="issue.index.results_for" />
            &quot;
            <span class="bold">{{ store.issueSearch.spec.search }}</span>
            &quot;
          </span>
          <div class="issue-index__total-count text-grey-600">
            <Translate
              t="issue.index.results.interpolated"
              :interpolations="{ n: totalCount }"
            />
          </div>
        </div>
        <Button
          :class="['issue-index__mobile-toggle', { 'issue-index__mobile-toggle--open': mobileToggleOptions }]"
          :size="ButtonSize.small"
          :type="ButtonType.secondary"
          @click="toggleMobileOpen"
        >
          <Icon
            class="issue-index__mobile-toggle-icon"
            icon="DropdownArrow"
            :color="Colors.primary"
          />
        </Button>
      </div>
    </div>
    <div class="issue-index__option">
      <Button
        :type="ButtonType.secondary"
        @click="onSpecificationClear"
        :disabled="store.hasNoSpecification"
      >
        <Translate
          class="issue-index__filter-text"
          t="search.clear_filters"
        />
        <Icon
          :options="{ color: store.hasNoSpecification ? Colors.grey600 : Colors.primaryDark }"
          class="icon icon-right"
          icon="Refresh"
        />
      </Button>
    </div>
    <div class="issue-index__option">
      <Combobox
        label="base.community.filter"
        :options="communityOptions"
        v-model="communityInput"
        @option:selected="onCommunityOptionSelected"
        scroll="small"
        class="combobox"
      ></Combobox>
    </div>
    <div class="issue-index__option">
      <DropDown
        fullwidth
        :options="sortOptions"
        label="Raða eftir"
        :model-value="selectedSortOption"
        @update:model-value="onSortOptionChange"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
.issue-index__options {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;

  @include mq.tablet {
    grid-template-columns: repeat(2, 1fr);
  }

  @include mq.mobile {
    &.issue-index__options--mobile-toggle {
      .issue-index__option:not(:first-child) {
        display: none;
      }
    }
  }
}

.issue-index__option {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.issue-index__total-count {
  display: fleX;
  justify-content: space-between;
  align-items: center;
}

.issue-index__mobile-toggle {
  @include mq.tablet {
    display: none;
  }

  .issue-index__mobile-toggle-icon {
    transition: transform 0.3s;
  }

  &.issue-index__mobile-toggle--open .issue-index__mobile-toggle-icon {
    transform: rotateX(180deg);
  }
}
</style>
