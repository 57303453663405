<script lang="ts" setup>
import { Geometry } from 'ol/geom';
import VectorSource, { Options } from 'ol/source/Vector';
import { provide, shallowRef } from 'vue';
import { mapVectorSourceKey } from '@/features/map/models/mapSymbols';
import Source from './Source.vue';

const props = defineProps<{
  options?: Options<Geometry>;
}>();

const source = shallowRef(new VectorSource<Geometry>(props.options));
provide(mapVectorSourceKey, () => source.value);
</script>
<template>
  <Source :source="source">
    <slot />
  </Source>
</template>
