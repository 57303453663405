<script lang="ts" setup>
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import { computed } from 'vue';
import Translate from '@/features/translations/Translate.vue';
import useProcessTranslation from '@/features/issueProcess/composables/useProcessTranslation';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  activeIssue: IssueDetails;
  skipped: string[] | undefined | null;
}>();

const emits = defineEmits<{
  (e: 'update:skipped', skipped: string[]): void;
}>();

const optionalPhases = computed(() => props.activeIssue.phases.filter((p) => p.isOptional));

const { t } = useI18n();
const { getProcessInformationKey, getProcessPhaseKey } = useProcessTranslation();

const processName = (issue: Maybe<IssueDetails>, phase: IssuePhase) => {
  const translationKey = getProcessPhaseKey(issue?.process, phase);
  const translation = t(translationKey);

  return translation !== translationKey ? translation : phase.name;
};
const isChecked = (pid: ID) => !props.skipped?.includes(pid.toString());

const onSkippedChecked = (phase: IssuePhase) => {
  const skipped = props.skipped ? [...props.skipped] : [];
  const pid = phase.id.toString();
  const index = skipped.indexOf(pid);

  if (index === -1) {
    skipped.push(pid);
  } else {
    skipped.splice(index, 1);
  }

  emits('update:skipped', [...skipped]);
};
</script>
<template>
  <LayoutFormGroup
    title="issue.optional_phases"
    v-if="optionalPhases?.length"
  >
    <template #explanation>
      <Translate
        :value="getProcessInformationKey(activeIssue?.process, 'optional_phases.help')"
        :default-value="t('issue.information.optional_phases.help')"
      />
    </template>
    <LayoutInput :span="3">
      <div class="checkbox-list">
        <Checkbox
          @update:model-value="() => onSkippedChecked(phase)"
          :model-value="isChecked(phase.id)"
          class="mb-2"
          v-for="phase in optionalPhases"
          :key="phase.id.toString()"
          :id="phase.id.toString()"
        >
          {{ `${processName(activeIssue, phase)}` }} {{ `${isChecked(phase.id) ? '(Fasi verður með)' : '(Fasa verður sleppt)'}` }}
        </Checkbox>
      </div>
    </LayoutInput>
  </LayoutFormGroup>
</template>

<style lang="scss" scoped></style>
