<script lang="ts" setup>
import Close from '../icons/Close.vue';
const props = withDefaults(
  defineProps<{
    open?: boolean;
    scrollable?: boolean;
    closable?: boolean;
    disableBackgroundClick?: boolean;
  }>(),
  {
    open: false,
    scrollable: false,
    closable: true,
    disableBackgroundClick: false,
  }
);

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const onMaskClicked = () => {
  if (props.closable && !props.disableBackgroundClick) {
    emits('close');
  }
};

const onCloseClicked = () => {
  if (props.closable) {
    emits('close');
  }
};
</script>

<template>
  <Teleport to="body">
    <Transition name="modal">
      <div v-show="open" class="modal-mask">
        <div class="modal-wrapper" @click.self="onMaskClicked">
          <div class="modal-container" :class="{ scrollbar: scrollable }">
            <slot />
            <button v-if="closable" class="modal-close" @click="onCloseClicked"><Close :heigth="24" :width="24"></Close></button>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/z-indicies' as zIndex;
.modal-mask {
  position: fixed;
  z-index: zIndex.$z-index-modal;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  position: relative;
  width: fit-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  &.scrollbar {
    max-height: 80vh;
    padding-right: 0;
    overflow-y: auto;
  }
}

.modal-close {
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
