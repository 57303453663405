import Feature from 'ol/Feature';
import GeoJSON from 'ol/format/GeoJSON';
import Geometry from 'ol/geom/Geometry';

interface UseGeoJson {
  featureToGeoJson: (feature: Feature<Geometry>) => string;
  featuresToGeoJson: (features: Feature<Geometry>[]) => string;
  geometryToGeoJson: (geometry: Geometry) => string;

  stringToGeometry: (s: string) => Geometry | null;
  stringToFeature: (s: string) => Feature | null;
  stringToFeatures: (s: string) => Feature[] | null;
}

export default function useGeoJson(): UseGeoJson {
  const format = new GeoJSON();

  const featureToGeoJson = (feature: Feature<Geometry>): string => {
    return format.writeFeature(feature);
  };

  const featuresToGeoJson = (features: Feature<Geometry>[]): string => {
    if (features.length > 0) {
      return format.writeFeatures(features);
    }
    return "";
  };

  const geometryToGeoJson = (geometry: Geometry): string => {
    return format.writeGeometry(geometry);
  };

  const stringToGeometry = (s: string): Geometry | null => {
    return format.readGeometry(s);
  };

  const stringToFeature = (s: string): Feature | null => {
    return format.readFeature(s);
  };

  const stringToFeatures = (s: string): Feature[] | null => {
    return format.readFeatures(s);
  };

  return {
    featureToGeoJson,
    featuresToGeoJson,
    geometryToGeoJson,
    stringToGeometry,
    stringToFeature,
    stringToFeatures,
  };
}
