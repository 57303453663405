<script lang="ts" setup>
import { IssueDetails } from '@/features/issues/models';
import InputSearch from '@/features/theme/base/InputSearch.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import { useAddDelegationTicketIssueAccessMutation } from '@/generated/graphql';
import debounce from 'lodash-es/debounce';
import { onMounted, onUnmounted, ref } from 'vue';
import useRemoteUserSearch from '../../composables/useRemoteUserSearch';
import UserCardList from './UserCardList.vue';

import ListItem from '@/features/theme/base/ListItem.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Icon from '@/features/theme/base/Icon.vue';
import { computed } from 'vue';
import { useGetIssueEditorsQuery, useRemoveDelegationTicketIssueAccessMutation, UserRoleType, GetIssueEditorsQuery } from '@/generated/graphql';

export type IssueEditor = GetIssueEditorsQuery['issue']['editors'][0];

const props = withDefaults(
  defineProps<{
    disabled?: boolean;
    issue: IssueDetails;
  }>(),
  {
    disabled: false,
  }
);

const { data, executeQuery: refreshEditorsList } = useGetIssueEditorsQuery({
  variables: { issueId: props.issue.id.toString() },
  requestPolicy: 'cache-and-network',
});

const { executeMutation: removeIssueTicket } = useRemoveDelegationTicketIssueAccessMutation();

const onDeleteEditor = async (editor: IssueEditor) => {
  await removeIssueTicket({ issueId: props.issue.id.toString(), userId: editor.user!.id });
  await refreshEditorsList();
};

const issueEditors = computed(() => {
  return data.value?.issue.editors?.filter((editor) => editor.accessRole === UserRoleType.DelegationConsultant) || [];
});

const { executeMutation: addIssueTicket } = useAddDelegationTicketIssueAccessMutation();

const search = ref('');
const { searchResults, performSearch } = useRemoteUserSearch();

const availableUsers = computed(() => {
  return searchResults.value.filter((u) => issueEditors.value.every((ie) => ie.user?.id !== u.id));
});

const runDebouncedSearch = debounce((val: string) => {
  performSearch({
    delegationId: props.issue.delegationId,
    search: val,
  });
}, 400);

const searchFocused = ref<boolean>(false);
const onSearchFocus = () => {
  searchFocused.value = true;
};

const onSearchUpdate = (val: string) => {
  search.value = val;
  runDebouncedSearch(val);
};

const onUserSelect = async (userId: string) => {
  await addIssueTicket({ issueId: props.issue.id.toString(), userId });
  await refreshEditorsList();

  searchFocused.value = false;
  search.value = '';
};

const onDocumentClick = () => {
  searchFocused.value = false;
};

onMounted(() => {
  search.value = '';
  document.addEventListener('click', onDocumentClick);
});

onUnmounted(() => {
  document.removeEventListener('click', onDocumentClick);
});
</script>

<template>
  <LayoutFormGroup title="user.delegation.single_issue_access">
    <template #explanation>
      <Translate t="user.delegation.single_issue_access.help" />
    </template>
    <LayoutInput :span="2">
      <div @click.stop class="position-relative">
        <div class="input-search-container" :class="{ searchFocused }">
          <InputSearch
            fullwidth
            :disabled="disabled"
            :include-button="false"
            label="user.delegation.single_issue_access.search.input"
            :icon-options="{ color: Colors.grey600 }"
            :model-value="search"
            @update:model-value="onSearchUpdate"
            @focus="onSearchFocus"
          />
        </div>
        <div class="results-container scrollbar">
          <h5 class="search-results-title px-2 py-1 bg-grey-200 p--large">
            <Translate t="user.delegation.single_issue_access.search.results.title" />
          </h5>
          <div class="bg-white px-2 py-2">
            <UserCardList
              v-if="availableUsers && availableUsers.length > 0"
              :users="availableUsers"
              :card-size="null"
              card-style="border"
              @select="onUserSelect"
            />
            <div v-else>
              <Translate t="user.delegation.single_issue_access.search.results.empty" />
            </div>
          </div>
        </div>
      </div>
    </LayoutInput>
  </LayoutFormGroup>

  <LayoutFormGroup v-if="issueEditors.length">
    <template #explanation>
      <Translate class="bold" t="user.delegation.single_issue_access.list" />
    </template>
    <LayoutInput :span="2" v-for="editor in issueEditors" :key="editor.user!.nationalId">
      <ListItem>
        <div class="py-1 color-primary bold">
          {{ `${editor.user!.fullName} - ${editor.user!.nationalId}` }}
        </div>
        <template #right>
          <Button :type="ButtonType.tertiaryIconOnly" @click="() => onDeleteEditor(editor)">
            <Icon icon="Delete" />
          </Button>
        </template>
      </ListItem>
    </LayoutInput>
  </LayoutFormGroup>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/z-indicies' as z;

.results-container {
  position: absolute;
  top: 6.9rem;
  min-width: 100%;
  left: 0;
  box-shadow: 0px 24px 96px rgba(colors.$primary, 0.1);
  border-radius: 2px;
  max-height: 0;
  overflow: hidden auto;
  padding-right: 0;
  z-index: z.$z-index-sidebar-search-results;
}

.detailed-search-container {
  overflow: hidden auto;
  max-height: 50vh;
  padding-right: 5rem;
}

.input-search-container {
  &.searchFocused + .results-container {
    max-height: 40vh;
  }
}
</style>
