import { Component } from 'vue';
import * as phaseSteps from '@/features/issueProcess/components/phaseSteps';
import { IssuePhaseStepEnum } from '@/generated/graphql';

type PhaseStepMapping = {
  [key in IssuePhaseStepEnum]: Component;
};

interface UsePhaseStepMapping {
  phaseStepMappings: PhaseStepMapping;
  getPhaseStepComponent: (stepType: IssuePhaseStepEnum) => Component;
}

export default function usePhaseStepMapping(): UsePhaseStepMapping {
  const phaseStepMappings: PhaseStepMapping = {
    [IssuePhaseStepEnum.FyrirliggjandiGogn]: phaseSteps.IssuePhaseStepDraft,
    [IssuePhaseStepEnum.UmsjonUmsagna]: phaseSteps.IssuePhaseStepReviews,
    [IssuePhaseStepEnum.GognTilStadfestingar]: phaseSteps.IssuePhaseStepFinal,
    [IssuePhaseStepEnum.NidurstadaUmsagnarferlis]: phaseSteps.IssuePhaseStepResults,
    [IssuePhaseStepEnum.TillagaTilAthugunar]: phaseSteps.IssuePhaseStepProposal,
    [IssuePhaseStepEnum.UmsognSkipulagsstofnunar]: phaseSteps.IssuePhaseStepProposalReview,
    [IssuePhaseStepEnum.StadfestingSkipulagsstofnunar]: phaseSteps.IssuePhaseStepFinish,
    [IssuePhaseStepEnum.InnsetningLokagagna]: phaseSteps.IssuePhaseStepResultsData,
  };

  const getPhaseStepComponent = (stepName: IssuePhaseStepEnum): Component => {
    return phaseStepMappings[stepName];
  };

  return {
    phaseStepMappings,
    getPhaseStepComponent,
  };
}
