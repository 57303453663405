<script lang="ts" setup>
import Interaction from 'ol/interaction/Interaction';
import { onMounted, onUnmounted, watch } from 'vue';
import useMap from '@/features/map/composables/useMap';

const getMap = useMap();

const props = defineProps<{
  interaction: Interaction;
}>();

const emits = defineEmits<{
  (e: 'mounted'): void;
  (e: 'unmounted'): void;
}>();

onMounted(() => {
  getMap().addInteraction(props.interaction);
  emits('mounted');
});

onUnmounted(() => {
  getMap().removeInteraction(props.interaction);
  emits('unmounted');
});

watch(
  () => props.interaction,
  (newValue, oldValue) => {
    getMap().removeInteraction(oldValue);
    getMap().addInteraction(newValue);
  }
);
</script>
<template>
  <slot />
</template>
