<script lang="ts" setup>
import Menu from '@/features/navigation/Menu.vue';
import Sidebar from '@/features/navigation/Sidebar.vue';
import Footer from '@/features/navigation/Footer.vue';
import useNavigationStore from './features/navigation/useNavigationStore';
import useSidebar from './features/navigation/useSidebar';

const store = useNavigationStore();
const { sidebarVisible } = useSidebar();
</script>

<template>
  <div :class="`background background--${store.mainMenuThemeKey}`">
    <div class="lines">
      <Menu
        v-if="store.mainMenu && store.activeArea"
        :nav="store.activeArea.navigation"
        :theme="store.mainMenuTheme"
        :class="{ 'mb-4': store.mainMenuMargin }"
      />
      <Sidebar v-if="sidebarVisible" />
      <main>
        <RouterView />
      </main>
      <Footer :class="{ 'mt-4': store.mainFooterMargin }" v-if="store.mainFooter && store.activeArea" :theme="store.mainMenuTheme"></Footer>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;

.background {
  &--login {
    background-color: colors.$primary--xlight;

    .lines {
      background-image: url('@/assets/login-lines-mobile.svg');
      background-repeat: no-repeat;
      background-position: top 10rem right;

      @include mq.tablet() {
        background-image: url('@/assets/login-lines.svg');
        background-position: top right;
        background-size: contain;
      }

      @include mq.desktop() {
        background-size: auto;
      }
    }
  }
}
</style>
