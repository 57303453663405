<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { computed } from 'vue';
import Icon from '../base/Icon.vue';
import { DataTableHeaderConfig, SortOrder } from './dataTableTypes';

const props = defineProps<{
  header: string;
  config?: Maybe<DataTableHeaderConfig>;
  sortOrder?: Maybe<SortOrder>;
}>();

const emits = defineEmits<{
  (e: 'click', header: string): void;
}>();

const onClick = () => {
  emits('click', props.header);
};

const formattedValue = computed(() => {
  const formatter = props.config?.formatter;

  if (formatter) {
    if (typeof formatter === 'string') {
      return formatter;
    } else {
      return formatter();
    }
  }

  return props.header;
});
</script>
<template>
  <th @click="onClick" :class="`data-table__header ${config?.className}}`">
    <span class="data-table__header-content">
      <Translate :value="formattedValue" />
      <Icon v-if="sortOrder" class="data-table__header-sort" :icon="sortOrder === 'asc' ? 'ArrowDown' : 'ArrowUp'" :options="{ width: 16, height: 16 }" />
    </span>
  </th>
</template>
<style lang="scss">
.data-table__header {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-bottom: 2rem;
}

.data-table__header-content {
  display: flex;
  align-items: center;
}
.data-table__header-sort {
  margin-left: 1rem;
}
</style>
