<script lang="ts" setup>
import TableSpacer from './TableSpacer.vue';
const props = withDefaults(
  defineProps<{
    headerRow?: boolean;
    clickable?: boolean;
    active?: boolean;
    tableSpacer?: boolean;
    spacerHeight?: number;
  }>(),
  {
    headerRow: false,
    tableSpacer: true,
    spacerHeight: 16,
    clickable: false,
    active: false,
  }
);

const emit = defineEmits<{
  (e: 'click'): void;
}>();

const onRowClick = () => {
  if (props.clickable) {
    emit('click');
  }
};
</script>

<template>
  <tr @click="onRowClick" class="row bold" :class="{ 'header-row': headerRow, 'data-row': !headerRow, clickable, active }">
    <slot />
  </tr>
  <TableSpacer v-if="tableSpacer" :height="spacerHeight"></TableSpacer>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.row {
  box-sizing: content-box;
  margin-bottom: 1.6rem;
}

.data-row {
  background-color: white;
}
.header-row {
  background-color: transparent;
}
.clickable {
  cursor: pointer;
}

.active {
  border: 1px solid colors.$primary;
}
</style>
