const enum Colors {
  // Primary
  primary = '#006F7E',
  primaryDark = '#00415A',
  primaryLight = '#C6F8EE',
  primary50 = '#80B7BF',
  primaryXLight = '#F4FEFC',
  primaryXXLight = '#E5F1F2',
  
  // Grey Scale
  grey900 = '#222222',
  grey800 = '#3F3F3F',
  grey700 = '#505050',
  grey600 = '#696969',
  grey500 = '#7F7F7F',
  grey400 = '#A0A0A0',
  grey300 = '#D0D0D0',
  grey200 = '#EFEFEF',
  grey100 = '#F9F9F9',
  greyBackground = '#F9F9F9',

  // Created
  blue = '#0075CE',
  blueDark = '#004394',
  blueLight = '#CAF2FC',

  // To Review
  purple = '#8D0BEA',
  purpleDark = '#290270',
  purpleLight = '#F4CDFD',

  // In Progress
  orange = '#FF5F00',
  orangeDark = '#B72D00',
  orangeLight = '#FFEBCC',

  // Results Confirmed
  green = '#1C892B',
  greenDark = '#0E6229',
  greenLight = '#DAF9D1',

  // Case Rejected
  red = '#DB1E3E',
  redDark = '#9D0F38',
  redLight = '#FFCFCB',

  // Miscellaneous
  white = '#FFF',
  black = '#000',
}

export { Colors };
