import { useGetCommunitiesFilterModelsQuery } from '@/generated/graphql';
import { computed } from 'vue';

export default function useCommunityFilterModels() {
  const { data, error, fetching } = useGetCommunitiesFilterModelsQuery();

  const communities = computed(() => data.value?.communities || []);
  const communitiesError = computed(() => error.value);
  const communitiesLoading = computed(() => fetching.value);
  const communitiesExcludingDisabled = computed(() => communities.value.filter(({ disabled }) => !disabled));

  return {
    communities,
    communitiesError,
    communitiesLoading,
    communitiesExcludingDisabled,
  };
}
