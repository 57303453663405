<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import DatePickerInput from '../../theme/base/DatePickerInput.vue';
import { useI18n } from 'vue-i18n';
import { IssueSpecificationInput } from '@/generated/graphql';
import { sub } from 'date-fns';
import useDateFormatting from '@/features/composables/useDateFormatting';

const props = defineProps<{
  modelValue: IssueSpecificationInput;
  filter: string[];
}>();

const { t } = useI18n();
const { formatDateLocalized } = useDateFormatting();

const emit = defineEmits<{
  (e: 'update:onPeriodUpdate', fromDate: Maybe<Date>, toDate: Maybe<Date>, filter: string[]): void;
}>();

const onFromUpdate = (fromDate: Maybe<Date>) => {
  emit('update:onPeriodUpdate', fromDate, props.modelValue.toDate, periodFilter(fromDate, props.modelValue.toDate));
};

const onToUpdate = (toDate: Maybe<Date>) => {
  emit('update:onPeriodUpdate', props.modelValue.fromDate, toDate, periodFilter(props.modelValue.fromDate, toDate));
};

const onPeriodFilterClick = (fromDate: Date) => {
  emit('update:onPeriodUpdate', fromDate, new Date(), periodFilter(fromDate, new Date()));
};

const onYearFilterClick = (year: number) => {
  const fromDate = new Date(year, 0, 1);
  const toDate = new Date(year, 11, 31);
  emit('update:onPeriodUpdate', fromDate, toDate, periodFilter(fromDate, toDate));
};

const periodFilter = (fromDate?: Maybe<Date>, toDate?: Maybe<Date>) => {
  const periods: string[] = [];
  if (fromDate) {
    periods.push(t('base.date.from') + ": " + formatDateLocalized(fromDate));
  }
  if (toDate) {
    periods.push(t('base.date.to') + ": " + formatDateLocalized(toDate));
  }
  return periods;
};

</script>
<template>
  <div class="date-period">
    <DatePickerInput 
      :model-value="modelValue.fromDate" 
      @update:model-value="onFromUpdate"
      :placeholder="t('base.date.from')">
    </DatePickerInput>
    <DatePickerInput 
      :model-value="modelValue.toDate"
      @update:model-value="onToUpdate"
      :placeholder="t('base.date.to')">
    </DatePickerInput>
  </div>
  <div class="period-select">
    <div class="grid-item" @click="onPeriodFilterClick(sub(new Date(), { weeks: 1 }))">
      <Translate t="search.period.one_week" />
    </div>
    <div @click="onPeriodFilterClick(sub(new Date(), { months: 1 }))">
      <Translate t="search.period.one_month" />
    </div>
    <div @click="onPeriodFilterClick(sub(new Date(), { months: 3 }))">
      <Translate t="search.period.three_months" />
    </div>
    <div @click="onPeriodFilterClick(sub(new Date(), { months: 6 }))">
      <Translate t="search.period.six_months" />
    </div>
    <div @click="onYearFilterClick(new Date().getFullYear())">
      {{ new Date().getFullYear() }}
    </div>
    <div @click="onYearFilterClick(new Date().getFullYear() - 1)">
      {{ new Date().getFullYear() - 1 }}
    </div>
  </div>
</template>

<style lang="scss" scoped>
  @use '@/scss/design-tokens/media-queries' as mq;
  @use '@/scss/design-tokens/colors' as colors;

  .date-period {
    display: flex;
    gap: 1.6rem;

    @include mq.mobile {
      flex-direction: column;
    }
  }

  .period-select {
    display: flex;
    gap: 1.6rem;
    margin-top: 2rem;
    color: colors.$primary;

    @include mq.mobile() {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    div {
      font-size: 1.6rem;
      font-weight: bolder;
      text-decoration: underline;
      cursor: pointer;
      display: inline-flex;
    }
  }
</style>
