<script lang="ts" setup>
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import { Table, TableRow, TableData } from '@/features/theme/base/tables';
import Translate from '@/features/translations/Translate.vue';
import { useGetFakeUsersQuery, useFakeUserTokenMutation, DelegationType, useGetRealUserQuery, DelegationTicketInput } from '@/generated/graphql';
import { computed, onMounted, ref } from 'vue';
import tokenService from '@/features/auth/tokenService';
import { useSignin } from '@/features/auth/useSignin';
import Alert from '@/features/theme/base/Alert.vue';
import useAuthStore from '@/features/auth/useAuthStore';
import useTranslationModelChecker from '@/features/translations/useTranslationModelChecker';

const { data } = useGetFakeUsersQuery();

interface FakeUserLogin {
  id: string;
  name: string;
  role: string;
  delegator?: string;
  delegatorId?: string;
  delegationId?: string;
  delegationType?: DelegationType;
  email?: string;
}

const { user } = useAuthStore();

const { executeMutation } = useFakeUserTokenMutation();

const signin = useSignin();

const switchUser = async (token: string) => {
  tokenService.saveToken(token);
  await signin.login();

  window.location.reload();
};

interface FakeUserTokenMutationInput {
  userId: string;
  input?: DelegationTicketInput;
}

const onClickRow = async (fake: FakeUserLogin) => {
  const { delegationId } = fake;

  const mutationVariables: FakeUserTokenMutationInput = {
    userId: fake.id,
  };

  if (delegationId) {
    mutationVariables.input = {
      delegationId,
    };
  }

  if (!tokenService.isFakeUser()) {
    const realUserToken = tokenService.getToken();
    if (realUserToken) {
      tokenService.saveRealUserToken(realUserToken);
    }
  } else {
    const token = tokenService.getRealUserToken();
    if (token && tokenService.getTokenUserId(token) === fake.id) {
      tokenService.clearRealUserToken();
      await switchUser(token);
    }
  }

  const { data } = await executeMutation(mutationVariables);
  if (data?.fakeUserToken.token) {
    await switchUser(data?.fakeUserToken.token);
  } else {
    throw new Error('Villa við innskráningu gervinotanda');
  }
};

const headers = ['Nafn', 'Umboð', 'Hlutverk', 'Netfang'];

const fakeUsers = computed(() => {
  const fakes = data?.value?.fakeUsers || [];
  return fakes.reduce<FakeUserLogin[]>((acc, fake) => {
    acc.push({
      id: fake.id,
      name: fake.fullName,
      role: fake.role,
      delegator: '-',
      email: fake?.email || '',
    });

    const delegations = fake.delegations.map((ticket) => {
      return {
        id: fake.id,
        delegationId: ticket.delegation?.id,
        name: fake.fullName,
        role: ticket.role,
        delegator: ticket.delegation?.entityName,
        email: ticket.email || '',
        delegatorId: ticket.delegation?.entityId,
        delegationType: ticket.delegation?.type,
      };
    });

    acc.push(...delegations);
    return acc;
  }, []);
});

const { isTranslationKey } = useTranslationModelChecker();

const realFakeUserId = ref<string>('');
const realFakeUser = ref<FakeUserLogin>();
const { executeQuery: fetchRealUser } = useGetRealUserQuery({ variables: { id: realFakeUserId }, pause: true });

const getRealUser = async () => {
  if (tokenService.isFakeUser()) {
    const token = tokenService.getRealUserToken();
    if (!token) {
      return;
    }

    realFakeUserId.value = tokenService.getTokenUserId(token);
    const { data } = await fetchRealUser({ requestPolicy: 'network-only' });
    const realUser = data.value?.realUser;
    if (realUser) {
      realFakeUser.value = {
        id: realUser.id,
        name: realUser.fullName,
        role: realUser.role,
        delegator: '-',
        email: realUser?.email || '',
      };
    }
  } else {
    if (user) {
      realFakeUser.value = {
        id: user.id,
        name: user.fullName,
        role: user.role,
        delegator: '-',
        email: user.email || '',
      };
    }
  }
};

const users = computed<FakeUserLogin[]>(() => {
  let users = [];
  if (realFakeUser.value) {
    users.push(realFakeUser.value);
  }
  return [...users, ...fakeUsers.value];
});

onMounted(() => {
  getRealUser();
});
</script>
<template>
  <LayoutCentered>
    <div class="mb-2">
      <Alert>
        <Translate
          t="user.fake.role"
          :interpolations="{
          user: `${tokenService.isFakeUser() ? 'gervi' : ''}notandinn ${user!.fullName}`,
          role: $t(`role.${user!.role}`)
        }"
        />
        <Translate v-if="isTranslationKey(`user.fake.role.${user!.role}`)" :value="`user.role.help.${user!.role}`" />
      </Alert>
    </div>
    <Table fullsize>
      <TableRow header-row>
        <TableData header-data v-for="header in headers" :key="header">
          {{ header }}
        </TableData>
      </TableRow>
      <TableRow v-for="fake in users" :key="fake.name" :active="true" clickable @click="() => onClickRow(fake)">
        <TableData class="color-primary">{{ fake.name }}</TableData>
        <TableData class="color-primary">{{ fake.delegator }}</TableData>
        <TableData>
          <Translate :value="`role.${fake.role}`" />
        </TableData>
        <TableData class="color-primary">{{ fake.email }}</TableData>
      </TableRow>
    </Table>
  </LayoutCentered>
</template>
