<script lang="ts" setup>
import { computed, provide } from 'vue';
import InputSearch from '../base/InputSearch.vue';
import Pagination from '../base/Pagination.vue';
import { dataTableSortKey, dataTableSortToggleKey } from './dataTableSymbols';
import DataTableTable from './DataTableTable.vue';
import { DataTableConfig, DataTableConfigStrict, DataTableDataRow, DataTableHeader } from './dataTableTypes';
import useDataTablePagination from './useDataTablePagination';
import useDataTableSearch from './useDataTableSearch';
import useDataTableSort from './useDataTableSort';

// Dec 5 '22, Vue / Volar doesn't seem to support generic components so Props and Emits are typed separately.
// DataTable consumers should use a model checker function for events emitting template typed items.

export type DataTableProps<T extends DataTableDataRow> = {
  dataset: T[];
  config?: Maybe<Partial<DataTableConfig<T>>>;
  selected?: Maybe<T>;
};

type DataTableEmits<T extends DataTableDataRow> = {
  (e: 'select', item: T): void;
};

const props = defineProps<DataTableProps<DataTableDataRow>>();
const emits = defineEmits<DataTableEmits<DataTableDataRow>>();

const dataTableHeaders = computed<DataTableHeader[]>(() => {
  if (props.dataset.length > 0) {
    return Object.keys(props.dataset[0]);
  }

  return [];
});

const { dataTableSearchFilter, dataTableSearch } = useDataTableSearch();

const { dataTableSortFilter, dataTableToggleSortBy, dataTableSortBy } = useDataTableSort();
provide(
  dataTableSortKey,
  computed(() => dataTableSortBy.value)
);
provide(dataTableSortToggleKey, dataTableToggleSortBy);

const { dataTablePaginationFilter, dataTablePaginationCandidates, dataTablePaginationPage, dataTablePaginationMaxPage } = useDataTablePagination();

const defaultDataTableConfig: DataTableConfigStrict<DataTableDataRow> = {
  headers: {},
  data: {},
  filters: [dataTableSearchFilter, dataTableSortFilter, dataTablePaginationFilter],
  selectable: false,
};
const dataTableConfig = computed(() => Object.assign({}, defaultDataTableConfig, props.config || {}));

const datasetForRender = computed<DataTableDataRow[]>(() => dataTableConfig.value.filters.reduce((data, filter) => filter(data), props.dataset));

const onPageSelect = (page: number) => {
  dataTablePaginationPage.value = page;
};

const onRowClick = (row: DataTableDataRow) => {
  if (props.config?.selectable) {
    emits('select', row);
  }
};
const selectedRowIndex = computed<number>(() => {
  if (!props.selected) {
    return -1;
  }

  const selected = props.selected;
  return datasetForRender.value.findIndex((item) => {
    for (const key in item) {
      if (item[key] !== selected[key]) {
        return false;
      }
    }
    return true;
  });
});
</script>
<template>
  <div class="data-table">
    <div class="data-table__widgets-top">
      <div class="data-table__widgets-top-left">
        <InputSearch label="base.search" v-model="dataTableSearch" :include-button="false" />
        <slot name="widget-top-left"></slot>
      </div>
      <div class="data-table__widgets-top-right">
        <slot name="widget-top-right"></slot>
      </div>
    </div>
    <DataTableTable
      :headers="dataTableHeaders"
      :dataset="datasetForRender"
      :config="dataTableConfig"
      @row-click="onRowClick"
      :selected-row-index="selectedRowIndex"
    />
    <div class="data-table__widgets-bottom">
      <Pagination
        v-if="dataTablePaginationMaxPage > 0"
        :candidates="dataTablePaginationCandidates"
        :selected="dataTablePaginationPage"
        @select="onPageSelect"
      />
    </div>
  </div>
</template>
<style lang="scss">
.data-table {
  display: flex;
  flex-direction: column;
}

.data-table__widgets-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  gap: 1rem;
}

.data-table__widgets-top-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.data-table__widgets-bottom {
  display: flex;
  margin-top: 2rem;
  justify-content: center;
}
</style>
