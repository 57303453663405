<script lang="ts" setup>
import Icon from '@/features/theme/base/Icon.vue';
import { computed } from 'vue';
import useIssue from '../composables/useIssue';
import { RelatedIssueViewModel } from '../models';

const props = defineProps<{
  issue: RelatedIssueViewModel;
}>();

const { issueNumberFormatted } = useIssue(computed(() => props.issue));
</script>
<template>
  <div class="flex related-container p-1">
    <span class="mr-2 p-1 bg-primary">
      <Icon icon="Folder" />
    </span>
    <div class="flex related-description">
      <span class="related_no text-grey-600">{{ issueNumberFormatted }}</span>
      <span>{{ issue.title || '[Heiti máls]' }}</span>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;

.related-container {
  align-items: center;
  border: 1px solid color.$grey-300;
  border-radius: 2px;
}

.related-description {
  flex-direction: column;
}

.related_no {
  margin-bottom: 0.5rem;
}
</style>
