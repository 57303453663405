import { defineStore } from 'pinia';
import { User } from './models';
import { computed, ref } from 'vue';
import { UserRoleType } from '@/generated/graphql';
import { TranslationKey } from '../translations/defaultMessages';

const userGroups = {
  admins: ['ADMIN', 'SUPER_ADMIN'],
  specialAccess: ['ADMIN', 'SUPER_ADMIN', 'DELEGATION_ADMIN'],
  community: ['DELEGATION_ADMIN', 'DELEGATION_NORMIE', 'DELEGATION_CONSULTANT'],
  slsEmployees: ['SLS_EMPLOYEE_UMHVERFISMATSSVID', 'SLS_EMPLOYEE_DEILISKIPULAGSSVID', 'SLS_EMPLOYEE_ADALSKIPULAGSSVID'],
  adalOrDeilisEmployee: ['SLS_EMPLOYEE_DEILISKIPULAGSSVID', 'SLS_EMPLOYEE_ADALSKIPULAGSSVID'],
};

export const useAuthStore = defineStore('auth', () => {
  const userRef = ref<User | null>(null);

  const user = computed(() => userRef.value);

  const setUser = (user: User) => (userRef.value = user);
  const clearUser = () => (userRef.value = null);

  const isAuthed = computed(() => !!userRef.value);
  const isAdmin = computed(() => userGroups.admins.includes(userRef.value?.role || ''));
  const isSpecialAccess = computed(() => userGroups.specialAccess.includes(userRef.value?.role || ''));
  const isCommunity = computed(() => userGroups.community.includes(userRef.value?.role || ''));
  const isSlsEmployee = computed(() => userGroups.slsEmployees.includes(userRef.value?.role || ''));
  const isUmhverfismatssvidsEmployee = computed(() => userRef.value?.role === 'SLS_EMPLOYEE_UMHVERFISMATSSVID');
  const isAdalOrDeilisEmployee = computed(() => userGroups.adalOrDeilisEmployee.includes(userRef.value?.role || ''));
  const activeRole = computed(() => userRef?.value?.role);

  const activeDelegation = computed(() => {
    return userRef?.value?.delegations.find((de) => de.id == userRef?.value?.delegationMemberId);
  });

  const hasCreateAccess = computed(() => {
    return isAuthed && (isSpecialAccess || isCommunity || isUmhverfismatssvidsEmployee);
  });

  const hasEditAccess = computed(() => {
    return isAuthed && (isSpecialAccess || isCommunity || isUmhverfismatssvidsEmployee || isSlsEmployee);
  });

  const mapDelegationRoleTranslationKey = (roleType: UserRoleType): TranslationKey => {
    switch (roleType) {
      case UserRoleType.DelegationAdmin:
        return 'role.DELEGATION_ADMIN';
      case UserRoleType.DelegationNormie:
        return 'role.DELEGATION_NORMIE';
      case UserRoleType.DelegationConsultant:
        return 'role.DELEGATION_CONSULTANT';
      default:
        return 'role.error';
    }
  };

  function isUserSlsEmployee(user: User) : boolean {
    return userGroups.slsEmployees.includes(user.role || '');
  }

  return {
    userRef,
    user,
    isAuthed,
    isAdmin,
    activeRole,
    activeDelegation,
    isCommunity,
    isSpecialAccess,
    isSlsEmployee,
    isUmhverfismatssvidsEmployee,
    isAdalOrDeilisEmployee,
    hasCreateAccess,
    hasEditAccess,
    setUser,
    clearUser,
    mapDelegationRoleTranslationKey,
    isUserSlsEmployee,
  };
});

export default useAuthStore;
