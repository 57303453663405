import { getMessages } from './i18n';
import { RouteLocationNormalized } from 'vue-router';

export const updateAreaMessagesMiddleware = (to: RouteLocationNormalized, from: RouteLocationNormalized): void => {
  if (to.meta.areaId !== undefined && from.meta.areaId !== to.meta.areaId) {
    getMessages(to.meta.areaId);
  }
};

export default updateAreaMessagesMiddleware;
