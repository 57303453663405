<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import InputText from '@/features/theme/base/InputText.vue';
import { StatutoryReviewerInput } from '@/generated/graphql';
import Option from '@/features/theme/base/models/Option';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import DropDown from '@/features/theme/base/DropDown.vue';
import { computed, ComputedRef } from 'vue';

import { useGetStatutoryReviewerCategoriesQuery } from '@/generated/graphql';

const { data } = useGetStatutoryReviewerCategoriesQuery();

const props = defineProps<{
  modelValue: StatutoryReviewerInput;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', model: StatutoryReviewerInput): void;
  (e: 'submit'): void;
}>();

const onSubmit = () => {
  emits('submit');
};

const onFieldInput = (field: keyof StatutoryReviewerInput, value: string) => {
  emits('update:modelValue', { ...props.modelValue, [field]: value });
};

const categories: ComputedRef<Option[]> = computed(() => {
  const _categories = data.value?.categories || [];
  return _categories.map((c) => ({ name: c, value: c }));
});
</script>
<template>
  <form @submit.prevent="onSubmit" class="container">
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <InputText :model-value="modelValue.name" @update:model-value="(val) => onFieldInput('name', val)" fullwidth label="base.label" />
        </LayoutInput>
        <LayoutInput>
          <DropDown
            :model-value="modelValue.category"
            @update:model-value="(val) => onFieldInput('category', val)"
            fullwidth
            label="base.category"
            :options="categories"
          />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <InputText :model-value="modelValue.email" @update:model-value="(val) => onFieldInput('email', val)" fullwidth label="base.email" />
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <Button :type="ButtonType.primary">{{ $t('base.save') }}</Button>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
</template>

<style scoped>
.container {
  min-width: 64vw;
}

.checkbox-container {
  display: flex;
  height: 100%;
  align-items: center;
}
</style>
