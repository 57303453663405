import useCollectionSearching from '@/features/composables/useCollectionSearching';
import { Monitoring, MonitoringDetails } from '@/features/monitoring/models';
import {
  MonitoringInput,
  useCreateMonitoringMutation,
  useDeleteMonitoringMutation,
  useGetMonitoringQuery,
  useGetMonitoringsQuery,
  useUpdateMonitoringMutation,
} from '@/generated/graphql';
import { computed, ComputedRef } from 'vue';
import useMonitoringModelChecker from './useMonitoringModelChecker';
import { useMonitoringStore } from './useMonitoringStore';

interface UseMonitoringRepository {
  monitorings: ComputedRef<(Monitoring | MonitoringDetails)[]>;
  getMonitorings(): Promise<(Monitoring | MonitoringDetails)[]>;
  getMonitoringDetails(monitoringId: ID): Promise<MonitoringDetails>;
  createMonitoring(input: MonitoringInput): Promise<MonitoringDetails>;
  deleteMonitoring(monitoringId: ID): Promise<Monitoring>;
  updateMonitoring(monitoringId: ID, input: MonitoringInput): Promise<MonitoringDetails>;
}

export default function useMonitoringRepository(): UseMonitoringRepository {
  const store = useMonitoringStore();
  const { isMonitoringDetails } = useMonitoringModelChecker();
  const { findById } = useCollectionSearching();

  const monitorings = computed(() => store.monitorings);

  const getMonitorings = async (): Promise<(Monitoring | MonitoringDetails)[]> => {
    const res = await useGetMonitoringsQuery();

    if (!res.data.value?.monitorings) {
      // Todo: Normal error handling
      throw new Error('Failed to load monitorings');
    }

    store.setMonitorings(res.data.value.monitorings);

    return res.data.value.monitorings;
  };

  const getMonitoringDetails = async (monitoringId: ID): Promise<MonitoringDetails> => {
    const storeMonitoring = findById(store.monitorings, monitoringId);

    if (isMonitoringDetails(storeMonitoring)) {
      return storeMonitoring;
    }

    const res = await useGetMonitoringQuery({
      variables: {
        monitoringId: monitoringId.toString(),
      },
    });

    if (!res.data.value?.monitoringById) {
      // Todo: Normal error handling
      throw new Error('Failed to get monitoring');
    }

    store.setMonitoringDetails(res.data.value.monitoringById);

    return res.data.value.monitoringById;
  };

  const { executeMutation: createMonitoringMutation } = useCreateMonitoringMutation();
  const createMonitoring = async (input: MonitoringInput): Promise<MonitoringDetails> => {
    const res = await createMonitoringMutation({ input });

    if (!res.data?.createMonitoring) {
      // Todo: Normal error handling
      throw new Error('Failed to create monitoring');
    }

    store.setMonitoringDetails(res.data.createMonitoring);

    return res.data.createMonitoring;
  };

  const { executeMutation: updateMonitoringMutation } = useUpdateMonitoringMutation();
  const updateMonitoring = async (monitoringId: ID, input: MonitoringInput): Promise<MonitoringDetails> => {
    const res = await updateMonitoringMutation({ monitoringId: monitoringId.toString(), input });

    if (!res.data?.updateMonitoring) {
      // Todo: Normal error handling
      throw new Error('Failed to update monitoring');
    }

    store.setMonitoringDetails(res.data.updateMonitoring);

    return res.data.updateMonitoring;
  };

  const { executeMutation: deleteMonitoringMutation } = useDeleteMonitoringMutation();
  const deleteMonitoring = async (monitoringId: ID): Promise<Monitoring> => {
    store.removeMonitoring(monitoringId);
    const res = await deleteMonitoringMutation({ monitoringId: monitoringId.toString() });
    if (!res.data?.deleteMonitoring) {
      // Todo: Normal error handling
      throw new Error('Failed to delete monitoring');
    }

    return res.data.deleteMonitoring;
  };

  return {
    monitorings,

    getMonitorings,
    getMonitoringDetails,
    createMonitoring,
    updateMonitoring,
    deleteMonitoring,
  };
}
