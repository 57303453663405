<script lang="ts" setup>
import Tabs from '@/features/theme/base/Tabs.vue';
import Tab from '@/features/theme/base/Tab.vue';
import useActiveIssue from '@/features/issueProcess/composables/useActiveIssue';
import { IssueDetails, IssuePhase } from '@/features/issues/models';
import Button from '@/features/theme/base/Button.vue';
import { ButtonSize, ButtonType } from '@/features/theme/base/models/ButtonType';
import Arrow from '@/features/theme/icons/Arrow.vue';
import DirectionType from '@/features/theme/icons/models/DirectionType';
import useNavigation from '@/features/navigation/useNavigation';
import useProcessTranslation from '../../composables/useProcessTranslation';
import { computed } from 'vue';
import { useProcessStore } from '@/features/issueProcess/composables/useProcessStore';

const props = defineProps<{
  activeIssue: IssueDetails;
}>();

const emits = defineEmits<{
  (e: 'tabChange'): void;
  (e: 'triggerIssueSaveWarning', intendedtab: ID): void;
}>();

const { activeIssuePhases, activePhase, activePhaseOrder, activeIssuePhasesOrdered, selectedPhase, selectPhase, basicInfoPhaseSelected } = useActiveIssue(
  computed(() => props.activeIssue)
);
const { pushActiveAreaRouteByName } = useNavigation();

const movePhase = async (phase: IssuePhase) => {
  if (phase.order <= activePhaseOrder.value) {
    if (processStore.isDirty) {
      emits('triggerIssueSaveWarning', phase.id);
      return;
    }
    return selectPhase(phase.id);
  }
};

const processStore = useProcessStore();

const onTabClick = (tab: string) => {
  const phase = activeIssuePhases.value.find(({ name }) => name === tab) as IssuePhase;
  if (selectedPhase.value?.isCommunicationPhase) {
    //emits('tabChange');
  }

  if (phase) {
    movePhase(phase);
  }
};

const onBackClick = () => {
  pushActiveAreaRouteByName('Issues');
};

const onBasicTabClick = () => selectPhase(null);
const canClickPhaseTab = (phase: IssuePhase): boolean => {
  return !!activePhase.value && phase.order <= activePhase.value.order && !phase.shouldSkip;
};
const { getProcessInformationKey, getProcessPhaseKey } = useProcessTranslation();
</script>
<template>
  <nav class="process-nav">
    <Button
      @click="onBackClick"
      :type="ButtonType.tertiaryIconOnly"
      :size="ButtonSize.medium"
      class="back-btn"
    >
      <Arrow
        :direction="DirectionType.left"
        :heigth="24"
        :width="24"
        color="#006F7E"
      />
    </Button>
    <Tabs>
      <Tab
        key="-1"
        :class="{ bold: !activePhase }"
        :active="basicInfoPhaseSelected"
        :value="getProcessInformationKey(activeIssue?.process)"
        default-value="issue.information"
        @click="onBasicTabClick"
      />
      <Tab
        v-for="phase in activeIssuePhasesOrdered"
        :class="{ bold: activePhase?.id === phase.id }"
        :key="phase.id"
        :active="selectedPhase?.id === phase.id && !basicInfoPhaseSelected"
        @click="() => onTabClick(phase.name)"
        :value="getProcessPhaseKey(activeIssue?.process, phase)"
        :default-value="phase.name"
        :disabled="!canClickPhaseTab(phase)"
        :clickable="canClickPhaseTab(phase)"
      />
    </Tabs>
  </nav>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.process-nav {
  display: flex;
  align-items: center;
  width: 100%;

  @include mq.laptop-down() {
    justify-content: space-between;
  }
}

.tabs {
  padding-top: 1.8rem;
  padding-bottom: 1.8rem;
}

.back-btn {
  min-width: 5.6rem;
  min-height: 5.6rem;
  box-sizing: border-box;
  height: 100%;
  margin-right: 2rem;

  @include mq.laptop-down() {
    display: none;
  }
}
</style>
