import { File as DomainFile } from '../models';

interface UseFileModelChecker {
  isDomainFile(file: DomainFile | File): file is DomainFile;
  isPlainFile(file: DomainFile | File): file is File;
}

export default function useFileModelChecker(): UseFileModelChecker {
  const isDomainFile = (file: DomainFile | File): file is DomainFile => {
    const df = file as DomainFile;
    return !!(df.id && df.path);
  };

  const isPlainFile = (file: DomainFile | File): file is File => {
    return !isDomainFile(file);
  };

  return { isPlainFile, isDomainFile };
}
