<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    value: string;
    defaultValue?: string;
    active?: boolean;
    disabled?: boolean;
    clickable?: boolean;
  }>(),
  {
    active: false,
    disabled: false,
    clickable: true,
  }
);

const emits = defineEmits<{
  (e: 'click', tab: string, disabled: boolean): void;
}>();

const onClick = () => {
  if (props.clickable) {
    emits('click', props.value, props.disabled);
  }
};

const { t } = useI18n();
</script>
<template>
  <li @click="onClick" :class="{ active, disabled, clickable }" class="tab">
    <Translate :value="value" :default-value="defaultValue ? t(defaultValue) : undefined" />
  </li>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
.tab {
  // padding: 0.8rem;
  cursor: no-drop;
  display: flex;
  align-items: center;

  &.active {
    color: colors.$primary;
  }

  &.disabled {
    opacity: 0.6;
  }

  &.clickable {
    cursor: pointer;
  }
}
</style>
