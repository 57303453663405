<script lang="ts" setup>
import Close from '@/features/theme/icons/Close.vue';

withDefaults(
  defineProps<{
    closable?: boolean;
    size?: Maybe<'small' | 'medium' | 'large'>;
  }>(),
  {
    closable: false,
    size: 'medium',
  }
);

const emits = defineEmits<{
  (e: 'close'): void;
}>();

const onClose = () => {
  emits('close');
};
</script>
<template>
  <div class="card" :class="size ? `card-size--${size}` : ''">
    <div v-if="$slots.header" class="card-header" :class="{ closable: closable }">
      <slot name="header" />
      <span @click="onClose" v-if="closable" class="close flex flex-center ml-2 cursor-pointer"><Close /></span>
    </div>
    <div class="card-body">
      <slot />
    </div>
    <div v-if="$slots.footer" class="card-footer">
      <slot name="footer" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;

.card {
  position: relative;
  box-sizing: border-box;
  background-color: white;

  &.card-size {
    &--small {
      width: 40rem;
      padding: spacing.$gutter-small;
      .card-header {
        margin-bottom: spacing.$gutter-small;
      }
      .card-footer {
        margin-top: spacing.$gutter-small;
      }
    }

    &--medium {
      padding: spacing.$gutter-medium;

      .card-header {
        margin-bottom: spacing.$gutter-medium;
        display: flex;
        justify-content: space-between;
      }
      .card-footer {
        margin-top: spacing.$gutter-medium;
      }
    }

    &--large {
      padding: spacing.$gutter-small;

      .card-header {
        margin-bottom: spacing.$gutter-large;
      }
      .card-footer {
        margin-top: spacing.$gutter-large;
      }
    }
  }
}

.closable {
  padding-right: 4rem;
}

.close {
  position: absolute;
  top: 2.5rem;
  right: 2.5rem;
}
</style>
