<script lang="ts" setup>
import { type Component, computed } from 'vue';
import { DataTableDataRow, DataTableItemConfig } from './dataTableTypes';

const props = defineProps<{
  item: unknown;
  row: DataTableDataRow;
  config?: Maybe<DataTableItemConfig>;
}>();

const formattedValue = computed(() => {
  if (props.config?.formatter) {
    const formatter = props.config.formatter;
    if (typeof formatter === 'string') {
      return formatter;
    } else {
      return formatter(props.item);
    }
  }

  return props.item;
});

const resolveComponent = (): Component | string => {
  if (props.config?.component) {
    return props.config?.component(props.item, props.row);
  }

  return 'span';
};
</script>
<template>
  <td :class="`data-table__data-item ${config?.className}`">
    <component
      :is="resolveComponent()"
      :value="formattedValue"
    >
      {{ formattedValue }}
    </component>
  </td>
</template>
<style lang="scss">
.data-table__data-item {
  padding: 1.5rem 1rem;
}
</style>
