<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import Translate from '@/features/translations/Translate.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import InputText from '@/features/theme/base/InputText.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { computed, ref } from 'vue';
import Icon from '@/features/theme/base/Icon.vue';

import {
  useGetStatutoryReviewerCategoriesQuery,
  useDeleteStatutoryReviewerCategoryMutation,
  useCreateStatutoryReviewerCategoryMutation,
} from '@/generated/graphql';
import { TranslationKey } from '@/features/translations/defaultMessages';

const { executeMutation: createCategory } = useCreateStatutoryReviewerCategoryMutation();
const { executeMutation: deleteCategory } = useDeleteStatutoryReviewerCategoryMutation();

const { executeQuery: getCategories } = useGetStatutoryReviewerCategoriesQuery({ pause: true, requestPolicy: 'network-only' });

const input = ref<string[]>([]);
const remoteCategories = ref<string[]>([]);

const emits = defineEmits<{
  (e: 'alert', success: boolean, message: TranslationKey): void;
}>();

const fetchCategories = async () => {
  const { data, error } = await getCategories();
  remoteCategories.value = [...(data.value?.categories || [])].sort();
  input.value = [...remoteCategories.value];

  if (error || !data.value?.categories) {
  }
};

fetchCategories();

const newCategory = ref<string>('');
const isNewCategoryValid = computed(() => {
  return !!newCategory.value;
});

const onClickAdd = () => {
  if (isNewCategoryValid) {
    input.value = [newCategory.value, ...input.value];
    newCategory.value = '';
  }
};

const onClickDelete = (category: string) => {
  input.value = input.value.filter((c) => c !== category);
};

const onSubmit = async () => {
  const createdCategories = input.value.filter((c) => !remoteCategories.value.includes(c));
  const deletedCategories = remoteCategories.value.filter((c) => !input.value.includes(c));

  const errors = [];

  for (const created of createdCategories) {
    try {
      await createCategory({ input: created });
    } catch (error) {
      errors.push(`Ekki tókst að stofna flokk "${created}"`);
    }
  }

  for (const deleted of deletedCategories) {
    try {
      await deleteCategory({ input: deleted });
    } catch (error) {
      errors.push(`Ekki tókst að eyða flokki "${deleted}"`);
    }
  }

  if (errors.length) {
    emits('alert', false, 'statutory_reviewer.categories.failed');
  } else {
    emits('alert', true, 'statutory_reviewer.categories.success');
  }

  await fetchCategories();
};
</script>
<template>
  <form @submit.prevent="onSubmit" class="container">
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <InputText :model-value="newCategory" @update:model-value="(val) => (newCategory = val)" fullwidth label="statutory_reviewer.categories.new" />
        </LayoutInput>
        <LayoutInput>
          <Button class="w-100" @click="onClickAdd" :type="ButtonType.secondary" :prevent="true" :disabled="!isNewCategoryValid">
            <span class="mr-1">
              <Translate t="statutory_reviewer.categories.create" />
            </span>
            <Icon icon="Plus" :options="{ width: 21, height: 21, color: Colors.primaryDark }" />
          </Button>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup v-for="category in input">
        <LayoutInput :span="2">
          <InputText fullwidth :label="category" disabled readonly />
        </LayoutInput>
        <LayoutInput>
          <Button @click="() => onClickDelete(category)" :type="ButtonType.tertiaryIconOnly" :prevent="true">
            <Icon icon="Delete" />
          </Button>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <Button :type="ButtonType.primary">{{ $t('base.save') }}</Button>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
</template>

<style scoped>
.container {
  min-width: 64vw;
}

.checkbox-container {
  display: flex;
  height: 100%;
  align-items: center;
}
</style>
