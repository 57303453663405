const enum IssuePhaseType {
  created = 'blue',
  toReview = 'purple',
  inProgress = 'orange',
  resultsConfirmed = 'green',
  caseRejected = 'red',
  iconOnly_Created = 'iconBlue',
  iconOnly_ToReview = 'iconPurple',
  iconOnly_InProgress = 'iconOrange',
  iconOnly_ResultsConfirmed = 'iconGreen',
  iconOnly_CaseRejected = 'iconRed',
}

const enum IssueType {
  planning = 'planning',
  institution = 'institution',
  case = 'case',
  document = 'document',
  map = 'map',
  ellipsis = 'ellipsis',
  none = 'none',
}

const enum PillSize {
  small = 'small',
  large = 'large',
}

export { IssuePhaseType, IssueType, PillSize };
