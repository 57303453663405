<script lang="ts" setup>
import Checkbox from '@/features/theme/base/Checkbox.vue';
import CheckboxToggle from '@/features/communities/components/CheckboxToggle.vue';
import useAllIssueProcessCategories from '@/features/issues/composables/useAllIssueProcessCategories';
import { IssueSearchProcessInput, IssueSearchProcessPhaseType } from './IssueSearchProcessInput';

const props = defineProps<{
  modelValue: IssueSearchProcessInput[];
  filter: string[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', value: IssueSearchProcessInput[], filter: string[]): void;
}>();

const { phaseTypesGroupedByCategories } = useAllIssueProcessCategories();

const isPhaseTypeChecked = (process: string | number, phaseType: IssueSearchProcessPhaseType): boolean => {
  const selectedProcess = props.modelValue.find(p => p.key == process);
  if (selectedProcess !== undefined) {
    return selectedProcess.value.some(val => val.id === phaseType.id);
  }
  return false;
};

const onPhaseTypeChecked = (process: string | number, phaseType: IssueSearchProcessPhaseType, selected: boolean): void => {
  var processPhases = props.modelValue;
  if (selected) {
    if (processPhases.find(p => p.key == process)) {
      processPhases.find(p => p.key == process)?.value.push({ ...phaseType });
    } else {
      processPhases.push({ key: process.toString(), value: [{ ...phaseType }] });
    }
  } else {
    const index = processPhases.find(p => p.key == process)?.value.findIndex(pt => pt.id == phaseType.id);
    if (index !== undefined && index !== -1) {
      processPhases.find(p => p.key == process)?.value.splice(index, 1);
      if (processPhases.find(p => p.key == process)?.value.length === 0) {
        const index = processPhases.findIndex(p => p.key == process);
        if (index !== -1) {
          processPhases.splice(index, 1);
        }
      }
    }
  }

  emit('update:modelValue', processPhases, processPhasesFilter(processPhases));
};

const processPhasesFilter = (processPhases: IssueSearchProcessInput[]) => {
  const processPhasesFilter: string[] = [];
  if (processPhases) {
    processPhases.forEach(processPhase => {
      const phaseNames: string[] = [];
      processPhase.value.forEach(phase => {
        phaseNames.push(phase.name);
      });
      processPhasesFilter.push(processPhase.key + ': [' + phaseNames.join(', ') + ']');
    });
  }
  return processPhasesFilter;
};

const isProcessChecked = (category: string | number, process: string | number): boolean => {
  const selectedProcess = props.modelValue.find(p => p.key == process);
  if (selectedProcess !== undefined) {
    const phaseTypes = phaseTypesGroupedByCategories.value[category][process].map(p => p.name);
    return selectedProcess.value.length === phaseTypes.length;
  }
  return false;
};

const onProcessChecked = (category: string | number, process: string | number, val: boolean) => {
  const phaseTypes = phaseTypesGroupedByCategories.value[category][process].map(x => ({ ...x }));

  var processPhases = props.modelValue;
  if (val) {
    if (processPhases.find(p => p.key == process)) {
      processPhases.find(p => p.key == process)!.value = phaseTypes;
    } else {
      processPhases.push({ key: process.toString(), value: phaseTypes });
    }
  } else {
    const index = processPhases.findIndex(p => p.key == process);
    if (index !== -1) {
      processPhases.splice(index, 1);
    }
  }
  emit('update:modelValue', processPhases, processPhasesFilter(processPhases));
};

const expandProcess = (process: string | number): boolean => {
  return props.modelValue.find(p => p.key == process) !== undefined;
};
</script>
<template>
  <div
    class="mb-2"
    v-for="(processCategories, category) in phaseTypesGroupedByCategories"
    :key="category"
  >
    <h5 class="process-category-caption my-1">{{ category }}</h5>
    <ul class="checkbox-list">
      <template
        v-for="(processPhases, process) in processCategories"
        :key="process"
      >
        <CheckboxToggle
          :label="process"
          v-if="processPhases.length > 0"
          :key="process"
          :id="`${process}`"
          :checked="isProcessChecked(category, process)"
          :expanded="expandProcess(process)"
          @checked="val => onProcessChecked(category, process, val)"
        >
          <ul>
            <li
              class="pl-6"
              v-for="phase in processPhases"
              :key="phase.name"
            >
              <Checkbox
                :id="`${process}.${phase.name}`"
                :key="phase.name"
                :model-value="isPhaseTypeChecked(process, phase)"
                @update:model-value="val => onPhaseTypeChecked(process, phase, val)"
                class="phase-type-item"
              >
                {{ phase.name }}
              </Checkbox>
            </li>
          </ul>
        </CheckboxToggle>
      </template>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
.checkbox-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  @include mq.mobile() {
    grid-template-columns: repeat(1, 1fr);
  }
}

.process-category-caption {
  font-size: 1.8rem;

  @include mq.mobile() {
    font-size: 1.6rem;
  }
}

.phase-type-item {
  margin: 0.25rem 0 0.25rem 0;
}
</style>
