<script lang="ts" setup>
import useNavigation from '@/features/navigation/useNavigation';
import ProcessStart from '@/features/issueProcess/components/ProcessStart.vue';
import { usePushIssueProcessYearOrder } from '@/features/issues/composables/usePushIssueProcessYearOrder';
import { IssueCardViewModel } from '../../models';

const { pushIssueProcessYearOrder } = usePushIssueProcessYearOrder();

const { navigateBack } = useNavigation();

const onProcessStarted = (issue: IssueCardViewModel) => {
  pushIssueProcessYearOrder(issue);
};

const onProcessCancelled = () => {
  navigateBack();
};
</script>
<template>
  <div class="layout-centered">
    <ProcessStart
      @start="onProcessStarted"
      @cancel="onProcessCancelled"
    />
  </div>
</template>
<style lang="scss" scoped>
.layout-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
