<script lang="ts" setup>
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import { IssuePhaseStepEnum, useGetPhaseStepsQuery } from '@/generated/graphql';
import { computed, ref, watch } from 'vue';
import useProcessObservers from '../../composables/useProcessObservers';
import { ProcessOperation, useProcessStore } from '../../composables/useProcessStore';
import { UpdatePhaseStepFieldInput } from '../../models/UpdatePhaseStepFieldInput';
import useActivePhaseOperations from '../../composables/useActivePhaseOperations';
import { IssueDetails } from '@/features/issues/models';
import RichTextEditor from '@/features/theme/base/richTextEditor/RichTextEditor.vue';
import { Delta } from '@vueup/vue-quill';
import useActiveIssue from '../../composables/useActiveIssue';

const props = defineProps<{
  phaseId: string;
  phaseStepType: IssuePhaseStepEnum;
  fieldKey: string;
  activeIssue: IssueDetails;
}>();

const phaseId = computed(() => props.phaseId);

const { data, fetching } = useGetPhaseStepsQuery({ variables: { phaseId }, requestPolicy: 'network-only' });
const { updatePhaseStepField } = useActivePhaseOperations(computed(() => props.activeIssue));

const stepFieldInput = ref<UpdatePhaseStepFieldInput>({
  key: '',
  value: '',
  iterationCounter: 0,
  phaseStepType: props.phaseStepType,
});

const phaseStepField = computed(() => {
  const currentStep = data.value?.phase.steps.find(s => s.type === props.phaseStepType);
  return currentStep?.fields.find(f => f.key === props.fieldKey);
});

const processStore = useProcessStore();
const nUpdates = ref(0);

const onIssuePhaseDescriptonUpdate = () => {
  nUpdates.value = nUpdates.value + 1;
  processStore.isDirty = nUpdates.value > 1;
};

watch(fetching, current => {
  if (current === false && nUpdates.value === 0) {
    const maybeDelta = phaseStepField.value?.richTextValue;
    const value = phaseStepField.value?.value;
    let ops = [];
    if (maybeDelta) {
      ops = JSON.parse(maybeDelta);
    } else if (value) {
      ops.push({ insert: value });
    }

    stepFieldInput.value = {
      key: props.fieldKey,
      quillDelta: new Delta(ops),
      iterationCounter: 0,
      phaseStepType: props.phaseStepType,
    };
  }
});

const { selectedPhase } = useActiveIssue(computed(() => props.activeIssue));

const onProcessSave = async () => {
  if (stepFieldInput.value.quillDelta?.length() && selectedPhase.value) {
    await updatePhaseStepField(selectedPhase.value?.id.toString(), {
      ...stepFieldInput.value,
    });
  }
};

useProcessObservers({
  [ProcessOperation.Save]: [onProcessSave],
});
</script>

<template>
  <LayoutFormGroup>
    <LayoutInput :span="3">
      <RichTextEditor
        translationKey="issue.phase.description"
        v-model="stepFieldInput.quillDelta"
        fullwidth
        @update:model-value="() => onIssuePhaseDescriptonUpdate()"
      />
    </LayoutInput>
  </LayoutFormGroup>
</template>
