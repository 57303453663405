import { Ref, computed, ref, watch } from 'vue';
import useIssueStore from '@/features/issues/composables/useIssueStore';
import { useGetMapIssuesQuery, useGetIssueCardViewModelConnectionQuery } from '@/generated/graphql';
import { useInfiniteScroll } from '@vueuse/core';
import { IssueCardViewModel } from '../models';
import { issueCardsCache } from '../issueCardsCache';

export let _issueCardsCache: IssueCardViewModel[] = [];

export default function useInfiniteScrollingIssueCards(elRef: Ref<HTMLElement | null>) {
  const store = useIssueStore();
  const after = computed(() => store.after);
  const issueSearch = computed(() => store.issueSearch);

  const { data, fetching: fetchingIssueCards } = useGetIssueCardViewModelConnectionQuery({
    requestPolicy: 'cache-first',
    variables: {
      first: 8,
      input: computed(() => issueSearch.value.spec),
      after: after,
    },
  });

  const isInitialized = ref(false);

  watch(
    fetchingIssueCards,
    (value, oldValue) => {
      if (after.value === undefined) {
        issueCardsCache.clear();
      }
      if ((oldValue && !value) || (oldValue === undefined && !value)) {
        isInitialized.value = true;
      }
    },
    {
      immediate: true,
    }
  );

  const issueCards = computed(() => {
    const batch: IssueCardViewModel[] = data.value?.issueConnection?.edges?.map((edge) => edge.node) ?? [];
    issueCardsCache.concat(batch);
    return issueCardsCache.get();
  });

  const pageInfo = computed(() => data.value?.issueConnection?.pageInfo || { hasNextPage: false });
  const totalCount = computed(() => data.value?.issueConnection?.totalCount || 0);

  useInfiniteScroll(
    elRef,
    () => {
      if (pageInfo.value.hasNextPage && pageInfo.value.endCursor) {
        store.after = pageInfo.value.endCursor;
      }
    },
    { distance: 200 }
  );

  const { data: issueData } = useGetMapIssuesQuery({
    variables: {
      specification: computed(() => issueSearch.value.spec),
    },
  });

  const mapIssues = computed(() => {
    return (
      issueData.value?.mapIssues?.map((mi) => ({
        id: mi.id,
        marker: mi.marker,
        isHidden: mi.isHidden,
        currentPhase: {
          id: mi.currentPhaseId,
          reviewStartDate: mi.currentPhaseReviewStartDate,
          reviewEndDate: mi.currentPhaseReviewEndDate,
          state: mi.currentPhaseState,
          hasReviews: mi.currentPhaseHasReviews,
          isLast: mi.currentPhaseIsLast,
        },
      })) || []
    );
  });

  return {
    fetchingIssueCards,
    isInitialized,
    mapIssues,
    issueCards,
    totalCount,
    pageInfo,
  };
}
