<script lang="ts" setup>
import { Options } from 'ol/layer/BaseTile';
import TileLayer from 'ol/layer/Tile';
import TileSource from 'ol/source/Tile';
import { provide, shallowRef } from 'vue';
import { mapTileLayerKey } from '@/features/map/models/mapSymbols';
import Layer from './Layer.vue';

const props = defineProps<{
  options?: Options<TileSource>;
}>();

const emits = defineEmits<{
  (e: 'mounted', layer: TileLayer<TileSource>): void;
  (e: 'unmounted', layer: TileLayer<TileSource>): void;
}>();

const layer = shallowRef(new TileLayer(props.options));

provide(mapTileLayerKey, () => layer.value);

const onLayerMounted = () => {
  emits('mounted', layer.value);
};

const onLayerUnmounted = () => {
  emits('unmounted', layer.value);
};
</script>
<template>
  <Layer @mounted="onLayerMounted" @unmounted="onLayerUnmounted" :layer="layer">
    <slot />
  </Layer>
</template>
