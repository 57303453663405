<script setup lang="ts">
import NumOfIssues from './NumOfIssues.vue';
import FaqAccordion from '@/features/faq/components/FaqAccordion.vue';
import TextAndVideos from './TextAndVideos.vue';
import NewIssuesCards from './NewIssuesCards.vue';
import HeroBanner from './HeroBanner.vue';
import useNavigationStore from '../navigation/useNavigationStore';
import IssueOnMap from './IssuesOnMap.vue';
import Translate from '../translations/Translate.vue';
import { computed, onMounted, ref } from 'vue';
import { IssueCardViewModel } from '../issues/models';
import { useRoute } from 'vue-router';
import { IssueSortEnum, IssueSpecificationInput, useGetIssueCardViewModelConnectionQuery } from '@/generated/graphql';

const store = useNavigationStore();
const route = useRoute();

store.setMainMenuTheme('frontpage');
store.setMainFooterMargin(false);
store.setMainMenuMargin(false);

const issueSpecification = ref<IssueSpecificationInput>({
  sortBy: IssueSortEnum.Created,
});

const { data } = useGetIssueCardViewModelConnectionQuery({
  requestPolicy: 'cache-and-network',
  variables: {
    input: issueSpecification,
    first: 8,
  },
});

const issues = computed<IssueCardViewModel[]>(() => data.value?.issueConnection?.edges?.map((edge) => edge.node) || []);
const information = ref();

onMounted(() => {
  if (route.hash) {
    const hash = route.hash;
    if (hash === '#information') {
      if (information) {
        setTimeout(() => {
          information.value.$el.scrollIntoView({ behavior: 'smooth' });
        }, 100);
      }
    }
  }
});
</script>

<template>
  <div class="flex flex-column">
    <HeroBanner />
    <div class="bg-lines flex">
      <NewIssuesCards :issues="issues" />
      <IssueOnMap />
    </div>
    <NumOfIssues :issues="issues" />
    <TextAndVideos ref="information" />
    <FaqAccordion><Translate t="frontpage.faq" /></FaqAccordion>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/fonts' as font;

.bg-lines {
  background: url('@/assets/Line-right.svg') no-repeat, linear-gradient(color.$foam 40%, color.$white 40%);
  flex-direction: column;

  @include mq.desktop-down {
    background: url('@/assets/Line-right.svg') no-repeat, linear-gradient(color.$foam 37%, color.$white 37%);
  }

  @include mq.laptop-down {
    background: url('@/assets/Line-right.svg') no-repeat, linear-gradient(color.$foam 45%, color.$white 45%);
  }

  @include mq.mobile {
    background: url('@/assets/Line-right.svg') no-repeat;
  }
}
</style>
