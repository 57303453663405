import { createApp } from 'vue';
import App from './App.vue';
import i18n from './features/translations/i18n';
import router, { routes } from './features/router';
import './scss/main.scss';
import store from './features/store';
import { useUrql } from './urql';
import setupAreas from './features/navigation/setupAreas';
import './ol';
import { useSignin } from './features/auth/useSignin';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '@vuepic/vue-datepicker/dist/main.css';

import * as Sentry from '@sentry/vue';

const app = createApp(App);

// Floating Vue Tooltips
// Use: v-tooltip="{ content: 'Ég er tooltip', theme: 'primary' }"
//      v-tooltip.bottom="{ content: 'Ég er bottom tooltip', theme: 'primary' }"
app.use(FloatingVue, {
  themes: {
    primary: {
      $extend: 'tooltip',
    },
    secondary: {
      $extend: 'tooltip',
    },
  },
});

app.directive('focus', {
  mounted: (el, binding) => {
    if (binding.value === undefined || binding.value) {
      el.focus();
    }
  },
});

Sentry.init({
  app,
  dsn: 'https://c7b7a955d80b2c051e2c84d26d8d6049@o4505596027207680.ingest.sentry.io/4505760542228480',
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://skipulagsgatt.test.lisa.is/', 'https://skipulagsgatt.is/'],
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 0.2, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

(async () => {
  await setupAreas();
  routes.forEach(router.addRoute);

  useUrql(app);
  app.use(router);
  app.use(store);
  app.use(i18n);

  const signin = useSignin();
  await signin.login();

  app.mount('#app');
})();
