import { createRouter, createWebHistory } from 'vue-router';
import updateActiveAreaMiddleware from '@/features/navigation/updateActiveAreaMiddleware';
import authMiddleware from '@/features/auth/authMiddleware';
import updateAreaMessagesMiddleware from '@/features/translations/updateAreaMessagesMiddleware';
import setNavigationDefaultsMiddleware from '@/features/navigation/setNavigationDefaultsMiddleware';

const router = createRouter({
  history: createWebHistory(),
  routes: [],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { left: 0, top: 0 };
  },
});

router.beforeEach(authMiddleware.routeGuards);
router.beforeEach(updateActiveAreaMiddleware);
router.beforeEach(setNavigationDefaultsMiddleware);
router.beforeEach(updateAreaMessagesMiddleware);

export default router;
