<script lang="ts" setup>
import { LoftmyndirCapability } from '@/features/loftmyndir/models';
import DropDown from '@/features/theme/base/DropDown.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Option from '@/features/theme/base/models/Option';
import { computed } from '@vue/reactivity';

const props = defineProps<{
  modelValue?: Maybe<LoftmyndirCapability>;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', v: Maybe<LoftmyndirCapability>): void;
}>();

const dropdownOptions: Option[] = [
  {
    name: 'issue.map.grayscale',
    value: LoftmyndirCapability.Grayscale,
    icon: 'MapGrayscale',
    extraClass: 'text-uppercase',
  },
  {
    name: 'issue.map.image',
    value: LoftmyndirCapability.Image,
    icon: 'MapLoftmyndir',
    extraClass: 'text-uppercase',
  },
  {
    name: 'issue.map.osm',
    value: 'OSM',
    icon: 'MapOsm',
    extraClass: 'text-uppercase',
  },
];

const onLayerImageUpdate = (value: string | LoftmyndirCapability) => {
  if (value === LoftmyndirCapability.Grayscale || value === LoftmyndirCapability.Image) {
    emits('update:modelValue', value);
  } else {
    emits('update:modelValue', null);
  }
};

const mapSelected = computed<string | LoftmyndirCapability>(() => {
  if (!props.modelValue) {
    return 'OSM';
  }
  return props.modelValue;
});
</script>
<template>
  <DropDown
    class="map-dropdown"
    reverse
    :scrollbar="false"
    :options="dropdownOptions"
    :model-value="mapSelected"
    :arrow-color="Colors.primary"
    @update:model-value="onLayerImageUpdate"
    selected-option-style
  />
</template>
<style lang="scss" scoped>
.map-dropdown {
  min-width: 25rem;
  max-width: 24rem;
}
</style>
