import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { ProjectionEnum } from '@/features/map/models/mapProjection';

interface UseProjections {
  transformGeometry: (geom: Geometry, p1: ProjectionEnum, p2: ProjectionEnum) => Geometry;
  transformGeometryIsToWgs: (geom: Geometry) => Geometry;
  transformGeometryWgsToIs: (geom: Geometry) => Geometry;

  transformFeature: (feature: Feature<Geometry>, p1: ProjectionEnum, p2: ProjectionEnum) => Feature<Geometry>;
  transformFeatureIsToWgs: (geom: Feature<Geometry>) => Feature<Geometry>;
  transformFeatureWgsToIs: (geom: Feature<Geometry>) => Feature<Geometry>;

  transformFeatures: (features: Feature<Geometry>[], p1: ProjectionEnum, p2: ProjectionEnum) => Feature<Geometry>[];
  transformFeaturesIsToWgs: (geom: Feature<Geometry>[]) => Feature<Geometry>[];
  transformFeaturesWgsToIs: (geom: Feature<Geometry>[]) => Feature<Geometry>[];
}

export default function useProjections(): UseProjections {
  const transformGeometry = (geom: Geometry, p1: ProjectionEnum, p2: ProjectionEnum): Geometry => {
    return geom.clone().transform(p1, p2);
  };

  const transformFeature = (feature: Feature<Geometry>, p1: ProjectionEnum, p2: ProjectionEnum): Feature<Geometry> => {
    const clone = feature.clone();
    clone.getGeometry()?.transform(p1, p2);
    return clone;
  };

  const transformFeatures = (features: Feature<Geometry>[], p1: ProjectionEnum, p2: ProjectionEnum): Feature<Geometry>[] => {
    return features.map((feature) => transformFeature(feature, p1, p2));
  };

  const transformGeometryIsToWgs = (geom: Geometry) => transformGeometry(geom, ProjectionEnum.EPSG3057, ProjectionEnum.EPSG4326);
  const transformGeometryWgsToIs = (geom: Geometry) => transformGeometry(geom, ProjectionEnum.EPSG4326, ProjectionEnum.EPSG3057);

  const transformFeatureIsToWgs = (feature: Feature<Geometry>) => transformFeature(feature, ProjectionEnum.EPSG3057, ProjectionEnum.EPSG4326);
  const transformFeatureWgsToIs = (feature: Feature<Geometry>) => transformFeature(feature, ProjectionEnum.EPSG4326, ProjectionEnum.EPSG3057);

  const transformFeaturesIsToWgs = (features: Feature<Geometry>[]) => transformFeatures(features, ProjectionEnum.EPSG3057, ProjectionEnum.EPSG4326);
  const transformFeaturesWgsToIs = (features: Feature<Geometry>[]) => transformFeatures(features, ProjectionEnum.EPSG4326, ProjectionEnum.EPSG3057);

  return {
    transformGeometry,
    transformGeometryIsToWgs,
    transformGeometryWgsToIs,

    transformFeature,
    transformFeatureIsToWgs,
    transformFeatureWgsToIs,

    transformFeatures,
    transformFeaturesIsToWgs,
    transformFeaturesWgsToIs,
  };
}
