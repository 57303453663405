<script lang="ts" setup>
import { File } from '@/features/files/models';
import FileListContent from '@/features/files/components/FileListContent.vue';
import useDateFormatting from '@/features/composables/useDateFormatting';
import Translate from '@/features/translations/Translate.vue';
import { IndexedFile } from '../models/file';
import { computed } from 'vue';

const props = withDefaults(
  defineProps<{
    files?: File[];
    download?: boolean;
    deletable?: boolean;
    editable?: boolean;
    showRegistrationDates?: boolean;
  }>(),
  {
    files: () => [],
    download: true,
    deletable: false,
    editable: false,
    showRegistrationDates: false,
  }
);

const dateMissingToken = 'Óskráð';

const emits = defineEmits<{
  (e: 'delete', file: File, index: number): void;
  (e: 'edit', name: string, index: number): void;
}>();

const onDeleteClick = async (file: File, index: number) => {
  emits('delete', file, index);
};

const onEditClick = async (name: string, index: number) => {
  emits('edit', name, index);
};

const { formatDateLocalized } = useDateFormatting();

const publishedAt = new Date();

const otherProps = computed(() => {
  const { files, ...other } = props;
  return other;
});

const indexedFiles = computed(() => {
  return props.files.reduce((acc, f, i) => {
    const maybeDate = f.publishedAt || f.createdAt;
    if (maybeDate) {
      const [date] = maybeDate.split('T');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push({ idx: i, file: f });
    } else {
      if (!acc[dateMissingToken]) {
        acc[dateMissingToken] = [];
      }
      acc[dateMissingToken].push({ idx: i, file: f });
    }

    return acc;
  }, {} as { [key: string]: IndexedFile[] });
});

const indexedFilesKeys = computed(() => {
  return Object.keys(indexedFiles.value).sort((a, b) => {
    return a.localeCompare(b);
  });
});

const maxWidthStyle = computed(() => {
  let n = [props.deletable, props.editable].filter(p => p).length;
  const calcInput = `100% - ${n} * 42px - ${n} * 1rem`;
  return `max-width: calc(${calcInput})`;
});
</script>
<template>
  <template
    v-if="showRegistrationDates"
    v-for="date in indexedFilesKeys"
  >
    <div class="mb-1">
      <div
        class="date"
        :style="maxWidthStyle"
      >
        <p
          v-if="date === dateMissingToken"
          class="color-orange--dark"
        >
          <Translate t="issue.phase.step.data_no_date" />
        </p>
        <p v-else>
          <Translate
            v-if="publishedAt"
            t="issue.phase.step.data_published_date"
            :interpolations="{ date: formatDateLocalized(date) }"
          />
        </p>
      </div>
    </div>
    <FileListContent
      v-bind="otherProps"
      :fileGroup="indexedFiles[date]"
      @delete="onDeleteClick"
      @edit="onEditClick"
    />
  </template>
  <template v-else>
    <FileListContent
      v-bind="otherProps"
      :fileGroup="files.map((file, idx) => ({ file, idx }))"
      @delete="onDeleteClick"
      @edit="onEditClick"
    />
  </template>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/spacing' as spacing;

.date {
  color: grey;
  font-weight: normal;
  margin-bottom: 1rem;
  display: flex;
  justify-content: flex-end;
}
</style>
