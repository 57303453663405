<script lang="ts" setup>
import Translate from '@/features/translations/Translate.vue';
import { computed, ref, watch } from 'vue';
const props = withDefaults(
  defineProps<{
    modelValue?: Maybe<string>;
    label?: string;
    fullwidth?: boolean;
    disabled?: boolean;
    errorMessage?: Maybe<string>;
    readonly?: boolean;
  }>(),
  {
    modelValue: '',
    fullwidth: false,
    readonly: false,
  }
);
const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
  (e: 'focus', value: boolean): void;
  (e: 'click'): void;
  (e: 'keyup.up'): void;
  (e: 'keyup.down'): void;
  (e: 'keyup.enter'): void;
  (e: 'keyup.esc'): void;
  (e: 'keydown.tab'): void;
}>();
const localValue = ref<string>('');
const inputValue = computed(() => localValue.value || props.modelValue);
const isFocus = ref(false);
const isActive = computed(() => isFocus.value || inputValue.value !== '');
const onInput = (e: Event) => {
  localValue.value = (e.target as HTMLInputElement).value || '';
  emit('update:modelValue', localValue.value);
};
watch(
  () => props.modelValue,
  (newValue) => {
    localValue.value = newValue || '';
  }
);

const onFocus = () => {
  if (!props.readonly) {
    isFocus.value = true;
  }
  emit('focus', true);
};

const onFocusOut = () => {
  isFocus.value = false;
  emit('focus', false);
};

const onKeyupUp = () => {
  emit('keyup.up');
};

const onKeyupDown = () => {
  emit('keyup.down');
};

const onKeyupEnter = () => {
  emit('keyup.enter');
};

const onKeyupEsc = () => {
  emit('keyup.esc');
};

const onKeydownTab = () => {
  emit('keydown.tab');
};

const onClick = () => {
  emit('click');
};
</script>

<template>
  <div @click="onClick" class="input_container" :class="{ active: isActive, 'max-width': fullwidth, disabled: disabled }">
    <slot v-if="label !== undefined" name="label">
      <label class="text_label">
        <Translate :value="label" />
      </label>
    </slot>
    <!-- <label class="text_label regular"><slot /></label> -->
    <input
      :value="inputValue"
      @input="onInput"
      class="text_input"
      :class="{ 'has-error': !!errorMessage }"
      type="text"
      @focus="onFocus"
      @focusout="onFocusOut"
      :disabled="disabled"
      :readonly="readonly"
      @keyup.up.prevent.exact="onKeyupUp"
      @keyup.down.prevent.exact="onKeyupDown"
      @keyup.enter.prevent.exact="onKeyupEnter"
      @keydown.tab.exact="onKeydownTab"
      @keyup.esc.prevent.exact="onKeyupEsc"
    />
    <Translate v-if="errorMessage" :value="errorMessage" class="error-message" />
  </div>
</template>
<style lang="scss" scoped>
.max-width {
  max-width: unset;
}
</style>
