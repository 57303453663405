<script lang="ts" setup>
import Button from '@/features/theme/base/Button.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import { Colors } from '@/features/theme/base/models/Colors';
import { useI18n } from 'vue-i18n';

const props = withDefaults(
  defineProps<{
    undoEnabled?: boolean;
    redoEnabled?: boolean;
  }>(),
  {
    undoEnabled: true,
    redoEnabled: true,
  }
);

const emits = defineEmits<{
  (e: 'undo'): void;
  (e: 'redo'): void;
}>();

const onUndo = () => {
  emits('undo');
};

const onRedo = () => {
  emits('redo');
};

const { t } = useI18n();
</script>
<template>
  <div class="flex">
    <Button v-tooltip="{ content: t('issue.map.undo.tooltip'), theme: 'primary' }" :disabled="!undoEnabled" @click="onUndo" :type="ButtonType.secondary">
      <Icon icon="Undo" :options="{ color: Colors.primary, width: 24, height: 24 }" />
    </Button>
    <Button v-tooltip="{ content: t('issue.map.redo.tooltip'), theme: 'primary' }" :disabled="!redoEnabled" @click="onRedo" :type="ButtonType.secondary">
      <Icon icon="Redo" :options="{ color: Colors.primary, width: 24, height: 24 }" />
    </Button>
  </div>
</template>
