<script lang="ts" setup>
import DataTableHead from './DataTableHead.vue';
import DataTableBody from './DataTableBody.vue';
import DataTableFoot from './DataTableFoot.vue';
import type { DataTableConfigStrict, DataTableDataRow, DataTableHeader } from './dataTableTypes';

defineProps<{
  headers: DataTableHeader[];
  dataset: DataTableDataRow[];
  config: DataTableConfigStrict<DataTableDataRow>;
  selectedRowIndex?: number;
}>();

const emits = defineEmits<{
  (e: 'row-click', row: DataTableDataRow, index: number): void;
}>();

const onRowClick = (row: DataTableDataRow, index: number) => {
  emits('row-click', row, index);
};
</script>
<template>
  <table class="data-table__table">
    <DataTableHead :config="config" :headers="headers" />
    <DataTableBody :config="config" :dataset="dataset" @row:click="onRowClick" :selected-index="selectedRowIndex" />
    <DataTableFoot :config="config" />
  </table>
</template>
<style lang="scss">
.data-table__table {
  border-collapse: separate;
  border-spacing: 0 1rem;
}
</style>
