type Base64ReaderResult = string | ArrayBuffer | null;

interface UseBase64 {
  fileToBase64(file: File): Promise<Base64ReaderResult>;
  fileToBase64String(file: File): Promise<string>;
}

export default function useBase64(): UseBase64 {
  const fileToBase64 = (file: File) =>
    new Promise<string | ArrayBuffer | null>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const fileToBase64String = async (file: File) => {
    const base64 = await fileToBase64(file);

    if (typeof base64 !== 'string') {
      throw new Error('Failed to read base64 string');
    }

    return base64.substring(base64.indexOf(',') + 1);
  };

  return {
    fileToBase64,
    fileToBase64String,
  };
}
