<script lang="ts" setup>
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';
import Icon, { IconType } from '@/features/theme/base/Icon.vue';
import useIssueStatus from '@/features/issues/composables/useIssueStatus';
import { IssueStatusEnum } from '@/generated/graphql';
import Checkbox from '@/features/theme/base/Checkbox.vue';

const props = defineProps<{
  isSelected: boolean;
  issueStatus: IssueStatusEnum;
  iconType: IconType;
  iconColor?: Colors | string;
  customClass: string;
}>();

const { mapIssueStatusTranslationKey } = useIssueStatus();

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean): void;
}>();

const updateSelected = (): void => {
  emit('update:modelValue', !props.isSelected);
};
</script>

<template>
  <div
    class="pill-container"
    :class="`${customClass}`"
  >
    <Checkbox
      :id="issueStatus"
      :model-value="isSelected"
      @update:model-value="updateSelected"
      custom-class="status-checkbox"
    >
      <Icon
        :icon="iconType"
        :height="24"
        :width="24"
        :color="iconColor"
      />
      <span class="pill-text bold text--small">
        <Translate :t="mapIssueStatusTranslationKey(issueStatus)" />
      </span>
    </Checkbox>
  </div>
</template>


<style lang="scss">
  @use '@/scss/design-tokens/colors' as color;
  
  .status-checkbox {
    .checkbox {
      display: flex;
      padding-top: 0;

      .checkbox-checkmark {
        margin: 0.2rem;
      }
      
      .checkbox-label {
        display: flex;
        align-self: center;
      }

      input:hover ~ .checkbox-label {
        color: unset;
      }

      input:checked ~ .checkbox-label {
        color: unset !important;
      }
    }
    .checkbox:hover {
      color: unset;
    }
  }

  .pill-text {
    text-transform: uppercase;
    font-size: 12px;
    align-self: center;
  }

  .pill-container {
    box-sizing: border-box;
    border-radius: 2px;
  }

  .in-progress,
  .in-sls-review {
    color: color.$blue--dark;
    background-color: color.$blue--light;
  }

  .complete {
    color: color.$green--dark;
    background-color: color.$green--light;
  }

  .unpublished {
    color: color.$red--dark;
    background-color: color.$red--light;
  }

  .reviewing {
    color: color.$orange--dark;
    background-color: color.$orange--light;
  }
</style>
