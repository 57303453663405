import { Component } from 'vue';
import ParagraphDefault from './ParagraphDefault.vue';
import IssueCreate from '@/features/issues/components/issueCreate/IssueCreate.vue';
import IssueIndex from '@/features/issues/components/issueIndex/IssueIndex.vue';
import Login from '@/features/auth/Login.vue';
import Delegations from '@/features/auth/Delegations.vue';
import IssueProcessManager from '@/features/issueProcess/components/IssueProcessManager.vue';
import IssueDetails from '@/features/issues/components/issueDetails/IssueDetails.vue';
import UserManagement from '@/features/userManagement/components/UserManagement.vue';
import StatutoryReviewersManagement from '@/features/statutoryReviewersManagement/components/StatutoryReviewersManagement.vue';
import Monitoring from '@/features/monitoring/components/Monitoring.vue';
import AuditLogs from '@/features/audits/components/AuditLogs.vue';
import Frontpage from '@/features/frontpage/Frontpage.vue';
import MyIssues from '@/features/issues/components/MyIssues.vue';
import PageError from '../pages/PageError.vue';
import CookieHubDeclaration from './CookieHubDeclaration.vue';
import Faq from '@/features/faq/FaqPage.vue';

export default {
  ParagraphDefault,
  CookieHubDeclaration,

  // Issues
  IssueDetails,
  IssueIndex,
  IssueCreate,
  MyIssues,

  // Process
  IssueProcessManager,

  // Login
  Login,
  Delegations,

  // UserManagement
  UserManagement,
  StatutoryReviewersManagement,

  // Monitoring
  Monitoring,

  // Audit logs
  AuditLogs,

  // Frontpage
  Frontpage,

  // Error Page
  PageError,

  // Instructions
  Faq,
} as Record<string, Component>;
