<script lang="ts" setup></script>
<template>
  <figure class="icon-fig">
    <img class="icon-img" src="@/assets/icon_loftmyndir.png" alt="aerial view map icon" />
  </figure>
</template>
<style lang="scss" scoped>
.icon-fig {
  max-width: 2.5rem;

  .icon-img {
    width: 100%;
  }
}
</style>
