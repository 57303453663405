<script lang="ts" setup>
import Article from '@/features/theme/base/article';
import Button from '@/features/theme/base/Button.vue';
import Modal from '@/features/theme/base/Modal.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Translate from '@/features/translations/Translate.vue';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';

withDefaults(
  defineProps<{
    prompt?: boolean;
  }>(),
  {
    prompt: false,
  }
);

const emits = defineEmits<{
  (e: 'cancel'): void;
  (e: 'continue'): void;
  (e: 'save'): void;
}>();

const onContinue = () => {
  emits('continue');
};

const onSave = () => {
  emits('save');
};

const onCancel = () => {
  emits('cancel');
};
</script>

<template>
  <Modal
    :open="prompt"
    @close="onCancel"
  >
    <Article
      class="flex flex-column save-warning-container flex-center"
      :articleSize="'medium'"
    >
      <div class="flex gap-1 flex-align-center mb-2">
        <Icon
          icon="Error"
          :height="32"
          :width="32"
          :color="Colors.redDark"
        />
        <h1 class="h5 color-red--dark">
          <Translate t="issue.phase.save.warning.title" />
        </h1>
      </div>
      <p><Translate t="issue.phase.save.warning.text" /></p>
      <div class="flex mt-3 gap-3 flex-center">
        <Button
          class="save-warning-btn"
          @click="onSave"
          :type="ButtonType.primary"
        >
          <Translate t="base.save" />
        </Button>
        <Button
          class="save-warning-btn"
          @click="onContinue"
          :type="ButtonType.primary"
        >
          <Translate t="base.continue.no.save" />
        </Button>
      </div>
    </Article>
  </Modal>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.save-warning-container {
  background-color: colors.$red--light;
}

.save-warning-btn {
  background-color: colors.$red--dark;

  &:hover {
    background-color: colors.$red--dark;
    box-shadow: 0 0.4rem 1.6rem colors.$red--dark;
  }

  &:active,
  &:focus {
    background-color: colors.$red--dark;
    box-shadow: inset colors.$red--dark 0 0 0 0.15rem, inset colors.$white 0 0 0 0.55rem, 0 0.4rem 1.6rem colors.$red--dark;
  }
}
</style>
