<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import InputText from '@/features/theme/base/InputText.vue';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Alert from '@/features/theme/base/Alert.vue';
import Article from '@/features/theme/base/article/Article.vue';
import ArticleHeader from '@/features/theme/base/article/ArticleHeader.vue';
import Translate from '@/features/translations/Translate.vue';

import useAuthStore from '@/features/auth/useAuthStore';

import { ref } from 'vue';
import { useUpdateMemailMutation } from '@/generated/graphql';
import { AlertType } from '../theme/base/models/AlertType';
import { TranslationKey } from '../translations/defaultMessages';

const props = withDefaults(
  defineProps<{
    span?: 3 | 2 | 1;
    buttonText?: string;
  }>(),
  {
    span: 3,
    buttonText: 'base.save',
  }
);

const authStore = useAuthStore();

const alertType = ref<Maybe<AlertType>>(null);
const alertMessage = ref<Maybe<TranslationKey>>(null);
const clearAlert = () => {
  alertType.value = null;
  alertMessage.value = null;
};

const { executeMutation } = useUpdateMemailMutation();

const emits = defineEmits<{
  (e: 'submit'): void;
}>();

const emailRegex = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;

const onSubmit = async () => {
  clearAlert();

  try {
    if (email.value === '') {
      return;
    }

    if (!emailRegex.test(email.value)) {
      alertMessage.value = 'user.preferences.invalid_email';
      alertType.value = AlertType.danger;
      return;
    }

    const { data } = await executeMutation({ email: email.value });
    const updated = data?.updateMemail.user?.email;

    if (updated) {
      alertMessage.value = 'user.preferences.on_save.success';
      alertType.value = AlertType.success;

      authStore.user!.email = updated;
    } else {
      throw new Error();
    }
  } catch (error) {
    alertMessage.value = 'user.preferences.on_save.error';
    alertType.value = AlertType.danger;
  } finally {
    emits('submit');
  }
};

const email = ref(authStore.user?.email ?? '');

const onUpdate = (value: string) => {
  email.value = value;
};
</script>
<template>
  <Article article-size="medium">
    <ArticleHeader>
      <Translate t="base.settings" />
    </ArticleHeader>
    <form
      @submit.prevent="onSubmit"
      class="container mb-2"
    >
      <LayoutForm>
        <LayoutFormGroup title="user.preferences.name.title">
          <LayoutInput :span="span">
            <InputText
              :model-value="authStore.user?.fullName"
              disabled
              fullwidth
              label="base.name"
            />
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup title="user.preferences.email.title">
          <template #explanation>
            <Translate t="user.preferences.email.help" />
          </template>
          <LayoutInput :span="span">
            <InputText
              :model-value="email"
              @update:model-value="onUpdate"
              fullwidth
              label="base.email"
            />
          </LayoutInput>
        </LayoutFormGroup>
        <LayoutFormGroup>
          <Button :type="ButtonType.primary">{{ $t(buttonText) }}</Button>
        </LayoutFormGroup>
      </LayoutForm>
    </form>

    <Alert
      v-if="alertMessage && alertType"
      :alert-type="alertType"
    >
      <Translate :t="alertMessage" />
    </Alert>
  </Article>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/media-queries' as mq;

.container {
  min-width: 640px;
  max-width: 60vw;

  @include mq.laptop-down {
    min-width: auto;
    max-width: auto;
  }
}
</style>
