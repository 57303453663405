<script lang="ts" setup>
import { Options } from 'ol/layer/BaseVector';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { provide, shallowRef } from 'vue';
import { mapVectorLayerKey } from '@/features/map/models/mapSymbols';
import Layer from './Layer.vue';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import Feature from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import useMap from '@/features/map/composables/useMap';

const props = defineProps<{
  options?: Options<VectorSource>;
}>();

const emits = defineEmits<{
  (e: 'mounted'): void;
  (e: 'unmounted'): void;
  (e: 'feature-click', feature: Feature<Geometry>): void;
}>();

const layer = shallowRef(new VectorLayer(props.options));

provide(mapVectorLayerKey, () => layer.value);

const getMap = useMap();
const onMapClick = (e: MapBrowserEvent<UIEvent>) => {
  layer.value.getFeatures(e.pixel).then((clickedFeatures) => {
    for (const feature of clickedFeatures) {
      emits('feature-click', feature);
    }
  });
};

const onLayerMounted = () => {
  getMap().on('click', onMapClick);
  emits('mounted');
};

const onLayerUnmounted = () => {
  getMap().un('click', onMapClick);
  emits('unmounted');
};
</script>
<template>
  <Layer
    @mounted="onLayerMounted"
    @unmounted="onLayerUnmounted"
    :layer="layer"
  >
    <slot />
  </Layer>
</template>
