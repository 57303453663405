<script setup lang="ts">
import { ref } from 'vue';
import DropdownArrow from '@/features/theme/icons/DropdownArrow.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import Translate from '@/features/translations/Translate.vue';

defineProps<{
  label: string;
  content: string;
}>();

const isOpen = ref(false);
const toggleAccordion = () => {
  isOpen.value = !isOpen.value;
};
</script>

<template>
  <div class="accordion-item">
    <div
      @click="toggleAccordion"
      class="label-arrow-container"
      :class="{ contentOpen: isOpen }"
    >
      <label
        class="label subtitle"
        :class="{ open: isOpen }"
      >
        <Translate :value="label" />
      </label>
      <div
        class="arrow-container"
        :class="{ open: isOpen }"
      >
        <DropdownArrow
          :color="isOpen ? Colors.white : Colors.grey600"
          :heigth="40"
          :width="40"
        ></DropdownArrow>
      </div>
    </div>
    <div
      v-if="isOpen"
      class="content-container"
    >
      <p class="regular subtitle">
        <Translate :value="content" />
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/spacing' as spacing;
@use '@/scss/design-tokens/media-queries' as media-queries;

.accordion-item {
  cursor: pointer;
  background-color: color.$white;
  border-bottom: 0.1rem solid #efefef;
  display: flex;
  flex-direction: column;
}

.label-arrow-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: spacing.$space-large * 1.5 spacing.$space-large;

  &.contentOpen {
    margin-bottom: spacing.$space-large;
  }
}

.label {
  color: color.$black;
  @include font.font-default-bold;

  &.open {
    color: color.$primary;
  }
}
.open {
  display: block;
}

.arrow-container {
  transition: all 0.3s;
  border: 0.1rem solid #efefef;
  border-radius: 50%;
  position: relative !important;
  height: 4rem;
  display: flex;

  &.open {
    border: none;
    background-color: color.$primary;

    @include media-queries.mobile {
      margin-left: 2.5rem;
    }
  }

  @include media-queries.mobile {
    margin-left: 2.5rem;
  }
}

.content-container {
  margin: 0 spacing.$space-large spacing.$space-x-large;
}
</style>
