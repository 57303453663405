import { MonitoringDetails } from '@/features/monitoring/models';

interface UseMonitoringModelChecker {
  isMonitoringDetails(monitoring: unknown): monitoring is MonitoringDetails;
}

export default function useMonitoringModelChecker(): UseMonitoringModelChecker {
  const isMonitoringDetails = (val: unknown): val is MonitoringDetails => {
    const monitoring = val as MonitoringDetails;
    return !!monitoring;
  };

  return {
    isMonitoringDetails,
  };
}
