<script lang="ts" setup>
import { Monitoring, MonitoringDetails } from '@/features/monitoring/models';
import { Table, TableRow, TableData } from '@/features/theme/base/tables';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import Icon from '@/features/theme/base/Icon.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import IssuePill from '@/features/issues/components/IssuePill.vue';
import { IssueType } from '@/features/theme/base/models/PillProps';
import { TranslationKey } from '@/features/translations/defaultMessages';
import Translate from '@/features/translations/Translate.vue';
import { useI18n } from 'vue-i18n';

defineProps<{
  monitorings: (Monitoring | MonitoringDetails)[];
}>();

const emits = defineEmits<{
  (e: 'delete', monitoring: Monitoring): void;
  (e: 'open_map', geoJson: string): void;
}>();

const headers: Array<TranslationKey> = [
  'monitoring.header.types',
  'monitoring.header.communities',
  'monitoring.header.zips',
  'monitoring.header.tags',
  'monitoring.header.map',
];

const onDeleteClick = (monitoring: Monitoring) => {
  emits('delete', monitoring);
};

const onClickMap = (geoJson: string) => {
  emits('open_map', geoJson);
};

const { t } = useI18n();
</script>
<template>
  <Table fullsize>
    <TableRow header-row>
      <TableData header-data v-for="header in headers" :key="header">
        <Translate :t="header" />
      </TableData>
      <TableData key="empty" />
    </TableRow>
    <TableRow v-for="monitoring in monitorings" :key="monitoring.id">
      <TableData>
        <IssuePill
          :icon-height="16"
          :icon-width="16"
          :pill-size="'small'"
          :pill-type="IssueType.case"
          v-for="process in monitoring.issueProcesses"
          :key="process.id"
        >
          <span class="pill-text">{{ process.title }}</span>
        </IssuePill>
      </TableData>
      <TableData>
        <div class="scroll-items scrollbar">
          <IssuePill
            :icon-height="16"
            :icon-width="16"
            :pill-size="'small'"
            :pill-type="IssueType.institution"
            v-for="community in monitoring.communities"
            :key="community.id"
          >
            <span class="pill-text">{{ community.name }}</span>
          </IssuePill>
        </div>
      </TableData>
      <TableData class="color-primary">
        <div class="scroll-items scroll-items--double scrollbar">
          <IssuePill pill-type="document" v-for="postalCode in monitoring.postalCodes" :key="postalCode.number">
            {{ postalCode.number }}
          </IssuePill>
        </div>
      </TableData>
      <TableData>
        <div class="scroll-items scrollbar">
          <IssuePill :icon-height="16" :icon-width="16" :pill-size="'small'" :pill-type="IssueType.planning" v-for="tag in monitoring.tags" :key="tag">
            <span class="pill-text">{{ tag }}</span>
          </IssuePill>
        </div>
      </TableData>
      <TableData>
        <Button @click="onClickMap(monitoring.areaGeoJson)" :type="ButtonType.tertiaryIconOnly">
          <div class="map-icon">
            <Icon
              v-if="!!monitoring.areaGeoJson && monitoring.areaGeoJson !== 'null'"
              icon="MapIcon"
              :options="{ height: 24, width: 24, color: Colors.primary }"
              v-tooltip="{ theme: 'primary', content: t('monitoring.map.location') }"
            />
          </div>
        </Button>
      </TableData>
      <TableData class="text-center">
        <Button @click="() => onDeleteClick(monitoring)" :type="ButtonType.tertiaryIconOnly">
          <Icon icon="Delete" :options="{ color: Colors.red }" />
        </Button>
      </TableData>
    </TableRow>
  </Table>
</template>
<style lang="scss" scoped>
.pill-text {
  margin-left: 0.25rem;
}

.map-icon {
  display: flex;
  justify-content: center;
}

.scroll-items {
  max-height: 80px;
  overflow-y: auto;

  &.scroll-items--double {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}
</style>
