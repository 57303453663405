<script lang="ts" setup>
import OverviewMap, { Options } from 'ol/control/OverviewMap';
import { onMounted, onUnmounted, provide, shallowRef } from 'vue';
import { mapOverviewKey } from '@/features/map/models/mapSymbols';
import useMap from '@/features/map/composables/useMap';

const props = withDefaults(
  defineProps<{
    options?: Options;
  }>(),
  {
    options: () => ({}),
  }
);

const overviewMap = shallowRef(Object.assign(new OverviewMap(props.options), { name: 'OL-OverviewMap' }));
const getMap = useMap();

if (!getMap) {
  throw new Error('No Map provided');
}

onMounted(() => {
  getMap().addControl(overviewMap.value);
});

onUnmounted(() => {
  getMap().removeControl(overviewMap.value);
});

provide(mapOverviewKey, () => overviewMap.value);
</script>
<template>
  <slot />
</template>
