<script lang="ts" setup>
import '@vuepic/vue-datepicker/src/VueDatePicker/style/main.scss';
import Confirm from '@/features/theme/base/Confirm.vue';
import { IssueDetails } from '../../models';
import useActivePhaseOperations from '@/features/issueProcess/composables/useActivePhaseOperations';
import { computed, ref, watch } from 'vue';
import DatePickerInput from '@/features/theme/base/DatePickerInput.vue';
import Alert from '@/features/theme/base/Alert.vue';
import { AlertType } from '@/features/theme/base/models/AlertType';
import Translate from '@/features/translations/Translate.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';

const props = defineProps<{
  active: boolean;
  issue: IssueDetails;
}>();

const emits = defineEmits<{
  (e: 'yes'): void;
  (e: 'no'): void;
}>();

const { changeReviewDates } = useActivePhaseOperations(computed(() => props.issue));
const currentPhase = computed(() => props.issue.currentPhase);
const startDate = ref(currentPhase.value?.reviewStartDate);
const endDate = ref(currentPhase.value?.reviewEndDate);

const onYes = async () => {
  emits('yes');
  await changeReviewDates(startDate.value!, endDate.value!);
};

const onNo = () => {
  emits('no');
  startDate.value = currentPhase.value?.reviewStartDate;
  endDate.value = currentPhase.value?.reviewEndDate;
};

watch(
  currentPhase, 
  (newValue) => {
    if (newValue) {
      startDate.value = newValue.reviewStartDate;
      endDate.value = newValue.reviewEndDate;
    }
  },
  {
    immediate: true,
  }
);

</script>

<template>
  <Confirm
    :active="active"
    @yes="onYes"
    @no="onNo"
  >
    <Alert
      class="alert"
      title="base.confirm"
      :alert-type="AlertType.success"
    >
      <Translate
        :value="`issue.phase.review.change_date`"
      />
    </Alert>
    <LayoutFormGroup  
      title="issue.phase.review_period"    
    >
      <LayoutInput>
        <DatePickerInput
          v-model="startDate"
          :clearable="false"
        />
      </LayoutInput>
      <LayoutInput>
        <DatePickerInput
          v-model="endDate"
          :clearable="false"
        />
      </LayoutInput>
    </LayoutFormGroup>
  </Confirm>
</template>