import { GetMeQuery, GetMeDocument, UserRoleType, DelegationTicket } from '@/generated/graphql';
import { client } from '@/urql';
import { User } from './models';
import tokenService from './tokenService';

interface ParseableUser {
  id: string;
  fullName: string;
  email?: string | null;
  delegations: DelegationTicket[];
  nationalId: string;
  isFake: boolean;
  role: UserRoleType;
}

const parseUser = (parseable: ParseableUser) => {
  const { id, fullName, email, delegations, nationalId, isFake, role } = parseable;

  let activeRole = role;
  const ticketId = tokenService.getTokenDelegationMemberId();
  if (ticketId) {
    const activeDelegation = delegations.find((d) => d.id === ticketId);
    if (activeDelegation?.role) {
      activeRole = activeDelegation.role;
    }
  }

  const user: User = {
    id,
    fullName,
    email,
    delegations,
    nationalId,
    isFake,
    role: activeRole,
    delegationMemberId: tokenService.getTokenDelegationMemberId(),
  };

  return user;
};

export const authService = {
  async getUser() {
    const { data, error } = await client.query<GetMeQuery>(GetMeDocument, {}).toPromise();
    if (error || !data?.me) {
      throw new Error('authService.ts::getUser: error fetching me:' + error?.message || '');
    }

    const { id, fullName, email, delegations, nationalId, isFake, role } = data.me;
    const parseable: ParseableUser = { id, fullName, email, delegations, nationalId, isFake, role };

    return parseUser(parseable);
  },
};
