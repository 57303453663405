import { useI18n } from 'vue-i18n';
import { InstructionsCategory } from '../InstructionsCategory';
import { computed } from 'vue';

export const useInstructions = () => {
  const { messages: localizedMessages, locale } = useI18n();

  const PREFIX = 'faq.routes.instructions.category.';
  const SEP = ';;';

  const categories = computed(() => {
    const messages = localizedMessages.value[locale.value];
    const categoryMessageKeys = Object.keys(messages).filter((m) => m.startsWith(PREFIX));
    const _categories: any = {};

    categoryMessageKeys.forEach((key) => {
      const categoryRex = /^faq.routes.instructions.category.\d+$/;
      const memberRex = /^(?<category>faq.routes.instructions.category.\d+).member.\d+$/;
      if (categoryRex.test(key)) {
        if (!_categories[key]) {
          _categories[key] = {
            id: key,
            title: messages[key],
            instructions: [],
          };
        } else {
          _categories[key].id = key;
          _categories[key].title = messages[key];
        }
      } else if (memberRex.test(key)) {
        const match = memberRex.exec(key);
        if (match?.groups && match.groups.category) {
          const [title, href] = messages[key].split(SEP);
          const instruction = {
            id: key,
            title,
            href,
          };

          const categoryKey = match.groups.category;
          if (!_categories[categoryKey]) {
            _categories[categoryKey] = {
              instructions: [instruction],
            };
          } else {
            _categories[categoryKey].instructions.push(instruction);
          }
        }
      }
    });

    return Object.values(_categories) as InstructionsCategory[];
  });

  return {
    categories,
  };
};
