import {
  useAddFilesToIssueMutation,
  useAddRelatedIssueMutation,
  useRemoveRelatedIssueMutation,
  useStartNextIssuePhaseMutation,
  useStartPreviousIssuePhaseMutation,
  useUpdateIssueMutation,
  useChangeIssueResponsiblePartyMutation,
} from '@/generated/graphql';
import { IssueDetails } from '@/features/issues/models';
import { UpdateIssueInput } from '../models/UpdateIssueInput';
import useUploadFiles from '@/features/files/composables/useUploadFiles';
import { unref } from 'vue';

interface UseActiveIssueOperations {
  updateActiveIssueInfo: (input: UpdateIssueInput) => Promise<IssueDetails>;
  startNextPhaseForActiveIssue: () => Promise<IssueDetails>;
  startPreviousPhaseForActiveIssue: () => Promise<IssueDetails>;
  addRelatedIssueToActiveIssue: (id: ID) => Promise<void>;
  removeRelatedIssueToActiveIssue: (id: ID) => Promise<void>;
  changeIssueResponsibleParty: (Id: ID, userId: string) => Promise<IssueDetails>;
}

export default function useActiveIssueOperations(issueId: MaybeRef<ID>): UseActiveIssueOperations {
  const { executeMutation: executeStartNextIssuePhaseMutation } = useStartNextIssuePhaseMutation();
  const { executeMutation: executeStartPreviousIssuePhaseMutation } = useStartPreviousIssuePhaseMutation();
  const { executeMutation: executeAddFilesToIssueMutation } = useAddFilesToIssueMutation();
  const { uploadIssueFiles } = useUploadFiles();

  const { executeMutation: executeUpdateIssueMutation } = useUpdateIssueMutation();
  const updateActiveIssueInfo = async (input: UpdateIssueInput): Promise<IssueDetails> => {
    const { quillDelta, files, ...issueInput } = input;
    if (quillDelta?.ops) {
      const opsJson = JSON.stringify(quillDelta.ops);
      issueInput.richTextDescription = opsJson;
      const desc = quillDelta.ops.map((op) => (typeof op.insert === 'string' ? op.insert : '')).join('');
      issueInput.description = desc;
    }

    const issueResponse = await executeUpdateIssueMutation({
      issueId: unref(issueId).toString(),
      input: issueInput,
    });

    if (!issueResponse.data?.updateIssue.issue) {
      // Todo: Error handling
      throw new Error('Failed to update basic information');
    }

    let issue = issueResponse.data.updateIssue.issue;

    if (files?.length) {
      const fileResponse = await uploadIssueFiles(unref(issueId).toString(), files);
      if (!fileResponse.success) {
        // Todo: Error handling
        console.error('Failed to upload issue file!');
      }

      var issueFileResult = await executeAddFilesToIssueMutation({ issueId: unref(issueId).toString(), fileIds: fileResponse.fileIds });

      if (issueFileResult.data?.addFilesToIssue.issue) {
        issue = issueFileResult.data.addFilesToIssue.issue;
      }
    }

    return issue;
  };

  const startNextPhaseForActiveIssue = async (): Promise<IssueDetails> => {
    const res = await executeStartNextIssuePhaseMutation({
      issueId: unref(issueId).toString(),
    });

    if (!res.data?.startNextIssuePhase.issue) {
      // Todo: Error handling
      throw new Error('Failed to start next issue phase');
    }

    return res.data.startNextIssuePhase.issue;
  };

  const startPreviousPhaseForActiveIssue = async (): Promise<IssueDetails> => {
    const res = await executeStartPreviousIssuePhaseMutation({
      issueId: unref(issueId).toString(),
    });

    if (!res.data?.startPreviousIssuePhase.issue) {
      throw new Error('Failed to start previous issue phase');
    }

    return res.data.startPreviousIssuePhase.issue;
  };

  const { executeMutation: executeAddRelatedIssueMutation } = useAddRelatedIssueMutation();
  const addRelatedIssueToActiveIssue = async (id: ID) => {
    const res = await executeAddRelatedIssueMutation({ issueId: id.toString(), otherId: unref(issueId).toString() });

    if (!res.data?.addRelatedIssue?.issue) {
      // Todo: Error handling
      throw new Error('Failed to add related issue');
    }
  };

  const { executeMutation: executeRemoveRelatedIssueMutation } = useRemoveRelatedIssueMutation();
  const removeRelatedIssueToActiveIssue = async (id: ID) => {
    const res = await executeRemoveRelatedIssueMutation({ issueId: id.toString() });

    if (!res.data?.removeRelatedIssue?.issue) {
      // Todo: Error handling
      throw new Error('Failed to remove related issue');
    }
  };

  const { executeMutation: changeIssueResponsiblePartyMutation } = useChangeIssueResponsiblePartyMutation();
  const changeIssueResponsibleParty = async (id: ID, userId: string): Promise<IssueDetails> => {
    const { data } = await changeIssueResponsiblePartyMutation({ issueId: id.toString(), userId });

    if (!data?.changeIssueResponsibleParty?.issue) {
      throw new Error('Ekki tókst að breyta ábyrgðaraðila');
    }

    return data?.changeIssueResponsibleParty?.issue;
  };

  return {
    updateActiveIssueInfo,
    startNextPhaseForActiveIssue,
    startPreviousPhaseForActiveIssue,
    addRelatedIssueToActiveIssue,
    removeRelatedIssueToActiveIssue,
    changeIssueResponsibleParty
  };
}
