<script lang="ts" setup>
import LayoutForm from '@/features/theme/base/layouts/LayoutForm.vue';
import LayoutFormGroup from '@/features/theme/base/layouts/LayoutFormGroup.vue';
import LayoutInput from '@/features/theme/base/layouts/LayoutInput.vue';
import InputText from '@/features/theme/base/InputText.vue';
import { UserInput, UserRoleType } from '@/generated/graphql';
import Option from '@/features/theme/base/models/Option';
import Button from '@/features/theme/base/Button.vue';
import { ButtonType } from '@/features/theme/base/models/ButtonType';
import DropDown from '@/features/theme/base/DropDown.vue';
import useAuthStore from '@/features/auth/useAuthStore';
import Checkbox from '@/features/theme/base/Checkbox.vue';
import { IsFakeUser } from '../composables/useUserManagement';
import { watchEffect } from 'vue';

const authStore = useAuthStore();

const props = defineProps<{
  modelValue: UserInput;
  isFakeUser: IsFakeUser;
}>();

const emits = defineEmits<{
  (e: 'update:modelValue', model: UserInput): void;
  (e: 'update:isFakeUser'): void;
  (e: 'submit'): void;
}>();

const onSubmit = () => {
  emits('submit');
};

const onFieldInput = (field: keyof UserInput, value: string) => {
  emits('update:modelValue', { ...props.modelValue, [field]: value });
};

const availableRoles: Option[] = [{ name: 'role.PUBLIC', value: UserRoleType.Public }];
if (authStore.isAdmin) {
  availableRoles.push(
    { name: 'role.ADMIN', value: UserRoleType.Admin },
    { name: 'role.SLS_EMPLOYEE_ADALSKIPULAGSSVID', value: UserRoleType.SlsEmployeeAdalskipulagssvid },
    { name: 'role.SLS_EMPLOYEE_DEILISKIPULAGSSVID', value: UserRoleType.SlsEmployeeDeiliskipulagssvid },
    { name: 'role.SLS_EMPLOYEE_UMHVERFISMATSSVID', value: UserRoleType.SlsEmployeeUmhverfismatssvid }
  );
}
</script>
<template>
  <form
    @submit.prevent="onSubmit"
    class="container"
  >
    <LayoutForm>
      <LayoutFormGroup>
        <LayoutInput>
          <InputText
            :model-value="modelValue.fullName"
            @update:model-value="(val) => onFieldInput('fullName', val)"
            fullwidth
            label="base.name"
          />
        </LayoutInput>
        <LayoutInput>
          <InputText
            :model-value="modelValue.nationalId"
            @update:model-value="(val) => onFieldInput('nationalId', val)"
            fullwidth
            label="base.ssn"
          />
        </LayoutInput>
        <LayoutInput v-if="authStore.isAdmin">
          <div class="checkbox-container">
            <Checkbox
              :model-value="props.isFakeUser.isFake"
              :disabled="props.isFakeUser.disabled"
              @update:model-value="() => emits('update:isFakeUser')"
              class="mb-1"
              :id="'isFake'"
            >
              {{ $t('user.fake') }}
            </Checkbox>
          </div>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <LayoutInput :span="2">
          <InputText
            :model-value="modelValue.email"
            @update:model-value="(val) => onFieldInput('email', val)"
            fullwidth
            label="base.email"
          />
        </LayoutInput>
        <LayoutInput>
          <DropDown
            :model-value="modelValue.role"
            @update:model-value="(val) => onFieldInput('role', val)"
            fullwidth
            label="Hlutverk"
            :options="availableRoles"
          ></DropDown>
        </LayoutInput>
      </LayoutFormGroup>
      <LayoutFormGroup>
        <Button :type="ButtonType.primary">{{ $t('base.save') }}</Button>
      </LayoutFormGroup>
    </LayoutForm>
  </form>
</template>

<style scoped>
.container {
  min-width: 64vw;
}

.checkbox-container {
  display: flex;
  height: 100%;
  align-items: center;
}
</style>
