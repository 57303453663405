<script lang="ts" setup>
import Card from '@/features/theme/base/Card.vue';
import { computed, ref } from 'vue';
import { useDelegationTokenMutation, usePlainUserTokenMutation, useGetMeQuery } from '@/generated/graphql';
import Radio from '@/features/theme/base/Radio.vue';
import Button from '@/features/theme/base/Button.vue';
import { useRouter } from 'vue-router';
import { SpecialAuthPaths } from './authMiddleware';
import tokenService from './tokenService';
import { useSignin } from './useSignin';
import Translate from '../translations/Translate.vue';
import useAuthStore from './useAuthStore';

const NOP_ID = 'NOP';

const router = useRouter();

const { data } = useGetMeQuery();
const { executeMutation: createDelegationToken } = useDelegationTokenMutation();
const { executeMutation: createPlainUserToken } = usePlainUserTokenMutation();

const userDelegations = computed(() => {
  return data.value?.me?.delegations || [];
});

const options = computed(() => {
  const mapped = userDelegations.value.filter((opt) => opt.delegation?.entityName).map((opt) => ({ id: opt.id, name: opt.delegation?.entityName }));
  return [{ id: NOP_ID, name: 'Ég er bara ég' }, ...mapped];
});

const { activeDelegation } = useAuthStore();

const selectedDelegation = ref<string>(activeDelegation?.id || NOP_ID);

const onContinueClick = async () => {
  if (selectedDelegation.value) {
    if (selectedDelegation.value !== NOP_ID) {
      const ticket = userDelegations.value.find((d) => d.id === selectedDelegation.value);
      if (!ticket) {
        throw new Error('Ekki tókst að sækja umboð');
      }

      const { delegation } = ticket;

      const { data } = await createDelegationToken({ input: { delegationId: delegation?.id } });
      if (data?.delegationToken?.token) {
        tokenService.saveToken(data?.delegationToken?.token);

        const signin = useSignin();
        await signin.login();
      }
    } else {
      const { data } = await createPlainUserToken({});
      if (data?.plainUserToken?.token) {
        tokenService.saveToken(data?.plainUserToken?.token);

        const signin = useSignin();
        await signin.login();
      }
    }
  }

  const from = router.currentRoute.value.query['from']?.toString();
  router.replace(from ?? SpecialAuthPaths.HOME);
};
</script>
<template>
  <div class="layout-centered">
    <Card>
      <template #header>
        <div class="title-container">
          <h6 class="h6 card-title color-primary bold">
            <Translate t="auth.select_account" />
          </h6>
          <p class="card-help p--large">
            <Translate t="auth.select_account.help" />
          </p>
        </div>
      </template>
      <ul class="list">
        <li class="list-item" v-for="option in options" :key="option.id">
          <Radio name="delegation" v-model="selectedDelegation" :id="option.name!" :value="option.id">
            {{ option.name }}
          </Radio>
        </li>
      </ul>
      <template #footer>
        <div class="flex flex-space-between">
          <Button @click="onContinueClick" :disabled="!selectedDelegation">
            <Translate t="base.continue" />
          </Button>
        </div>
      </template>
    </Card>
  </div>
</template>

<style lang="scss" scoped>
.layout-centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  flex-direction: column;
}

.list {
  margin: 1rem 0 3rem 0;
}

.list-item {
  margin-bottom: 1.6rem;
}
</style>
