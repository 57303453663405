<script lang="ts" setup>
import LayoutSplit from '@/features/theme/base/layouts/LayoutSplit.vue';
import IssueMap from '@/features/issues/components/issueMap/IssueMap.vue';
import IssueCardList from '@/features/issues/components/issueList/IssueCardList.vue';
import { computed, ref, onMounted, nextTick, watch } from 'vue';
import useIssueStore from '@/features/issues/composables/useIssueStore';
import useWatchedIssues from '@/features/issues/composables/useWatchedIssues';
import useAuthUser from '@/features/auth/useAuthUser';
import LoginPrompt from '@/features/auth/LoginPrompt.vue';
import Translate from '@/features/translations/Translate.vue';
import Spinner from '@/features/theme/base/Spinner.vue';
import useNavigationStore from '@/features/navigation/useNavigationStore';
import IssueMapCta from './IssueMapCta.vue';
import { useRouteQuery } from '@vueuse/router';
import useInfiniteScrollingIssueCards from '../../composables/useInfiniteScrollingIssueCards';
import { IssueCardViewModel } from '../../models';
import IssueIndexOptions from '@/features/issues/components/issueIndex/IssueIndexOptions.vue';

const store = useIssueStore();

const cardListContainerRef = ref<HTMLElement | null>(null);
const { issueCards, mapIssues, isInitialized, totalCount } = useInfiniteScrollingIssueCards(cardListContainerRef);

const mapDrawKey = ref(0);
watch(
  computed(() => store.issueSearch),
  () => {
    mapDrawKey.value += 1;
    map.value.updateMap(3);
    map.value.deselect();
    selected.value = undefined;
  }
);

const selected = ref<string>();
const onIssueSelected = (issue: IssueCardViewModel) => {
  selected.value = issue.id.toString();
};

const onIssueMapSelected = (issueId: string) => {
  selected.value = issueId;
};

const onIssueDeselected = () => {
  selected.value = undefined;
};

const { isAuthenticated } = useAuthUser();
const { toggleWatchIssue } = useWatchedIssues();
const onIssueBookmark = (issueId: string) => {
  if (isAuthenticated.value) {
    toggleWatchIssue(issueId);
  } else {
    loginPrompt.value = true;
  }
};

const onSpecificationClear = () => {
  store.resetIssueSpecification();
};

const search = useRouteQuery<string>('search');
onMounted(() => {
  if (!search) {
    onSpecificationClear();
  }
});

const loginPrompt = ref<boolean>(false);
const onLoginCancel = () => {
  loginPrompt.value = false;
};

const navStore = useNavigationStore();
navStore.setMainMenuMargin(false);

const mapView = ref<boolean>(false);
const listView = ref<boolean>(false);

const onCtaClick = () => {
  // Stop forcing full screen list
  listView.value = false;
};

const onCtaMobileClick = () => {
  // Explicit full screen map
  mapView.value = true;
  listView.value = false;
  nextTick(() => {
    if (map.value) {
      map.value.updateMap(2);
    }
  });
};

const onMapHide = () => {
  // Explicit full screen list
  listView.value = true;
  mapView.value = false;
};

const map = ref();
const onMapExpand = (expand: boolean) => {
  // Toggle full screen map or split view
  mapView.value = expand;
  nextTick(() => {
    if (map.value) {
      map.value.updateMap();
    }
  });
};
</script>

<template>
  <LayoutSplit
    class="layout"
    :column="listView || mapView"
    :class="{ 'layout--map': mapView }"
  >
    <template #left>
      <div
        class="pt-4 left-container"
        v-if="!mapView"
        :class="{ focused: listView, 'list--mobile': mapView }"
      >
        <div
          v-if="isInitialized"
          class="issue-list-container"
        >
          <IssueIndexOptions :total-count="totalCount" />
          <div
            ref="cardListContainerRef"
            class="card-list-container scrollbar"
            :class="{ 'has-filters': !store.hasNoSpecification }"
          >
            <IssueCardList
              v-if="issueCards.length > 0"
              @select="onIssueSelected"
              @deselect="onIssueDeselected"
              :selected="selected"
              :issues="issueCards"
              @bookmark="onIssueBookmark"
              card-style="margin-small"
              class="mb-3"
            />
            <div v-else>
              <Translate t="search.results.none" />
            </div>
          </div>
        </div>
        <div
          v-else
          class="flex flex-center flex-column"
        >
          <Spinner />
          <Translate t="base.loading" />
        </div>
        <div class="issue-map-cta">
          <IssueMapCta
            v-if="listView"
            @click="onCtaClick"
            class="issue-map-cta--laptop"
          />
          <IssueMapCta
            v-if="!mapView"
            @click="onCtaMobileClick"
            class="issue-map-cta--mobile"
            type="show"
          />
        </div>
      </div>
      <LoginPrompt
        :prompt="loginPrompt"
        @cancel="onLoginCancel"
      >
        <template #help>
          <Translate t="monitoring.prompt" />
        </template>
      </LoginPrompt>
    </template>
    <template #right>
      <IssueMap
        ref="map"
        class="map"
        :class="{ focused: mapView, 'map--mobile': !mapView }"
        v-if="!listView"
        @hide="onMapHide"
        @expand="onMapExpand"
        @select="onIssueMapSelected"
        @deselect="onIssueDeselected"
        :issue-id="selected"
        :issues="mapIssues"
        expandable
        :expanded="mapView"
        :hideable="!listView && !mapView"
        :legend="mapView"
        :draw-key="mapDrawKey"
      />
    </template>
  </LayoutSplit>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/layout' as layout;
@use '@/scss/design-tokens/media-queries' as mq;

$filter-container-height: min(10%, 6.4rem);

.map {
  height: 100%;
  width: 100%;

  &.focused {
    height: layout.$main-height;
  }

  @include mq.mobile() {
    height: layout.$main-height;
  }

  &--mobile {
    @include mq.laptop-down() {
      display: none;
    }
  }
}

.combobox {
  display: flex;
  flex-grow: 1;
}

.layout {
  padding-right: 2rem;
  padding-left: 2rem;

  @include mq.laptop() {
    padding: 0;
  }

  &--map {
    padding: 0;
  }
}

.issue-map-cta {
  position: sticky;
  display: flex;
  flex-direction: row-reverse;
  bottom: 2.4rem;

  &--laptop {
    display: none;
    right: calc(9.6rem + 0.6rem);

    @include mq.laptop() {
      display: flex;
    }
  }

  &--mobile {
    display: flex;
    right: 0.6rem;

    @include mq.laptop() {
      display: none;
    }
  }
}

.left-container {
  height: layout.$main-height;
  box-sizing: border-box;
  @include mq.laptop() {
    padding-left: 9.6rem;
  }

  &.focused {
    @include mq.laptop() {
      padding-right: 9.6rem;
    }
  }
}

.list--mobile {
  @include mq.mobile() {
    display: none;
  }
}

.issue-list-container {
  height: 100%;
}

.card-list-top-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;

  @include mq.mobile() {
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5rem;
  }
}

.grid-item {
  display: inline-flex;
  flex-direction: column;

  @include mq.mobile() {
    padding: 0.5rem;
    width: 90%;
  }
}

.card-list-container {
  max-height: calc(100% - $filter-container-height - 5rem);
  padding-right: 0;
  overflow: hidden auto;

  @include mq.mobile {
    max-width: calc(100vw - 4rem);
    max-height: calc(100% - $filter-container-height - 5rem);
  }
}
</style>
