<script setup lang="ts">
import Logo from '@/features/theme/icons/Logo.vue';
import { MenuTheme } from '@/features/theme/models';
import { NavigationType } from '@/generated/graphql';
import { MenuThemes } from '@/features/theme/themes';
import useAuthUser from '@/features/auth/useAuthUser';
import useNavigation from './useNavigation';
import Button from '../theme/base/Button.vue';
import { ButtonType, ButtonSize } from '../theme/base/models/ButtonType';
import Icon from '../theme/base/Icon.vue';
import { Colors } from '../theme/base/models/Colors';
import useSidebar from './useSidebar';
import Translate from '../translations/Translate.vue';
import MainMenu from './MainMenu.vue';

withDefaults(
  defineProps<{
    nav: NavigationType;
    theme?: MenuTheme;
  }>(),
  {
    theme: () => MenuThemes.default,
  }
);

const { isAuthenticated } = useAuthUser();

const { showSidebar } = useSidebar();
const onSearchClick = () => {
  showSidebar();
};

const { pushActiveAreaRouteByName, isRoute } = useNavigation();
const onLoginClick = () => {
  pushActiveAreaRouteByName('Login');
};
</script>

<template>
  <div id="menu">
    <div
      class="menu flex menu-container"
      :class="`bg-${theme.background}`"
    >
      <div
        title="nav.menu"
        class="menu-beta-container"
      >
        <RouterLink
          to="/"
          class="hidden-in-mobile hidden-in-tablet hidden-in-laptop"
        >
          <Logo
            :heigth="48"
            :width="theme.logoWidth"
            :with-text="theme.logoText"
            :color="theme.color"
          ></Logo>
          <span class="sr-only">Frontpage</span>
        </RouterLink>
        <RouterLink
          to="/"
          class="hidden-in-desktop"
        >
          <Logo
            :heigth="40"
            :width="40"
            :color="theme.color"
          ></Logo>
          <span class="sr-only">Frontpage</span>
        </RouterLink>
        <div
          :class="`color-${theme.color}`"
          class="menu-beta-text"
        >
          <Translate t="base.beta-launch" />
        </div>
      </div>

      <div
        class="right-button-group"
        :class="`color-${theme.color}`"
      >
        <Button
          v-if="!isAuthenticated && !isRoute('Login')"
          @click="onLoginClick"
          :type="ButtonType.tertiary"
          :class="`login-button ${theme.loginTextClass}`"
          class="hidden-in-mobile hidden-in-tablet"
        >
          <Translate t="base.login" />
        </Button>
        <Button
          :type="ButtonType.primary"
          @click="onSearchClick"
          class="hidden-in-mobile"
        >
          <Translate t="nav.search" />
          <Icon
            class="icon-right"
            icon="Search"
            :color="Colors.white"
          />
        </Button>
        <Button
          :type="ButtonType.primary"
          @click="onSearchClick"
          :size="ButtonSize.small"
          class="hidden-in-desktop hidden-in-laptop"
        >
          <span class="sr-only">Issue Search</span>
          <Icon
            icon="Search"
            :color="Colors.white"
          />
        </Button>
        <MainMenu
          :theme="theme"
          :nav="nav"
        />
      </div>
    </div>
    <div id="menu-submenu"></div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as color;
@use '@/scss/design-tokens/layout' as layout;
@use '@/scss/design-tokens/media-queries' as media;
@use '@/scss/design-tokens/z-indicies' as z-index;

.menu {
  height: layout.$menu-height;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: z-index.$z-index-menu;
}

.menu-container {
  padding: 0 4.8rem;
  margin: 0;

  @include media.laptop-down {
    padding: 0 2rem;
  }
}

.right-button-group {
  display: flex;
  gap: 4rem;

  @include media.laptop-down {
    gap: 2rem;
  }
}

.text-white:hover {
  color: color.$grey-600;
}

.buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.first-button {
  margin-right: 2rem;
}

.delegation {
  margin-right: 2rem;
}

.delegation__username {
  font-style: italic;
  font-size: 0.94em;
}

.menu-beta-container {
  display: flex;
  gap: 2rem;
  @include media.laptop-down {
    display: block;
  }
}

.menu-beta-text {
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  line-height: 170%;

  @include media.laptop-down {
    font-size: 1.4rem;
    line-height: 100%;
    // padding: 0.5rem;
  }
}
</style>
