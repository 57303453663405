<script lang="ts" setup>
import { computed } from 'vue';
import Icon from './Icon.vue';
import { Colors } from './models/Colors';

const props = defineProps<{
  candidates: number[];
  selected: number;
  numCenter?: number;
}>();

const emits = defineEmits<{
  (e: 'select', page: number): void;
}>();

const onCandidateClicked = (candidate: number) => {
  emits('select', candidate);
};
const maxCandidate = computed<number>(() => {
  return props.candidates.length > 0 ? props.candidates[props.candidates.length - 1] : 1;
});

const backNavigation = computed<boolean>(() => {
  return props.selected > 1;
});
const onBackClick = () => {
  if (backNavigation.value) {
    emits('select', props.selected - 1);
  }
};
const hasBackDots = computed<boolean>(() => {
  return props.candidates.length > 2 && props.candidates[0] !== props.candidates[1] - 1;
});

const nextNavigation = computed<boolean>(() => {
  return props.selected < maxCandidate.value;
});
const onNextClick = () => {
  if (nextNavigation.value) {
    emits('select', props.selected + 1);
  }
};
const hasNextDots = computed<boolean>(() => {
  return props.candidates.length > 2 && props.candidates[props.candidates.length - 1] !== props.candidates[props.candidates.length - 2] + 1;
});
</script>
<template>
  <div class="pagination">
    <span class="pagination__nav mr-2" :class="{ disabled: !backNavigation }" @click="onBackClick">
      <Icon class="pagination__left" icon="DropdownArrow" :options="{ color: backNavigation ? Colors.primary : Colors.grey300, width: 28, height: 28 }" />
    </span>
    <div class="pagination__pages">
      <span class="pagination__dots pagination__dots--back" :class="{ hidden: !hasBackDots }">&hellip;</span>
      <span class="pagination__dots pagination__dots--next" :class="{ hidden: !hasNextDots }">&hellip;</span>
      <span v-for="candidate in candidates" class="pagination__item" :class="{ selected: selected === candidate }" @click="() => onCandidateClicked(candidate)">
        {{ candidate }}
      </span>
    </div>
    <span class="pagination__nav ml-2" :class="{ disabled: !nextNavigation }" @click="onNextClick">
      <Icon class="pagination__right" icon="DropdownArrow" :options="{ color: nextNavigation ? Colors.primary : Colors.grey300, width: 28, height: 28 }" />
    </span>
  </div>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;

.pagination {
  display: flex;
  align-items: center;

  @include mq.laptop-down() {
    width: 100%;
    justify-content: space-between;
  }
}

.pagination__left {
  transform: rotate(90deg);
}

.pagination__right {
  transform: rotate(-90deg);
}

.pagination__nav {
  display: flex;
  justify-content: center;
  align-items: center;

  &:not(.disabled) {
    cursor: pointer;

    &:hover {
      background-color: colors.$primary--xxlight;
    }
  }
}

.pagination__pages {
  display: flex;
}

.pagination__item {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  order: 3;
  gap: 0.4rem;
  width: 2.8rem;
  height: 2.8rem;

  cursor: pointer;

  &:hover {
    background-color: colors.$primary--xxlight;
    color: colors.$primary--dark;
  }

  &.selected {
    background-color: colors.$primary;
    color: colors.$white;
  }

  &:nth-child(3) {
    order: 1;
  }

  &:last-child {
    order: 5;
  }
}

.pagination__dots {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  line-height: 2.8rem;
  height: 2.8rem;
  width: 2.8rem;

  &--back {
    order: 2;
  }

  &--next {
    order: 4;
  }

  &.hidden {
    display: none;
  }
}
</style>
