<script lang="ts" setup>
import LayoutCentered from '@/features/theme/base/layouts/LayoutCentered.vue';
import { Paragraph } from '@/generated/graphql';
import { onMounted } from 'vue';

interface ParagraphProps {
  paragraph: Paragraph;
}

defineProps<ParagraphProps>();

onMounted(() => {
  if (window.cookiehub && window.cookiehub.renderDeclaration) {
    window.cookiehub.renderDeclaration();
  }
});
</script>
<template>
  <LayoutCentered>
    <div class="cookiehub-declaration"></div>
  </LayoutCentered>
</template>
