<script lang="ts" setup>
import Map from '@/features/map/components/Map.vue';
import LayerVector from '@/features/map/components/layers/LayerVector.vue';
import { LayerZIndex } from '@/features/issues/models/issueMaps';
import SourceVector from '@/features/map/components/sources/SourceVector.vue';
import IssueLayerDraw from '@/features/issues/components/issueMap/IssueLayerDraw.vue';
import { LoftmyndirCapability } from '@/features/loftmyndir/models';
import { computed, ref, shallowRef, watch } from 'vue';
import FeatureType from 'ol/Feature';
import Geometry from 'ol/geom/Geometry';
import { Issue, IssueDetails, MapIssue } from '@/features/issues/models';
import IssueLayerImage from './IssueLayerImage.vue';
import IssueLayerFeatures from './IssueLayerFeatures.vue';
import IssueDrawTool from './IssueDrawTool.vue';
import { DrawTool } from '@/features/issues/models/issueMaps';
import IssueLayerImageDropdown from './IssueLayerImageDropdown.vue';
import IssueLayerCluster from './IssueLayerCluster.vue';
import { Coordinate } from 'ol/coordinate';
import IssueOverlay from './IssueOverlay.vue';
import { Point } from 'ol/geom';
import useCollectionSearching from '@/features/composables/useCollectionSearching';
import OverviewMap from '@/features/map/components/OverviewMap.vue';
import { getWidth as getExtentWidth, getCenter as getExtentCenter } from 'ol/extent';
import { getPointResolution } from 'ol/proj';
import useProjection from '@/features/map/composables/useProjection';
import { default as MapType } from 'ol/Map';
import IssueMapLegend from './IssueMapLegend.vue';
import useIssueFeatures from '../../composables/useIssueFeatures';
import IssueImportTool from './IssueImportTool.vue';
import { FeatureDecorated } from '@/features/map/models/mapModels';
import IssueUndoRedoTool from './IssueUndoRedoTool.vue';
import Feature from 'ol/Feature';
import useMapFeatureStack from '../../composables/useMapFeatureStack';
import FileInputBase64, { Base64FilePayload } from '@/features/files/components/FileInputBase64.vue';
import { useGetIssueQuery } from '@/generated/graphql';
import Icon from '@/features/theme/base/Icon.vue';
import Translate from '@/features/translations/Translate.vue';
import { Colors } from '@/features/theme/base/models/Colors';
import { useI18n } from 'vue-i18n';
import { featureCmpKey } from '../../issueGeometriesBlacklist';
import IssueDeleteTool from '@/features/issues/components/issueMap/IssueDeleteTool.vue';
import useShapeFileFeatures from '@/features/issues/composables/useShapeFileFeatures';

const props = withDefaults(
  defineProps<{
    issue?: Issue | IssueDetails;
    issueId?: string;
    issues?: MapIssue[];
    drawable?: boolean;
    legend?: boolean;
    importable?: boolean;
    expanded?: boolean;
    expandable?: boolean;
    hideable?: boolean;
    deletable?: boolean;
    drawKey?: number;
  }>(),
  {
    drawable: false,
    legend: false,
    importable: false,
    issues: () => [],
    expanded: false,
    expandable: false,
    hideable: false,
    deletable: false,
    issueId: '',
    drawKey: 1,
  }
);

const emits = defineEmits<{
  (e: 'features', features: Feature<Geometry>[]): void;
  (e: 'delete', feature: Feature<Geometry>): void;
  (e: 'select', issueId: string): void;
  (e: 'deselect'): void;
  (e: 'expand', expand: boolean): void;
  (e: 'hide'): void;
}>();

const issueLayerFeaturesRef = ref();

const issueId = computed(() => {
  return props.issueId;
});

const { data: getIssueData } = useGetIssueQuery({
  variables: {
    issueId: issueId,
  },
});

const { findById } = useCollectionSearching();
const { getIssueMarkerFeature } = useIssueFeatures();

const issueDetails = computed(() => {
  if (props.issueId || props.issue?.id) {
    return props.issue || getIssueData.value?.issue;
  }
});

const loftmyndir = ref<Maybe<LoftmyndirCapability>>(LoftmyndirCapability.Grayscale);
const drawTool = ref<DrawTool>();
const freehand = ref<boolean>(false);

const { features, pushFeature, canRedo, canUndo, undoFeature, redoFeature, clearFeatures, deleteFeature } = useMapFeatureStack();
const updateFeatures = () => {
  emits('features', [...features.value, ...extraFeatures.value.map(({ feature }) => feature as Feature<Geometry>)]);
};

const onDrawFeature = (feature: FeatureDecorated<Geometry>) => {
  addFeature(feature);
};

const onUndo = () => {
  undoFeature();
  updateFeatures();
};

const onRedo = () => {
  redoFeature();
  updateFeatures();
};

const addFeature = (feature: FeatureDecorated<Geometry>) => {
  pushFeature(feature);
  updateFeatures();
};

const deselect = () => {
  overlayCoordinates.value = null;
  emits('deselect');
};

const extraFeatures = ref<FeatureDecorated<Geometry>[]>([]);

// Bætir bara við feature í feature stack ef hann er ekki til á máli eða í feature stack
const addUniqueFeature = (feature: FeatureDecorated<Geometry>) => {
  const cmpKey = featureCmpKey(feature);
  if (issueLayerFeaturesRef.value.vectorSourceContainsFeature(feature)) {
    return;
  }

  if (features.value.some(f => featureCmpKey(f) === cmpKey)) {
    return;
  }

  if (!extraFeatures.value.some(f => featureCmpKey(f as FeatureDecorated<Geometry>) === cmpKey)) {
    extraFeatures.value.push(feature);
  }

  updateFeatures();
};

// Fjarlægir feature úr stack og fjarlægir af feature-a af korti sem hanga á málinu
const removeFeatureCompletely = (feature: FeatureDecorated<Geometry>) => {
  if (issueLayerFeaturesRef.value) {
    issueLayerFeaturesRef.value.removeFeatureFromVectorSource(feature);
  }

  const extraFeaturesIndex = extraFeatures.value.findIndex(f => featureCmpKey(f as FeatureDecorated<Geometry>) === featureCmpKey(feature));
  if (extraFeaturesIndex !== -1) {
    extraFeatures.value.splice(extraFeaturesIndex, 1);
  }

  removeFeature(feature);
  updateFeatures();
};

const removeFeature = (feature: FeatureDecorated<Geometry>) => {
  deleteFeature(feature);
  updateFeatures();
};

const overlayCoordinates = ref<Maybe<Coordinate>>();

const onOverlayClose = () => {
  deselect();
};

const onCluster = () => {
  deselect();
};

const onClusterFeature = (feature: FeatureType<Point>) => {
  const featureCoords = feature.getGeometry()!.getCoordinates();
  const issue = findById(props.issues, feature.getId());
  if (issue) {
    emits('select', issue.id.toString());
    overlayCoordinates.value = featureCoords;
  }
};

const localMap = shallowRef<MapType>();
const onMapMounted = (map: MapType) => {
  localMap.value = map;
  calculateMapWidth();
};

const mapReady = computed<boolean>(() => localMap.value !== undefined);

const mapWidth = ref<number>(0);
const mapWidthRounded = computed<number>(() => Math.round(mapWidth.value / 10) * 10);

const calculateMapWidth = () => {
  if (localMap.value) {
    const extent = localMap.value.getView().calculateExtent(localMap.value.getSize());
    const pointsToKm = getPointResolution(useProjection(), 1, getExtentCenter(extent)) / 1000;
    const width = getExtentWidth(extent);
    mapWidth.value = width * pointsToKm;
  }
};

const onMapZoomChange = () => {
  calculateMapWidth();
};

watch(issueDetails, (newValue, oldValue) => {
  if (oldValue !== undefined) {
    const feature = getIssueMarkerFeature(oldValue);
    if (feature) {
      removeFeatureCompletely({ feature });
    }
  }

  if (newValue && newValue.id !== oldValue?.id) {
    const markerGeom = getIssueMarkerFeature(newValue)?.getGeometry();
    if (markerGeom) {
      overlayCoordinates.value = markerGeom.getCoordinates();
    }
  }
  if (newValue?.geographies) {
    if (oldValue && oldValue.geographies) {
      if (oldValue.geographies.features.length !== newValue.geographies.features.length) {
        clearFeatures();
      }
    } else {
      clearFeatures();
    }
  }
});

const onImport = () => {
  const sf = document.getElementById('shapefile');
  if (sf) {
    sf.click();
  }
};

const { serializeShapeFile, shapeFileFeatures } = useShapeFileFeatures(computed(() => issueDetails.value?.currentPhase));
async function onShapefile(files: Base64FilePayload[]) {
  const newShapeFileFeatures = await serializeShapeFile(files);
  for (const feature of newShapeFileFeatures) {
    pushFeature(feature);
  }
  updateFeatures();
}

const onExpandClick = () => {
  emits('expand', !props.expanded);
};

const onHideClick = () => {
  emits('hide');
};

const updateMap = (zoom: number = 4) => {
  localMap.value?.getView().setZoom(zoom);
  localMap.value?.updateSize();
};

const { t } = useI18n();

defineExpose({ deselect, addFeature, updateMap, removeFeature, addUniqueFeature, removeFeatureCompletely });

type ToggleTools = 'draw' | 'delete';

const selectedToggleTool = ref<ToggleTools>();
const selectToggleTool = (tool: ToggleTools | 'none') => {
  if (tool !== 'none') {
    selectedToggleTool.value = tool;
  } else {
    selectedToggleTool.value = undefined;
  }
};
const onToolToggle = (tool: ToggleTools, toggle: boolean) => {
  selectToggleTool(toggle ? tool : 'none');
  if (tool !== 'draw' || !toggle) {
    drawTool.value = undefined;
  }
};

const onPersistentFeatureClick = (feature: Feature<Geometry>) => {
  if (selectedToggleTool.value === 'delete') {
    emits('delete', feature);
    removeFeatureCompletely({ feature });
  }
};

const isDeleteToolEnabled = computed(() => {
  if (!props.deletable) {
    return false;
  }

  if (issueDetails.value?.geographies?.features.length) {
    return issueDetails.value.geographies.features.length > 0;
  }

  return false;
});

const additionalFeatures = computed(() => [...shapeFileFeatures.value, ...extraFeatures.value.map(({ feature }) => feature as FeatureType<Geometry>)]);
</script>
<template>
  <Map
    ref="mapRef"
    class="ol__map"
    @mounted="onMapMounted"
    @change:zoom="onMapZoomChange"
  >
    <div class="top-right">
      <IssueMapLegend v-if="legend" />
      <IssueDeleteTool
        v-if="deletable"
        :disabled="!isDeleteToolEnabled"
        :toggle="selectedToggleTool === 'delete'"
        @update:toggle="v => onToolToggle('delete', v)"
      />
      <IssueUndoRedoTool
        v-if="drawable"
        :undo-enabled="canUndo"
        :redo-enabled="canRedo"
        @undo="onUndo"
        @redo="onRedo"
      />
      <IssueDrawTool
        v-if="drawable"
        v-model="drawTool"
        :toggle="selectedToggleTool === 'draw'"
        @update:toggle="v => onToolToggle('draw', v)"
      />
      <IssueImportTool
        v-if="importable"
        @click="onImport"
      />
      <div
        class="hide-map-tool"
        @click="onHideClick"
        v-if="hideable"
      >
        <Translate t="issue.map.hide" />
        <Icon
          class="icon-right"
          icon="Minimize"
          :options="{ color: Colors.primary }"
        />
      </div>
    </div>
    <div class="bottom-left flex flex-wrap gap-2 map-info-wrapper">
      <IssueLayerImageDropdown v-model="loftmyndir" />
      <div class="map-width px-1 py-1 bold">{{ mapWidthRounded }} km</div>
    </div>
    <div class="center-left">
      <div
        v-if="expandable"
        class="expand-tool"
        :class="{ expanded }"
        @click="onExpandClick"
        v-tooltip="{ content: expanded ? t('issue.map.minimize') : t('issue.map.expand'), theme: 'primary' }"
      >
        <Icon
          class="expand-icon"
          icon="Chevron"
        />
      </div>
    </div>
    <IssueLayerImage :loftmyndir="loftmyndir" />
    <IssueLayerDraw
      :key="drawKey"
      v-if="drawable"
      :draw-type="drawTool"
      :freehand="freehand"
      @feature="onDrawFeature"
    />
    <LayerVector
      @feature-click="onPersistentFeatureClick"
      :options="{ zIndex: LayerZIndex.Top }"
      :key="drawKey"
    >
      <SourceVector>
        <IssueLayerFeatures
          ref="issueLayerFeaturesRef"
          v-if="issueDetails"
          :key="issueDetails.id"
          :issue="issueDetails"
          :additional-features="additionalFeatures"
        />
      </SourceVector>
    </LayerVector>
    <IssueLayerCluster
      v-if="issues"
      :key="issues.length"
      :issues="issues"
      @cluster="onCluster"
      @feature="onClusterFeature"
    />
    <IssueOverlay
      v-if="overlayCoordinates && issues.length > 0 && issueDetails"
      @close="onOverlayClose"
      :position="overlayCoordinates"
      :issue="issueDetails"
    />
    <OverviewMap
      v-if="mapReady"
      :options="{ className: 'ol-overviewmap ol-custom-overviewmap', collapsed: false, collapsible: false }"
    >
      <IssueLayerImage :loftmyndir="loftmyndir" />
    </OverviewMap>
    <span class="shapefile_input">
      <FileInputBase64
        id="shapefile"
        class="shapefile_input"
        @files="onShapefile"
        label="issue.files.shapefile.upload"
      />
    </span>
  </Map>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/media-queries' as mq;

.top-right {
  display: flex;
  position: absolute;
  top: 1rem;
  right: 4.8rem;
  gap: 1rem;

  @include mq.laptop-down() {
    right: 2rem;
  }
}

.top-left {
  position: absolute;
  top: 1rem;
  left: 4.8rem;
  min-width: 200px;

  @include mq.laptop-down() {
    left: 2rem;
  }
}

.center-left {
  position: absolute;
  top: 50%;
}

.expand-tool {
  height: 8.8rem;
  margin-top: -4.4rem;
  display: flex;
  align-items: center;
  padding-right: 0.4rem;
  padding-left: 0.4rem;
  background: colors.$white;
  cursor: pointer;
  box-shadow: 0px 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  border-radius: 0px 0.2rem 0.2rem 0.2rem;

  &.expanded {
    .expand-icon {
      transform: rotate(180deg);
    }
  }
}

.hide-map-tool {
  display: flex;
  align-items: center;
  background: colors.$white;
  padding: 1.8rem 1.6rem;
  box-shadow: 0px 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  border-radius: 0px 0.2rem 0.2rem 0.2rem;
  text-transform: uppercase;
  cursor: pointer;
}

.bottom-left {
  position: absolute;
  bottom: 1rem;
  left: 2.2rem;
  align-items: flex-end;

  @include mq.laptop-down() {
    flex-direction: column-reverse;
    align-items: flex-start;
    left: 2rem;
  }
}

.map-width {
  display: flex;
  justify-content: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) -33.87%, #ffffff 69.35%);
  border: 2px solid colors.$primary;
  border-top: none;
  border-radius: 2px;
  font-size: 1.4rem;
  min-width: 9rem;
}

.shapefile_input {
  display: none;
}

.map-info-wrapper {
  @include mq.laptop() {
    max-width: 50%;
  }
}
</style>
