<script setup lang="ts">
import { computed } from 'vue';
import { useGetAcitveIssuesCountQuery } from '@/generated/graphql';
import Translate from '../translations/Translate.vue';

const { data } = useGetAcitveIssuesCountQuery();

const nCases = computed(() => data.value?.activeIssuesCount ?? 0);
</script>

<template>
  <div class="flex flex-column bg-primary--xlight num-of-issues-container">
    <div class="header-container">
      <h1>
        <span class="cases-num">{{ nCases }}</span>
        <span class="cases-text"><Translate t="frontpage.num_of_issues.title" /></span>
      </h1>
    </div>
    <div class="paragraphs-container">
      <p class="subtitle cases-paragraph">
        <Translate t="frontpage.num_of_issues.content.1" />
      </p>
      <p class="subtitle cases-paragraph">
        <Translate t="frontpage.num_of_issues.content.2" />
      </p>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;
@use '@/scss/design-tokens/fonts' as font;
@use '@/scss/design-tokens/media-queries' as media-queries;

.num-of-issues-container {
  padding-bottom: 15rem;
  padding-top: 20rem;

  @include media-queries.laptop-down {
    padding: 15rem 0rem;
  }

  @include media-queries.mobile {
    padding: 6rem 2rem;
  }
}
.header-container {
  display: flex;
  align-items: baseline;
  margin: 0 auto 2rem;
  // width: 51%;
  padding-bottom: 2rem;

  @include media-queries.mobile {
    width: 100%;
    padding-top: 4rem;
  }
}

.cases-num {
  font-size: 20rem;
  @include font.font-default-bold;
  background-image: url('@/assets/background.png');
  background-size: cover;
  background-clip: text;
  background-repeat: repeat-x;
  -webkit-background-clip: text;
  color: transparent;
  @include media-queries.laptop-down {
    font-size: 14rem;
  }

  @include media-queries.mobile {
    font-size: 11rem;
  }
}

.cases-text {
  @include font.font-default-bold;
  font-size: 8rem;
  color: colors.$primary--dark;
  @include media-queries.laptop-down {
    font-size: 4rem;
  }
}

.cases-paragraph {
  @include font.font-default-regular;
}

.paragraphs-container {
  display: flex;
  gap: 4rem;
  flex-direction: column;
  margin: auto;
  width: 50%;

  @include media-queries.mobile {
    width: 100%;
  }
}
</style>
