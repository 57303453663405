<script lang="ts" setup>
import { computed } from 'vue';
import { FileType } from '../base/models/ButtonType';
import { Colors } from '../base/models/Colors';

const props = withDefaults(
  defineProps<{
    width?: number;
    height?: number;
    color?: Colors;
    background?: Colors;
    fileType?: FileType;
  }>(),
  {
    fileType: FileType.doc,
    width: 48,
    height: 48,
  }
);

const style = computed(() => ({
  width: props.width,
  height: props.height,
  color: props.color,
  backgroundColor: props.background,
}));
</script>
<template>
  <span :style="style" :class="`file file-${fileType} bold flex flex-center`">{{ fileType }}</span>
</template>
<style lang="scss" scoped>
@use '@/scss/design-tokens/colors' as colors;

.file {
  display: flex;
  justify-content: center;
  align-items: center;

  &-PDF {
    color: colors.$red;
  }

  &-DOC {
    color: colors.$blue--dark;
  }

  &-MP4 {
    color: colors.$purple;
  }

  &-PPT {
    color: colors.$purple--dark;
  }

  &-XLW {
    color: colors.$green--dark;
  }

  &-TXT {
    color: colors.$black;
  }

  &-SHP {
    color: colors.$primary--dark;
  }
}
</style>
